import React, { Component }
  from "react";
import CATEGORIES
  from '../../../constants/categories'

class CategoryItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: CATEGORIES,
      selectedCategory: props.selectedCategories,
      selectedColor: "#ff5f00",
      noSelectedColor: "#7a7a7a",
      showMore: false
    };
  }

  renderCategories() {
    let { categories, selectedCategory, showMore } = this.state
    let { translate } = this.props
    let displayCategories = !showMore ? categories.slice(0, 6) : categories

    return (
      displayCategories.map((category) => {
        return (
          <div key={category.Name} onClick={(e, categoryId) => this.setCategories(category.Value)} className={`filters-category-item ${selectedCategory.indexOf(category.Value) !== -1 ? 'active' : ''}`}>
              <div className="filters-category-icon" >
                  <category.image width="46px" height="46px" color={selectedCategory.indexOf(category.Value) !== -1 ? "#ff5f00" : "#7a7a7a"}/>
              </div>
              <div className="filters-category-title">
                  <span>{ translate(`app.categories.${category.Name}`) }</span>
              </div>
          </div>
        )
      })
    )
  }

  setCategories(itemIndex) {
    if(itemIndex === -1) {
      if(this.state.selectedCategory.indexOf(-1) !== -1) {
        this.state.selectedCategory.splice(-1, 1)
      } else {
        this.setState({selectedCategory: [-1]})
        this.props.changeCategories([-1])
      }
    } else {
      if(this.state.selectedCategory.indexOf(itemIndex) !== -1) {
        this.setState({
          selectedCategory: this.state.selectedCategory.filter(function(value) {
            return value !== itemIndex
          }),
        })
        this.props.changeCategories(this.state.selectedCategory.filter(function(value) {
          return value !== itemIndex
        }))
      } else {
        if(this.state.selectedCategory.indexOf(-1) !== -1) {
          this.state.selectedCategory.splice(-1, 1)
        }
        this.setState({
          selectedCategory: [...this.state.selectedCategory, itemIndex],
        })
        this.props.changeCategories([...this.state.selectedCategory, itemIndex])
      }
    }
  }

  render() {
    let { translate } = this.props

    return (
      <div className="row">
          <div className="filters-category">
              <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="filters-category-head">
                      <div className="filters-category-head-title">
                          <h1>{ translate('app.filtersInputs.category') }</h1>
                      </div>
                      <div className="filters-category-head-more" >
                          <a href={undefined}  onClick={() => this.setState({showMore: !this.state.showMore})} >{ this.state.showMore ? translate('app.filtersInputs.show_less') : translate('app.filtersInputs.show_more') }</a>
                      </div>
                  </div>
              </div>
              <div className="filters-category-items-wrap">
                {this.renderCategories()}
              </div>
          </div>
      </div>
    );
  }
}

export default CategoryItems
