
  import api
    from '../helpers/api'

  import qs from 'qs';

  import { getLanguage }
    from "../services/token";

  const BASE_URL = 'https://app.tendoo.com/api';
  // const BASE_URL = 'https://nesolutendoowebtest20191003073447.azurewebsites.net/api/';

  export function fetchCookbooks(token, addCookBooks, handleError=null) {
    fetch(`${BASE_URL}/book?limit=5&offset=0&LangId=${getLanguage}`, {
      headers: new Headers({
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    })
      .then(response => response.json())
      .then((data) => {
        addCookBooks(data)
      })
      .catch(err => {
        handleError && handleError()
        console.log("FETCH COOKBOOKS ERROR", err)
      })
  }

  export function fetchSingleCookbook(token, cookBookId, saveCookBookData, handleError=null) {
    fetch(`${BASE_URL}/book/${cookBookId}?LangId=${getLanguage}`, {
      headers: new Headers({
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    })
    .then(response => response.json())
    .then((data) => {
      saveCookBookData(data)
    })
    .catch(err => {
      handleError && handleError()
    })
  }

  export function fetchSingleRecipe(token, recipeId, saveRecipeData, handleError=null) {
    fetch(`${BASE_URL}/recipe/${recipeId}?LangId=${getLanguage}`, {
      headers: new Headers({
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    })
      .then(response => response.json())
      .then((data) => {
        if(data.Message === "The request is invalid.") {
          window.location.href = '/404';
        }
        saveRecipeData(data)
      })
      .catch(err => {
        handleError && handleError()
      })
  }

  export function fetchRecipes(token, limit, offset, addRecipes) {
    return async function(){
    fetch(`${BASE_URL}/recipe?limit=${limit}&offset=${offset}&LangId=${getLanguage}`, {
      headers: new Headers({
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    })
    .then(response => response.json())
    .then((data) => {
      addRecipes(data)
    })
    .catch(err => {
    //   removeToken()
      console.log("FETCH RECIPES ERROR", err)
    })
    }
  }

  export function fetchRecipesWithTags(tagName, addTagsRecipes) {
    return async function(){
      let url = `${BASE_URL}/recipe/tag?Tag=${tagName}&LangId=${getLanguage}`

      api.get(url)
      .then((data) => {
        addTagsRecipes(data)
      })
      .catch(err => {
      //   removeToken()
        console.log("FETCH RECIPES WITH TAGS ERROR", err)
      })
    }
  }

  export function recipeIngridients(token, recipeId, saveIngridientsData) {
    fetch(`${BASE_URL}/recipe/${recipeId}/ingredient?LangId=${getLanguage}`, {
      headers: new Headers({
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    })
      .then(response => response.json())
      .then((data) => {
        saveIngridientsData(data)
      })
      .catch(err => {
        console.log("RECIPE INGREDIENTS ERROR", err)
      })
  }

  export function fetchComments(token, recipeId, saveComments) {
    fetch(`${BASE_URL}/recipe/${recipeId}/comment?LangId=${getLanguage}`, {
      headers: new Headers({
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    })
      .then(response => response.json())
      .then((data) => {
        saveComments(data)
      })
      .catch(err => {
        console.log("FETCH COMMENTS ERROR", err)
      })
  }

  export function createComment(token, recipeId, comment, addComment) {
      let data = {
        "text": comment,
      }

      const url = `${BASE_URL}/recipe/${recipeId}/comment?LangId=${getLanguage}`

      api.post(url, qs.stringify(data))
       .then((response) => {
         addComment(response)
       })
       .catch((err) => {
         console.log("CREATE COMMENT ERROR", err.response.data)
       })
  }

  export function rateRecipe(token, recipeId, rate, changeRate) {
      let opts = ["VeryBad", "Bad", "Ok", "Good", "VeryGood"]

      let data = {
        "RatingValue": opts[rate-1],
      }

      const url = `${BASE_URL}/recipe/${recipeId}/rate`

      api.post(url, qs.stringify(data))
       .then((response) => {
         changeRate(response)
       })
       .catch((err) => {
         console.log("RATE RECIPE ERROR", err.response.data)
       })
  }


  export function fetchHowTo(recipeID, howToData) {
    api.get(`/recipe/${recipeID}/howto?LangId=${getLanguage}`)
      .then(response => {
        howToData(response)
      })
      .catch((err) => {
        console.log("FETCH HOW TO ERROR", err)
      })
  }

  export function likeComment(recipeID, commentID) {
    api.patch(`/recipe/${recipeID}/comment/${commentID}/like`)
      .then(response => {
      })
      .catch((err) => {
        console.log("LIKE COMMENT ERROR", err)
      })
  }

  export function unlikeComment(recipeID, commentID) {
    api.patch(`/recipe/${recipeID}/comment/${commentID}/dislike`)
      .then(response => {
      })
      .catch((err) => {
        console.log("UNLIKE COMMENT ERROR", err)
      })
  }
