import React
  from 'react'
import { Link }
  from "react-router-dom";
import RecipeCard
  from './RecipeCard'
import { fetchRecipes }
  from '../../actions/recipes'
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';

class Recipes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      loading:false,
      recipeLoading:false,
      limit: 20,
      token:'',
      items:[],
      currentHoverRecipe: null
    };
  }

  async UNSAFE_componentWillMount(){
    this.fetchRecords()
  }

  addRecipes(data){
    this.setState({
      loading: false,
      offset: this.state.offset + 20,
    //   items: [...this.state.items, ...data ]
    }, ()=> this.setState({recipeLoading:false}))
  }

  fetchRecords() {
    // this.setState({loading: true})
    let { limit, offset } = this.state;
    this.props.fetchRecipes(limit, offset, this.addRecipes.bind(this))
  }

  changeCurrentHoverRecipe(itemId) {
    let { currentHoverRecipe } = this.state

    if(currentHoverRecipe != itemId) {
      this.setState({currentHoverRecipe: itemId})
    }
  }

  renderRecipes(){
    let { translate } = this.props
    let recipes = this.props.recipes.slice(0,9)
    return recipes.map((item) => {
      return(
        <RecipeCard
          aid={item.Author && item.Author.Id}
          item={item}
          key={item.Id}
          id={item.Id}
          videos={item.Videos}
          source={item.CoverPath}
          heading={item.Name}
          time={item.PrepTime}
          paragraph={translate('app.explorePage.by') + " " + (item.Author && item.Author.Name)}
          currentHoverRecipe={this.state.currentHoverRecipe}
          changeCurrentHoverRecipe={(e, itemId) => this.changeCurrentHoverRecipe(itemId)}
        />
      )
    })
  }

  render(){
    let { translate } = this.props
    return(
        <section className="recipes section-top-120">
        <div className="container">
            <div className="main-title">
                <h1>{ translate('app.explorePage.recipes') }</h1>
            </div>
            <div className="row">
                {this.renderRecipes()}
                <div className="col-md-12">
                    <div className="gen-link-more mg-top-15">
                        <Link className="more effect-underline"
                        to={`/recipes`}>{ translate('app.explorePage.view_all_recipes') }</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

function mapStateToProps(state){
  return ({
            recipes: state.recipes,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps,
  { fetchRecipes })(Recipes);
