import React from 'react';
import { connect }
  from 'react-redux';
import { changeNumberOfPeople }
  from '../../../actions/recipes';

class HowManyModal extends React.Component {
  state = {
    numOfPeople: 2,
  }

  componentDidMount() {
    window.inputnumber()
  }

  goToVideoSteps(e) {
    e.stopPropagation();
    let _this = this
    if(this.props.fromCarousel) {
      this.props.setVideoParams()
      setTimeout(function(){
        window.$('#video-plp').modal('toggle');
        window.$('#video').modal('toggle');
      }, 400);
    } else {
      this.props.setVideoParams()
      this.props.setStepByStepVideo()
      window.$('#video-plp').modal('toggle');
    }
  }

  incrementNumberOfPeople() {
    this.props.incrementNumberOfPeople()
    this.props.changeNumberOfPeople(this.props.numOfPeople + 1)
  }

  decrementNumberOfPeople() {
    this.props.decrementNumberOfPeople()
    this.props.changeNumberOfPeople(this.props.numOfPeople - 1)
  }

  render() {
    let { translate } = this.props
    return(
      <div className="modal blk-modal fade" id="video-plp" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <div className="blk-modal-content">
                <h1>{translate('app.recipeDetails.how_many_people')}</h1>
                <div className="quantity-input">
                  <form>
                    <div className="number-input">
                      <span
                        className="change minus"
                        onClick={() => this.decrementNumberOfPeople()}
                        >
                        -
                      </span>
                      <input
                        type="text"
                        className="input-box"
                        min="1"
                        value={this.props.numOfPeople}

                        readOnly
                      />
                      <span
                        className="change plus"
                        onClick={() => this.incrementNumberOfPeople()}
                        >
                        +
                      </span>
                    </div>
                  </form>
                </div>
                <div className="blk-button">
                  <a href={undefined}  onClick={(e) => this.goToVideoSteps(e)} className="button" >{translate('app.recipeDetails.step_to_step')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null,{ changeNumberOfPeople })( HowManyModal );
