import React
    from 'react'

import { Link }
    from "react-router-dom"

const BlogItem = (props) => {
    return(
        <div className="col-sm-6 col-md-4">
            <Link to={`/blog/:id`}>
                <div className="blog-item">
                    <div className="blog-img">
                        <img src={props.source} alt="blog-1" className="img-responsive" />
                    </div>
                    <div className="blog-content">
                        <h1 className="blog-title">{props.title}</h1>
                        <p className="blog-text">{props.text}</p>
                    </div>
                    <div className="blog-info">
                        <span className="blog-date">{props.date}</span>
                        <span className="blog-author">{props.author}</span>
                    </div>
                </div>
            </Link>
        </div>
    )
  }

  export default BlogItem
