import {
  LOG_OUT,
  BASE_URL,
  CURRENT_USER
} from '../constants';

import axios
  from 'axios';

import api
  from '../helpers/api';

import { storeToken, removeToken }
  from '../services/token';

import qs
  from 'qs';

export function signIn (username, password, afterLogin, catchedErrors) {
  return function(dispatch) {
    let data = {
      "grant_type": "password",
      "username": username,
      "password": password,
      "client_id": "O5e42JOKkAY12e1M",
      "scope": "App"
    }
    const url = `${BASE_URL}/auth/`

    api.post(url, qs.stringify(data))
      .then(data => {
        if(data) {
          storeToken(data.access_token)
          afterLogin(data.access_token)
        } else {
          alert("Data are null")
        }
      })
      .catch(err => {
        console.log("SIGN IN ERROR", err)
        catchedErrors(err.data)
      })
  }
}



export function signUp (firstname, lastname, email, password, countryName, afterRegister, catchedErrors) {


  return function(dispatch) {
    let data = {
      "firstname": firstname,
      "lastname": lastname,
      "email": email,
      "password": password,
      "language": countryName === ("Germany" || "Switzerland" || "Austria") ? 0 : 1
    }
    const url = `${BASE_URL}/signup`;

    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url,
    };
    axios(options)
    .then(response => {
      afterRegister(response)
    })
    .catch(error => {
      console.log("SIGN UP ERROR: ", error.response.data)
      catchedErrors(error.response.data)
    })
  }
}

export function signInWithSocialMedia(
  fb_token,
  afterLogin,
  catchedErrors,
  provider='Facebook'
) {
  return function() {
    let data = {
    	"Provider": provider,
    	"Token": fb_token,
    }

    const url = `${BASE_URL}/signup/externalauth`;

    axios.post(url, data)
    .then(result => {
      if(result) {
        storeToken(result.data.access_token)
        afterLogin(result.data.access_token)
      } else {
        alert("Response is null")
      }
    })
    .catch(error => {
      console.log("SOCIAL LOGIN ERROR", error)
      catchedErrors(error)
    })
  }
}

export function forgotPasswordRequest(email, afterRequest, catchedErrors) {
  return function(dispatch) {
    let data = {
    	"email": email,
    }
    const url = `${BASE_URL}/signup/passwordreset`;

    api.post(url, qs.stringify(data))
      .then(response => {
        afterRequest()
      })
      .catch(error => {
        console.log("FORGOT PASSWORD ERROR: ", error.data)
        catchedErrors(error.data)
      })
  }
}


export function userSignedIn(token) {
  return function(dispatch) {
    const url = `${BASE_URL}/me/`

    api.get(url)
      .then(data => {
        dispatch({
          type: CURRENT_USER,
          payload: data
        })
      })
      .catch(error => {
        removeTokenAndLogout(dispatch)
        console.log("USER SIGN IN ERROR", error.response.data)
      })
  }
}


export function current_user(userArgs) {
  return (dispatch) => {
    dispatch({
      type: CURRENT_USER,
      payload: userArgs
    })
  }
}

export function logOut(afterLogOut) {
  return (dispatch) => {
    dispatch({
      type: LOG_OUT,
    })

    afterLogOut && afterLogOut()
  }
}

function removeTokenAndLogout(dispatch){
  removeToken()

  dispatch({
    type: LOG_OUT,
  })
}
