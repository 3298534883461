import React
    from 'react'

const ArrowDownIcon = (props) => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.plusColor ? props.plusColor : "#7A7A7A"}
        fillRule="nonzero"
        d="M4.681 1.647a3.333 3.333 0 0 0-3.033 3.034A84.89 84.89 0 0 0 1.333 12c0 2.456.105 4.895.315 7.32a3.333 3.333 0 0 0 3.033 3.033 84.87 84.87 0 0 0 7.319.314 84.87 84.87 0 0 0 7.319-.314 3.333 3.333 0 0 0 3.033-3.034A84.89 84.89 0 0 0 22.667 12a84.89 84.89 0 0 0-.315-7.319 3.333 3.333 0 0 0-3.033-3.034A84.88 84.88 0 0 0 12 1.333a84.88 84.88 0 0 0-7.319.314zM4.567.32A86.213 86.213 0 0 1 12 0c2.497 0 4.975.106 7.433.319a4.667 4.667 0 0 1 4.248 4.247C23.894 7.028 24 9.506 24 12c0 2.494-.106 4.972-.32 7.434a4.667 4.667 0 0 1-4.247 4.247A86.204 86.204 0 0 1 12 24c-2.5 0-4.978-.106-7.433-.319a4.667 4.667 0 0 1-4.248-4.247A86.224 86.224 0 0 1 0 12c0-2.494.106-4.972.32-7.434A4.667 4.667 0 0 1 4.566.32zM11.333 18V6c0-.889 1.334-.889 1.334 0v12c0 .889-1.334.889-1.334 0zM18 12.667H6c-.889 0-.889-1.334 0-1.334h12c.889 0 .889 1.334 0 1.334z"
      />
    </svg>
  )
}

export default ArrowDownIcon
