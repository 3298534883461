import React
  from "react";
import { SuccessMessageIcon }
  from '../../assets/icons/AppIcons'
import SvgImage
  from '../../components/shared/SvgImage';
import $ 
  from "jquery"
  
class SuccessMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }

  componentDidMount() {
    setTimeout(function() {
      $('#success-message-id').fadeOut("slow");
    }, 4000);
  }

  render() {
    return (
      <div className={`message-alert`} id="success-message-id">
        <div className="message-alert-overlay"></div>
        <div className="message-alert-content">
          <SvgImage
            svg={SuccessMessageIcon}
            style={{width: 22}}
          />
          <div className="message-alert-content-inner">
            <h1>{this.props.titleSuccess}</h1>
            <p>{this.props.descriptionSuccess}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessMessage;
