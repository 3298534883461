import React
  from 'react';
import RecipeTableItem
  from '../../../components/recipes/RecipeTableItem'
import { getToken }
  from "../../../services/token";
import { recipeIngridients }
  from '../../../helpers/recipe';
import { addRecipeToShopping }
  from '../../../actions/shopping'
import { connect }
  from 'react-redux';

class IngredientsModal extends React.Component {
  state = {
    ingridients: [],
    fetched: false,
    ingredientsNumber:5,
    numOfPeople: this.props.numOfPeople,
  }

  componentDidMount(){
    if(this.props.recipeId) {
      recipeIngridients(getToken, this.props.recipeId, this.saveIngridientsData.bind(this))
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.recipeId !== newProps.recipeId) {
      recipeIngridients(getToken, newProps.recipeId, this.saveIngridientsData.bind(this))
    }

    if(this.props.numOfPeople !== newProps.numOfPeople) {
      this.setState({numOfPeople: newProps.numOfPeople})
    }
  }

  saveIngridientsData(data){
    if(Array.isArray(data)){
      this.setState({
        ingridients: data,
        fetched: true
      })
    }
  }

  renderIngredients(){
    let { ingridients } = this.state
    if(ingridients) {
      return ingridients.map((item)=>{
        return <RecipeTableItem
          key={item.Ingredient.Name}
          ingridient={item}
          numOfPeople={this.state.numOfPeople}
        />
      })
    }
  }

  addToShoppingList(){
    this.props.addRecipeToShopping(this.props.recipeId, this.props.numOfPeople)
  }

  render() {
    let { translate } = this.props
    return(
      <div id="shopingIngredients" className="modal shop-modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="close"
                type="button"
                data-dismiss="modal">
                ×
              </button>
              <h1 className="title">{translate('app.recipeDetails.ingredients')}</h1>
            </div>

            <div className="modal-pre-body">
              <div className="ingredients-quantity">
                <h1>{translate('app.recipeDetails.how_many_people')}</h1>

                <div className="quantity-input">
                  <form>
                    <div className="number-input">
                      <span
                        className="change minus"
                        onClick={() => this.setState({numOfPeople: this.state.numOfPeople === 1 ? this.state.numOfPeople : this.state.numOfPeople - 1})}
                      >-
                      </span>

                      <input
                        className="input-box"
                        min="0"
                        type="text"
                        readOnly
                        value={this.state.numOfPeople}
                      />
                      <span
                        className="change plus"
                        onClick={() => this.setState({numOfPeople: this.state.numOfPeople + 1})}
                      >+
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="modal-body">
              <div className="recipes-extra-table mg-bottom-15">
                <table>
                  <tbody>
                    {this.renderIngredients()}
                  </tbody>
                </table>
              </div>

              <div className="add-shoping-list">
                <a
                  href={null}
                  className="button"
                  onClick={() => this.addToShoppingList()}>
                  {translate('app.recipeDetails.add_to_shopping_list')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { addRecipeToShopping })(IngredientsModal)
