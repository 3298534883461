export const LOG_OUT = 'LOG_OUT'
export const CURRENT_USER = 'CURRENT_USER'
export const FAVOURITE_RECIPE = 'FAVOURITE_RECIPE'
export const FAVOURITE_COOKBOOK = 'FAVOURITE_COOKBOOK'
export const ADD_COOKBOOK = 'ADD_COOKBOOK'
export const REMOVE_COOKBOOK = 'REMOVE_COOKBOOK'
export const ADD_RECIPE = 'ADD_RECIPE'
export const REMOVE_RECIPE = 'REMOVE_RECIPE'
export const LOADING = 'LOADING'
export const CLOSE_LOADING = 'CLOSE_LOADING'

export const BASE_URL = 'https://app.tendoo.com/api';
export const API = 'https://app.tendoo.com';
// export const BASE_URL = 'https://nesolutendoowebtest20191003073447.azurewebsites.net/api/';
// export const API = 'https://nesolutendoowebtest20191003073447.azurewebsites.net';
export const FETCH_RECIPES = 'FETCH_RECIPES'
export const FETCH_COOKBOOKS = 'FETCH_COOKBOOKS'
export const FETCH_SLIDER_COOKBOOKS = 'FETCH_SLIDER_COOKBOOKS'
export const FETCH_LATEST_COOKBOOKS = 'FETCH_LATEST_COOKBOOKS'
export const FETCH_SHOPPING_RECIPES = 'FETCH_SHOPPING_RECIPES'
export const FETCH_SHOPPING_DEP = 'FETCH_SHOPPING_DEP'
export const REMOVE_SHOPPING_DEP = 'REMOVE_SHOPPING_DEP'
export const REMOVE_SHOPPING_RECIPE = 'REMOVE_SHOPPING_RECIPE'
export const ADD_RECIPE_TO_SHOPPING_LIST = 'ADD_RECIPE_TO_SHOPPING_LIST'
export const FETCH_TOOLS = 'FETCH_TOOLS'
export const HIDE_TAB_BAR = 'HIDE_TAB_BAR'
export const SHOW_TAB_BAR = 'SHOW_TAB_BAR'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const FETCH_TAGS = 'FETCH_TAGS'

export const HIDE_OVERLAY = 'HIDE_OVERLAY'
export const SHOW_OVERLAY = 'SHOW_OVERLAY'
export const CHANGE_FILTERS = 'CHANGE_FILTERS'
export const RATE_RECIPE = 'RATE_RECIPE'
export const CURRENT_PATH = "CURRENT_PATH"
export const REFRESH_CURRENT_PAGE = "REFRESH_CURRENT_PAGE"
export const NUMBER_OF_PEOPLE = "NUMBER_OF_PEOPLE"
