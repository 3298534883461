import { BASE_URL }
  from '../constants/types'

import qs
  from 'qs';

import api
  from './api'

import { getLanguage }
  from "../services/token";

export async function getShoppingNote(fetchNotes) {
  let url = `${BASE_URL}/shopping/note?LangId=${getLanguage}`

  api.get(url)
    .then((data) => {
      fetchNotes(data)
    })
    .catch(err => {
      console.log("GET SHOPPING NOTE ERROR", err)
    })
}

export async function addShoppingNote(name, fetchNote) {
  let url = `${BASE_URL}/shopping/note`

  let data = { "Name": name }

  api.post(url, qs.stringify(data))
    .then((data) => {
      fetchNote(data)
    })
    .catch(err => {
      console.log("ADD SHOPPING NOTE ERROR", err)
    })
}

export async function removeShoppingNote(id) {
  let url = `${BASE_URL}/shopping/note/${id}`

  api.delete(url)
    .then((data) => {
    })
    .catch(err => {
      console.log("REMOVE SHOPPING NOTE ERROR", err)
    })
}
