import {
    REFRESH_CURRENT_PAGE,
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case REFRESH_CURRENT_PAGE:
       return action.payload
      default:
       return state
    }
  }
