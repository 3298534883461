import React
  from 'react';
import ReactLoading
  from 'react-loading';
import RenderSearch
  from '../../../components/shared/RenderSearch'
import SearchNotFound
  from '../SearchNotFound'

class AuthorItems extends React.Component {
  displayAuthors() {
    let { authors } = this.props
    if(authors.length !== 0) {
      return (
        authors.map((item)=> {
         return (
           <RenderSearch
             name={item.Name}
             source={item.ImagePath ? item.ImagePath : ''}
             id={item.Id}
             key={item.Id}
             location={'authors'}
           />
          )
        })
     )
    } else {
      return (
        this.noResults()
      )
    }
  }

	render() {
    let { title } = this.props
    if(this.props.loading) {
      return (
        <ReactLoading
          type={"spokes"}
          color={"#000"}
          height={18} width={18}
        />
      )
    } else {
      return (
        <>
          <div className="search-result-title">
            <h1>{title}</h1>
          </div>
          {this.displayAuthors()}
        </>
      )
    }
  }

  noResults(){
    return (
      <SearchNotFound />
    )
  }
}

export default AuthorItems
