import api
  from './api'
import { getLanguage }
  from "../services/token";

export const BASE_URL = 'https://app.tendoo.com/api';
// const BASE_URL = 'https://nesolutendoowebtest20191003073447.azurewebsites.net/api/';

export async function fetchCookbook(cookbookId, addCookBook) {
  let url = `${BASE_URL}/book/${cookbookId}?LangId=${getLanguage}`
   api.get(url)
    .then((data) => {
      addCookBook(data)
    })
    .catch(err => {
      if(err.data.Message === "The request is invalid.") {
        window.location.href = '/404';
      }
      console.log("FETCH COOKBOOK ERROR", err)
    })
}

export async function fetchFavouriteCookbooks(addCookbooks) {
  let url = `${BASE_URL}/book?Favorized=true&LangId=${getLanguage}`
   api.get(url)
    .then((data, token) => {
      addCookbooks(data, token)
    })
    .catch(err => {
      console.log("FETCH FAVOURITE COOKBOOKS ERROR", err)
    })
}

export function fetchDishPart(bookId, dishId, addDishes){
  let url = `${BASE_URL}/recipe?BookId=${bookId}&LangId=${getLanguage}`

  url = dishId ? url + `&DishPartitionId=${dishId}` : url

   api.get(url)
    .then((data) => {
      addDishes(data)
    })
    .catch(err => {
      console.log("FETCH DISH PART ERROR", err)
    })
}

export function fetchCookbooksCount(addCookbookCount) {
  return function(dispatch) {
    const url = `${BASE_URL}/book/count`

    api.get(url)
     .then((response) => {
       addCookbookCount(response)
     })
     .catch((err) => {
       console.log("FETCH COOKBOOKS COUNTE ERROR", err.response)
     })
  }
}

export function fetchCookbookOfMonth(getCookbookMonth){
  let url = `${BASE_URL}/slider/month?LangId=${getLanguage}`
  // let url = `${BASE_URL}/slider/573c7b17-a5e1-40d3-8dbd-054e5ae892de?LangId=${getLanguage}`
   api.get(url)
    .then((data) => {
      getCookbookMonth(data)
    })
    .catch(err => {
      console.log("FETCH RECIPES FOR COOKBOOK ERROR", err)
    })
}

export function fetchRecipesForCookbook(bookId, getData){
  let url = `${BASE_URL}/recipe?BookId=${bookId}&LangId=${getLanguage}`
   api.get(url)
    .then((data) => {
      getData(data)
    })
    .catch(err => {
      console.log("FETCH RECIPES FOR COOKBOOK ERROR", err)
    })
}
