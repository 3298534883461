import React from 'react';

const Dessert = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
      <path d="M861 1159 c-25 -11 -51 -25 -59 -31 -7 -7 -35 -11 -60 -9 l-47 2
      -226 -228 c-124 -125 -240 -248 -257 -272 l-32 -44 0 -163 c0 -134 3 -165 16
      -178 8 -9 22 -16 30 -16 21 0 966 301 982 313 9 7 12 56 12 185 l0 175 -39 37
      c-39 37 -209 127 -286 150 -61 19 -63 27 -12 50 26 12 47 27 47 35 0 20 -16
      18 -69 -6z m-116 -93 c-9 -13 -29 -26 -46 -30 -42 -8 -86 -55 -95 -100 -27
      -146 148 -231 245 -120 26 30 31 43 31 89 0 44 -5 59 -27 86 -15 17 -40 37
      -56 44 -25 10 -26 13 -12 25 13 11 25 10 73 -5 79 -24 229 -96 280 -134 61
      -45 57 -66 -20 -89 -35 -10 -249 -77 -476 -147 -226 -71 -415 -125 -418 -122
      -13 12 29 60 258 292 173 177 237 235 255 235 22 0 23 -1 8 -24z m67 -85 c28
      -25 33 -36 33 -76 0 -40 -5 -51 -33 -76 -81 -72 -198 2 -170 108 19 74 110 97
      170 44z m368 -221 c0 -27 -2 -50 -5 -50 -3 0 -217 -67 -475 -150 -258 -82
      -472 -150 -475 -150 -3 0 -5 24 -5 53 l0 54 268 83 c147 46 357 111 467 146
      110 34 206 63 213 63 8 1 12 -15 12 -49z m0 -147 l0 -58 -468 -148 c-257 -81
      -473 -148 -479 -147 -9 0 -13 18 -13 55 l0 54 233 75 c127 41 336 108 462 149
      127 41 238 76 248 76 14 1 17 -8 17 -56z"/>
      </g>
    </svg>
  )
}

export default Dessert
