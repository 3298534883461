import {
  FETCH_SLIDER_COOKBOOKS
} from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case FETCH_SLIDER_COOKBOOKS:
      return [ ...state, ...action.payload ]
    default:
      return state
  }
}
