import React
  from "react";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }



  render() {
     return (
       <div className="row">
          <section className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="page-content-inner">
                            <h1>Privacy Policy</h1>
                                <h2>Privacy policy and user data</h2>
                                <p>Last updated: 2. November 2019<br/>
                                    The mobile application and website of Tendoo is provided by:<br/>
                                    Tendoo AG company<br/>
                                    Birkenstrasse 47, 6343 Rotkreuz<br/>
                                    Switzerland.
                                    <br/><br/>
                                    Tendoo AG as well mentioned as “we” or “us” hereinafter. Tendoo cares about your privacy.
                                    You can read how certain information is used and shared through our Services as described
                                    below. In short, we don’t sell any personal data. If you have any concerns or questions
                                    please let us know by E-Mail contact (at) tendoo.com
                                </p>

                                <h2>Personal Information You Provide to Us</h2>
                                <p>We receive and store information you enter on our Services or provide to us in any other way.
                                    The types of Information collected may include your name, email address, and location.
                                    We automatically receive your location when you use the Services. We use various technologies
                                    to determine location, such as location services of the applicable operating system or browser
                                    and sensor data from your device that may, for example, provide information on nearby Wi-Fi-
                                    access points and cell towers. The Personal Information you provide is used for such purposes
                                    as allowing you to set up a user account and profile that can be used to interact with other
                                    users through the Services, improving the content of the Services. We may also draw upon this
                                    Personal Information in order to adapt the Services of our community to your needs, to
                                    research the effectiveness of our network and Services, and to develop new tools for the
                                    community.
                                    <br/> <br/>
                                    By signing-up /-in through a Facebook or Google Profile that profile will be linked to our user
                                    database. We receive publicly available information as well as the users e-mail address, user name,
                                    and profile picture to simplify the creation of a new user account for you.
                                    <br/> <br/>
                                    More information of Facebook regarding their Facebook connect:
                                    <br/>
                                    <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a>
                                    <br/>
                                    <a href="https://www.facebook.com/policies">https://www.facebook.com/policies</a>
                                    <br/> <br/>
                                    More information of Google regarding “Google Sign-In”:
                                    <br/>
                                    <a href="https://www.google.com/intl/en-GB/policies/privacy/">https://www.google.com/intl/en-GB/policies/privacy/</a>
                                    <br/>
                                    <a href="https://www.google.com/intl/en-GB/policies/terms/">https://www.google.com/intl/en-GB/policies/terms/</a>
                                    <br/> <br/>
                                    Interactive information provided by you like by writing a comment will be visible publicly
                                    and is saved for an unlimited time. We can’t control how other users might use that
                                    information. If you want to delete such
                                </p>

                                <h2>Information Collected Automatically</h2>
                                <p>When you use the Services, tendoo automatically receives and records information on our server logs from your
                                    browser or mobile platform, including your location, IP address, browser type, operating information, mobile
                                    carrier, device and application IDs, time of installation, cookie information, regarding your use of our Services
                                    and the page you requested. We treat this data as non personal information, except where we are required to do
                                    otherwise under applicable law. Tendoo only uses this data in aggregate or anonymous form. We may provide
                                    aggregate or anonymous information to our partners about how our users, collectively, use our Services,
                                    so that our partners may also understand how often people use their services and our Services.</p>

                                <h2>Visability</h2>
                                <p>Only logged in users can see your thumb ups and downs, comments, your user name and profile photo.</p>

                                <h2>Email Communications</h2>
                                <p>By providing your email address to us, you expressly consent to receive emails from us, where permitted by law.
                                    We may use email to communicate with you, to send information that you have requested or to send information
                                    about other products or services developed or provided by us or our partners. If you have a Services account
                                    and do not want to receive commercial email or other mail from us, please indicate your preference by removing
                                    yourself from our newsletter or emailing contact (at) tendoo.com.
                                </p>

                                <h2>How Do I Delete My Account?</h2>
                                <p>In this Version the only thing you can do is to delete your account on Tendoo web/application.
                                    If you want to delete any footprint from the past, please write us an email.
                                </p>

                                <h2>How Do I Delete My Account?</h2>
                                <p>Tendoo may amend this Privacy Policy from time to time. Use of information we collect now is subject to the
                                    Privacy Policy in effect at the time such information is used. If we make material changes in the way we use Personal
                                    Information, we will notify you by posting an announcement on our Services or sending you an email.
                                    Users are bound by any changes to the Privacy Policy when they use the Services after such changes have been first posted.
                                    <br/><br/>
                                    What If I Have Questions or Concerns?
                                    <br/><br/>
                                    If you have any questions or concerns regarding privacy using the Service, please send a detailed message to:
                                    contact (at) tendoo.com. We will make every effort to resolve your concerns.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
       </div>
     )
  }
};

export default Privacy
