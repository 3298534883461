import api
  from '../helpers/api';

export function checkIngredient(ingredientId) {
  api.patch(`/shopping/${ingredientId}/check`)
    .then((response) => {
    })
    .catch((err) => {
      console.log("CHECK INGREDIENT ERROR")
    })
}

export function unCheckIngredient(ingredientId) {
  api.patch(`/shopping/${ingredientId}/uncheck`)
    .then((response) => {
    })
    .catch((err) => {
      console.log("UNCHECK INGREDIENT ERROR")
    })
}
