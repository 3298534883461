import React
  from 'react'
import PureImage
  from '../../components/shared/PureImage'
import { fetchCookbooks }
  from '../../actions/recipes'
import { connect }
  from 'react-redux';
import { Link }
  from "react-router-dom"
import Spinner
  from '../../assets/img/eclipse-spinner.gif'
import CookBookBackground
  from '../../assets/img/cookbook-month-bg.png'
import { fetchRecipesForCookbook, fetchCookbookOfMonth }
  from '../../helpers/cookbook'
import { getTranslate }
  from 'react-localize-redux'
import StarRatings
  from 'react-star-ratings';
import { isSafari }
  from 'react-device-detect';

class NewSlider extends React.Component{
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      callSlider: false,
      page: "",
      loading:true,
      sliderLoading:true,
      currentItem: 0,
      recipes:[],
      cookbook: null
    };
  }

  async componentDidMount(){
    fetchCookbookOfMonth(this.getCookbookMonth.bind(this))
  }

  getCookbookMonth(data){
    this.setState({cookbook: data})
    fetchRecipesForCookbook(data.BookId, this.getData.bind(this))
  }

  getData(data){
    this.setState({
      recipes: data,
      loading: false
    }, () => window.slider())
  }

  errorHandle() {
  }

  truncate(str) {
    return str.length > 150 ? str.substring(0, 200) + "..." : str;
  }

  renderCookbookRecipes() {
    if(this.state.recipes) {
      return (
        this.state.recipes.map((item, index)=>{
         return (
           <div className="cookbook-month-item" key={index}>
            <Link to={{pathname:`/recipes/${item.Id}`}}>
              <PureImage src={item.CoverPath} alt="cookbook-month" className="img-responsive" />
              <div className="content">
                <h1>{item.Name}</h1>
              </div>
            </Link>
          </div>
          )
        })
      )
    }
  }

  displayCookbookSlider() {
    let { cookbook } = this.state
    let author = cookbook && cookbook.Book.Author
    let { translate } = this.props

    return (
      <section className="cookbook-month">
        <div className="cookbook-month-bg" style={{backgroundImage: `url(${CookBookBackground})`}}></div>
          <div className="container">
              <div className="row">
                  <div className="col-sm-6 col-md-5">
                      <div className="cookbook-month-content">
                          <div className="badge">{ translate('app.explorePage.cookbook_of_the_month') }</div>
                          <h1>{cookbook && cookbook.Title}</h1>
                          <div className="cookbook-month-content-author">
                              <span className="author">{ translate('app.explorePage.by') } {author && author.Name}</span>
                              <span className="rating" data-tooltip="Average rating of each recipe in this cookbook">
                                <StarRatings
                                  rating={cookbook.Book.Rating.GlobalRating ? cookbook.Book.Rating.GlobalRating : 0}
                                  starRatedColor="#F5BD00"
                                  starEmptyColor="#F7F7F7"
                                  starDimension="20px"
                                  starSpacing="0px"
                                  numberOfStars={5}
                                  name='rating'
                                />
                              </span>
                          </div>
                          <p>{cookbook && cookbook.Book.Description && this.truncate(cookbook.Book.Description)}</p>
                          <Link className="button" to={{pathname:`/cookbooks/${cookbook && cookbook.BookId}`}}>
                            { translate('app.explorePage.discover_this_cookbook') }
                          </Link>
                      </div>
                  </div>
                  <div className="col-sm-6 col-md-7">
                      <div className="cookbook-month-img">
                          <PureImage src={cookbook.ImagePath ? cookbook.ImagePath : cookbook.Book.HeaderPath} alt="cookbook-month" className="" />
                      </div>
                  </div>
              </div>
              <div className="cookbook-month-slider">
                  <h1 className="title">{ translate('app.explorePage.recipes_in_this_cookbook') }</h1>
                  <div className="cookbook-month-slider-wrap">
                    {this.renderCookbookRecipes()}
                  </div>
                  <div className="cookbook-month-controls">
                        <button className="cookbook-month-prev"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><path fill="#7A7A7A" fillRule="nonzero" d="M7.817 15.146a.5.5 0 0 1 0 .708.498.498 0 0 1-.705 0l-6.6-6.615A1.753 1.753 0 0 1 .51 6.765L7.102.147a.498.498 0 0 1 .705 0 .5.5 0 0 1 0 .706l-6.59 6.618a.751.751 0 0 0 0 1.06l6.6 6.615z"/></svg></button>
                        <button className="cookbook-month-next"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16"><path fill="#7A7A7A" fillRule="nonzero" d="M.175.853a.462.462 0 0 1 0-.707.611.611 0 0 1 .795 0l7.419 6.619c.766.683.766 1.79-.001 2.474l-7.43 6.615a.611.611 0 0 1-.794 0 .462.462 0 0 1 0-.708l7.43-6.614a.693.693 0 0 0 0-1.06L.175.852z"/></svg></button>
                    </div>
              </div>
          </div>
      </section>
    )
  }

  render(){
    let { cookbook } = this.state

    if(!this.state.loading && cookbook) {
      return this.displayCookbookSlider()
    } else {
        return (
          <section className="explore-slider" style={{paddingLeft: "45%", paddingTop: "20%",  paddingBottom: "12%"}}>
            <img src={Spinner} alt="logo" width="200px" height="200px"/>
          </section>
        )
      }
  }
}

function mapStateToProps(state){
    return {
            cookbooks: state.cookbooks,
            translate: getTranslate(state.locale)
           }
  }

export default connect(mapStateToProps, { fetchCookbooks })(NewSlider)
