import React, { Component }
  from "react";
import { ArrowDownIcon }
  from "../../../assets/icons";
import CATEGORIES
  from '../../../constants/categories'

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: CATEGORIES,
      selectedCategory: props.selectedCategories,
      selectedColor: "#ff5f00",
      noSelectedColor: "#7a7a7a",
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.selectedCategories !== newProps.selectedCategories) {
      this.setState({selectedCategory: newProps.selectedCategories})
    }
  }

  displayCategories() {
    let { categories, selectedCategory } = this.state
    let { translate } = this.props

    return (
      categories.map((category) => {
        return (
          <div
            key={category.Name}
            className="col-xs-6 col-sm-6 col-md-6">
            <a
              href={undefined} 
              className="category-item"
              onClick={(e, categoryId) => this.setCategories(category.Value)}>
              <div className="category-img"
                style={{border: selectedCategory.indexOf(category.Value) !== -1 ? "1px dotted #ff5f00" : "1px dotted"}}
              >
                <img
                  src={category.image}
                  width="80px"
                  height="80px"
                  alt="cateogry"
                />
              </div>
              <span
                className="category-title"
                style={{color: selectedCategory.indexOf(category.Value) !== -1 ? "#ff5f00" : ""}}
              >{ translate(`app.categories.${category.Name}`) }</span>
            </a>
          </div>
        )
      })
    )
  }

  setCategories(itemIndex) {
    if(itemIndex === -1) {
      if(this.state.selectedCategory.indexOf(-1) !== -1) {
        this.state.selectedCategory.splice(-1, 1)
      } else {
        this.setState({selectedCategory: [-1]})
        this.props.changeCategories([-1])
      }
    } else {
      if(this.state.selectedCategory.indexOf(itemIndex) !== -1) {
        this.setState({
          selectedCategory: this.state.selectedCategory.filter(function(value) {
            return value !== itemIndex
          }),
        })
        this.props.changeCategories(this.state.selectedCategory.filter(function(value) {
          return value !== itemIndex
        }))
      } else {
        if(this.state.selectedCategory.indexOf(-1) !== -1) {
          this.state.selectedCategory.splice(-1, 1)
        }
        this.setState({
          selectedCategory: [...this.state.selectedCategory, itemIndex],
        })
        this.props.changeCategories([...this.state.selectedCategory, itemIndex])
      }
    }
  }

  displayItem() {
    let { selectedCategory } = this.state
    let title = ""
    let currentItem = ""
    selectedCategory.forEach((category, index) => {
      currentItem = this.state.categories.filter(function(value) {
        return value.Value === category
      })

      title = title + currentItem[0].Name

      if(selectedCategory[selectedCategory.length - 1] !== selectedCategory[index]) {
        if(selectedCategory[0] !== -1) {
          title = title + ", "
        }
      }
    })
    return (
      this.truncate(title)
    )
  }

  truncate(str) {
    return str.length > 40 ? str.substring(0, 37) + "..." : str;
  }

  render() {
    let { translate } = this.props

    return (
      <div className="dropdown-wrapp">
        <h1 className="filter-inner-title">{ translate('app.filtersInputs.category') }</h1>
        <div className="dropdown">
          <button
            className="dropdown-toggle"
            type="button"
          >
            { this.displayItem() }
            <ArrowDownIcon />
          </button>
          <div className="dropdown-menu"  style={{height: 360, overflow: "hidden", overflowY: "scroll" }}>
            <div className="select-wrapp">
              <div className="row">
                {this.displayCategories()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Categories
