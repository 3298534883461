import {
    FETCH_LATEST_COOKBOOKS
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case FETCH_LATEST_COOKBOOKS:
        return action.payload
      default:
        return state
    }
  }
