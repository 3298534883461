import React
  from 'react'
import RecipeCard
  from '../../../components/recipes/RecipeCard';
import { fetchRecipesWithTags }
  from '../../../helpers/recipe';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif'

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      items: [],
      tag: "",
      currentHoverRecipe: null
    };
  }

  async componentDidMount(){
    let { search } = this.props.location

    if(search) {
      let term = search.split("?")[1]
      let tag = term.split("=")[1]
      this.setState({tag})
      this.props.fetchRecipesWithTags(tag, this.fetchRecipesOfTag.bind(this))
    } else {
      window.location.href = '/404'
    }
  }

  fetchRecipesOfTag(data) {
    this.setState({items: data, loading: false})
  }

  changeCurrentHoverRecipe(itemId) {
    let { currentHoverRecipe } = this.state

    if(currentHoverRecipe != itemId) {
      this.setState({currentHoverRecipe: itemId})
    }
  }

  renderRecipes(){
    let { items, loading } = this.state
    let { translate } = this.props

    if(!loading){
      if(items.length !== 0) {
          return items.map((item) => {
            return(
              <RecipeCard
                key={item.Id}
                item={item}
                id={item.Id}
                source={item.CoverPath}
                heading={item.Name}
                time={item.PrepTime}
                paragraph={translate('app.explorePage.by') + " " + (item.Author && item.Author.Name)}
                videos={item.Videos}
                IsFavorized={item.IsFavorized}
                setRecipe={(value) => this.setState({recipe: value})}
                currentHoverRecipe={this.state.currentHoverRecipe}
                changeCurrentHoverRecipe={(e, itemId) => this.changeCurrentHoverRecipe(itemId)}
              />
            )
          })
        } else {
          return (
            <p style={{marginLeft: "40%", marginBottom: 80, marginTop: 50}}>
              { translate('app.filtersInputs.not_found') }
            </p>
          )
        }
    } else {
      return (
        <div style={{paddingLeft: "45%", marginBottom: "10%",marginTop: "10%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }
  }

  render(){
    let { translate } = this.props

    return(
      <section className="tags">
          <div className="container">
              <div className="row">
                  <div className="col-sm-12 col-md-12">
                      <div className="tags-head">
                          <h1 className="blog-title">{this.state.tag.replace('%20', ' ')}</h1>
                          <span>{this.state.items.length} {translate('app.search.results')}</span>
                      </div>
                  </div>
                  {this.renderRecipes()}
              </div>
          </div>
      </section>
    )
  }
}

function mapStateToProps(state){
  return ({
            recipes: state.recipes,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { fetchRecipesWithTags })(Tags)
