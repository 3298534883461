import {
    REMOVE_COOKBOOK,
    ADD_COOKBOOK,
    FETCH_COOKBOOKS,
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case FETCH_COOKBOOKS:
        return [ ...state, ...action.payload ]
      case REMOVE_COOKBOOK:
      return [ ...state.map(function(v) {
        if(v.Id === action.payload.Id){
          return { ...v, IsFavorized: false}
        }else{
          return v
        }
       })]
      case ADD_COOKBOOK:
      return [ ...state.map(function(v) {
        if(v.Id === action.payload.Id){
          return { ...v, IsFavorized: true}
        }else{
          return v
        }
       })]
      default:
        return state
    }
  }
