import React
  from 'react'
import { connect }
  from 'react-redux';
import { shoppingDepartment }
  from '../../actions/shopping';
import { TickIcon }
  from '../../assets/icons'
import PureImage
  from '../../components/shared/PureImage';
import IncludeDepartmentIngredients
  from "../shared/modals/IncludeDepartmentIngredients"
import { checkIngredient, unCheckIngredient }
  from '../../helpers/ingredients';
import {
  getShoppingNote,
  addShoppingNote,
  removeShoppingNote
} from '../../helpers/shopping';
import { getTranslate }
  from 'react-localize-redux';
import UNITS
  from '../../constants/symbols';

class DepartmentShopping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active:[],
      includeIngredients: [],
      selectedIngredients: [],
      allIngredients: [],
      selectedDepartmentIngredients: [],
    };
  }

  async componentDidMount(){
    await this.props.shoppingDepartment()
    getShoppingNote(this.fetchNotes.bind(this))
    if(this.props.departments.length > 0) {
      this.selectedIngredientsItem(this.props.departments)
    }
  }

  async UNSAFE_componentWillMount(){
    await this.props.shoppingDepartment()
    getShoppingNote(this.fetchNotes.bind(this))
    if(this.props.departments.length > 0) {
      this.selectedIngredientsItem(this.props.departments)
    }
  }

  async fetchNotes(data) {
    await this.setState({allNotes: data})
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.departments) {
      let { departments } = newProps
      this.selectedIngredientsItem(departments)
    }
  }

  async selectedIngredientsItem(departments) {

    let allIngredientsDeps = []
    let selectedIngredients = []

    if(this.state.allNotes) {
      departments.forEach((dep) => {
        dep.Ingredients.forEach((ing) => {
          allIngredientsDeps.push(ing)
          if(ing.State === 1) {
            selectedIngredients.push(ing)
          }
        })
      })
    }


    await this.setState({
      allIngredients: allIngredientsDeps,
      selectedDepartmentIngredients: selectedIngredients
    })
  }

  toggleState(id){
    if(this.state.active.indexOf(id) === -1){
      this.setState({active: [...this.state.active, id]})
    }else{
      this.setState({active: this.state.active.filter(function(value) {
          return value !== id
        })
      })
    }
  }

  changeIngredients(selectIngredients) {
    this.setState({includeIngredients: selectIngredients})
  }

  async removeIncludeIngredient(item) {
    await this.removeFromNote(item)
  }

  async removeFromNote(ingredient) {
    let removeNote = this.state.allNotes.filter(function(value) {
      return value.Name === ingredient.Name
    })
    removeShoppingNote(removeNote[0].Id)
    this.setState({allNotes: this.state.allNotes.filter(function(value) {
        return value.Name !== ingredient.Name
      })
    })
  }

  async addIngredient(searchObject) {
    this.setState({selectedIngredients: [...this.state.selectedIngredients, searchObject]})
    await this.addAtNote(searchObject)
  }

  addAtNote(ingredient)  {
    addShoppingNote(ingredient.name, this.fetchNote.bind(this))
  }

  fetchNote(note) {
    this.setState({allNotes: [...this.state.allNotes, note]})
  }

  async addDepartmentIngradientToList(ingredient) {
    if(this.state.selectedDepartmentIngredients.map(function(i) { return i.Id; }).indexOf(ingredient.Id) !== -1){
      this.setState({selectedDepartmentIngredients: this.state.selectedDepartmentIngredients.filter(function(value) {
          return value.Id !== ingredient.Id
        })
      })
      await unCheckIngredient(ingredient.Id)
    } else {
      this.setState({selectedDepartmentIngredients: [...this.state.selectedDepartmentIngredients, ingredient]})
      await checkIngredient(ingredient.Id)
    }
  }

  autocompleteDisplay() {
    let { serachIngredients } = this.state
    return (
      <div style={{ position: 'absolute',
                    backgroundColor: "#f0f0f0",
                    height: serachIngredients.length === 0 ? 0 : 200,
                    top: 60,
                    zIndex: 99,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflow: "hidden",
                    overflowY: "scroll"}}>
        <ul style={{margin: 10}}>
          { serachIngredients.map((ingredient, index) => {
              return (
                <li
                  style={{marginBottom: 8}}
                  key={index}
                >
                  <a href={undefined}  onClick={() => this.addItem(ingredient)}>{ingredient.Name}</a>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }

  truncate(str) {
    return str.length > 12 ? str.substring(0, 11) + ".." : str;
  }

  truncateUnits(str) {
    return str.length > 8 ? str.substring(0, 7) + ".." : str;
  }

  displayDepartmentIngredients(persons, item) {
    return (
      item.map((item, index)=>{

        let checkSelectIngredient =(this.state.selectedDepartmentIngredients.map(function(e) { return e.Id; }).indexOf(item.Id) !== -1)
        return (
          <tr key={index}
            onClick={() => this.addDepartmentIngradientToList(item)}
            style={{opacity: checkSelectIngredient ? 0.4 : 1}}>
            <td style={{width: 100}}>
              <div className="recipes-table-img">
                <PureImage src={item.ThumbPath} className="img-responsive" />
              </div>
            </td>
            <td style={{width: 120}}>{this.truncate(item.Name)}</td>
            <td>
              <span className="mass"> {Math.round(item.ImperialAmount * persons, 2)} { checkSelectIngredient ? this.truncateUnits(UNITS[item.MetricUnit]) : UNITS[item.MetricUnit] }</span>
            </td>
            { checkSelectIngredient &&
              <td>
                <TickIcon />
              </td>
            }
          </tr>
        )
      })
    )
  }

  displayDepartment() {

    if(this.props.departments) {
      return (
        this.props.departments.map((item)=>{
          let ingredientUnSelect = item.Ingredients.filter(ingredient => !this.state.selectedDepartmentIngredients.includes(ingredient));
          let ingredientSelect = item.Ingredients.filter(ingredient => this.state.selectedDepartmentIngredients.includes(ingredient));

          return (
            <div
              key={item.DepartementId}
              className="recipes-extra-table mg-bottom-15">
              <span className="title" style={{paddingBottom: 10}}>{item.DepartementName}</span>
              <table className="department-title-style">
                <tbody>
                  {this.displayDepartmentIngredients(item.Persons, ingredientUnSelect)}
                  {this.displayDepartmentIngredients(item.Persons, ingredientSelect)}
                </tbody>
              </table>
            </div>
          )
        })
      )
    }
  }

  render() {
    let { translate } = this.props

    if(this.props.departments.length > 0) {
      return(
        <div>
          <div
            id="tab2"
            className="tab-slider-body"
          >
            {this.displayDepartment()}
          </div>

          <div className="select-wrapp">
            <IncludeDepartmentIngredients
              ingredients={this.state.allIngredients}
              ingredientsAdded={this.state.allNotes}
              removeIngredient={this.removeIncludeIngredient.bind(this)}
              addIngredient={this.addIngredient.bind(this)}
              changeIngredients={(selectExcludeIngredients) => this.changeIngredients(selectExcludeIngredients)}
              translate={translate}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className="tab-slider-container">
          <div style={{height:100}}>
            <p>{translate('app.filtersInputs.not_found')}</p>
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state){
  return {
           shopping: state.shopping,
           departments: state.shopping.departments,
           translate: getTranslate(state.locale)
         }
}

export default connect(mapStateToProps, { shoppingDepartment })(DepartmentShopping)
