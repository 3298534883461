import React, { Component }
  from "react";
import { PlusIcon, TrashIcon, TickIcon, PluseActiveIcon }
  from "../../../assets/icons";
import PureImage
  from '../../../components/shared/PureImage';
import { checkIngredient, unCheckIngredient }
  from '../../../helpers/ingredients';

class IncludeDepartmentIngredients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serachIngredients: [],
      searchText: "",
      ingradient: null,
      ingredientsAdded: [],
      nodeSelected: []
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let checkedIngredients = []
    if(this.props.ingredientsAdded !== newProps.ingredientsAdded) {
      checkedIngredients = newProps.ingredientsAdded.filter(ingredient =>  ingredient.State === 1);
      this.setState({nodeSelected: checkedIngredients})
    }
  }

  renderAuthimg(item){
    if(this.props.ingredients.length !== 0) {
      let findIngredients = this.props.ingredients.filter(ingredient => ingredient.Name === item.Name);
      if(findIngredients.length !== 0) {
        return <PureImage src={findIngredients[0].ThumbPath} />
      } else {
        return <img alt="ingredient" src={"https://wm.soft-ua.net/images/noimage.png"} />
      }
    }
  }

  addItem(ingredient) {
    this.setState({ingradient: ingredient, serachIngredients: [], searchText: ingredient.Name})
    this.clickPlus(true, ingredient)
  }

  clickPlus(from_select = false, ingredient = null) {
    let { searchText } = this.state
    let { ingredientsAdded } = this.props

    if(from_select) {
      let checkSelectNodeExist = (ingredientsAdded.map(function(e) { return e.Name; }).indexOf(ingredient.Name) !== -1)
      if(!checkSelectNodeExist) {
        this.props.addIngredient({index: this.props.ingredientsAdded.length, name: ingredient.Name})
        this.setState({searchText: ""})
      } else {
        alert("This node exist in your list..!")
        this.setState({searchText: ""})
      }
    } else {
      if(searchText.trim() !== "") {
        let checkSelectNodeExist = (ingredientsAdded.map(function(e) { return e.Name; }).indexOf(searchText) !== -1)
        if(!checkSelectNodeExist) {
          this.props.addIngredient({index: this.props.ingredientsAdded.length, name: searchText})
          this.setState({searchText: ""})
        } else {
          alert("This node exist in your list..!")
        }
      }
    }
  }

  async selectNode(node) {
    if(this.state.nodeSelected.map(function(i) { return i.Id; }).indexOf(node.Id) !== -1){
      this.setState({nodeSelected: this.state.nodeSelected.filter(function(value) {
          return value.Id !== node.Id
        })
      })
      await unCheckIngredient(node.Id)
    } else {
      this.setState({nodeSelected: [...this.state.nodeSelected, node]})
      await checkIngredient(node.Id)
    }
  }

  autocompleteDisplay() {
    let { serachIngredients } = this.state
    return (
      <div style={{ position: 'absolute',
                    backgroundColor: "#f0f0f0",
                    height: serachIngredients.length === 0 ? 0 : 200,
                    top: 60,
                    zIndex: 99,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflow: "hidden",
                    overflowY: "scroll"
                  }}>
        <ul style={{margin: 10}}>
          { serachIngredients.map((ingredient, index) => {
              return (
                <li
                  style={{marginBottom: 8}}
                  key={index}
                >
                  <a href={undefined}  onClick={() => this.addItem(ingredient)}>{ingredient.Name}</a>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }

  displayIngradientsAdded(ingredientsAdded) {
    if(ingredientsAdded) {
      return (
        ingredientsAdded.map((ingredient, index) => {
          let checkSelectNode = (this.state.nodeSelected.map(function(e) { return e.Id; }).indexOf(ingredient.Id) !== -1)
          return (
            <li
              key={index}
              style={{opacity: checkSelectNode ? 0.4 : 1}}
              onClick={() => this.selectNode(ingredient)}>
              <div className="ingredient-content">
                <div className="ingredient-img">
                  {this.renderAuthimg(ingredient)}
                </div>
                <span style={{marginLeft: 20}}>
                  {ingredient.Name}
                </span>
              </div>
              { !checkSelectNode ?
                <a href={undefined}  onClick={() => this.props.removeIngredient(ingredient)}>
                  <div className="ingredient-delete">
                    <TrashIcon />
                  </div>
                </a>
                :
                <div style={{marginRight: 8}}>
                  <TickIcon />
                </div>
              }
            </li>
          )
        })
      )
    }
  }

  displayItems() {

  }

  removeIngredient(ingredient) {
    var array = [...this.props.ingredientsAdded]; // make a separate copy of the array
    var index = array.indexOf(ingredient)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ingredientsAdded: array});
      this.props.changeIngredients(array)
    }
  }

  displayPlusIcon() {
    let { searchText } = this.state

    if(searchText.trim() !== "") {
      return (
        <a href={undefined}  onClick={() => this.clickPlus()}>
          <PluseActiveIcon />
        </a>
      )
    } else {
      return (
        <a href={undefined} >
          <PlusIcon />
        </a>
      )
    }
  }

  searchData(e) {
    this.setState({serachIngredients: [], searchText: e.target.value})
    this.findIngredient(e.target.value)
  }

  findIngredient(query) {
    if (query === '') {
      this.setState({serachIngredients: []})
      return
    }

    const { ingredients } = this.props;
    const regex = new RegExp(`${query.trim()}`, 'i');
    let filterIngredients = ingredients.filter(ingredient => ingredient.Name.search(regex) >= 0);

    this.setState({serachIngredients: filterIngredients})
  }

  render() {
    let { ingredientsAdded } = this.props
    if(!ingredientsAdded) {
      return <div />
    }

    let ingredientSelect = ingredientsAdded.filter(ingredientAdded => this.state.nodeSelected.includes(ingredientAdded));
    let ingredientUnSelect = ingredientsAdded.filter(ingredientAdded => !this.state.nodeSelected.includes(ingredientAdded));
    return (
      <div className="select-wrapp">
        <div className="add-ingredients">
          <input
            type="text"
            id="ingredients-dep"
            name="ingredients-dep"
            className="inp-add-ingr"
            placeholder={this.props.translate('app.filtersInputs.add_ingredient')}
            onChange={(e) => this.searchData(e)}
            value={this.state.searchText}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
          />
          {this.displayPlusIcon()}
          {this.autocompleteDisplay()}
        </div>
        <ul className="ingredients-list" style={{maxHeight: 150, overflow: "hidden", overflowY: "scroll"}}>
          {this.displayIngradientsAdded(ingredientUnSelect)}
          {this.displayIngradientsAdded(ingredientSelect)}
        </ul>
      </div>
    );
  }
}

export default IncludeDepartmentIngredients
