import React
  from 'react'
import PureImage
  from '../../components/shared/PureImage'
import UNITS
  from '../../constants/symbols';

class RecipeTableItem extends React.Component {
  _isMounted = false

  UNSAFE_componentWillMount() {
		this._isMounted = true;
  }

  render() {
    let { ingridient } = this.props
    if(ingridient && this._isMounted) {
      return(
        <tr>
          <td>
            <div className="recipes-table-img">
              <PureImage src={ingridient.Ingredient.ImagePath} />
            </div>
          </td>
          <td>{ingridient.Ingredient.Name}</td>
          <td className="text-right"><span className="tb-quantity">{Math.round(ingridient.ImperialAmount * this.props.numOfPeople, 2)} { UNITS[ingridient.MetricUnit] }</span></td>
        </tr>
      )
    } else {
      return null
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default RecipeTableItem
