import React from 'react';

const Bake = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 150.000000 150.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)" fill={props.color} stroke="none">
        <path d="M452 1204 c-215 -57 -339 -286 -273 -502 12 -39 42 -101 67 -139 28
        -42 41 -69 33 -72 -29 -9 -49 -42 -49 -82 0 -33 6 -47 34 -75 40 -40 62 -42
        162 -14 l70 19 80 -26 c113 -37 238 -37 347 1 l78 26 66 -20 c78 -24 121 -25
        151 -4 29 20 52 65 52 102 0 29 -35 72 -58 72 -7 0 5 21 25 47 175 229 118
        520 -125 640 -72 36 -81 38 -171 38 -76 0 -106 -5 -142 -22 l-46 -21 -69 24
        c-79 27 -153 30 -232 8z m262 -57 c71 -33 141 -103 174 -175 37 -81 42 -195
        12 -274 -12 -32 -25 -58 -29 -58 -5 0 -29 22 -54 49 l-45 48 5 60 c13 142 -79
        242 -221 243 -153 0 -251 -135 -211 -292 19 -74 50 -126 100 -164 42 -32 39
        -36 -54 -65 l-55 -17 -33 42 c-144 181 -145 386 -4 526 31 31 76 66 101 78 94
        45 214 44 314 -1z m301 28 c22 -4 66 -21 98 -38 136 -72 213 -225 186 -371
        -19 -109 -105 -256 -149 -256 -11 0 -44 9 -75 21 l-55 20 48 47 c65 64 85 110
        90 203 3 62 0 83 -16 115 -40 78 -111 124 -192 124 -49 0 -53 3 -105 55 -48
        50 -52 56 -35 65 23 12 118 28 145 24 11 -1 38 -6 60 -9z m-448 -212 c-31 -69
        -31 -217 1 -287 12 -27 22 -53 22 -57 0 -5 -15 -17 -34 -26 -33 -15 -36 -15
        -70 7 -91 55 -141 193 -105 287 23 63 109 122 176 123 l32 0 -22 -47z m441 32
        c76 -32 122 -102 122 -184 0 -69 -50 -165 -108 -205 -41 -29 -42 -30 -77 -13
        -19 9 -35 20 -35 24 0 5 10 33 22 63 30 75 30 203 0 278 l-20 52 29 0 c16 0
        46 -7 67 -15z m-305 -54 c22 -27 24 -36 20 -98 -6 -78 12 -126 68 -182 71 -71
        227 -155 346 -186 85 -23 109 -42 96 -81 -5 -15 -21 -36 -35 -47 -25 -18 -26
        -18 -109 4 -235 63 -405 174 -475 311 -43 85 -47 213 -9 299 17 37 18 38 45
        24 15 -8 39 -28 53 -44z m10 -462 c-6 -14 -171 -88 -248 -112 -131 -40 -139
        -41 -168 -15 -29 25 -42 66 -29 86 5 7 49 24 98 38 49 14 125 44 168 67 l79
        42 51 -50 c28 -27 50 -53 49 -56z m79 -46 c24 -14 67 -37 98 -51 l55 -24 -40
        -16 c-56 -22 -255 -22 -310 0 l-40 16 65 30 c36 17 79 39 95 51 17 11 31 20
        33 20 1 1 21 -11 44 -26z" />
      </g>
    </svg>
  )
}

export default Bake
