import React
  from "react";
import AuthorCookBooksCard
  from "../../../components/authors/details/AuthorCookBooksCard";
import { getTranslate }
  from 'react-localize-redux';
import { connect }
  from 'react-redux';

class AuthorCookbooks extends React.Component {
  cutString(s, n){
    let { translate } = this.props
    if(s) {
      var cut= s.indexOf(' ', n);
      if(cut === -1) return s;
      return s.substring(0, cut) + "..."
    } else {
      return (
        translate('app.explorePage.no_description_available')
      )
    }
  }

  displayCookbook() {
    return (
      this.props.items.map((item, index) => {
        return (
          <AuthorCookBooksCard
            key={index}
            item={item}
            IsFavorized={item.IsFavorized}
            id={item.Id}
            source={item.CoverPath}
            heading={item.Name}
            slider={false}
            paragraph={this.cutString(item.Description, 15)}
          />
        )
      })
    )
  }

  render() {
    return (
      <div className="more-cookbooks section-top-60">
        <div className="main-title">
          <h2>Cookbooks by me</h2>
        </div>
        <div className="row">
          <div className="more-cookbooks-slider">
            {this.displayCookbook()}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(AuthorCookbooks)
