import {
    REMOVE_RECIPE,
    ADD_RECIPE,
    FETCH_RECIPES,
    RATE_RECIPE
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case FETCH_RECIPES:
        return [ ...state, ...action.payload ]
      case REMOVE_RECIPE:
      return [ ...state.map(function(v) {
        if(v.Id === action.payload.Id){
          return { ...v, IsFavorized: false}
        }else{
          return v
        }
       })]
      case ADD_RECIPE:
      return [ ...state.map(function(v) {
        if(v.Id === action.payload.Id){
          return { ...v, IsFavorized: true}
        }else{
          return v
        }
       })]
       case RATE_RECIPE:
        return [ ...state.map(function(v) {
          if(v.Id === action.recipeId){
            return { ...v, Rating: action.payload}
          }else{
            return v
          }
         })]
      default:
        return state
    }
  }
