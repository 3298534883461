import {
  BASE_URL,
} from '../constants';

import api
  from './api'

import { getLanguage }
  from "../services/token";
import { convertHHMMSS }
 from "../services/converts";

export async function getCategories(addCategories) {
  let url = `${BASE_URL}/category?LangId=${getLanguage}`
  api.get(url)
    .then((data) => {
      addCategories(data)
    })
    .catch(err => {
      console.log("GET CATEGORIES ERROR", err)
    })
}

export async function getIngredients(addIngredients) {
  let url = `${BASE_URL}/ingredient?LangId=${getLanguage}`
  api.get(url)
    .then((data) => {
      addIngredients(data)
    })
    .catch(err => {
      console.log("GET INGREDIENTS ERROR", err)
    })
}

export async function getFiltersRecipes(params, addRecipes) {
  let url = `${BASE_URL}/recipe?LangId=${getLanguage}&`

  let categoriesParams = params["categories"]
  let includeIngredientsParams = params["includeIngredients"]
  let excludeIngredientsParams = params["excludeIngredients"]

  if(!categoriesParams.includes(-1)) {
    categoriesParams.forEach((cat, index) => {
      url = url + `DishTypes=${cat}&`
    })
  }

  // includeIngredientsParams params
  includeIngredientsParams.forEach((include, index) => {
    url = url + `IncludedIng[]=${include.Id}&`
  })

  // excludeIngredientsParams params
  excludeIngredientsParams.forEach((exclude, index) => {
    url = url + `ExcludedIng[]=${exclude.Id}&`
  })

  if(params["slidersValues"][1] != 300) {
    url = url + `maxCalories=${params["slidersValues"][1]}&`
  }

  if(params["slidersValues"][0] != 180) {
    let convertTime = convertHHMMSS(params["slidersValues"][0])
    url = url + `CookTime=${convertTime}&`
  }

  api.get(url)
    .then((data) => {
      addRecipes(data, url)
    })
    .catch(err => {
      console.log("GET FILTERS RECIPES ERROR", err)
    })
}

export async function getFiltersCookbooks(params, addCookbooks) {
  let url = `${BASE_URL}/book?LangId=${getLanguage}&`

  if(params["searchTerm"] && params["searchTerm"] !== "" && params["searchTerm"] !== null) {
    url = url + `Term=${params["searchTerm"]}&`
  }

  if(params["authorId"] !== null) {
    url = url + `AuthorId=${params["authorId"]}&`
  }

  // if(params["OrderAsc"] !== null){
  //   url = url + `OrderAsc=${params["OrderAsc"] === "Ascending" ? true : false }&`
  // }

  if(params["OrderBy"] !== null){
    url = url + `OrderBy=${params["OrderBy"]}`
  }

  api.get(url)
    .then((data) => {
      addCookbooks(data, url)
    })
    .catch(err => {
      console.log("GET FILTERS COOKBOOKS ERROR", err)
    })
}
