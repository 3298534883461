import React
  from 'react'
import { getToken }
  from "../../../services/token";
import { favoriteCookbook, unFavoriteCookbook }
  from '../../../actions/favourites';
import { connect }
  from 'react-redux';
import { FavShapeIcon }
  from '../../../assets/icons';
import StarRatings
  from 'react-star-ratings';
import { motion }
  from "framer-motion"
import PureImage
  from '../../../components/shared/PureImage'
import { setCurrentPath }
  from '../../../helpers/currentPath';

class CookBookCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing:false,
      image:'',
      IsFavorized: props.item.IsFavorized,
      favouriteLoading: false
    };
  }

  renderImg() {
    if(this.props.source != undefined){
      return <PureImage src={this.props.source} className="img-responsive" />
    }
  }

  addFavorite() {
    let { item } = this.props
    if(getToken) {
      this.setState({favouriteLoading: true})
      this.props.favoriteCookbook(item, this.updateCookbook.bind(this))
    } else {
      setCurrentPath()
    }
  }

  removeFavorite() {
    let { item } = this.props
    if(getToken) {
      this.setState({favouriteLoading: true})
      this.props.unFavoriteCookbook(item, this.updateCookbook.bind(this))
    } else {
      setCurrentPath()
    }
  }

  updateCookbook(IsFavorized){
    this.setState({
      IsFavorized: IsFavorized,
      favouriteLoading: false
    })
  }

  displayFavouriteIcon() {
    let { favouriteLoading, IsFavorized } = this.state
    if(favouriteLoading) {

    } else {
      if(IsFavorized) {
        return (
          <motion.div
            className={"toggle-fav-book fav-book-on"}
            onClick={() => this.removeFavorite()}
            initial={{ scale: 0 }}
            animate={{ rotate: 360, scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 15
            }}
          />
        )
      } else {
        return (
          <motion.div
            className={"toggle-fav-book fav-book-off"}
            onClick={ getToken ? () => this.addFavorite() : () => setCurrentPath()}
            initial={{ scale: 0 }}
            animate={{ rotate: 360, scale: 1 }}
            whileHover={{ scale: 1.5, rotate: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 15
            }}
          />
        )
      }
    }
  }

  truncate(str) {
    return str.length > 22 ? str.substring(0, 22) + "..." : str;
  }

  cutString(s, n){
    if(s) {
      var cut = s.indexOf(' ', n);
      if(cut === -1) return s;
      return s.substring(0, cut) + "..."
    } else {
      return (
        "Not description"
      )
    }
  }

  render(){
    let { item } = this.props
    return(
      <div className={ (!this.props.bigger ? this.props.slider === false ? "col-sm-6 col-md-3 cookbook-item" : "col-xs-6 col-sm-6 col-md-3" : this.props.slider === false ? "col-sm-6 col-md-4 cookbook-item" : "col-sm-6 col-md-4" )}>
        <a href={`/cookbooks/${this.props.id}`}>
          <div className="cookbooks-item">
            <div className="cookbooks-img">
              {this.renderImg()}
            </div>
            <div className="cookbooks-content" >
              <h1>{this.props.heading}</h1>
              <span>by {item.Author.Name}</span>
              <div className="cookbooks-content-footer" data-tooltip="Average rating of each recipe in this cookbook">
                <p style={{width: 175}}>
                  {this.cutString(this.props.paragraph, 18)}
                </p>
                <div className="content-rate">
                  <StarRatings
                    rating={item.Rating.GlobalRating ? item.Rating.GlobalRating : 0}
                    starRatedColor="#F5BD00"
                    starDimension="16px"
                    starSpacing="0px"
                    numberOfStars={5}
                    name='rating'
                  />
                </div>
              </div>
            </div>
          </div>
        </a>
        <div className="cookbooks-assets" style={{right: 28}}>
          <FavShapeIcon />
          {this.displayFavouriteIcon()}
        </div>
      </div>

    )
  }
}

export default connect(null,
  { favoriteCookbook, unFavoriteCookbook })(CookBookCard);
