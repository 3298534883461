import React
  from "react";
import PureImage
  from '../../components/shared/PureImage';

class RenderSearch extends React.Component {

  render() {
    let item = this.props
    return (
      <a href={this.props.ingradientFilter ? this.props.ingradientFilter : `/${this.props.location}/`+this.props.id}>
        <div className="search-result-item search-close" onClick={item.onPress}>
          <div className="search-result-img">
            <PureImage src={item.source} />
          </div>
          <div className="search-result-text">
            <h1>{item.name}</h1>
          </div>
        </div>
      </a>
    )
  }
}

export default RenderSearch
