import React
  from 'react'
import Banner
  from '../../../components/cookbooks/details/Banner';
import BigHeading
   from '../../../components/cookbooks/details/BigHeading';
import SideImage
  from '../../../components/cookbooks/details/SideImage';
import Nav
  from '../../../components/cookbooks/details/Nav'
import { fetchCookbook }
  from '../../../helpers/cookbook'
import { favoriteCookbook, unFavoriteCookbook }
  from '../../../actions/favourites';
import { connect }
  from 'react-redux';
import { getToken }
  from "../../../services/token";
import { unSubscribeUser, subscribeUser, authSubscribes }
  from '../../../helpers/author';
import { getTranslate }
 from 'react-localize-redux';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';
import SuccessMessage
  from '../../../components/shared/SuccessMessage';
import {Helmet}
  from "react-helmet";
import ReactGA
  from 'react-ga';

class CookbookDetails extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      cookbook: "",
      loading: false,
      favouriteLike: false,
      titleSuccess: "Ingredient successful",
      descriptionSuccess: "5 Ingredients in your shopping list",
      successMessage: false,
    };
  }

  componentDidMount() {
    ReactGA.event({
      category: 'Cookbook navigation',
      action: `Go to: ${window.location.pathname}`
    });
  }

  async UNSAFE_componentWillMount(){
    this.setState({loading: true})
    let id = this.props.match && this.props.match.params.id
    fetchCookbook(id, this.saveCookBookData.bind(this))
  }

  UNSAFE_componentWillReceiveProps(newProps){
    // this.setState({loading: true})
    let newId = newProps.match.params.id
    fetchCookbook(newId, this.saveCookBookData.bind(this))
  }


  saveCookBookData(data){
    let aid = data.Author && data.Author.Id
    getToken && authSubscribes(getToken, aid, this.saveAuthSubscribes.bind(this))
    this.setState({
      cookbook: data,
      loading: false
    })
  }

  favoriteOnPress(){
    let { cookbook } = this.state
    this.setState({favouriteLike: true})
    if(cookbook.IsFavorized){
      this.props.unFavoriteCookbook(cookbook, this.updateCookbook.bind(this))
    }else{
      this.props.favoriteCookbook(cookbook, this.updateCookbook.bind(this))
    }
  }

  updateCookbook(isFavourite){
    this.setState({
      favouriteLike: false,
      cookbook: {
        ...this.state.cookbook,
        IsFavorized: isFavourite,
        IsSubscribed: true,
      }
    })
  }

  saveAuthSubscribes(data){
    this.setState({
      ...data,
    })
  }

  onUnSubscribe(authorId){
    let { translate } = this.props
    this.setState({
      IsSubscribed:false
    })

    unSubscribeUser(getToken, authorId, this.userSubscribed.bind(this))
    this.setMessage("Unsubscribe", translate('app.recipeDetails.unsubscribe_success_message'))
  }

  onSubscribe(authorId){
    let { translate } = this.props
    this.setState({
      IsSubscribed:true
    })
    subscribeUser(getToken, authorId, this.userSubscribed.bind(this))
    this.setMessage("Subscribe", translate('app.recipeDetails.subscribe_success_message'))
  }

  setMessage(title, description) {
    let _this = this
    this.setState({
      titleSuccess: title,
      descriptionSuccess: description,
      successMessage: true
    }, () => {
      setTimeout(function() {
        _this.setState({
          titleSuccess: "",
          descriptionSuccess: description,
          successMessage: false
        })
      }, 4500);
    })
  }

  userSubscribed(data){
    this.setState({
      ...data,
    })
  }

  render(){
    let cookbook = this.state.cookbook
    let aid = cookbook.Author && cookbook.Author.Id
    let { translate } = this.props

    if(this.state.loading) {
      return (
        <div style={{paddingLeft: "45%", paddingBottom: "10%", paddingTop: "20%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }

    return (
      <>
        <Helmet
          title={"Tendoo"}
          meta={[
          { property: 'og:title', content: cookbook.Name },
          { property: 'og:site_name', content: "web.tendoo.com" },
          { property: 'og:type', content: "website" },
          { property: 'og:url', content: "web.tendoo.com" + this.props.match.url},
          { property: 'og:description', content: cookbook.Description },
          { property: 'og:image', content: cookbook.CoverPath },
          ]}
        />
        <Banner
          source={cookbook.CoverPath}/>
          <div className="container">
            <div className="row">
              <div className="cookbook-profile-info mg-top-130">
                <BigHeading
                  cookbook={cookbook}
                  author={cookbook.Author}
                  authorName={cookbook.Author && cookbook.Author.Name}
                  authorId={aid}
                  description={cookbook.Description ? cookbook.Description : translate('app.cookbookDetails.no_description_yet') }
                  bigHeader={cookbook.Name}
                  ratings={cookbook.Rating && cookbook.Rating.GlobalRating}
                  totalRatings={cookbook.Rating && cookbook.Rating.TotalRatings}
                  views={cookbook.Views}
                  source={(cookbook.Author && cookbook.Author.ImagePath)}
                  likePress={this.favoriteOnPress.bind(this)}
                  liked={cookbook.IsFavorized}
                  isSubscribed={this.state.IsSubscribed}
                  subscribe={()=> this.state.IsSubscribed ? this.onUnSubscribe(aid) : this.onSubscribe(aid)}
                  translate={translate}
                  favouriteLike={this.state.favouriteLike}
                  match={this.props.match}
                  />
                <SideImage
                  source={cookbook.CoverPath}/>
              </div>
              <Nav
                id={cookbook.Id}
                partitions={cookbook.DishPartitions}
                translate={translate}
              />
            </div>
        </div>

        { this.state.successMessage &&
          <SuccessMessage
            titleSuccess={this.state.titleSuccess}
            descriptionSuccess={this.state.descriptionSuccess}
          />
        }
      </>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}


export default connect(mapStateToProps, { favoriteCookbook, unFavoriteCookbook })(CookbookDetails);
