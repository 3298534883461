import React
  from 'react';
import ReactPlayer
  from 'react-player';
import PureImage
  from '../../../components/shared/PureImage';
import ReactLoading
  from 'react-loading';
import { isSafari }
  from 'react-device-detect';

class VideoPreview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
      loading: true,
		}
	}

  endVideo() {
    this.props.stopPlaying()
  }

  renderAuthimg(){
    if(this.props.source != undefined){
      return (
        <PureImage src={this.props.source} style={{width: this.props.noStyle ? "130%" : "100%", height: this.props.noStyle ? "130%" : 280 }}/>
      )
    }
  }

  displayVideoSection() {
    if(isSafari) {
      if(this.props.hovered) {
        if(this.props.currentVideo) {
          return (
            <ReactPlayer
              url={this.props.currentVideo.Mp4FilePath }
              playing={this.props.playing}
              onEnded={this.endVideo.bind(this)}
              muted={true}
              onReady={() => this.setState({loading: false})}
              width={this.props.noStyle ? '130%' : "100%"}
            />
          )
        }
      } else {
        return (
          this.renderAuthimg()
        )
      }
    } else {
      if(this.props.currentVideo) {
        return (
          <ReactPlayer
            url={this.props.currentVideo.Mp4FilePath }
            playing={this.props.playing}
            onEnded={this.endVideo.bind(this)}
            muted={true}
            onReady={() => this.setState({loading: false})}
            width={this.props.noStyle ? '130%' : "100%"}

          />
        )
      }
    }

  }

  displayLoading() {
    return (
      <div style={{marginTop: "32%", marginLeft: "48%", position: "absolute", zIndex: 98, display: this.state.loading && this.props.currentVideo? "block" : "none"}}>
        <ReactLoading
          type={"spin"}
          color={"#ffffff"}
          height={30} width={30}
        />
      </div>
    )
  }

  displayMissingVideo() {
    return (
      <div style={{height: '280px', justifyContent: 'center',  alignItems: 'center', zIndex: 99, display: !this.props.currentVideo? "flex" : "none"}}>
        <p style={{color: "#ffffff"}}>Missing Video</p>
      </div>
    )
  }

	render() {
		return (
      <div>
        {this.displayMissingVideo()}
        {this.props.hovered && this.displayLoading()}
        {this.displayVideoSection()}
      </div>
    )
	}
}
export default VideoPreview
