import React from 'react';

const Salad = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
        <path d="M789 1241 c-33 -33 -39 -45 -39 -82 0 -25 9 -61 20 -82 11 -20 20
        -52 20 -70 0 -18 7 -44 17 -57 l16 -25 -29 26 c-38 35 -67 49 -98 49 -36 0
        -33 -15 17 -79 57 -73 67 -92 31 -60 -59 54 -112 74 -199 74 -63 0 -89 -5
        -120 -22 -139 -74 -183 -232 -121 -432 39 -127 34 -169 -25 -190 -35 -12 -35
        -37 0 -34 32 4 16 -15 -22 -25 -37 -10 -45 -38 -9 -34 22 2 22 1 -7 -24 -17
        -14 -31 -32 -31 -40 0 -16 26 -19 35 -5 3 6 25 23 48 39 l43 29 18 -25 c14
        -18 21 -21 30 -12 8 8 6 19 -7 41 -27 48 -22 56 39 70 50 11 62 10 137 -12
        109 -32 193 -31 267 3 77 35 115 70 147 135 53 109 29 241 -61 341 -30 32 -33
        41 -20 46 28 11 95 6 151 -10 33 -10 76 -14 104 -12 79 8 149 68 149 128 0 23
        -37 60 -60 60 -12 0 -15 8 -12 39 5 51 -23 81 -74 81 -41 0 -64 -11 -120 -57
        -47 -38 -60 -29 -44 28 7 25 6 39 -5 56 -9 13 -19 23 -23 23 -4 0 -6 16 -4 35
        9 76 -24 125 -84 125 -29 0 -44 -8 -75 -39z m105 -7 c23 -23 30 -59 17 -95
        -16 -48 -14 -59 14 -59 28 0 31 -15 14 -54 -9 -19 -8 -29 4 -46 14 -20 12 -24
        -31 -65 -26 -24 -50 -41 -54 -39 -5 3 -8 22 -8 42 0 20 -7 46 -15 57 -10 13
        -13 30 -9 53 5 26 2 38 -15 56 -12 13 -24 39 -28 59 -5 32 -1 41 30 72 38 38
        58 43 81 19z m281 -210 c10 -11 14 -25 11 -38 -19 -66 -20 -63 22 -70 52 -8
        58 -28 21 -70 -48 -55 -82 -61 -191 -36 -74 17 -102 19 -137 11 l-43 -9 33 35
        c42 43 187 166 214 181 28 16 51 15 70 -4z m-609 -177 c1 -76 34 -146 101
        -218 68 -73 213 -138 213 -96 0 8 -17 19 -38 25 -128 39 -248 189 -240 301 l3
        42 55 -28 c51 -26 55 -30 52 -60 -2 -17 2 -41 8 -53 25 -46 118 -78 147 -49
        12 12 42 -25 73 -93 40 -89 12 -223 -58 -279 -55 -43 -230 -7 -327 67 -50 38
        -68 43 -63 17 8 -45 152 -118 252 -130 38 -5 45 -8 31 -14 -39 -15 -141 -9
        -200 11 -32 11 -69 20 -82 20 -13 0 -21 4 -18 10 3 5 -20 36 -53 67 -77 77
        -104 135 -109 238 -3 44 0 91 5 105 10 22 11 23 11 4 2 -42 32 -124 46 -124
        19 0 19 12 0 74 -8 27 -15 68 -15 92 0 35 5 47 34 72 31 27 65 43 121 55 49
        11 50 10 51 -56z"/>
        <path d="M445 750 c-9 -15 50 -123 104 -188 58 -70 180 -155 253 -177 66 -19
        80 -19 76 2 -2 11 -18 20 -42 25 -113 24 -262 149 -329 276 -37 70 -49 82 -62
        62z"/>
      </g>
    </svg>
  )
}

export default Salad
