import React 
  from 'react'

import Login
  from './login'

import Register
  from './register'

import ResetPassword
  from './resetPassword'

import ForgotPassword
  from './forgotPassword'

import { Route }
  from "react-router-dom";

import LogoWhite
  from '../../assets/img/logo-white.svg'

import BackgroundImage
  from '../../assets/img/login-background.jpg'

const Auth = (props) => {
  return (
    <section className="login" style={{ backgroundImage: `url(${BackgroundImage})` }} >
      <div className="container">
        <div className="login-wrapper">
          <div className="login-logo">
            <a href={undefined} >
              <img src={LogoWhite} alt="logo"/>
            </a>
          </div>

          <Route exact path="/auth" component={Login} />
          <Route exact path="/auth/new" component={Register} />
          <Route exact path="/auth/forgetpassword" component={ForgotPassword} />
          <Route exact path="/auth/resetPassword/:token" component={ResetPassword} />
        </div>
      </div>

      <div className="login-footer">
        <ul>
          <li><a href={undefined} >Data Privacy</a></li>
          <li><a href={undefined} >Terms of use </a></li>
        </ul>
        <p className="copyright">Copyright © 2019, Tendoo. All Rights Reserved.</p>
      </div>
    </section>

  );
}

export default Auth
