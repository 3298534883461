import React from 'react'
import Mockup from '../../assets/img/mockup.png'
import AppStoreWhite from '../../assets/img/app-store-white.png'
import GooglePlayStoreWhite from '../../assets/img/googleplay-store-white.png'
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux'

const GetApp = (props) => {
  let { translate } = props
  return(
      <section className="get-app section-top-190">
          <div className="container">
              <div className="row">
                  <div className="col-sm-6 col-md-6">
                      <div className="get-app-img">
                          <img src={Mockup} alt="iphone-mockup" className="img-responsive" />
                      </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                      <div className="get-app-content-wrapp">
                          <div className="get-app-content">
                              <h1>{ translate('app.explorePage.get_the_app') }</h1>
                              <p>{ translate('app.explorePage.app_description') }</p>
                          </div>
                          <div className="get-app-badge">
                              <a href="https://apps.apple.com/us/app/tendoo/id1479534967" target="_blank" rel="noopener noreferrer"><img src={AppStoreWhite} alt="" /></a>
                              <a href="https://play.google.com/store/apps/details?id=com.apper.tendoo" target="_blank" rel="noopener noreferrer"><img src={GooglePlayStoreWhite} alt="" /></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

function mapStateToProps(state){
  return ({
            cookbooks: state.cookbooks,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(GetApp)
