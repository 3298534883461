import React
  from 'react';
import PureImage
  from '../../../components/shared/PureImage';

class ToolsImageProduct extends React.Component {
  _isMounted = false
	constructor(props) {
		super(props);

		this.state = {
		}
	}

  UNSAFE_componentWillMount() {
		this._isMounted = true;
  }

  renderToolsImg(){
    if(this.props.toolImagePath != undefined){
      return (
        <div className="img-syle" data-toggle="modal" data-target="#brand">
          <PureImage src={this.props.toolImagePath} />
        </div>
      )
    } else {
      return <div></div>
    }
  }

  render() {
    if(this._isMounted) {
      return (
        this.renderToolsImg()
      )
    } else {
      return (<div></div>)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default ToolsImageProduct
