import { FETCH_TAGS }
  from '../constants/types';

import api
  from '../helpers/api'
import { getLanguage }
  from "../services/token";

export function fetchTags() {
  return function(dispatch) {
    api.get(`/tag?LangId=${getLanguage}`)
      .then(response => {
        dispatch({
          type: FETCH_TAGS,
          payload: response
        })
      })
      .catch((err) => {
        console.log("FETCH TAGS ERROR", err)
      })
  }
}
