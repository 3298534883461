import {
    FETCH_SHOPPING_DEP,
    REMOVE_SHOPPING_RECIPE,
    ADD_RECIPE_TO_SHOPPING_LIST,
    FETCH_SHOPPING_RECIPES
  } from '../constants/types';

import api
  from '../helpers/api'

import qs
  from 'qs';

import { getLanguage }
  from "../services/token";

export function shoppingDepartment() {
  return function(dispatch) {
    api.get(`/shopping/departement?LangId=${getLanguage}`)
      .then(response => {
        dispatch({
          type: FETCH_SHOPPING_DEP,
          payload: response
        })
      })
      .catch((err) => {
        console.log("SHOPPING DEPARTMENT ERROR", err)
      })
  }
}

export function shoppingRecipes(stopLoading=null) {
  return function(dispatch) {
    api.get(`/shopping/recipe?LangId=${getLanguage}`)
      .then(response => {
        dispatch({
          type: FETCH_SHOPPING_RECIPES,
          payload: response
        })
        stopLoading && stopLoading()
      })
      .catch((err) => {
        stopLoading && stopLoading()
        console.log("SHOPPING RECIPES ERROR", err)
      })
  }
}

export function removeShoppingRecipes(recipeId) {
  return function(dispatch) {
    api.delete(`/shopping/recipe/${recipeId}`)
      .then(response => {
        dispatch({
          type: REMOVE_SHOPPING_RECIPE,
          payload: recipeId
        })
      })
      .catch((err) => {
        console.log("REMOVE SHOPPING RECIPES ERROR", err)
      })
  }
}

export function addRecipeToShopping( recipeId, persons, recipeAlert="pl") {
  return function(dispatch) {
    let data = {
      "RecipeId": recipeId,
      "Persons": persons
    }
    api.post('/shopping/recipe/', qs.stringify(data))
    .then(response => {
      dispatch({
        type: ADD_RECIPE_TO_SHOPPING_LIST,
        payload: response
      })
      var answer = window.confirm("Recipe has been Added successfully to shopping list")
    	if (answer){
        window.$('#shopingIngredients').modal('hide');
    	}
    	else{
    		alert("Thanks for sticking around!")
    	}
      dispatch(shoppingDepartment())
    })
    .catch((err) => {
      if(err.data && err.data.Message){
        alert(err.data.Message)
        // alert(err.data.Message)
      }
      console.log("ADD RECIPE TO SHOPPING ERROR", err)
    })
  }
}

export function updateShopppingPersons(shoppingId, persons) {
  return function(dispatch) {
    let data = {
      "Persons": persons
    }

    api.patch(`/shopping/${shoppingId}/persons?LangId=${getLanguage}`, qs.stringify(data))
    .then(response => {
      dispatch({
        type: FETCH_SHOPPING_RECIPES,
        payload: response.Recipes
      })
      dispatch({
        type: FETCH_SHOPPING_DEP,
        payload: response.Departments
      })
    })
    .catch((err) => {
      console.log("ADD RECIPE TO SHOPPING ERROR", err)
    })
  }
}
