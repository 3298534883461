import React from 'react';

const Smoothie = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
      <path d="M710 1264 c-6 -14 -10 -29 -10 -34 0 -5 -5 -31 -11 -58 l-10 -49 -54
      -12 c-110 -25 -201 -118 -212 -216 -5 -39 -9 -45 -29 -45 -23 0 -24 -3 -24
      -69 0 -65 2 -70 23 -73 22 -3 22 -6 39 -273 9 -148 17 -280 17 -292 l1 -23
      259 0 259 0 6 63 c3 34 11 167 17 294 l12 233 24 0 c22 0 23 3 23 70 0 67 -1
      70 -24 70 -20 0 -24 6 -30 48 -12 91 -100 188 -187 207 -34 8 -33 3 -14 95 8
      40 13 76 9 81 -3 5 -21 9 -40 9 -28 0 -36 -5 -44 -26z m-43 -206 c-3 -13 -14
      -65 -23 -115 l-18 -93 -93 0 c-87 0 -93 1 -93 20 0 29 26 88 55 126 27 35 116
      84 153 84 20 0 23 -4 19 -22z m130 11 c81 -30 144 -103 158 -181 l7 -38 -125
      0 -124 0 13 58 c7 31 18 83 24 115 6 31 13 57 15 57 2 0 16 -5 32 -11z m213
      -289 l0 -40 -310 0 -310 0 0 40 0 40 310 0 310 0 0 -40z m-50 -87 c0 -10 -7
      -128 -15 -263 -8 -135 -15 -253 -15 -262 0 -17 -18 -18 -230 -18 l-230 0 0 28
      c0 15 -7 128 -15 252 -8 124 -15 237 -15 253 l0 27 260 0 c241 0 260 -1 260
      -17z"/>
      </g>
    </svg>
  )
}

export default Smoothie
