import React
  from "react";
import { Link }
  from "react-router-dom";
import { Route }
  from "react-router-dom";
import ChangePassword
  from "./changePassword";
import EditProfile
  from "./editProfile";
import Feedback
  from "./feedback";
import Language
  from "./language";
import Measurements
  from "./measurements";
import PushNotification
  from "./pushNotification";
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import DeleteAccountModal
  from "../../../components/shared/modals/DeleteAccountModal";
import { getToken }
  from '../../../services/token';
import SuccessMessage
  from '../../../components/shared/SuccessMessage';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.checkPath(),
      titleSuccess: "Ingredient successful",
      descriptionSuccess: "5 Ingredients in your shopping list",
      successMessage: false
    };
  }

  componentDidMount() {
    if(!getToken) {
      this.props.history.push("/");
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.location.state) {
      this.setState({currentPage: newProps.location.state.currentPage})
    }
  }

  checkPath() {
    switch(window.location.pathname) {
     case '/settings/edit_profile':
       return 1;
     case '/settings/change_password':
       return 2;
     case '/settings/language':
       return 3;
     case '/settings/measurements':
       return 4;
     case '/settings/push_notification':
       return 5;
     case '/settings/feedback':
       return 6;
     default:
       return 1;
    }
  }

  setMessage(title, description) {
    let _this = this
    this.setState({
      titleSuccess: title,
      descriptionSuccess: description,
      successMessage: true
    }, () => {
      setTimeout(function() {
        _this.setState({
          titleSuccess: "",
          descriptionSuccess: "description",
          successMessage: false
        })
      }, 4500);
    })
  }

  render() {
   let { currentPage } = this.state
   let { translate } = this.props
   return (
     <>
      <section className="settings section-top-70">
        <div className="container">
          <div className="settings-title">
            <h1>{translate('app.settings.settings')}</h1>
          </div>
          <div className="row">
            <div className="col-sm-3 col-md-3">
              <div className="settings-menu">
                <ul>
                  <li className={currentPage === 1 ? "active" : ""}>
                    <Link
                      onClick={() => this.setState({currentPage: 1})}
                      to={`/settings/edit_profile`}
                    >
                      {translate('app.settings.edit_profile')}
                    </Link>
                  </li>
                  <li className={currentPage === 2 ? "active" : ""}>
                    <Link
                      onClick={() => this.setState({currentPage: 2})}
                      to={`/settings/change_password`}>
                      {translate('app.settings.change_password')}
                    </Link>
                  </li>
                  <li className={currentPage === 3 ? "active" : ""}>
                    <Link
                      onClick={() => this.setState({currentPage: 3})}
                      to={`/settings/language`}>
                      {translate('app.settings.language')}
                    </Link>
                  </li>
                  <li className={currentPage === 4 ? "active" : ""}>
                    <Link
                      onClick={() => this.setState({currentPage: 4})}
                      to={`/settings/measurements`}>
                      {translate('app.settings.measurements')}
                    </Link>
                  </li>
                  <li className={currentPage === 5 ? "active" : ""}>
                    <Link
                      onClick={() => this.setState({currentPage: 5})}
                      to={`/settings/push_notification`}>
                      {translate('app.settings.push_notification')}
                    </Link>
                  </li>
                  <li className={currentPage === 6 ? "active" : ""}>
                    <Link
                      onClick={() => this.setState({currentPage: 6})}
                      to={`/settings/feedback`}>
                      {translate('app.settings.feedback')}
                    </Link>
                  </li>
                  <li className="last-item">
                    <a href={undefined}  data-toggle="modal" data-target="#delete">
                      {translate('app.settings.delete_account')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-9 col-md-9">
              <Route path="/settings/edit_profile"
                component={() =>
                  <EditProfile
                    setMessage={(title, description) => this.setMessage(title, description)}
                  />
                }
              />
              <Route path="/settings/change_password"
                component={() =>
                  <ChangePassword
                    setMessage={(title, description) => this.setMessage(title, description)}
                  />
                }
              />
              <Route path="/settings/language"
                component={() =>
                  <Language
                    setMessage={(title, description) => this.setMessage(title, description)}
                    me={this.props.me}
                    translate={translate}
                  />
                }
              />
              <Route path="/settings/measurements"
                component={() =>
                  <Measurements
                    setMessage={(title, description) => this.setMessage(title, description)}
                  />
                }
              />
              <Route path="/settings/push_notification" component={PushNotification} />
              <Route path="/settings/feedback"
                component={() =>
                  <Feedback
                    setMessage={(title, description) => this.setMessage(title, description)}
                  />
                }
              />
            </div>
          </div>
        </div>

        <DeleteAccountModal />

      </section>
      { this.state.successMessage &&
        <SuccessMessage
          titleSuccess={this.state.titleSuccess}
          descriptionSuccess={this.state.descriptionSuccess}
        />
      }
    </>
    )
  }
};

function mapStateToProps(state){
  return ({
            me: state.current_user,
            recipes: state.recipes,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(Settings)
