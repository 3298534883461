import React
  from 'react';

import { Provider }
  from 'react-redux';

import store
  from './store';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Auth
  from './pages/auth'

import InsideApp
  from './pages/inside'

import { initialize, addTranslation }
  from 'react-localize-redux';

import Authorization
  from './components/highOrder/Authorization'

import translations
  from './translations/index.json';

import ReactGA
  from 'react-ga';

import MobileDetect
  from 'mobile-detect'

class App extends React.Component{
  componentDidMount() {
    ReactGA.initialize('UA-166973106-1');
    ReactGA.pageview(window.location.pathname);
  }

  UNSAFE_componentWillMount(){
    var detector = new MobileDetect(window.navigator.userAgent)
    if(detector.mobile()) {
      this.suggestStore(detector)
    }
    if( store ) {
      const languages = ['en', 'de'];
      let defaultLanguage = localStorage.getItem('language')
      store.dispatch(initialize(languages,
        { defaultLanguage:
          (defaultLanguage || 'en')
        }
      ));

      store.dispatch(addTranslation(translations));
    }
  }

  suggestStore(detector) {
    const r = window.confirm(`To use all features with a better experience use the Tendoo app`);

    if (r === true) {
      let appSuggest = ""
      if(detector.os() === "iOS") {
        appSuggest = "https://apps.apple.com/mk/app/tendoo/id1479534967"
      } else {
        appSuggest = "https://play.google.com/store/apps/details?id=com.apper.tendoo&hl=en"
      }
        window.location.href = appSuggest;
      // window.location(appSuggest, '_blank');
    }

  }

  render(){

    return(
      <Provider store={ store }>
        <Router>
          <div className="App">
            <Switch>
              <Route path="/auth" component={Authorization(Auth)} />
              <Route path="/" component={InsideApp} />
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App;
