import React
    from 'react'
import Background
    from '../../../assets/img/bg-author.jpeg'

const BackgroundImage = (props) => {
  return(
    <section className="authors-banner" style={{backgroundImage: `url(${Background})`}}>
      <div className="container">
        <div className="author-banner-content">
          <h1>{props.translate('app.authors.our_lovely_chefs')}</h1>
          <p>{props.translate('app.authors.explore_chefs')}</p>
        </div>
      </div>
    </section>
  )
}

export default BackgroundImage
