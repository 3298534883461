import api
  from './api'
import { getLanguage }
  from "../services/token";

export function fetchAuthors(setAuthors) {
  api.get(`/author?LangId=${getLanguage}`)
  .then((response) => {
    setAuthors(response)
  })
  .catch((err) => {
    console.log("FETCH AUTHORS ERROR", err)
  })
}

export function fetchBestAuthors(setBestAuthors) {
  api.get(`/author/best?limit=4&LangId=${getLanguage}`)
  .then((response) => {
    console.log(response)
    setBestAuthors(response)
  })
  .catch((err) => {
    console.log("FETCH AUTHORS ERROR", err)
  })
}

export function subscribeUser(token, authorId, userSubscribed) {
  api.patch(`/author/${authorId}/subscribe`)
    .then((response) => {
      userSubscribed(response)
    })
    .catch((err) => {
      console.log("SUBSCRIBE USER ERROR", err.response.data)
    })
}

export function authSubscribes(token, authorId, saveAuthSubscribes) {
  api.get(`/author/${authorId}/subscription?LangId=${getLanguage}`)
    .then((response) => {
      saveAuthSubscribes(response)
    })
    .catch((err) => {
      console.log("AUTH SUBSRCIBES ERROR", err.response)
    })
}


export function unSubscribeUser(token, authorId, userSubscribed) {
  api.patch(`/author/${authorId}/unsubscribe`)
    .then((response) => {
      userSubscribed(response.data)
    })
    .catch((err) => {
      console.log("UNSUBSCRIBE USER ERROR", err.response.data)
    })
}

export function fetchAuthor(authorId, setAuthor) {
  api.get(`/author/${authorId}?LangId=${getLanguage}`)
  .then((response) => {
    setAuthor(response)
  })
  .catch((err) => {
    if(err.data.Message === "The request is invalid.") {
      window.location.href = '/404';
    }
    console.log("FETCH AUTHOR ERROR", err.response)
  })
}

export function fetchAuthorCookbooks(authorId, setAuthorCookboks) {
  api.get(`/book?AuthorId=${authorId}&LangId=${getLanguage}`)
  .then((response) => {
    setAuthorCookboks(response)
  })
  .catch((err) => {
    console.log("FETCH AUTHOR COOKBOOKS", err.response)
  })
}
