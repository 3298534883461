import React, { Component }
  from "react";
import Slider
  from 'rc-slider';
import 'rc-slider/assets/index.css';
import { convertHHMMSS }
 from "../../../services/converts";

class RangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.value !== newProps.value) {
      this.setState({value: newProps.value})
    }
  }

  onChangeSlider(value) {
    this.setState({value: value})
    this.props.changeMainValue(value)
  }

  render() {
    return (
      <div className="range-wrapp">
        <div className="range-head">
          <h1 className="filter-inner-title">{this.props.label}</h1>
          <p>
            <span id="time-value">{this.props.timeMode ? this.state.value : this.state.value}</span> {this.props.type}
          </p>
        </div>
        <Slider
          defaultValue={this.state.value}
          value={this.state.value}
          trackStyle={{backgroundColor: "#ff5f00", height: 0.9, marginTop: 8 }}
          handleStyle={{width: 26, height: 26, border: "1px solid #ff5f00"}}
          railStyle={{height: 0.9, marginTop: 8}}
          onChange={(v) => this.onChangeSlider(v)}
          min={this.props.min}
          max={this.props.max}
        />
      </div>
    );
  }
}

export default RangeSlider
