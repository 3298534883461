import React from 'react';

class CompleteVideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playVideo: false,
      autoPlay: false,
      videoActions: [],
      actionMillis: [],
      currentVideo: 0,
      currentTime: 0,
      currentVideoTime: 0,
      userActions: true,
      videoText: null,
      fullScreen: this.props.fullScreen,
      autoStop: false,
      muted: false
    };

    // window.toggleMoreVideo()
  }

  componentDidMount() {
    // window.toggleMoreVideo()
    if(this.props.recipe && this.props.recipe.Videos) {
      this.setState({video: this.props.recipe.Videos.Main, coverPath: this.props.recipe.CoverPath})
    }
  }

  UNSAFE_componentWillUnmount(){

  }

  async UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.recipe !== newProps.recipe) {
      if(newProps.recipe.Videos.Main) {
        await this.setState({video: newProps.recipe.Videos.Main, coverPath: newProps.recipe.CoverPath})
      }
    }

    if(newProps.fullScreen !== this.state.fullScreen){
      this.setState({fullScreen: newProps.fullScreen, video: this.props.recipe.Videos.Main})
    }
  }

  displayVideoPlayer() {
    if(this.state.video) {
      return (
        <video ref="vidRef" width="100%" height="100%" controls>
          <source src={this.state.video.Mp4FilePath}
                  type="video/mp4"/>
        </video>
      )
    }
  }

  closeAndPauseVideo() {
    this.refs.vidRef && this.refs.vidRef.pause()
  }

  render() {
    let { fullScreen } = this.state
    return(
      <div className={`${fullScreen ? 'modal video-modal fade in fullscreen' : 'modal video-modal fade in'}`}
           id="complete-video"
           role="dialog"
           data-backdrop="static"
           data-keyboard="false"
           style={{overflowY: 'hidden'}}>
        <div className="modal-dialog">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => this.closeAndPauseVideo()}>
              &times;
            </button>
            {this.displayVideoPlayer()}
          </div>
        </div>
      </div>
  )}
}

export default CompleteVideoModal
