import React from 'react';

const AlcoholicDrinks = (props) => {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
      <path d="M635 1238 c-11 -7 -41 -12 -67 -13 -29 -1 -54 -8 -66 -18 -10 -9 -30
      -17 -45 -17 -15 0 -33 -10 -45 -25 -11 -14 -25 -25 -31 -25 -35 0 -53 -113
      -25 -157 13 -21 13 -23 -5 -23 -84 0 -135 -104 -148 -301 -10 -161 22 -316 79
      -376 18 -19 32 -23 80 -23 l58 0 0 -39 c0 -70 6 -71 316 -71 250 0 272 1 287
      18 15 17 17 56 17 349 l0 331 30 12 c35 15 50 37 50 77 0 15 7 37 15 47 20 27
      19 55 -5 94 -11 18 -20 45 -20 60 0 17 -10 38 -24 52 -41 37 -110 48 -261 38
      -27 -2 -61 3 -75 9 -31 16 -89 16 -115 1z m100 -37 c20 -12 42 -16 63 -13 18
      2 39 -1 47 -8 11 -10 20 -8 41 9 32 25 52 26 82 5 12 -8 30 -13 41 -9 10 3 29
      -3 45 -16 23 -18 27 -28 23 -54 -4 -24 0 -36 14 -48 23 -20 25 -60 4 -77 -9
      -7 -13 -21 -10 -31 9 -28 -2 -55 -28 -66 -33 -15 -66 1 -73 37 -6 29 -27 40
      -39 20 -10 -16 -45 -11 -73 10 -14 12 -35 20 -46 19 -37 -2 -46 1 -46 16 0 8
      -10 25 -23 37 -22 22 -33 24 -159 26 -145 4 -178 -4 -178 -38 0 -13 -7 -20
      -18 -20 -15 0 -18 8 -18 50 0 44 3 51 23 56 13 4 23 12 23 20 0 18 29 33 52
      26 13 -3 25 3 35 18 14 19 22 22 60 16 36 -5 50 -2 70 14 32 25 50 25 88 1z
      m-3 -198 c10 -9 18 -22 18 -29 0 -18 32 -34 68 -34 16 0 35 -7 42 -15 7 -8 29
      -15 50 -15 29 0 41 -6 54 -25 9 -14 23 -25 31 -25 13 0 15 -45 15 -328 0 -242
      -3 -331 -12 -340 -17 -17 -519 -17 -536 0 -9 9 -12 112 -12 404 0 216 3 399 6
      408 9 24 249 23 276 -1z m-312 -103 c0 -17 -7 -20 -45 -20 -39 0 -46 -4 -60
      -29 -34 -67 -43 -333 -13 -446 14 -56 34 -75 80 -75 31 0 38 -3 38 -20 0 -17
      -7 -20 -48 -20 -52 0 -67 10 -94 65 -64 128 -52 451 19 536 21 25 32 29 74 29
      42 0 49 -3 49 -20z m0 -296 l0 -245 -32 3 c-55 5 -63 35 -63 238 0 214 9 250
      61 250 l34 0 0 -246z"/>
      <path d="M531 816 c-8 -9 -11 -86 -9 -257 l3 -244 40 -3 c64 -5 65 0 65 259 0
      194 -2 230 -16 243 -19 20 -68 21 -83 2z m57 -249 c2 -222 2 -227 -18 -227
      -19 0 -20 6 -20 223 0 123 3 227 7 231 23 23 28 -13 31 -227z"/>
      <path d="M691 816 c-8 -9 -11 -86 -9 -257 l3 -244 45 0 45 0 3 244 c2 171 -1
      248 -9 257 -6 8 -24 14 -39 14 -15 0 -33 -6 -39 -14z m57 -249 c2 -222 2 -227
      -18 -227 -19 0 -20 6 -20 223 0 123 3 227 7 231 23 23 28 -13 31 -227z"/>
      <path d="M846 814 c-14 -13 -16 -49 -16 -243 0 -259 1 -264 65 -259 l40 3 3
      244 c2 171 -1 248 -9 257 -15 19 -64 18 -83 -2z m62 -247 c2 -222 2 -227 -18
      -227 -19 0 -20 6 -20 223 0 123 3 227 7 231 23 23 28 -13 31 -227z"/>
      </g>
    </svg>
  )
}

export default AlcoholicDrinks
