import React
    from 'react'

const LoginIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path fill="#FF5F00" d="M7.86 15.238c2.465 0 4.526-.26 6.181-.777.363-.113.587-.477.524-.852l-.321-1.933c-.143-.858-.848-1.51-1.715-1.585-1.433-.124-2.99-.186-4.67-.186-1.68 0-3.237.062-4.67.186-.866.075-1.572.727-1.714 1.585l-.322 1.933c-.062.375.162.739.525.852 1.655.517 3.716.777 6.181.777zm0 .762c-2.538 0-4.673-.27-6.41-.812-.725-.226-1.173-.954-1.048-1.704l.321-1.933c.2-1.2 1.188-2.114 2.4-2.219 1.456-.126 3.035-.19 4.736-.19 1.702 0 3.28.064 4.736.19 1.213.105 2.2 1.018 2.4 2.22l.322 1.932c.125.75-.323 1.478-1.049 1.704-1.736.542-3.872.812-6.409.812zM4.811 3.81c0 1.683 1.364 3.047 3.047 3.047 1.684 0 3.048-1.364 3.048-3.047 0-1.684-1.364-3.048-3.048-3.048-1.683 0-3.047 1.364-3.047 3.048zm-.762 0C4.05 1.705 5.755 0 7.86 0c2.103 0 3.809 1.705 3.809 3.81 0 2.104-1.705 3.809-3.81 3.809-2.104 0-3.81-1.705-3.81-3.81z"/>
    </svg>
  )
}

export default LoginIcon
