import React
  from 'react';
import ReactLoading
  from 'react-loading';
import RenderSearch
  from '../../../components/shared/RenderSearch'
import SearchNotFound
  from '../SearchNotFound'
import { getTranslate }
  from 'react-localize-redux';
import { connect }
  from 'react-redux';

class AllItems extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

  displayItems() {
    let { all, title, checkStatus, state, translate } = this.props
    if(!checkStatus) {
      return (
        all.map((item, index)=>{
          if(state[item].length != 0) {
            return (
              <div key={index}>
                <div className="search-result-title" style={{marginTop: 30}}>
                  <h1>{translate('app.search.'+item.charAt(0)+item.slice(1))}</h1>
                </div>
                { state[item].map((results, index)=>{
                  return (
                    <RenderSearch
                      key={index}
                      name={results.Name}
                      source={results.ImagePath ? results.ImagePath : results.CoverPath ? results.CoverPath : ''}
                      id={results.Id}
                      ingradientFilter={item == "ingredients" ? `recipes?IncludedIng[]=${results.Id}` : null}
                      location={item}
                    />
                  )
                })}
              </div>
            )
          }
        })
      )
    } else {
      return (
        <>
          <div className="search-result-title">
            <h1>{title}</h1>
          </div>
          {this.noResults()}
        </>
      )
    }
  }

	render() {
    if(this.props.loading) {
      return (
        <ReactLoading
          type={"spokes"}
          color={"#000"}
          height={18} width={18}
        />
      )
    } else {
      return (
        this.displayItems()
      )
    }
  }

  noResults(){
    return (
      <SearchNotFound />
    )
  }
}


function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(AllItems);
