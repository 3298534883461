import React
  from 'react'

import BlogItem
  from '../../../components/blog/BlogItem'

import Blog1
  from '../../../assets/img/blog-1.jpg'

import Blog2
  from '../../../assets/img/blog-2.jpg'

import Blog3
  from '../../../assets/img/blog-3.jpg'

import Blog6
  from '../../../assets/img/blog-6.jpeg'

import BlogSingle
  from '../../../assets/img/blog-single.jpg'

const BlogDetails = (props) => {
  return(
    <>
      <section className="blog-single-banner" style={{backgroundImage: `url(${Blog6})`}} >
        <div className="blog-single-banner-overlay"></div>
        <div className="container">
          <div className="blog-single-banner-content">
            <h1>Protein Bars for daily life</h1>
            <span className="author">by Jessica Anderson</span>
            <span className="date">Jan 29 2018</span>
          </div>
        </div>
      </section>

      <section className="blog-article section-top-80">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-8 col-md-center">
              <div className="blog-single-content">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.</p>
                <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <img src={BlogSingle} alt="blog-single" className="img-responsive" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.</p>
                <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <div className="blog-single-share">
                  <span>Share this post on:</span>
                  <ul>
                    <li><a href={undefined} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#7a7a7a" fillRule="evenodd" d="M8.546 16H.883A.883.883 0 0 1 0 15.117V.883C0 .395.395 0 .883 0h14.234c.488 0 .883.395.883.883v14.234a.883.883 0 0 1-.883.883H11.04V9.8h2.08l.311-2.415H11.04V5.848c0-.7.194-1.176 1.2-1.176h1.279v-2.16c-.619-.067-1.24-.1-1.863-.1a2.91 2.91 0 0 0-3.11 3.196v1.781H6.461V9.8h2.085V16z"/></svg></a></li>
                    <li><a href={undefined} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 612 612"><path fill="#7a7a7a" d="M612,116.258c-22.525,9.981-46.694,16.75-72.088,19.772c25.929-15.527,45.777-40.155,55.184-69.411 c-24.322,14.379-51.169,24.82-79.775,30.48c-22.907-24.437-55.49-39.658-91.63-39.658c-69.334,0-125.551,56.217-125.551,125.513 c0,9.828,1.109,19.427,3.251,28.606C197.065,206.32,104.556,156.337,42.641,80.386c-10.823,18.51-16.98,40.078-16.98,63.101 c0,43.559,22.181,81.993,55.835,104.479c-20.575-0.688-39.926-6.348-56.867-15.756v1.568c0,60.806,43.291,111.554,100.693,123.104 c-10.517,2.83-21.607,4.398-33.08,4.398c-8.107,0-15.947-0.803-23.634-2.333c15.985,49.907,62.336,86.199,117.253,87.194 c-42.947,33.654-97.099,53.655-155.916,53.655c-10.134,0-20.116-0.612-29.944-1.721c55.567,35.681,121.536,56.485,192.438,56.485 c230.948,0,357.188-191.291,357.188-357.188l-0.421-16.253C573.872,163.526,595.211,141.422,612,116.258z"/>s</svg></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="blog-similar-posts">
                <div className="blog-similar-posts-title">
                  <h1>Similar posts</h1>
                </div>
                <div className="row">
                  <BlogItem
                    source={Blog1}
                    title={"Protein Bars for daily life"}
                    text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
                    date={"Jan 29 2018"}
                    author={"by Jessica Anderson"}
                  />
                  <BlogItem
                    source={Blog2}
                    title={"Protein Bars for daily life"}
                    text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
                    date={"Jan 29 2018"}
                    author={"by Jessica Anderson"}
                  />
                  <BlogItem
                    source={Blog3}
                    title={"Protein Bars for daily life"}
                    text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
                    date={"Jan 29 2018"}
                    author={"by Jessica Anderson"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogDetails
