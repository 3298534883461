import React, { Component }
  from 'react'
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import { forgotPasswordRequest }
  from '../../actions/auth';
import ReactLoading
  from 'react-loading';

class ForgotPassword extends Component {
  state = {
    loading: false,
    email: '',
    sendRequest: false,
    errors: []
  }

  submitResetPasswordRequest(e) {
    e.preventDefault()
    this.setState({loading: true})
    let {
      email,
    } = this.state

    this.props.forgotPasswordRequest(
      email,
      this.afterRequest.bind(this),
      this.catchedErrors.bind(this)
    )
  }

  afterRequest() {
    this.setState({sendRequest: true, loading: false })
  }

  catchedErrors(error){
    this.setState({ error: error.Message, loading: false })
  }

  submitButton() {
    let { translate } = this.props
    if(this.state.loading) {
      return (
        <div style={{marginLeft: "40%" }}>
          <ReactLoading
            type={"spin"}
            height={45} width={45}
          />
        </div>
      )
    } else {
      return (
        <input type="submit"
          className="send"
          value={translate('auth.send')}
          onClick={(e) => this.submitResetPasswordRequest(e) }
        />
      )
    }
  }

  render() {
    let { translate } = this.props
    if(!this.state.sendRequest) {
      return(
        <>
        <div className="login-title">
          <h1>{translate('auth.forgot_password')}</h1>
          <p>{translate('auth.forgot_password_description')}</p>
        </div>
        <div className="login-form">
          <form className="">
            {this.state.error && <p className="form-error" style={{color: 'red', marginBottom: 10}}>{this.state.error}</p>}
            <div className="input-wrap">
              <input type="email"
                className="form-control"
                placeholder={translate('auth.email')}
                value={this.state.email}
                onChange={({ target }) => this.setState({ email: target.value })}
                required />
            </div>
            <div className="submit-button">
              {this.submitButton()}
            </div>
          </form>
        </div>
        </>
      )
    } else {
      return (
        <div className="login-title">
          <h1>Forgot password result?</h1>
          <p>Please check your email and click on the provided link to reset your password</p>
        </div>
      )
    }
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { forgotPasswordRequest })(ForgotPassword)
