import React from 'react'
import BgBlog1 from '../../assets/img/bg-blog-1.jpg'
import BgBlog2 from '../../assets/img/bg-blog-2.jpg'
import BgBlog3 from '../../assets/img/bg-blog-3.jpg'
import { Link } from "react-router-dom"

const BlogSlider = (props) => {
    return(
        <section className="explore-slider blog-slider">
            <div className="main-explore-slider">
                <div className="item">
                    <img src={BgBlog1} alt="bg-explorer" />
                    <div className="container">
                        <div className="content">
                            <span className="date">Jan 29, 2019</span>
                            <h1>Protein Bars for daily life</h1>
                            <p>by Jessica Anderson</p>
                            <div className="content-discover">
                                <Link className="button" to={`/blog/:id`}>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={BgBlog2} alt="bg-explorer" />
                    <div className="container">
                        <div className="content">
                            <span className="date">Jan 29, 2019</span>
                            <h1>Protein Bars for daily life</h1>
                            <p>by Jessica Anderson</p>
                            <div className="content-discover">
                                <Link className="button" to={`/blog/:id`}>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={BgBlog3} alt="bg-explorer" />
                    <div className="container">
                        <div className="content">
                            <span className="date">Jan 29, 2019</span>
                            <h1>Protein Bars for daily life</h1>
                            <p>by Jessica Anderson</p>
                            <div className="content-discover">
                                <Link className="button" to={`/blog/:id`}>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="second-explore-slider">
                <div className="item">
                    <img src={BgBlog1} alt="bg-explorer" />
                    <div className="content">
                        <h1>Protein Bars for daily life</h1>
                        <p>by Jessica Anderson</p>
                    </div>
                </div>
                <div className="item">
                    <img src={BgBlog2} alt="bg-explorer" />
                    <div className="content">
                        <h1>Protein Bars for daily life</h1>
                        <p>by Jessica Anderson</p>
                    </div>
                </div>
                <div className="item">
                    <img src={BgBlog3} alt="bg-explorer" />
                    <div className="content">
                        <h1>Protein Bars for daily life</h1>
                        <p>by Jessica Anderson</p>
                    </div>
                </div>
            </div>
        </section>
    )
  }

  export default BlogSlider
