import React
  from 'react'
import { Link }
  from "react-router-dom"
import { getToken }
  from "../../../services/token";
import PureImage
  from '../../../components/shared/PureImage'
import { favoriteCookbook, unFavoriteCookbook }
  from '../../../actions/favourites';
import { connect }
  from 'react-redux';
import { FavShapeIcon }
  from '../../../assets/icons';
import StarRatings
  from 'react-star-ratings';
import { motion }
  from "framer-motion"
import { setCurrentPath }
  from '../../../helpers/currentPath';

class AuthorCookBooksCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing:false,
      image:'',
      IsFavorized: props.item.IsFavorized,
      favouriteLoading: false
    };
  }

  renderAuthimg(){
    if(this.props.source != undefined){
      return <PureImage src={this.props.source} className="img-responsive" />
    }
  }

  addFavorite() {
    let { item } = this.props
    this.setState({favouriteLoading: true})
    this.props.favoriteCookbook(item, this.updateCookbook.bind(this))
  }

  removeFavorite() {
    let { item } = this.props
    this.setState({favouriteLoading: true})
    this.props.unFavoriteCookbook(item, this.updateCookbook.bind(this))
  }

  updateCookbook(IsFavorized){
    this.setState({
      IsFavorized: IsFavorized,
      favouriteLoading: false
    })
  }

  displayFavouriteIcon() {
    let { favouriteLoading, IsFavorized } = this.state
    if(favouriteLoading) {

    } else {
      if(IsFavorized) {
        return (
          <motion.div
            className={"toggle-fav-book fav-book-on"}
            onClick={getToken ? () => this.removeFavorite() : () => setCurrentPath()}
            initial={{ scale: 0 }}
            animate={{ rotate: 360, scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 15
            }}
          />
        )
      } else {
        return (
          <motion.div
            className={"toggle-fav-book fav-book-off"}
            onClick={getToken ? () => this.addFavorite() : () => setCurrentPath()}
            initial={{ scale: 0 }}
            animate={{ rotate: 360, scale: 1 }}
            whileHover={{ scale: 1.5, rotate: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 15
            }}
          />
        )
      }
    }
  }

  render(){
    let { item } = this.props
    return(
      <div className={ (!this.props.bigger ? this.props.slider === false ? "col-sm-6 col-md-4 cookbook-item" : "col-sm-6 col-md-4" : this.props.slider === false ? "col-sm-6 col-md-4 cookbook-item" : "col-sm-6 col-md-4" )}>
        <Link href={undefined}  to={{pathname:`/cookbooks/` + this.props.id, aboutProps:{id: this.props.id}}}>
          <div className="cookbooks-item">
            <div className="cookbooks-img">
              {this.renderAuthimg()}
            </div>
            <div className="cookbooks-content">
              <h1>{this.props.heading}</h1>
              <span>by Jamie Oliver</span>
              <div className="cookbooks-content-footer">
                <p style={{width: 175}}>{this.props.paragraph}</p>
                <div className="content-rate">
                  <StarRatings
                    rating={item.Rating ? item.Rating.TotalRatings : 0}
                    starRatedColor="#F5BD00"
                    starDimension="16px"
                    starSpacing="0px"
                    numberOfStars={5}
                    name='rating'
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="cookbooks-assets" style={{right: 28}}>
          <FavShapeIcon />
          {this.displayFavouriteIcon()}
        </div>
      </div>

    )
  }
}

export default connect(null,
  { favoriteCookbook, unFavoriteCookbook })(AuthorCookBooksCard);
