import React
  from 'react'
import AuthorDetailsBck
  from '../../../components/authors/details/AuthorDetailsBck';
import AuthorInfo
  from '../../../components/authors/details/AuthorInfo';
import AuthorDescription
  from '../../../components/authors/details/AuthorDescription';
import AuthorCookbooks
  from '../../../components/authors/details/AuthorCookbooks';
import {
  fetchAuthor,
  fetchAuthorCookbooks
} from '../../../helpers/author';
import { unSubscribeUser, subscribeUser, authSubscribes }
  from '../../../helpers/author';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif'
import { connect }
  from 'react-redux';
import { getTranslate }
 from 'react-localize-redux';
import { getToken }
 from "../../../services/token";
import SuccessMessage
  from '../../../components/shared/SuccessMessage';
import ReactGA
 from 'react-ga';

class AuthorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: null,
      authorCookbooks: null,
      titleSuccess: "Ingredient successful",
      descriptionSuccess: "5 Ingredients in your shopping list",
      successMessage: false
    };
  }

  componentDidMount() {
    fetchAuthor(this.props.match.params.id, this.addCookBooks.bind(this))
    fetchAuthorCookbooks(this.props.match.params.id, this.addAuthorCookBooks.bind(this))

    ReactGA.event({
      category: 'Author navigation',
      action: `Go to: ${window.location.pathname}`
    });
  }

  addCookBooks(data){
    let aid = this.props.match.params.id
    getToken && authSubscribes(getToken, aid, this.saveAuthSubscribes.bind(this))
    this.setState({
       author: data
    })
  }

  onUnSubscribe(authorId){
    let { translate } = this.props
    this.setState({
      IsSubscribed:false
    })

    unSubscribeUser(getToken, authorId, this.userSubscribed.bind(this))
    this.setMessage(translate('app.recipeDetails.unsubscribe'), translate('app.recipeDetails.unsubscribe_success_message'))
  }

  onSubscribe(authorId){
    let { translate } = this.props
    this.setState({
      IsSubscribed:true
    })
    subscribeUser(getToken, authorId, this.userSubscribed.bind(this))
    this.setMessage(translate('app.recipeDetails.subscribe'), translate('app.recipeDetails.subscribe_success_message'))
  }

  userSubscribed(data){
    this.setState({
      ...data,
    })
  }

  saveAuthSubscribes(data){
    this.setState({
      ...data,
    })
  }

  addAuthorCookBooks(data){
    this.setState({
       authorCookbooks: data
    })
  }

  setMessage(title, description) {
    let _this = this
    this.setState({
      titleSuccess: title,
      descriptionSuccess: description,
      successMessage: true
    }, () => {
      setTimeout(function() {
        _this.setState({
          titleSuccess: "",
          descriptionSuccess: description,
          successMessage: false
        })
      }, 4500);
    })
  }

  render() {
    let aid = this.state.author && this.state.author.Id

    if(this.state.author == null || this.state.authorCookbooks == null) {
      return (
        <div style={{paddingLeft: "45%", paddingBottom: "15%", paddingTop: "15%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }

    return(
      <>
        <section className="author-profile">
          <AuthorDetailsBck
            image={this.state.author.ImagePath}
          />
          <div className="container">
            <div className="row">
              <AuthorInfo
                author={this.state.author}
                translate={this.props.translate}
                isSubscribed={this.state.IsSubscribed}
                subscribe={()=> this.state.IsSubscribed ? this.onUnSubscribe(aid) : this.onSubscribe(aid)}
              />
            <div className="col-xs-12 col-sm-12 col-md-9">
                <AuthorDescription
                  authorDescription={this.state.author.Description}
                />
                <AuthorCookbooks
                  items={this.state.authorCookbooks}
                />
              </div>
            </div>
          </div>
        </section>
      { this.state.successMessage &&
        <SuccessMessage
          titleSuccess={this.state.titleSuccess}
          descriptionSuccess={this.state.descriptionSuccess}
        />
      }
      </>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {  })(AuthorDetails);
