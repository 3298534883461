import { BASE_URL, CURRENT_USER }
  from '../constants/types';

import qs
  from 'qs';

import api
  from '../helpers/api'

export function updateMeData( image, data, afterUpdate, catchedErrors ) {
  return async function (dispatch) {
    const url = `${BASE_URL}/me/`
    var imgResult = null
    if(image){
      var form = new FormData();
      await form.append("avatar", image);

      const config = {
        headers: {
          "enctype": "multipart/form-data",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        mimeType: "multipart/form-data",
        contentType: false
      };

      await api.post('/me/avatar', form, config)
      .then((data) => {
        imgResult = data.Path
        dispatch({
          type: CURRENT_USER,
          payload: {
            AvatarPath: data.Path
          }
        })
      })
      .catch(err => {
        console.log("UPDATE MY DATA ERROR", err)
      })
    }

    api.patch(url, qs.stringify(data))
    .then((data) => {
      if(data.Code) {
        catchedErrors(data.Message)
      } else {
        dispatch({
          type: CURRENT_USER,
          payload: data
        })
        afterUpdate(imgResult)
      }
    })
    .catch(err => {
      catchedErrors(err.data.Message)
    })
  }
}

export function current_user(userArgs) {
  return (dispatch) => {
    dispatch({
      type: CURRENT_USER,
      payload: userArgs
    })
  }
}

export function updateNotification(notificationValue, afterRequest) {
  return (dispatch) => {
    const url = `${BASE_URL}/me/update_notifications`
    let data = {
      "Notifications": notificationValue,
    }

    api.patch(url, qs.stringify(data))
    .then(data => {
      afterRequest(data.Notifications)
      dispatch({
        type: CURRENT_USER,
        payload: data
      })
    })
    .catch(error => {
      console.log(data)
    })
  }
}
