import React
  from "react";
import { getToken }
  from "../../services/token";
import { logOut, userSignedIn }
  from '../../actions/auth'
import { searchRecipes, searchCookbooks, searchIngredients, getSuggestions, searchAuthors }
  from '../../helpers/search';
import { RecipeItems, CookbookItems, AllItems, IngredientItems, AuthorItems }
  from './searchTabs';
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';

class Search extends React.Component {
  constructor(props) {
    super(props);
    // this.startSearch = this.startSearch.bind(this);
    this.state = {
      recipes: [],
      cookbooks: [],
      ingredients: [],
      authors: [],
      suggestions:[],
      all:["recipes", "cookbooks", "authors", "ingredients"],
      current:'all',
      showItems: false,
      recipesSearchLoading: false,
      loading:false,
      search:'',
      typing: false,
      typingTimeout: 0,
      recipeDoneLoading:false,
      cookbooksDoneLoading:false,
      authorsDoneLoading:false,
      ingredientsDoneLoading:false
    };
  }

  componentDidMount(){
    if(getToken){
      this.props.userSignedIn(getToken)
    }
    let { term } = this.props
    this.setState({loading: true})
    getSuggestions(term ? term : "a", this.updateSuggestions.bind(this))
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let _this = this
    setTimeout(function() {
      _this.searchInput.focus()
    }, 500);
  }

  changeSerachTerm(text) {
    this.setState({search: text, showItems: false, loading:true})
  }

  startSearch(event){
    const self = this;

    self.setState({
      recipeDoneLoading:false,
      cookbooksDoneLoading:false,
      authorsDoneLoading:false,
      ingredientsDoneLoading:false
    })

    if (self.state.typingTimeout) {
       clearTimeout(this.state.typingTimeout);
    }

    self.setState({
       search: event,
       typing: false,
       typingTimeout: setTimeout(function () {
          self.changeSerachTerm(event)
          self.changeShowItems()
          self.getSuggest(event)
          self.setState({showItems: true, loading: true})
        }, 1000)
    });
}

changeShowItems() {
    let _this = this
    _this.requestSearchRecipes()
  }

  requestSearchRecipes() {
    var { search } = this.state
    searchRecipes(search, this.addRecipes.bind(this))
  }

  requestSearchBooks() {
    var { search } = this.state
    searchCookbooks(search, this.addCookbooks.bind(this))
  }

  requestIngredients() {
    var { search } = this.state
    searchIngredients(search, this.addIngredients.bind(this))
  }

  requestAuthors() {
    var { search } = this.state
    searchAuthors(search, this.addAuthors.bind(this))
  }

  addRecipes(data) {
    this.setState({recipes: data, recipeDoneLoading:true})
    this.requestSearchBooks()
  }

  addCookbooks(data) {
    this.setState({cookbooks: data, cookbooksDoneLoading:true})
    this.requestIngredients()
  }

  addIngredients(data) {
    this.setState({ingredients: data, ingredientsDoneLoading:true})
    this.requestAuthors()
  }

  addAuthors(data) {
    this.setState({loading: false, authors: data, authorsDoneLoading:true})
  }

  getSuggest(term){
    // let term = this.state.search
    getSuggestions(term, this.updateSuggestions.bind(this))
  }

  updateSuggestions(value) {
    this.setState({suggestions: value.map(t => t.Term)})
  }

  triggerSearch(event){
    this.setState({showItems: true, loading: true})
    this.changeSerachTerm(event)
    this.changeShowItems()
    this.getSuggest(event)
  }

  displayResultCount() {
    let { translate } = this.props
    let current = this.state[this.state.current]
    let count = 0
    if(this.state.current === "all") {
      this.state.all.map((item)=> {
        count = count + this.state[item].length
      })
    }

    if(this.state.current === "recipes") {
      count = count + this.state["recipes"].length
    }

    if(this.state.current === "cookbooks") {
      count = count + this.state["cookbooks"].length
    }

    if(this.state.current === "ingredients") {
      count = count + this.state["ingredients"].length
    }

    if(this.state.current === "authors") {
      count = count + this.state["authors"].length
    }

    if(current != undefined) {
      return (
        <p>
          <span>{count}</span> {translate('app.search.results')}
        </p>
      )
    } else {
      return (
        <p><span>0</span> {translate('app.search.results')}</p>
      )
    }
  }

  displayTabs() {
    let { translate } = this.props
    let checkStatus = (this.state.search !== '' && this.state.cookbooks.length === 0 && this.state.recipes.length === 0 && this.state.ingredients.length === 0 && this.state.authors.length === 0)

    if(this.state.search !== '') {
      return (
        <>
          <div className="search-menu">
            <ul>
              <li className={this.state.current === "all" ? "active" : ""}>
                <a href={undefined}  onClick={()=> this.setState({current: 'all'})}>
                  {translate('app.search.all')}
                </a>
              </li>
              <li className={this.state.current === "recipes" ? "active" : ""}>
                <a href={undefined}  onClick={()=> this.setState({current: 'recipes'})}>
                  {translate('app.search.recipes')}
                </a>
              </li>
              <li className={this.state.current === "cookbooks" ? "active" : ""}>
                <a href={undefined}  onClick={()=> this.setState({current: 'cookbooks'})}>
                  {translate('app.search.cookbooks')}
                </a>
              </li>
              <li className={this.state.current === "authors" ? "active" : ""}>
                <a href={undefined}  onClick={()=> this.setState({current: 'authors'})}>
                  {translate('app.search.authors')}
                </a>
              </li>
              <li className={this.state.current === "ingredients" ? "active" : ""}>
                <a href={undefined}  onClick={()=> this.setState({current: 'ingredients'})}>
                  {translate('app.search.ingredients')}
                </a>
              </li>
            </ul>
          </div>
          <div className="search-result-area">
            <div className="search-result">
              {!this.state.loading && this.displayResultCount()}
            </div>
            {this.state.current == "all" && <AllItems loading={this.state.loading} all={this.state.all}  state={this.state} title="All" checkStatus={checkStatus} /> }
            {this.state.current == "cookbooks" && <CookbookItems loading={this.state.loading} cookbooks={this.state.cookbooks}  title={translate('app.search.cookbooks')} /> }
            {this.state.current == "recipes" && <RecipeItems loading={this.state.loading} recipes={this.state.recipes}  title={translate('app.search.recipes')} /> }
            {this.state.current == "authors" && <AuthorItems loading={this.state.loading} authors={this.state.authors}  title={translate('app.search.authors')} /> }
            {this.state.current == "ingredients" && <IngredientItems loading={this.state.loading} ingredients={this.state.ingredients}  title={translate('app.search.ingredients')} /> }
          </div>
        </>
      )
    } else {
      return (
        <ul>
          {this.state.suggestions.map((item)=>
            <p
              onClick={()=> this.setState({search: item, suggestions:[]})}
              style={{fontSize: 18, fontWeight: 500, color: '#1A1A1A', marginTop: 15}}
              key={item}>
              {item}
            </p>
          )}
        </ul>
      )
    }
  }

  render() {
    let { translate } = this.props

    return (
      <section className="search" style={{overflow:'scroll'}}>
        <div className="container">
          <div className="search-close">
             <svg version="1.1"
                  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 31.112 31.112" width="24" height="24">
              <polygon fill="#7a7a7a" points="31.112,1.414 29.698,0 15.556,14.142 1.414,0 0,1.414 14.142,15.556 0,29.698 1.414,31.112 15.556,16.97 29.698,31.112 31.112,29.698 16.97,15.556" />
            </svg>
          </div>
          <div className="row">
            <div className="col-sm-8 col-md-6 col-md-center">
              <div className="search-form">
                    <svg xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" viewBox="0 0 20 20">
                      <path fill="#7a7a7a"
                        fillRule="nonzero"
                        d="M14.562 13.917l4.462 4.303a.457.457 0 1 1-.646.646l-4.461-4.304a7.761 7.761 0 1 1 .645-.645zM1.912 8.76a6.848 6.848 0 1 0 13.696 0 6.848 6.848 0 0 0-13.696 0z">
                      </path>
                    </svg>
                    <input
                      ref={(input) => { this.searchInput = input; }}
                      value={this.state.search}
                      onChange={(evt)=> this.startSearch(evt.target.value)}
                      className="search-input"
                      type="text"
                      name="search"
                      placeholder={translate('app.search.search')}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="none" />
                  {/* {this.state.suggestions.length !== 0 &&
                  <div style={{position:'absolute', background:'white',
                      border:1, borderStyle:'solid', width:'100%',
                      zIndex:2, padding:10, borderColor:'#f0f0f0'}}>
                    {this.state.suggestions.map((item)=>
                      <p onClick={()=> this.setState({search: item, suggestions:[]})} style={{color:'#ff5f00'}}>{item}</p>
                    )}
                  </div>} */}
                </div>
                {this.displayTabs()}
            </div>
          </div>
        </div>
      </section>
    )
}}

function mapStateToProps(state) {
    return ({
              me: state.current_user,
              translate: getTranslate(state.locale),
              refreshCurrentPage: state.refreshCurrentPage
            })
  }

export default connect(mapStateToProps, { logOut, userSignedIn })(Search);
