import React
  from 'react'
import RecipeCard
  from '../../../components/recipes/RecipeCard';
import { FiltersForm }
  from '../../../components/shared/filters';
import { fetchRecipes, fetchRecipesCount }
  from '../../../actions/recipes';
import { getFiltersRecipes }
  from '../../../helpers/filters';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif'
import HowManyModal
  from "../../../components/shared/modals/HowMany";
import VideoModal
  from "../../../components/shared/modals/VideoModal";
import ToolsModal
  from "../../../components/shared/modals/ToolsModal";
import { convertInteger }
 from "../../../services/converts";

class Recipes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      loading: false,
      recipeLoading: false,
      limit: 21,
      token:'',
      pages: 1,
      currentPage: 0,
      timeRangeValue: 180,
      caloriesRangeValue: 300,
      ingredients: [],
      includeIngredients: [],
      prepareIncludeIngredients: [],
      excludeIngredients: [],
      prepareExcludeIngredients: [],
      selectedCategories: [-1],
      listingFilters: false,
      recipe: null,
      numOfPeople: 2,
      pathParams: props.history.location.search,
      currentHoverRecipe: null,
      fullScreen: false,
      defineVideoModal: false
    };
  }

  async componentDidMount(){
    window.initialModals()
    window.slider()
    if(this.state.pathParams !== "") {
      let onlyParams = this.state.pathParams.split('?')[1]
      let currentItem = ""
      let page = 0
      let caloriesRangeValue = 300
      let timeRangeValue = 180
      let prepareIncludeIngredients = []
      let prepareExcludeIngredients = []
      let selectedCategories = [-1]
      let setListingFilters = false

      await onlyParams.split("&").forEach(function (kv) {
        currentItem = kv.split("=", 2);

        if(currentItem[0] === "maxCalories") {
          caloriesRangeValue = currentItem[1]
          setListingFilters = true
        }

        if(currentItem[0] === "CookTime") {
          let convertToString = convertInteger(currentItem[1])
          timeRangeValue = convertToString
          setListingFilters = true
        }

        if(currentItem[0] === "DishTypes") {
          if(parseInt(currentItem[1]) !== -1) {
            selectedCategories.push(parseInt(currentItem[1]))
            setListingFilters = true
          }
        }

        if(currentItem[0] === "IncludedIng[]") {
          prepareIncludeIngredients.push(currentItem[1])
          setListingFilters = true
        }

        if(currentItem[0] === "ExcludedIng[]") {
          prepareExcludeIngredients.push(currentItem[1])
          setListingFilters = true
        }

        if(currentItem[0] === "page") {
          page = currentItem[1] - 1
        }
      });
      this.setState({timeRangeValue, caloriesRangeValue, selectedCategories, prepareIncludeIngredients, prepareExcludeIngredients, currentPage: page, offset: 21 * page, listingFilters: setListingFilters})
    }

    this.fetchRecords()
  }

  async UNSAFE_componentWillMount(){
  }

  addRecipes(data){
    this.setState({
      loading: false,
      offset: 21 * this.state.currentPage,
      items: data
    })
  }

  fetchRecords() {
    let { limit, offset, pathParams } = this.state;
    let onlyParams = pathParams.split('?')[1] != undefined ? pathParams.split('?')[1] : ""
    this.setState({loading: true})
    this.props.fetchRecipesCount(this.addRecipesCount.bind(this))
    this.props.fetchRecipes(limit, offset, this.addRecipes.bind(this), onlyParams)
  }

  addRecipesCount(result) {
    this.setState({pages: Math.round(result/21)})
  }

  fetchMoreRecords(number) {
    let { limit } = this.state;
    let onlyParams = `?page=${number}`
    this.props.history.push(`/recipes?page=${number + 1}`)
    this.setState({loading: true})
    this.props.fetchRecipes(limit, number * 21, this.addRecipes.bind(this), onlyParams)
  }

  displayPreviousPage() {
    let { currentPage } = this.state

    if(currentPage !== 0) {
      return (
        <li className="page-item" onClick={this.previousPage.bind(this)}>
          <a href={undefined}  className="page-link">
            <i className="fas fa-chevron-left"></i>
          </a>
        </li>
      )
    }
  }

  displayNextPage() {
    let { currentPage, pages } = this.state

    if(currentPage !== pages - 1) {
      return (
        <li className="page-item">
          <a href={undefined}  className="page-link" onClick={this.nextPage.bind(this)}>
            <i className="fas fa-chevron-right">
            </i>
          </a>
        </li>
      )
    }
  }

  previousPage() {
    window.scrollTo(0, 0);
    this.changePage(this.state.currentPage - 1)
  }

  nextPage() {
    window.scrollTo(0, 0);

    this.changePage(this.state.currentPage + 1)
  }

  changePage(number) {
    this.setState({currentPage: number})
    this.fetchMoreRecords(number)
    window.scrollTo(0, 0);
  }

  clearFilters() {
    this.setState({
      loading: false,
      offset: 0,
      listingFilters: false,
    })

    this.props.history.push(`/recipes`)
    window.location.reload();
  }

  paginate() {
    return (
      <ul className="pagination">
        {this.displayPreviousPage()}
        { Array.from(Array(this.state.pages), (e, i) => {
          return (
              <li key={i} className={"page-item " + (this.state.currentPage === i  ? 'active' : '')} >
                <a href={undefined}  className="page-link"
                   onClick={(number) => this.changePage(i)}>
                  {i + 1}
                </a>
              </li>
            )
          })
        }
        {this.displayNextPage()}
      </ul>
    )
  }

  addRecipesFromFilters(data, url){
    this.setState({
      loading: false,
      listingFilters: true,
      items: data
    })

    this.props.history.push(`/recipes?${url.split('?')[1]}`)
  }

  checkFiltersResult(object) {
    let { timeRangeValue, caloriesRangeValue, includeIngredients, excludeIngredients, selectedCategories } = object
    if(timeRangeValue == 180 && caloriesRangeValue == 300 && includeIngredients.length == 0 && excludeIngredients.length == 0 && (selectedCategories[0] == -1 || selectedCategories.length == 0)) {
      window.location.href = '/recipes'
    }
  }

  applyFilters(object) {
    let { timeRangeValue, caloriesRangeValue, includeIngredients, excludeIngredients, selectedCategories } = object
    this.setState({loading: true, caloriesRangeValue})
    this.checkFiltersResult(object)

    let params = {
      categories: selectedCategories,
      slidersValues: [timeRangeValue, caloriesRangeValue],
      includeIngredients: includeIngredients,
      excludeIngredients: excludeIngredients
    }
    getFiltersRecipes(params, this.addRecipesFromFilters.bind(this))
  }

  incrementNumberOfPeople() {
    this.setState({numOfPeople: this.state.numOfPeople + 1})
  }

  decrementNumberOfPeople() {
    this.setState({numOfPeople: this.state.numOfPeople === 1 ? this.state.numOfPeople : this.state.numOfPeople - 1})
  }

  changeCurrentHoverRecipe(itemId) {
    let { currentHoverRecipe } = this.state

    if(currentHoverRecipe != itemId) {
      this.setState({currentHoverRecipe: itemId})
    }
  }

  renderRecipes(){
    let { items, loading, currentHoverRecipe } = this.state
    let { translate } = this.props

    if(items){
      if(items.length !== 0) {
        if(loading) {
          return (
            <div style={{paddingLeft: "45%", marginBottom: "10%",marginTop: "10%"}}>
              <img src={Spinner} alt="logo" width="200px"/>
            </div>
          )
        } else {
          return items.map((item) => {
            return(
              <RecipeCard
                key={item.Id}
                item={item}
                id={item.Id}
                source={item.CoverPath}
                heading={item.Name}
                time={item.PrepTime}
                paragraph={translate('app.explorePage.by') + " " + (item.Author && item.Author.Name)}
                videos={item.Videos}
                IsFavorized={item.IsFavorized}
                setRecipe={(value) => this.setState({recipe: value})}
                currentHoverRecipe={currentHoverRecipe}
                changeCurrentHoverRecipe={(e, itemId) => this.changeCurrentHoverRecipe(itemId)}
              />
            )
          })
        }
      } else {
        return (
          <p style={{marginLeft: "40%", marginBottom: 80, marginTop: 50}}>
            { translate('app.filtersInputs.not_found') }
          </p>
        )
      }
    } else {
      return (
        <div style={{paddingLeft: "45%", marginBottom: "10%",marginTop: "10%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }
  }

  setVideoParams() {
    this.setState({fullScreen: true, defineVideoModal: true})
  }

  renderModals() {
    let { defineVideoModal } = this.state
    let { translate } = this.props
    return (
      <>
        <HowManyModal
          incrementNumberOfPeople={this.incrementNumberOfPeople.bind(this)}
          decrementNumberOfPeople={this.decrementNumberOfPeople.bind(this)}
          numOfPeople={this.state.numOfPeople}
          recipe={this.state.recipe}
          translate={translate}
          setVideoParams={this.setVideoParams.bind(this)}
          fromCarousel
        />
        { defineVideoModal &&
          <VideoModal
            recipe={this.state.recipe}
            numOfPeople={this.state.numOfPeople}
            translate={translate}
            fullScreen={this.state.fullScreen}
            exitModalVideo={() => this.setState({defineVideoModal: false})}
          />
        }
        <ToolsModal translate={translate}/>
      </>
    )
  }

  changeTimeValue(value) {
    this.setState({timeRangeValue: value})
  }

  render(){
    return(
      <>
        <FiltersForm
          history={this.props.history}
          applyFilters={this.applyFilters.bind(this)}
          listingFilters={this.state.listingFilters}
          clearFilters={this.clearFilters.bind(this)}
          changeCategories={this.changeCategories.bind(this)}
          caloriesRangeValue={this.state.caloriesRangeValue}
          timeRangeValue={this.state.timeRangeValue}
          changeTimeValue={this.changeTimeValue.bind(this)}
          selectedCategories={this.state.selectedCategories.length > 0 ? this.state.selectedCategories : [-1]}
          prepareIncludeIngredients={this.state.prepareIncludeIngredients}
          prepareExcludeIngredients={this.state.prepareExcludeIngredients}
        />
        <section className="recipes section-top-60">
          <div className="container">
            <div className="row">
              {this.renderRecipes()}
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <div className="pagination-wrapper">
              {!this.state.listingFilters && !this.state.loading && this.paginate()}
            </div>
          </div>
        </section>
        {this.renderModals()}
      </>
    )
  }

  changeCategories(selectedCategories) {
    // this.setState({selectedCategories: selectedCategories})
  }
}

function mapStateToProps(state){
  return ({
            recipes: state.recipes,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { fetchRecipes, fetchRecipesCount })(Recipes)
