import React
  from 'react';
import { removeShoppingRecipes, shoppingRecipes, shoppingDepartment }
  from '../../../actions/shopping';
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import ShoppingRecipe
  from '../../recipes/ShoppingRecipe';
import DepartmentShopping
  from '../../recipes/DepartmentShopping';

class ShoppingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tab:'recipe'
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.close)
  }

  close(e){

    if(e.keyCode === 27){
      window.$('#shopingList').modal('hide');
      window.$('#complete-video').modal('hide');
      window.$('#delete').modal('hide');
      window.$('#video-plp').modal('hide');
      window.$('#video').modal('hide');
      window.$('#rate').modal('hide');
      window.$('#brand').modal('hide');
      window.$('.search').removeClass('show');
      window.$('body').removeClass('search-on');
      // props.onCloseModal()
    }
  }

  UNSAFE_componentWillMount(){
    this.props.shoppingRecipes(this.stopLoading.bind(this))
    this.props.shoppingDepartment()
  }

  stopLoading() {
    this.setState({ loading: false });
  }

  removeRecipe(recipe) {
    var arrayRecipes = [...this.state.recipes];
    var index = arrayRecipes.indexOf(recipe)
    if (index !== -1) {
      arrayRecipes.splice(index, 1);
      this.setState({recipes: arrayRecipes});
    }
  }

  removeRecipeFromShopping(itemId) {
    var r = window.confirm("Remove from shopping list");
    if (r === true) {
      this.props.removeShoppingRecipes(itemId)
    }
  }

  displayRecipe() {
    let { translate } = this.props
    if(this.props.recipes.length > 0) {
      return (
        this.props.recipes.map((item)=>{
          return (
            <ShoppingRecipe
              key={item.Id}
              id={item.Id}
              source={item.ThumbPath}
              name={item.RecipeName}
              ingredients={item.Ingredients}
              persons={item.Persons}
              shopping={this.props.shopping}
              deleteClick={()=> this.removeRecipeFromShopping(item.Id)}
            />
          )
        })
      )
    } else {
      return (
        <div className="tab-slider-container">
          <div style={{height:100}}>
            <p>{translate('app.filtersInputs.not_found')}</p>
          </div>
        </div>
      )
    }
  }

  render() {
    let { translate } = this.props
    return (
      <div className="modal shop-modal fade" id="shopingList" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h1 className="title">{translate('app.recipeDetails.shopping_list')}</h1>
            </div>
            <div className="modal-body">
                <div className="tab-slider-nav">
                  <ul className="tab-slider-tabs">
                    <li className="tab-slider-trigger active"
                      rel="tab1" onClick={()=> this.setState({tab:'recipe'})}>{translate('app.favourite.recipes')}</li>
                    <li className="tab-slider-trigger"
                      rel="tab2" onClick={()=> this.setState({tab:'department'})}>{translate('app.favourite.department')}</li>
                  </ul>
                </div>
                {this.state.tab === 'recipe'
                ?
                  this.displayRecipe()
                :
                  <DepartmentShopping />
                }
            </div>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state){
  return {
           shopping: state.shopping,
           recipes: state.shopping.recipes,
           translate: getTranslate(state.locale)
         }
}

export default connect(mapStateToProps, { removeShoppingRecipes, shoppingRecipes, shoppingDepartment })(ShoppingModal)
