import React, { Component }
  from "react";
import { ArrowDownIcon, PlusIcon, TrashIcon }
  from "../../../assets/icons";
import PureImage
  from '../../../components/shared/PureImage';

class IncludeIngredients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serachIngredients: [],
      searchText: "",
      ingradient: null,
      ingredientsAdded: props.includeIngredients
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.includeIngredients !== newProps.includeIngredientss) {
      this.setState({ingredientsAdded: newProps.includeIngredients })
    }
  }

  renderAuthimg(image){
    return <PureImage src={image} />
  }

  autocompleteDisplay() {
    let { serachIngredients } = this.state
    return (
      <div style={{ position: 'absolute',
                    backgroundColor: "#f0f0f0",
                    height: serachIngredients.length === 0 ? 0 : 200,
                    top: 60,
                    zIndex: 99,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflow: "hidden",
                    overflowY: "scroll"}}>
        <ul style={{margin: 10}}>
          { serachIngredients.map((ingredient, index) => {
              return (
                <li
                  style={{marginBottom: 8}}
                  key={index}
                >
                  <a href={undefined}  onClick={() => this.addItem(ingredient)}>{ingredient.Name}</a>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }

  searchData(e) {
    this.setState({serachIngredients: [], searchText: e.target.value})
    this.findIngredient(e.target.value)
  }

  addItem(ingradient) {
    this.setState({ingradient: ingradient, serachIngredients: [], searchText: ingradient.Name})
    this.clickPlus(ingradient)
  }

  findIngredient(query) {
    if (query === '') {
      this.setState({serachIngredients: []})
      return
    }

    const { ingredients } = this.props;
    const regex = new RegExp(`${query.trim()}`, 'i');
    let filterIngredients = ingredients.filter(ingredient => ingredient.Name.search(regex) >= 0);

    this.setState({serachIngredients: filterIngredients})
  }

  clickPlus(ingredient) {
    let { ingredientsAdded, searchText } = this.state

    if(ingredient === null && searchText.trim() !== "") {
      // const regex = new RegExp(`${searchText.trim()}`, 'i');
      // let ingradientSearched = ingredients.filter(ingredient => ingredient.Name.search(regex) >= 0);
    }

    if(ingredient !== null) {
      let ingredientsExist = ingredientsAdded.filter(ingredientAdd => ingredientAdd === ingredient);

      if(ingredientsExist.length === 0) {
        var joined = ingredientsAdded.concat(ingredient);
        this.setState({ ingredientsAdded: joined, searchText: "", ingredient: null, serachIngredients: [] })
        this.props.changeIncludeIngredients(joined)
      }
    }
  }

  displayIngradientsAdded() {
    let { ingredientsAdded } = this.state
    return (
      <ul className="ingredients-list" style={{maxHeight: 150, overflow: "hidden", overflowY: "scroll"}}>
        { ingredientsAdded.map((ingredient, index) => {
          return (
            <li key={index}>
              <div className="ingredient-content">
                <div className="ingredient-img">
                  {this.renderAuthimg(ingredient.ImagePath)}
                </div>
                <span>{ingredient.Name}</span>
              </div>
              <a href={undefined}  onClick={() => this.removeIngredient(ingredient)}>
                <div className="ingredient-delete">
                  <TrashIcon />
                </div>
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  removeIngredient(ingredient) {
    var array = [...this.state.ingredientsAdded]; // make a separate copy of the array
    var index = array.indexOf(ingredient)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ingredientsAdded: array});
      this.props.changeIncludeIngredients(array)
    }
  }

  displayTitle() {
    let { translate } = this.props
    let { ingredientsAdded } = this.state
    let title = ""

    if(ingredientsAdded.length < 1) {
      return (
        translate('app.filtersInputs.add_ingredient')
      )
    } else {
      ingredientsAdded.forEach((ingredient, index) => {
        title = title + ingredient.Name

        if(ingredientsAdded[ingredientsAdded.length - 1] !== ingredientsAdded[index]) {
          title = title + ", "
        }
      })

      // return (
      //   ingredientsAdded.length + " items selected"
      // )

      return (
        this.truncate(title)
      )
    }
  }

  truncate(str) {
    return str.length > 40 ? str.substring(0, 37) + "..." : str;
  }

  openDropDown() {
    this.nameInput.focus();
  }

  render() {
    let { ingradient } = this.state
    let { translate } = this.props

    return (
      <div className="dropdown-wrapp">
        <h1 className="filter-inner-title">{ translate('app.filtersInputs.include_ingredients') }</h1>
        <div className="dropdown">
          <button
            className="dropdown-toggle"
            type="button"
            onClick={() => this.openDropDown()}
          >
            { this.displayTitle() }
            <ArrowDownIcon />
          </button>
          <div className="dropdown-menu" >
            <div className="select-wrapp">
              <div className="add-ingredients">
                <input
                  ref={(input) => { this.nameInput = input; }}
                  type="text"
                  id="ingredients-include"
                  name="ingredients"
                  className="inp-add-ingr"
                  placeholder={translate('app.filtersInputs.add_ingredient')}
                  onChange={(e) => this.searchData(e)}
                  value={this.state.searchText}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="none"
                />
                <a
                  href={undefined}
                  onClick={() => this.clickPlus(ingradient)}
                >
                  <PlusIcon
                    plusColor={this.state.searchText.length !== 0 ? "#ff5f00" : "#7A7A7A"}
                  />
                </a>
                {this.autocompleteDisplay()}
              </div>
              {this.displayIngradientsAdded()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default IncludeIngredients
