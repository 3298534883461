
export const storeCurrentPath = () => {
  localStorage.setItem('currentPath', `${window.location.pathname}${window.location.search}`);
  window.location.href = "/auth"
}

export const resetCurrentPath = () => {
  localStorage.setItem('currentPath', "/");
}

export const getCurrentPath = localStorage.getItem('currentPath')
