import React
    from "react";

import BannerLogo
  from '../../assets/img/logo-mobile.png'

class Banner extends React.Component {

 getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return "android" ;

        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
           return "ios"

        }

        return "unknown";
    }

  UNSAFE_componentWillMount(){
    this.getMobileOperatingSystem()
  }

  render() {
    return (
        <div className="banner-mobile" style={{display:'none'}}>
        <div className="banner-mobile-wrap">
            <div className="banner-mobile-left">
                <div className="banner-close">
                    <svg width="13" height="13" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      enableBackground="new 0 0 64 64">
                        <g>
                          <path fill="#1a1a1a" d="M28.941,31.786L0.613,60.114c-0.787,0.787-0.787,2.062,0,2.849c0.393,0.394,0.909,0.59,1.424,0.59 c0.516,0,1.031-0.196,1.424-0.59l28.541-28.541l28.541,28.541c0.394,0.394,0.909,0.59,1.424,0.59c0.515,0,1.031-0.196,1.424-0.59 c0.787-0.787,0.787-2.062,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.062-0.786-2.848,0 L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z">
                          </path>
                        </g>
                    </svg>
                </div>
                <div className="banner-logo">
                    <img src={BannerLogo} alt="" />
                </div>
                <div className="banner-content">
                    <h1>Tendoo</h1>
                    <p>Refive</p>
                    {this.getMobileOperatingSystem() === 'ios'
                        ? <span>GET - On the App Store</span>
                        : <span>GET - On the PlayStore</span>
                    }
                </div>
            </div>
            <div className="banner-mobile-right">
                    {this.getMobileOperatingSystem() === 'ios'
                        ? <a href="https://apps.apple.com/mk/app/tendoo/id1479534967">View</a>
                        : <a href="https://play.google.com/store/apps/details?id=com.apper.tendoo&hl=en">View</a>
                    }
            </div>
        </div>
    </div>
    )
  }
}

export default Banner
