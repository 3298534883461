import React
  from 'react'
import { getToken }
  from "../../services/token";
import PureImage
  from '../../components/shared/PureImage'
import { recipeIngridients }
  from '../../helpers/recipe';
import { addRecipeToShopping }
  from '../../actions/shopping';
import { connect }
  from 'react-redux';
import UNITS
  from '../../constants/symbols';
import { setCurrentPath }
  from '../../helpers/currentPath';

class RecipeItems extends React.Component{
  constructor(props) {
    super();

    this.state = {
      ingridients: [],
      fetched: false,
      ingredientsNumber:5,
      numOfPeople: props.numOfPeopleIngra,
      displayCover: 0,
      width: 640,
      height: 450,
      isMobile: false,
      isDesktop: false,
    }

    window.addEventListener("resize", this.update.bind(this));
  }

  update() {
    if(window.innerWidth < 745) {
      if(!this.state.isMobile) {
        this.setState({isMobile: true, isDesktop: true})
      }
    } else if (window.innerWidth < 992 && window.innerWidth > 745) {
      this.setState({isDesktop: true, isMobile: false})
    } else {
      if(this.state.isMobile || this.state.isDesktop) {
        this.setState({isMobile: false, isDesktop: false})
      }
    }
  };

  componentDidMount(){
    if(this.props.recipeId != undefined) {
      recipeIngridients(getToken, this.props.recipeId, this.saveIngridientsData.bind(this))
    }

    if(window.innerWidth < 745) {
      if(!this.state.isMobile) {
        this.setState({isMobile: true, isDesktop: true})
      }
    } else if (window.innerWidth < 992 && window.innerWidth > 745) {
      this.setState({isDesktop: true, isMobile: false})
    } else {
      if(this.state.isMobile || this.state.isDesktop) {
        this.setState({isMobile: false, isDesktop: false})
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(this.container) {
      this.setState({
        width: this.container.offsetWidth + 40,
      });
    }
    if(this.props.recipeId !== newProps.recipeId) {
      recipeIngridients(getToken, newProps.recipeId, this.saveIngridientsData.bind(this))
    }
  }

  saveIngridientsData(data){
    if(Array.isArray(data)){
      this.setState({
        ingridients: data,
        fetched: true
      })
    }
  }

  truncate(str) {
    return str.length > 10 ? str.substring(0, 9) + "..." : str;
  }

  displayIngredients() {
    let { ingridients } = this.state
    return (
       ingridients.map((item, index)=>{
         return (
          <div key={index} className="col-xs-12 col-sm-6 col-md-6">
            <div className="recipes-extra-ingredients-item">
              <div className="recipes-extra-ingredients-content">
                <div className="recipes-extra-ingredients-img">
                  <PureImage src={item.Ingredient.ImagePath} />
                </div>
                <span>{this.state.isMobile ? item.Ingredient.Name : this.truncate(item.Ingredient.Name)}</span>
              </div>
              <div className="recipes-extra-ingredients-quantity">
                <span>{Math.round(item.ImperialAmount * this.props.numOfPeopleIngra, 2)} { UNITS[item.MetricUnit] }</span>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  displayIngredientsImage() {
    return (
      <PureImage src={this.props.ingredientImage} />
    )
  }

  renderToolImage(){
    if(this.props.toolImage != undefined){
      return <PureImage src={this.props.toolImage} />
    }
  }

  renderIngredientImg(){
    let _this = this
    var { ingredients } = this.props
    if(ingredients && ingredients.length > 0){
      return (
        <div
          className="img-syle"
          ref={el => (_this.container = el)}
          onMouseEnter={() => this.setState({displayCover: 100})}
          onMouseLeave={() => this.setState({displayCover: 0})}>
          <div style={{ display: _this.state.displayCover === 0 ? "none" : "block", position:'absolute', width: this.state.isMobile ? "94%" : '95%', height: this.state.isMobile ? "94%" : `${_this.state.displayCover}%`, zIndex: 99, backgroundColor: "black", opacity: 0.6, borderRadius: 22}}>
          </div>
          {_this.displayIngredientsNames()}
          <PureImage src={_this.props.ingredientImage} style={this.state.isDesktop ? {width: "100%"} : {width: undefined}}/>
        </div>
      )
    }

  }

  displayIngredientsNames() {
    var { ingredients } = this.props
    let topPosition = this.state.isMobile ? 400 : 450
    let imagePosition = this.state.isMobile ? 470 : 560
    let leftTab = this.state.isMobile ? 7 : 14
    if(ingredients && ingredients.length > 0) {
        return(
          ingredients.map((i, index) => {
            return (
              <div
                key={index}
                style={{ display: this.state.displayCover === 0 ? "none" : "flex",
                         position: 'absolute',  left: i.X * imagePosition + leftTab,
                         top: i.Y * topPosition, justifyContent: 'center',
                         alignItem: "center", backgroundColor: "rgba(26,26,26,0.5)",
                         borderRadius: 12, zIndex: 1000, width: i.Width * imagePosition,
                         paddingBottom: 5}}>
                <div style={{position: "absolute",
                            backgroundColor: '#ff5f00',
                            width: 10,
                            height: 10,
                            borderRadius: 13,
                            top: -5 }} />
                          <p style={{
                            fontSize: 16,
                            alignSelf: 'center',
                            fontStyle: "bold",
                            color: "#ffffff",
                            marginTop: 5}}>
                            {i.Name}
                          </p>
              </div>
            )

          })
        )
      } else {
        return (
          <p style={{display: this.state.displayCover === 0 ? "none" : "block", color: "#ffffff", paddingTop: "30%", marginLeft: "25%", position: 'absolute', zIndex: 1000}}>
            Not found any ingredients
          </p>
        )
      }
  }

  render(){
    let { translate } = this.props
    return(
      <section className="recipes-extra section-top-90">
          <div className="container">
              <div className="recipes-extra-slider-wrap">
                  <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                          <div className="recipes-extra-title">
                              <h1>{translate('app.recipeDetails.ingredients')}</h1>
                          </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6">
                          <div className="recipes-extra-ingredients">
                              <div className="row">
                                  <div className="col-xs-12 col-sm-12 col-md-12">
                                      <div className="recipes-extra-ingredients-head">
                                          <h1>{translate('app.recipeDetails.how_many_people')}</h1>
                                          <div className="quantity-input">
                                              <div className="number-input">
                                                <span onClick={() => this.props.decrementNumberOfPeopleIngra()} className="change minus">-</span>
                                                  <input
                                                    type="text"
                                                    className="input-box"
                                                    min="1"
                                                    readOnly=""
                                                    onChange={() => console.log("onChange")}
                                                    value={this.props.numOfPeopleIngra}
                                                   />
                                                 <span onClick={() => this.props.incrementNumberOfPeopleIngra()} className="change plus">+</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  {this.displayIngredients()}
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 col-md-6">
                                      <div className="add-shopping-list">
                                        {getToken ?
                                          <div
                                          className="button"
                                          data-toggle="modal" data-target="#shopingIngredients"
                                          >
                                            {translate('app.recipeDetails.add_to_shopping_list')}
                                          </div>
                                          :
                                          <div
                                          className="button"
                                          onClick={() => setCurrentPath()}
                                          >
                                            {translate('app.recipeDetails.add_to_shopping_list')}
                                          </div>
                                        }
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                          <div className="recipes-extra-banner">
                            {this.renderIngredientImg()}
                          </div>
                        </div>
                        </div>
                        <div className="row section-top-60">
                          <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="recipes-extra-title">
                              <h1>{translate('app.recipeDetails.tools_title')}</h1>
                            </div>
                            <div className="recipes-buy">
                              <div className="recipes-buy-img">
                                {this.renderToolImage()}
                              </div>
                              <div className="recipes-buy-button">
                                <a href={undefined}  className="button" data-toggle="modal" data-target="#brand">{translate('app.recipeDetails.buy_now')}</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    )
  }
}

export default connect(null, { addRecipeToShopping })(RecipeItems)
