import React
    from 'react'

const PluseActiveIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="plus_button" data-name="plus button" transform="translate(-6 -6)">
        <path id="Pluse-1" d="M10.681,7.647a3.333,3.333,0,0,0-3.034,3.034Q7.333,14.317,7.333,18t.315,7.319a3.333,3.333,0,0,0,3.034,3.034q3.625.314,7.319.314t7.319-.314a3.333,3.333,0,0,0,3.034-3.034q.315-3.636.315-7.319t-.314-7.319a3.333,3.333,0,0,0-3.034-3.034Q21.688,7.333,18,7.333T10.681,7.647Zm-.115-1.328Q14.254,6,18,6t7.433.319a4.667,4.667,0,0,1,4.247,4.247Q30,14.259,30,18t-.319,7.434a4.667,4.667,0,0,1-4.247,4.247Q21.751,30,18,30t-7.433-.319a4.667,4.667,0,0,1-4.247-4.247Q6,21.741,6,18t.319-7.434A4.667,4.667,0,0,1,10.567,6.319ZM17.333,24V12a.667.667,0,1,1,1.333,0V24A.667.667,0,0,1,17.333,24ZM24,18.667H12a.667.667,0,1,1,0-1.333H24A.667.667,0,0,1,24,18.667Z" fill="#ff5f00"/>
      </g>
    </svg>
  )
}

export default PluseActiveIcon
