import qs
  from 'qs';
import api
  from './api'

import { BASE_URL }
  from '../constants/types';

export async function changePassword(oldPassword, password, resultChange) {
  let data = {
    "OldPassword": oldPassword,
    "Password": password,
  }

  let url = `${BASE_URL}/me/password`

  api.patch(url, qs.stringify(data))
    .then((data) => {
      resultChange(data)
    })
    .catch(error => {
      resultChange(error)
    })
}

export async function deleteEmail(message, afterRequest) {
  let data = {
    "Message": message,
  }

  let url = `${BASE_URL}/me/delete_email`

  api.post(url, qs.stringify(data))
  .then((data) => {
    afterRequest(true)
  })
  .catch(error => {
    console.log("DELETE ACCOUNT", error)
  })
}

export function deleteAccount(message, afterRequest) {
  let data = {
    "Message": message,
  }
  debugger
  const url = `${BASE_URL}/me/delete_account`
  api.patch(url, qs.stringify(data))
  .then(data => {
    debugger
    afterRequest(true)
  })
  .catch(error => {
    afterRequest(false)
  })
}
