import React, { Component }
  from 'react'
import { Link }
  from "react-router-dom";
import { connect }
  from 'react-redux';
import { getTranslate }
 from 'react-localize-redux';
import { signUp }
  from '../../actions/auth';
import ReactLoading
  from 'react-loading';
import ReactGA
  from 'react-ga';
import axios
  from 'axios'

class Register extends Component {
  state = {
    loading: false,
    fullName: '',
    username: '',
    email: '',
    password: '',
    errors: [],
    countryName: ''
  }

  componentDidMount() {
    this.getGeoInfo();
    ReactGA.event({
      category: 'Navigation',
      action: 'Register screen'
    });
  }

  //location
  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            countryName: data.country_name,
        });
    }).catch((error) => {
        console.log("GET GEO INFO ERROR", error);
    });
  };


  submitRegister(e) {
    e.preventDefault()
    this.setState({loading: true})

    let {
      fullName,
      username,
      email,
      password,
      countryName
    } = this.state

    this.props.signUp(
      fullName,
      username,
      email,
      password,
      countryName,
      this.afterRegister.bind(this),
      this.catchedErrors.bind(this)
    )
  }

  afterRegister(result) {
    this.setState({loading: false })
    window.location.href = '/auth';
  }

  catchedErrors(error){
    this.setState({ error: error.Message, loading: false })
  }

  submitButton() {
    let { translate } = this.props
    if(this.state.loading) {
      return (
        <div style={{marginLeft: "40%" }}>
          <ReactLoading
            type={"spin"}
            height={45} width={45}
          />
        </div>
      )
    } else {
      return (
        <input
          type="submit"
          className="send"
          value={translate('auth.sign_up')}
          onClick={(e) => this.submitRegister(e) }
        />
      )
    }
  }

  render() {
    let { translate } = this.props
    return(
      <>
      <div className="login-title">
        <h1>{translate('auth.create_account')}</h1>
        <Link className="login.html"
              to={`/auth`}>
           {translate('auth.or_sign_in')}
        </Link>
      </div>
      <div className="login-form">
        {this.state.error && <p className="form-error" style={{color: 'red', marginBottom: 10}}>{this.state.error}</p>}
        <form className="">
          <div className="input-wrap">
            <input type="text"
                   className="form-control"
                   placeholder={translate('auth.full_name')}
                   value={this.state.fullName}
                   onChange={({ target }) => this.setState({ fullName: target.value })}
                   required/>
          </div>
          <div className="input-wrap">
            <input type="text"
                   className="form-control"
                   placeholder={translate('auth.username')}
                   value={this.state.username}
                   onChange={({ target }) => this.setState({ username: target.value })}
                   required />
          </div>
          <div className="input-wrap">
            <input type="email"
                   className="form-control"
                   placeholder={translate('auth.email')}
                   value={this.state.email}
                   onChange={({ target }) => this.setState({ email: target.value })}
                   required />
          </div>
          <div className="input-wrap">
            <input id="create-password"
                   type="password"
                   className="form-control"
                   name="password"
                   value={this.state.password}
                   onChange={({ target }) => this.setState({ password: target.value })}
                   placeholder={translate('auth.password')} />
             <span toggle="#create-password"
                   className="pass-eye-off toggle-password"></span>
          </div>
          <div className="submit-button">
            {this.submitButton()}
          </div>
        </form>
      </div>
      <div className="login-mssg">
        <p>{translate('auth.agree_description')}</p>
      </div>
      </>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { signUp })(Register)
