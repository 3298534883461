import React
  from "react";
import { connect }
  from 'react-redux';
import PureImage
  from '../../../components/shared/PureImage'
import { updateMeData }
  from '../../../actions/me.js';
import ReactLoading
  from 'react-loading';
import { getTranslate }
  from 'react-localize-redux';

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      userName: "",
      lastName: "",
      email: "",
      image: null,
      saveRequest: false,
      error: null,
      loading: true
    };
  }

  componentDidMount() {
    if(this.props.me) {
      let { me } = this.props
      if(me) {
        this.setState({
          firstName: me.FirstName,
          userName: `${me.FirstName}${me.LastName}`,
          lastName: me.LastName,
          email: me.Email,
          image: me.AvatarPath,
          loading: false
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.me !== newProps.me) {
      let { me } = newProps
      if(me) {
        this.setState({
          firstName: me.FirstName,
          userName: `${me.FirstName}${me.LastName}`,
          lastName: me.LastName,
          email: me.Email,
          image: me.AvatarPath,
          loading: false
        })
      }
    }
  }

  displayImage() {
    if(this.state.image) {
      if(this.state.image === this.props.me.AvatarPath) {
        return (
          <PureImage src={this.state.image} />
        )
      } else {
        return (
          <img
            alt="profile"
            src={URL.createObjectURL(this.state.image)}
          />
        )
      }
    }
  }

  submitSaveButton() {
    let { image, firstName, lastName, email } = this.state
    this.setState({saveRequest: true})

    let data = {
      FirstName: firstName,
      LastName: lastName,
      Email: email
    }

    this.props.updateMeData(
      image === this.props.me.AvatarPath ? null : image,
      data,
      this.afterUpdate.bind(this),
      this.catchedErrors.bind(this)
    )
  }

  afterUpdate(data) {
    let { translate } = this.props
    this.setState({saveRequest: false})
    this.props.setMessage(translate('app.settings.edit_profile'), translate('app.settings.edit_profile_successfully'))
  }

  catchedErrors(message) {
    let _this = this
    this.setState({error: message, saveRequest: false})
    setTimeout(function() {
      _this.setState({error: null})
    }, 4000);
  }

  displaySaveButton() {
    let { translate } = this.props
    if(this.state.saveRequest) {
      return (
        <div style={{paddingLeft: "45%"}}>
          <ReactLoading
            type={"spokes"}
            color={"#000000"}
            height={32} width={32}
          />
        </div>
      )
    } else {
      return (
        <a
          href={undefined}
          className="button"
          onClick={this.submitSaveButton.bind(this)}
        >{translate('app.settings.save')}</a>
      )
    }
  }

  changeImage(event) {
    if(event.target.files[0] != undefined) {
      this.setState({
        image: event.target.files[0],
      })
    } else {
      this.setState({
        image: '',
      })
    }
  }

  render() {
    let { translate } = this.props

    if(this.state.loading) {
      return (
        <div style={{paddingLeft: "55%",marginTop: "20%"}}>
          <ReactLoading
            type={"spokes"}
            color={"#000000"}
            height={50} width={50}
          />
        </div>
      )
    }

    return (
     <div className="row">
       {this.state.error && <p className="form-error" style={{color: 'red', marginBottom: 20, marginLeft: 20}}>{this.state.error}</p>}
       <div className="col-sm-12 col-md-4">
         <div className="settings-info">
           <div className="settings-img">
            {this.displayImage()}
           </div>
           <div className="settings-upload">
            <input
                type="file"
                onChange={e => this.changeImage(e)}
                id="f02"
                name="avatar"
                accept="image/png, image/jpeg"
                style={{width: 0}}/>
              <label htmlFor="f02">{translate('app.settings.change_profile_picture')}</label>
             {/*<a href={undefined} >Remove photo</a>*/}
           </div>
         </div>
       </div>
       <div className="col-sm-6 col-md-4">
         <div className="settings-input">
           <label htmlFor="name">{translate('app.settings.first_name')}</label>
           <input
            type="text"
            className="form-control"
            id="firstname"
            value={this.state.firstName}
            onChange={e => this.setState({firstName: e.target.value})}
            required
           />
         </div>
         <div className="settings-input">
           <label htmlFor="name">{translate('app.settings.username')}</label>
           <input
            type="text"
            className="form-control"
            id="username"
            value={this.state.userName}
            onChange={e => this.setState({userName: e.target.value})}
            disabled={true}
           />
         </div>
         <div className="settings-input">
         </div>
       </div>
       <div className="col-sm-6 col-md-4">
         <div className="settings-input">
           <label htmlFor="name">{translate('app.settings.last_name')}</label>
           <input
            type="text"
            className="form-control"
            id="lastname"
            value={this.state.lastName}
            onChange={e => this.setState({lastName: e.target.value})}
            required
           />
         </div>
         <div className="settings-input">
           <label htmlFor="name">{translate('app.settings.email')}</label>
           <input
            type="email"
            className="form-control"
            id="email"
            value={this.state.email}
            onChange={e => this.setState({email: e.target.value})}
            disabled={true}
           />
         </div>
         <div className="settings-save">
            {this.displaySaveButton()}
         </div>
       </div>
     </div>
    )
  }
};

function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}
export default connect(mapStateToProps, { updateMeData })(EditProfile);
