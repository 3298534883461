import {  FAVOURITE_RECIPE,
    ADD_RECIPE,
    REMOVE_RECIPE }
from '../../constants/types';

export default function(state=[], action){
switch(action.type){
case FAVOURITE_RECIPE:
    return action.payload
case ADD_RECIPE:
return [ ...state, action.payload ]
    case REMOVE_RECIPE:
return [ ...state.filter(function(v) {
    return v.Id !== action.payload.Id
})]
default:
return state
}
}
