import React from "react";

class AuthorDetailsBck extends React.Component {
  displayDescription() {
    if(this.props.authorDescription) {
      return this.props.authorDescription
    } else {
      return "Not description"
    }
  }

  render() {
    return (
      <div className="author-profile-description">
        <h1>Description</h1>
        <p>
          {this.displayDescription()}
        </p>
      </div>
    );
  }
};

export default AuthorDetailsBck;
