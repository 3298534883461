import React
  from "react";
import { logOut, userSignedIn }
  from '../../actions/auth'
import LogoWhite
  from "../../assets/img/logo-white.svg";
import ProfileStatus
  from "./ProfileStatus";
import { Link }
  from "react-router-dom";
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import { isSafari }
  from 'react-device-detect';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      page: window.location.pathname,
      search:true
    };
  }

  routeChange() {
    this.props.history.push('/auth');
  }

  componentDidMount(){
    // if(getToken){
    //   this.props.userSignedIn(getToken)
    // }else{
    //   window.location.assign('/auth')
    // }
  }

  render() {
    let { translate } = this.props
    console.log("me", this.props.me && this.props.me)
    return (
      <header className="header">
        <div className="container-fluid">
          <div className="header-inner">
            <div className="header-inner-left">
              <div className="logo">
                <a href={undefined} >
                  <img src={LogoWhite} alt="Logo" onClick={()=> this.setState({page: "/"})} />
                </a>
              </div>
              <div className="nav-menu-wrapp">
                <div className="nav-mobile">
                  <div className="nav-right">
                    <ul>
                      <li>
                        <a href={undefined}  className="closebtn">
                          <svg
                            width="16px"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            height="16px"
                            viewBox="0 0 64 64"
                            // xmlns:xlink="http://www.w3.org/1999/xlink"
                            enableBackground="new 0 0 64 64"
                          >
                            <g>
                              <path
                                fill="#fff"
                                d="M28.941,31.786L0.613,60.114c-0.787,0.787-0.787,2.062,0,2.849c0.393,0.394,0.909,0.59,1.424,0.59  c0.516,0,1.031-0.196,1.424-0.59l28.541-28.541l28.541,28.541c0.394,0.394,0.909,0.59,1.424,0.59c0.515,0,1.031-0.196,1.424-0.59   c0.787-0.787,0.787-2.062,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.062-0.786-2.848,0   L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"
                              />
                            </g>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <nav className="nav-menu">
                  <ul>
                    <li className={ this.state.page === '/' ? "active" : ''} onClick={()=> this.setState({page: "/"})}>
                      <Link to={`/`}>
                        { translate('app.tabs.explore') }
                      </Link>
                    </li>
                    <li className={ this.state.page.indexOf("/cookbooks") > -1 ? "active" : '' }  onClick={()=> this.setState({page: "/cookbooks"})}>
                      <Link to={`/cookbooks`}>
                        { translate('app.tabs.cookbooks') }
                      </Link>
                    </li>
                    <li className={ this.state.page.indexOf("/recipes") > -1 ? "active" : '' } onClick={()=> this.setState({page: "/recipes"})}>
                      { isSafari ?
                        <a href="/recipes">
                          { translate('app.tabs.recipes') }
                        </a>
                      :
                        <Link to={`/recipes`}>
                          { translate('app.tabs.recipes') }
                        </Link>
                      }
                    </li>
                    <li className={ this.state.page  === '/authors' ? "active" : '' }  onClick={()=> this.setState({page: "/authors"})}>
                      <Link to={`/authors`}>
                        { translate('app.tabs.authors') }
                      </Link>
                    </li>
                    <li className={ this.state.page  === '/blog' ? "active" : '' }  onClick={()=> this.setState({page: "/blog"})}>
                      <Link to={`/blog`}>
                        { translate('app.tabs.blog') }
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="nav-end">
              <ProfileStatus />
              <button
                className="main-menu-indicator" id="open-menu"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  viewBox="0 0 612 612"
                >
                  <g>
                    <g id="menu">
                      <g>
                        <path
                          fill="#7a7a7a"
                          d="M0,95.625v38.25h612v-38.25H0z M0,325.125h612v-38.25H0V325.125z M0,516.375h612v-38.25H0V516.375z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}
export default connect(mapStateToProps, { logOut, userSignedIn })(Header);
