import React
  from 'react';
import ReactLoading
  from 'react-loading';
import RenderSearch
  from '../../../components/shared/RenderSearch'
import SearchNotFound
  from '../SearchNotFound'

class IngredientItems extends React.Component {

  displayIngredients() {
    let { ingredients } = this.props
    if(ingredients.length !== 0) {
      return (
      ingredients.map((item)=> {
       return (
         <RenderSearch
           name={item.Name}
           source={item.ImagePath ? item.ImagePath : ''}
           id={item.Id}
           key={item.Id}
           ingradientFilter={`recipes?IncludedIng[]=${item.Id}`}
           location={'ingredients'}
         />
        )
      })
     )
    } else {
      return (
        this.noResults()
      )
    }
  }

	render() {
    let { title } = this.props

    if(this.props.loading) {
      return (
        <ReactLoading
          type={"spokes"}
          color={"#000"}
          height={18} width={18}
        />
      )
    } else {
      return (
        <>
          <div className="search-result-title">
           <h1>{title}</h1>
          </div>
          {this.displayIngredients()}
        </>
      )
    }
  }

  noResults(){
    return (
      <SearchNotFound />
    )
  }
}

export default IngredientItems
