import {
  BASE_URL,
  FETCH_RECIPES,
  FETCH_COOKBOOKS,
  FETCH_LATEST_COOKBOOKS,
  RATE_RECIPE,
  FETCH_SLIDER_COOKBOOKS,
  NUMBER_OF_PEOPLE
} from '../constants/types';

import api
  from '../helpers/api'

import qs
  from 'qs';

import { getLanguage }
  from '../services/token';

export function fetchRecipes(limit, offset, addRecipes, onlyParams) {
  return function(dispatch) {
    const url = `${BASE_URL}/recipe?limit=${limit}&offset=${offset}&${onlyParams}&LangId=${getLanguage}`

    api.get(url)
     .then((response) => {
       dispatch({
         type: FETCH_RECIPES,
         payload: response
       })
       addRecipes(response)
     })
     .catch((err) => {

     })
  }
}

export function fetchCookbooks(limit, offset, addCookBooks, handleError=null, onlyParams) {
  return async function(dispatch) {
    let url = `${BASE_URL}/book?limit=${limit}&offset=${offset}&${onlyParams}&LangId=${getLanguage}`

    api.get(url)
      .then((data) => {
        addCookBooks(data)
        dispatch({
          type: FETCH_COOKBOOKS,
          payload: data
        })
      })
      .catch(err => {
        handleError && handleError()
        console.log("FETCH COOKBOOKS ERROR", err)
      })
  }
}

export function fetchLatestCookbooks(addLatestCookBooks, handleError=null) {
  return async function(dispatch) {
    let url = `${BASE_URL}/book?OrderAsc=true&OrderBy=created&LangId=${getLanguage}`
    api.get(url)
      .then((data) => {
        dispatch({
          type: FETCH_LATEST_COOKBOOKS,
          payload: data
        })
      })
      .catch(err => {
        handleError && handleError()
        console.log("FETCH LATEST COOKBOOKS ERROR", err)
      })
  }
}

export function fetchRecipesCount(addRecipesCount) {
  return function(dispatch) {
    const url = `${BASE_URL}/recipe/count`

    api.get(url)
     .then((response) => {
       addRecipesCount(response)
     })
     .catch((err) => {
       console.log("FETCH RECIPES COUNT ERROR", err.response)
     })
  }
}

export function rateRecipe(recipeId, rate, changeRate) {
  return async function(dispatch) {
    let opts = ["VeryBad", "Bad", "Ok", "Good", "VeryGood"]

    let data = {
      "RatingValue": opts[rate - 1],
    }

    const url = `${BASE_URL}/recipe/${recipeId}/rate`

    api.post(url, qs.stringify(data))
     .then((response) => {
       changeRate(response)
       dispatch({
         type: RATE_RECIPE,
         recipeId,
         payload: response
       })
     })
     .catch((err) => {
       console.log("RATE RECIPE ERROR", err.response)
     })
  }
}

export function fetchSliderCookbooks(addSliderItems, handleError=null) {
  return async function(dispatch) {
    let url = `${BASE_URL}/slider/home?LangId=${getLanguage}`

    api.get(url)
      .then((data) => {
        addSliderItems(data)
        dispatch({
          type: FETCH_SLIDER_COOKBOOKS,
          payload: data
        })
      })
      .catch(err => {
        handleError && handleError()
        console.log("FETCH COOKBOOKS ERROR", err)
      })
  }
}

export function changeNumberOfPeople(number) {
  return async function(dispatch) {
    dispatch({
      type: NUMBER_OF_PEOPLE,
      payload: number
    })
  }
}
