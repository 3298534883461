import React
  from "react";
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import { updateNotification }
  from '../../../actions/me';
import ReactLoading
  from 'react-loading';

class PushNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNotification: false,
      loadingFetch: true
    };
  }

  componentDidMount() {
    if(this.props.me) {
      let { me } = this.props
      this.setState({
        currentNotification: me.Notifications,
        loadingFetch: false
      })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.me !== newProps.me) {
      let { me } = newProps
      this.setState({
        currentNotification: me.Notifications,
        loadingFetch: false
      })
    }
  }

  handleChange() {
    let { currentNotification } = this.state
    this.setState({
      loadingFetch: true
    })

    this.props.updateNotification(!currentNotification, this.afterUpdate.bind(this))
  }

  afterUpdate(data) {
    this.setState({
      currentNotification: data,
      loadingFetch: false
    })
  }

  displayCheckImput() {
    let { currentNotification, loadingFetch } = this.state
    if(!loadingFetch) {
      return (
        <div className="checkbox">
          <input onChange={this.handleChange.bind(this)} type="checkbox" value="" id="check2" checked={currentNotification} />
          <label htmlFor="check2"></label>
        </div>
      )
    } else {
      return (
        <ReactLoading
          type={"spokes"}
          color={"#000000"}
          height={30} width={30}
        />
      )
    }
  }

  render() {
   let { translate } = this.props
   return (
     <div className="row">
       <div className="col-sm-9 col-md-9">
         <div className="settings-notification">
           <div className="settings-notification-head">
             <h1>{translate('app.settings.notifications')}</h1>
             {this.displayCheckImput()}
           </div>
           <p>{translate('app.settings.notification_description')}</p>
         </div>
       </div>
     </div>
   )
  }
};

function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { updateNotification })(PushNotification);
