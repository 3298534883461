import React, { Component }
  from 'react'

import { Link }
  from "react-router-dom";

import { connect }
  from 'react-redux'

import { getTranslate }
  from 'react-localize-redux';

import { signIn, signInWithSocialMedia }
  from '../../actions/auth'

import md5
  from 'md5';

import ReactLoading
  from 'react-loading';

import FacebookLogin
  from 'react-facebook-login/dist/facebook-login-render-props';

import GoogleLogin
  from 'react-google-login';

import ReactGA
  from 'react-ga';

import { getCurrentPath, resetCurrentPath }
from '../../services/currentPath';

class Login extends Component{
  state = {
    loading: false,
    username: '',
    password: '',
    errors: []
  }

  componentDidMount() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Login screen'
    });
  }

  submitLogin(e) {
    e.preventDefault()

    let {
      username,
      password,
    } = this.state

    this.setState({ loading: true, errors: [] })

    password = md5(password)

    this.props.signIn(
      username,
      password,
      this.afterLogin.bind(this),
      this.catchedErrors.bind(this)
    )
  }

  catchedErrors(error){
    this.setState({ errors: error.error_description, loading: false })
  }

  afterLogin(token){
    this.setState({loading: false})
    window.location.href = getCurrentPath ? getCurrentPath : '/';
    resetCurrentPath()
  }

  submitButton() {
    let { translate } = this.props
    if(this.state.loading) {
      return (
        <div style={{marginLeft: "40%" }}>
          <ReactLoading
            type={"spin"}
            height={45} width={45}
          />
        </div>
      )
    } else {
      return (
        <input
          type="submit"
          className="send"
          value={translate('auth.sign_in')}
          onClick={(e) => this.submitLogin(e) } />
      )
    }
  }

  responseFacebook = (response) => {
    if(response.status !== "unknown") {
      this.setState({ loading: true, errors: [] })
      this.facebookLogin(response.accessToken)
    }
  }

  async facebookLogin(token) {
    if(token) {
      await this.props.signInWithSocialMedia(
        token,
        this.afterLogin.bind(this),
        this.catchedErrors.bind(this)
      )
    }
  }

  responseGoogleSuccess = (response) => {
    this.setState({ loading: true, errors: [] })
    this.loginWithGoogle(response.tokenId)
  }

  async loginWithGoogle(token){
    try {
      if (token) {
        this.props.signInWithSocialMedia(
          token,
          this.afterLogin.bind(this),
          this.catchedErrors.bind(this),
          "Google"
        );
      }
      // this.setState({ loading: false, errors: [] })
    } catch ({ message }) {
      this.setState({ loading: false, errors: ['login: Error: try again later, have problem with google login']})
    }
  }

  responseGoogleError = (response) => {
    console.log("google error response", response);
  }


  displayFacebokButton() {
    return (
      <FacebookLogin
        appId="1322572474488719"
        callback={this.responseFacebook}
        render={renderProps => (
          <a href={undefined}  onClick={renderProps.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="16" height="16" viewBox="0 0 16 16">
              <path fill="#FFF" fillRule="evenodd"
                d="M8.546 16H.883A.883.883 0 0 1 0 15.117V.883C0 .395.395 0 .883 0h14.234c.488
                0 .883.395.883.883v14.234a.883.883 0 0
                1-.883.883H11.04V9.8h2.08l.311-2.415H11.04V5.848c0-.7.194-1.176
                1.2-1.176h1.279v-2.16c-.619-.067-1.24-.1-1.863-.1a2.91 2.91
                0 0 0-3.11 3.196v1.781H6.461V9.8h2.085V16z"/>
            </svg>
          </a>
        )}
      />
    )
  }

  displayGoogleButton() {
    return (
      <GoogleLogin
        clientId="520625882481-m4q0uhornglqruf6e4dlpv4oh4hld2cp.apps.googleusercontent.com"
        render={renderProps => (
          <a href={undefined}  onClick={renderProps.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16"
              height="16" viewBox="0 0 16 16">
              <path fill="#FFF" fillRule="nonzero"
                d="M8 16A8 8 0 1 1 8 0a7.917 7.917 0 0 1 5.356 2.083
                24.531 24.531 0 0 0-1.745 1.773c-.152.172-.3.335-.521.56a4.642
                4.642 0 0 0-6.394.224 4.869 4.869 0 0 0 0 6.721 4.568 4.568 0 0 0 3.3
                1.392c2.707 0 3.678-1.268 4.3-3.172H8V6.416h7.641a9.382 9.382
                0 0 1-1.869 7.21A7.265 7.265 0 0 1 8 16z"/>
            </svg>
          </a>
        )}
        buttonText="Login"
        onSuccess={this.responseGoogleSuccess}
        onFailure={this.responseGoogleError}
        cookiePolicy={'single_host_origin'}
      />
    )
  }

  render(){
    let { translate } = this.props
    return (
      <>
      <div className="login-title">
        <h1>{translate('auth.sign_in')}</h1>
        <Link className="create-account.html"
              to={`/auth/new`}>
           {translate('auth.or_create_account')}
        </Link>
      </div>
      <div className="login-form">
        {this.state.errors.length > 0 && <p className="form-error" style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>}
        <form className="">
          <div className="input-wrap">
            <input type="email"
                   className="form-control"
                   placeholder={translate('auth.email')}
                   value={this.state.email}
                   onChange={({ target }) => this.setState({ username: target.value })}
                   required />
          </div>
          <div className="input-wrap">
            <input id="password-field"
                   type="password"
                   className="form-control"
                   name="password"
                   placeholder={translate('auth.password')}
                   value={this.state.password}
                   onChange={({ target }) => this.setState({ password: target.value })}
                   required />

            <span toggle="#password-field" className="pass-eye-off toggle-password"></span>
          </div>
          <div className="submit-button">
            {this.submitButton()}
          </div>
        </form>
        <div className="form-forgot">
          <Link to={`/auth/forgetpassword`}>
            {translate('auth.forgot_password')}
          </Link>
        </div>
      </div>
      <div className="login-other-form">
        <span className="login-text">{translate('auth.or_continue')}</span>
        <div className="login-social">
          <ul>
            <li>
              {this.displayFacebokButton()}
            </li>
            <li>
              {this.displayGoogleButton()}
            </li>
            <li>
              {/* <a href={undefined} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                  height="16" viewBox="0 0 16 16">
                  <g fill="#FFF" fillRule="evenodd">
                    <path fillRule="nonzero"
                      d="M11.584 0H4.415A4.42 4.42 0 0 0 0 4.415v7.169A4.42
                      4.42 0 0 0 4.415 16h7.169A4.42 4.42 0 0 0 16
                      11.585v-7.17A4.42 4.42 0 0 0 11.584 0zm3 11.585a3
                      3 0 0 1-3 3H4.415a3 3 0 0 1-3-3v-7.17a3 3 0 0 1
                      3-3h7.169a3 3 0 0 1 3 3v7.17z"/>
                    <path fillRule="nonzero"
                      d="M8 3.862a4.138 4.138 0 1 0 0 8.276 4.138
                      4.138 0 0 0 0-8.276zm0 6.851a2.713 2.713 0 1 1
                      0-5.426 2.713 2.713 0 0 1 0 5.426z"/>
                    <path d="M12.215 2.624a1.17 1.17 0 1 0
                      0 2.34 1.17 1.17 0 0 0 0-2.34z"/>
                  </g>
                </svg>
              </a> */}
            </li>
          </ul>
        </div>
      </div>
      </>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { signIn, signInWithSocialMedia })(Login)
