import React
  from 'react';
import { Play1Icon, PauseIcon }
  from '../../../assets/icons/AppIcons'
import PureImage
  from '../../../components/shared/PureImage';
import SvgImage
  from '../../../components/shared/SvgImage';

class PlayVideo extends React.Component {
  _isMounted = false
	constructor(props) {
		super(props);

		this.state = {
		}
	}

  UNSAFE_componentWillMount() {
		this._isMounted = true;
  }

  displayPlayIcon() {
    let { recipe } = this.props

    if(recipe && recipe.Videos && recipe.Videos.Main) {
      return (
        <div data-toggle="modal" data-target="#video-plp"
          style={{zIndex: 5, position: "absolute",top: "45%",left: "48%", paddingLeft: 5, paddingRight: 25}}
          onClick={() => this.props.setRecipe(recipe)}>
          <SvgImage
            svg={this.state.playVideo ? PauseIcon : Play1Icon}
            style={{width: 22}}
          />
        </div>
      )
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderToolsImg(){
    if(this.props.CoverPath != undefined && this._isMounted){
      return (
        <div style={{width: "100%", height: "100%"}}>
          {this.displayPlayIcon()}
          <PureImage src={this.props.CoverPath} style={{width: "130%"}}/>
        </div>
      )
    } else {
      return <div></div>
    }
  }

	render() {
		return (
      this.renderToolsImg()
    )
	}
}

export default PlayVideo
