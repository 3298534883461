import React from "react";
import { WEB_URL } from '../../../constants';
import { Route } from "react-router-dom";
import Cookbooks from "./cookbooks";
import Recipes from "./recipes";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const Favorites = props => {

  let currentPage = window.location.href
  let checkCurrent = currentPage == `${WEB_URL}/favorites/cookbooks`
  let { translate } = props

  return (
    <div className="favorites">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="favorites-title">
              <h1>{checkCurrent ? translate('app.favourite.cookbooks') : translate('app.favourite.recipes')}</h1>
            </div>
          </div>
          <div className="favorites-nav container">
            <ul>
              <li className={!checkCurrent ? "active" : ""}>
                <Link to={`/favorites/recipes`}>{ translate('app.favourite.recipes') }</Link>
              </li>
              <li className={checkCurrent ? "active" : ""}>
                <Link to={`/favorites/cookbooks`}>{ translate('app.favourite.cookbooks') }</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Route path="/favorites/cookbooks" component={Cookbooks} />
      <Route exact path="/favorites/recipes" component={Recipes} />
    </div>
  );
};

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(Favorites)
