import React
    from 'react'

const DepartmentIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 25"><path fill="#7a7a7a" fillRule="nonzero" d="M16.111 12.222c0-2.76.112-5.73.336-8.91a3.55 3.55 0 0 1 .968-2.2H3.89c-1.105 0-2.011.994-2.23 2.312-.303 1.828-.548 5.223-.548 8.794 0 2.552.155 6.026.466 10.42l.66-.662c.601-.6 1.556-.653 2.22-.123L5.767 22.9a.556.556 0 0 0 .694 0l1.383-1.109a1.667 1.667 0 0 1 2.087.002l1.374 1.106c.202.163.49.164.694.002l1.408-1.12a1.667 1.667 0 0 1 2.073 0l1.09.864a119.42 119.42 0 0 1-.46-10.423zM20 0a3.333 3.333 0 0 1 3.333 3.333v3.89c0 .92-.746 1.666-1.666 1.666h-2.223a.556.556 0 0 1 0-1.111h2.223a.556.556 0 0 0 .555-.556V3.333A2.222 2.222 0 0 0 20 1.111a2.45 2.45 0 0 0-2.445 2.279c-.222 3.155-.333 6.1-.333 8.832 0 3.418.152 6.86.456 10.326a1.111 1.111 0 0 1-1.798.967l-1.09-.864a.556.556 0 0 0-.69 0l-1.408 1.12a1.667 1.667 0 0 1-2.083-.007L9.236 22.66a.556.556 0 0 0-.696 0l-1.383 1.108a1.667 1.667 0 0 1-2.082.001l-1.312-1.047a.556.556 0 0 0-.74.041l-.66.66a1.111 1.111 0 0 1-1.894-.707C.156 18.3 0 14.801 0 12.218c0-3.63.25-7.084.563-8.975C.863 1.436 2.171 0 3.889 0H20zM3.889 7.778a.556.556 0 1 1 0-1.111h5.555a.556.556 0 0 1 0 1.11H3.889zm7.778 0a.556.556 0 1 1 0-1.111h2.221a.556.556 0 1 1 0 1.11h-2.221zM3.889 11.11a.556.556 0 1 1 0-1.111h5.555a.556.556 0 0 1 0 1.111H3.889zm7.778 0a.556.556 0 1 1 0-1.111h2.221a.556.556 0 1 1 0 1.111h-2.221zm-7.778 3.333a.556.556 0 1 1 0-1.11h5.555a.556.556 0 0 1 0 1.11H3.889zm7.778 0a.556.556 0 1 1 0-1.11h2.221a.556.556 0 1 1 0 1.11h-2.221zm-7.778 3.334a.556.556 0 1 1 0-1.111h5.555a.556.556 0 0 1 0 1.11H3.889zm7.778 0a.556.556 0 1 1 0-1.111h2.221a.556.556 0 1 1 0 1.11h-2.221z"></path>
    </svg>
  )
}

export default DepartmentIcon
