import React
  from 'react'

import RecipeCard
  from '../../../components/recipes/RecipeCard'

import { fetchFavouriteRecipes }
  from '../../../actions/favourites';

import { connect }
  from 'react-redux';

import NoFavorite
  from '../../../components/recipes/NoFavorite';

import Spinner
  from '../../../assets/img/eclipse-spinner.gif';

class RecipesFavorites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentHoverRecipe: null
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchFavouriteRecipes(this.stopLoading.bind(this));
  }

  stopLoading() {
    this.setState({ loading: false });
  }

  changeCurrentHoverRecipe(itemId) {
    let { currentHoverRecipe } = this.state

    if(currentHoverRecipe != itemId) {
      this.setState({currentHoverRecipe: itemId})
    }
  }

  displayRecipes() {
    let { currentHoverRecipe } = this.state

    if (!this.state.loading) {
      let { favouriteRecipes } = this.props;
      if (favouriteRecipes.length !== 0) {
        return favouriteRecipes.map((item)=> {
          return (
            <RecipeCard
              aid={item.Author && item.Author.Id}
              item={item}
              key={item.Id}
              id={item.Id}
              videos={item.Videos}
              source={item.CoverPath}
              heading={item.Name}
              time={item.PrepTime}
              paragraph={"by " + (item.Author && item.Author.Name)}
              currentHoverRecipe={currentHoverRecipe}
              changeCurrentHoverRecipe={(e, itemId) => this.changeCurrentHoverRecipe(itemId)}
            />
            );
        })
      } else {
        return (
          <NoFavorite recipe/>
        );
      }
    } else {
      return null;
    }
  }

  render(){
    if(this.state.loading) {
      return (
        <div style={{paddingLeft: "47%", marginBottom: "5%",marginTop: "5%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }

    return(
      <div className="container">
        <div className="row">
           {this.displayRecipes()}
        </div>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return { favouriteRecipes: state.favouriteRecipes };
}

export default connect(
  mapStateToProps,
  { fetchFavouriteRecipes }
)(RecipesFavorites);
