import jQuery from 'jquery';
import "slick-carousel"

var $ = jQuery

function hambugerNavigation() {
    $("#open-menu").click(function(){
        $('.nav-menu-wrapp').addClass('is-open');
        $('body').addClass('is-open');
    });
    $(".closebtn").click(function(){
        $('.nav-menu-wrapp').removeClass('is-open');
        $('body').removeClass('is-open');
    });
    $(".nav-menu ul li a").click(function(){
        $('.nav-menu-wrapp').removeClass('is-open');
        $('body').removeClass('is-open');
    });
}

function hideshowPass() {
    $(".toggle-password").click(function() {
        $(this).toggleClass("pass-eye-off pass-eye");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });
}

function favbook() {
    $(".toggle-fav-book").click(function(){
        $(this).toggleClass("fav-book-on fav-book-off");
    });
}
function favrecipes() {
    $(".recipes-asset").click(function(){
        $(this).toggleClass("fav-recipes-on fav-recipes-off");
    });
}

function slider() {
    $('.main-explore-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        dots: false,
        rows: 0,
        fade: true,
        asNavFor: '.second-explore-slider',
        accessibility: false,
        draggable: false,
        nextArrow: '.next',
        prevArrow: '.previous',
    });
    $('.second-explore-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        asNavFor: '.main-explore-slider',
        dots: false,
        arrows: false,
        infinite: false,
        rows: 0,
        centerMode: true,
        focusOnSelect: true,
        variableWidth: true,
        accessibility: false,
        draggable: false,
    });
    $('.cookbooks-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        rows: 0,
        infinite: false,
        variableWidth: false,
        draggable: true,
        accessibility: true,
        draggable: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.cookbook-month-slider-wrap').slick({
        dots: false,
        arrows: true,
        infinite: false,
        rows: 0,
        centerMode: false,
        focusOnSelect: true,
        variableWidth: true,
        accessibility: false,
        draggable: true,
        swipeToSlide: true,
        nextArrow: '.cookbook-month-next',
		    prevArrow: '.cookbook-month-prev'
    });
    $('.recipes-howto-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        infinite: true,
        rows: 0,
        focusOnSelect: true,
        variableWidth: true,
        draggable: true,
        accessibility: false,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    $('.recipes-extra-slider-wrap').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: false,
        rows: 0,
        focusOnSelect: false,
        variableWidth: true,
        draggable: false,
        accessibility: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false
                }
            }
        ]
    });

    $('.more-cookbooks-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: false,
        rows: 0,
        focusOnSelect: false,
        draggable: false,
        mobileFirst: true,
        accessibility: false,
        responsive: [
          {
            breakpoint: 767,
            settings: "unslick"
          }
        ]
    });
    jQuery(window).on('resize', function() {
        // $('.more-cookbooks-slider').slick('resize');
    });
}

function videoSlider () {
    const slider = $(".video-list");
    slider.slick({
        dots: false,
        arrows: false,
        infinite: false,
        rows: 0,
        focusOnSelect: true,
        variableWidth: true,
        draggable: true,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 5,
        accessibility: false,
        slidesToScroll: 1,
    });
//    slider.on('wheel', (function(e) {
//        e.preventDefault();
//        if (e.originalEvent.deltaY < 0) {
//            $(this).slick('slickPrev');
//        } else {
//            $(this).slick('slickNext');
//        }
//    }));
}

function rangeSlider() {
  if($("#range-time").slider){
    $("#range-time").slider({
        range: "min",
        max: 100,
        value: 30,
        slide: function (e, ui) {
            $("#time-value").html(ui.value);
        }
    });
  }

  if($("#range-cal").slider){
    $("#range-cal").slider({
        range: "min",
        max: 100,
        value: 30,
        slide: function (e, ui) {
            $("#cal-value").html(ui.value);
        }
     });
  }
}

function featuresTab() {
    $("document").ready(function(){
        $(".tab-slider-body").hide();
        $(".tab-slider-body:first").show();
    });

    $(".tab-slider-nav li").click(function() {
        $(".tab-slider-body").hide();
        var activeTab = $(this).attr("rel");
        $("#"+activeTab).fadeIn();
        if($(this).attr("rel") === "tab2"){
            $('.tab-slider-tabs').addClass('slide');
        }else{
            $('.tab-slider-tabs').removeClass('slide');
        }
        $(".tab-slider-nav li").removeClass("active");
        $(this).addClass("active");
    });
}

function search() {
  $('.click-search').click(function(){
    $('.search').addClass('show');
    $('body').addClass('search-on');
  });
}

function removeSearchBox() {
  $('.search-close').click(function(){
    $('.search').removeClass('show');
    $('body').removeClass('search-on');
  });
}

function toggleMoreVideo() {
    $(".show-more").click(function(){
        $(".show-more-dialog").toggle();
        $(".video-narrated").toggle();
    });
    $(".show-more").click(function(){
        $(".show-more").toggleClass("active");
    });
}


function inputnumber() {
    $('.change').on('click', function(){
        var selfObj = $(this);
        var numberInput = selfObj.closest('.number-input').find('.input-box');
        var currentValue = numberInput.val().length && !(isNaN(numberInput.val())) ? parseInt(numberInput.val()) : 0;
        var minVal = numberInput[0].hasAttribute('min') ? numberInput.attr('min') : false;
        var maxVal = numberInput[0].hasAttribute('max') ? numberInput.attr('max') : false;
        if(selfObj.hasClass('minus')) {
            if(minVal) {
                if(currentValue > parseInt(minVal)) {
                    numberInput.val(currentValue - 1);
                }
                else {
                    numberInput.val(currentValue);
                }
            }
            else {
                numberInput.val(currentValue - 1);
            }
        }
        else if(selfObj.hasClass('plus')) {
            if(maxVal) {
                if(currentValue < parseInt(maxVal)) {
                    numberInput.val(currentValue + 1);
                }
                else {
                    numberInput.val(currentValue);
                }
            }
            else {
                numberInput.val(currentValue + 1);
            }
        }
    });
}

function closeBanner() {
    $( ".banner-close" ).click(function() {
      $( this ).parent().parent().fadeOut();
    });
  }

function initialSelects() {
  $('.dropdown-toggle').on('click', function (e) {
    $(this).parent().toggleClass('open')
  });

  $('body').on('click', function (e) {
    if (!$('.dropdown').is(e.target)
        && $('.dropdown').has(e.target).length === 0
        && $('.open').has(e.target).length === 0
    ) {
        $('.dropdown').removeClass('open');
    }
  });
}

function initialModals() {
  $('#starts-step').on('click', function (e) {
    window.$('#video-plp').modal('toggle');
    setTimeout(function() {
      window.$('#video').modal('show');
    },350);
  });
}

function init() {
  setInterval(function() {
    search();
  }, 350);

  hambugerNavigation();
  hideshowPass();
  videoSlider();
  rangeSlider();
  featuresTab();
  removeSearchBox();
  favbook();
  favrecipes();
  closeBanner();
}

window.slider = slider
window.rangeSlider = rangeSlider
window.initialSelects = initialSelects
window.initialModals = initialModals
window.toggleMoreVideo = toggleMoreVideo
window.inputnumber = inputnumber
window.search = search

jQuery(document).ready(function($) {
  init();
});

window.addEventListener('popstate', function(event) {
});
