import React
  from 'react';

import CookBookCard
  from '../../../components/cookbooks/index/CookBookCard'

import { fetchFavouriteCookbooks }
  from '../../../actions/favourites';

import { connect }
  from 'react-redux';

import NoFavorite
  from '../../../components/recipes/NoFavorite'

import Spinner
  from '../../../assets/img/eclipse-spinner.gif';

class CookbooksFavorites extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchFavouriteCookbooks(this.stopLoading.bind(this));
  }

  stopLoading() {
    this.setState({ loading: false });
  }

  displayCookbooks() {
    if (!this.state.loading) {
      let { favouriteCookbooks } = this.props;
      if (favouriteCookbooks.length !== 0) {
        return favouriteCookbooks.map((item)=> {
          return (
            <CookBookCard
              key={item.Id}
              id={item.Id}
              item={item}
              source={item.CoverPath}
              heading={item.Name}
              slider={true}
              paragraph={item.Description ? item.Description.substring(0,70) + "...": "No description available"}
            />
        );
      })
      } else {
        return (
          <NoFavorite />
        );
      }
    } else {
      return null;
    }
  }
  render(){
    if(this.state.loading) {
      return (
        <div style={{paddingLeft: "47%", marginBottom: "5%",marginTop: "5%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }

    return(
      <div className="container">
        <div className="row">
          {this.displayCookbooks()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { favouriteCookbooks: state.favouriteCookbooks };
}

export default connect(
  mapStateToProps,
  { fetchFavouriteCookbooks }
)(CookbooksFavorites);
