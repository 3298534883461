import React
  from 'react'

import BlogSlider
  from '../../../components/blog/BlogSlider'

import BlogItem
  from '../../../components/blog/BlogItem'

import Blog1
  from '../../../assets/img/blog-1.jpg'

import Blog2
  from '../../../assets/img/blog-2.jpg'

import Blog3
  from '../../../assets/img/blog-3.jpg'

import Blog4
  from '../../../assets/img/blog-4.jpeg'

import Blog5
  from '../../../assets/img/blog-5.jpg'

import Blog6
  from '../../../assets/img/blog-6.jpeg'

class Blog extends React.Component {

  componentDidMount(){

      window.slider()
  }

  render(){
    return(
      <>
      <BlogSlider />

      <section className="blog section-top-90">
        <div className="container">
          <div className="row">
            <BlogItem
              source={Blog1}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog2}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog3}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog4}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog5}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog6}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog1}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog2}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
            <BlogItem
              source={Blog3}
              title={"Protein Bars for daily life"}
              text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry…"}
              date={"Jan 29 2018"}
              author={"by Jessica Anderson"}
            />
          <div className="col-sm-12 col-md-12">
              <div className="pagination-wrapper">
                <ul className="pagination">
                  <li className="page-item">
                    <a href="asdasd" className="page-link"><i className="fas fa-chevron-left"></i></a>
                  </li>
                  <li className="page-item active">
                    <a href="asdasd" className="page-link">1</a>
                  </li>
                  <li className="page-item">
                    <a href="asdasd" className="page-link">2</a>
                  </li>
                  <li className="page-item">
                    <a href="asdasd" className="page-link">3</a>
                  </li>
                  <li className="page-item">
                    <a href="asdasd" className="page-link"><i className="fas fa-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    )
  }
}

export default Blog
