import React
  from "react";
import { connect }
  from 'react-redux';
import StarRatings
  from 'react-star-ratings';
import ReactLoading
  from 'react-loading';
import { addFeedback }
  from '../../../actions/feedback.js';
import { getTranslate }
  from 'react-localize-redux';

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 5,
      feedbackText: "",
      success: null
    };
  }

  changeRating( newRating ) {
    this.setState({
      rating: newRating
    });
  }

  displaySaveButton() {
    let { translate } = this.props
    if(this.state.saveRequest) {
       return (
         <div style={{marginLeft: "45%"}}>
           <ReactLoading
             type={"spokes"}
             color={"#000000"}
             height={30} width={30}
           />
         </div>
       )
     } else {
      return (
        <a
          href={undefined} 
          onClick={() => this.handleSave()}
          className="button">
            {translate('app.settings.send_feedback')}
        </a>
      )
    }
  }


  handleSave() {
    this.setState({saveRequest: true})

    let data = {"Description": this.state.feedbackText, "FeedbackValue": this.state.rating}
    this.props.addFeedback(data, this.afterRequest.bind(this))
  }

  afterRequest(result) {
    let { translate } = this.props
    this.props.setMessage("Feedback successfully", translate('app.settings.feedback_successfully'))
    this.setState(
      {success: translate('app.settings.feedback_successfully'), saveRequest: false, rating: 5, feedbackText: ''}
      , () => {
        setTimeout(() => {
          this.setState({ success: null});
        }, 3000);
      }
    );
  }

  render() {
   let { translate } = this.props
   return (
     <div className="row">
       <div className="col-sm-7 col-md-7 col-md-center">
         <div className="settings-feedback">
           <h1>{translate('app.settings.like_tendoo')}</h1>
           <div className="contet-rate-wrap">
             <div className="content-rate">
               <StarRatings
                 rating={this.state.rating}
                 starRatedColor="#F5BD00"
                 starHoverColor="#F5BD00"
                 starDimension="45px"
                 starSpacing={"0"}
                 numberOfStars={5}
                 changeRating={this.changeRating.bind(this)}
                 name='rating'
               />
             </div>
             <div className="content-rate-info">
               <span>{translate('app.settings.very_bad')}</span>
               <span>{translate('app.settings.very_well')}</span>
             </div>
           </div>
           <div className="settings-input">
            <textarea
              className="form-control"
              name="message"
              id="message"
              placeholder={translate('app.settings.message')}
              value={this.state.feedbackText}
              onChange={e => this.setState({feedbackText: e.target.value})}
              required
            ></textarea>
           </div>
           <div className="feedback-button">
            {this.displaySaveButton()}
           </div>
         </div>
       </div>
     </div>
   )
  }
};

function mapStateToProps(state) {
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { addFeedback })(Feedback);
