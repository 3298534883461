import axios from 'axios';
import { getToken }
  from '../services/token'

import {
  BASE_URL,
} from '../constants';

let api = axios.create({
  baseURL: BASE_URL,

  validateStatus: function (status) {
    switch(status) {
      case 200:
      case 201:
      return true;
      case 401:
      // navigate to auth
      break;
      default:
      return true;
    }
  },

  responseType: "json"
});

api.interceptors.request.use(
  async config => {
    const token = getToken

    config.headers['content-type'] = 'application/x-www-form-urlencoded'

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use((response) => {
  switch(response.status) {
    case 200:
    case 201:
    return response.data;
    case 401:
    // navigate to auth
    break;
    default:
    return Promise.reject({data: response.data || {}, status: response.status});
  }
});

//api.interceptors.request.use(request => {
//  console.log('Starting Request', request)
//  return request
//})

export default api;
