import React 
  from 'react';
import { getToken }
  from '../../services/token'

const Authorization = (WrappedComponent) => {
  class HOC extends React.Component {
    UNSAFE_componentWillMount(){
      if(getToken){
        this.props.history.push('/')
      }
    }

    render() {
      return <WrappedComponent { ...this.props } />;
    }
  }

  return HOC;
};

export default Authorization
