import {
  NUMBER_OF_PEOPLE,
} from '../constants/types';

export default function(state=2, action){
  switch(action.type){
    case NUMBER_OF_PEOPLE:
     return action.payload
    default:
     return state
  }
}
