import React
  from 'react';
import PureImage
  from '../../../components/shared/PureImage';

class ImageWithIngredients extends React.Component {
  _isMounted = false
	constructor(props) {
		super(props);

		this.state = {
      displayCover: 0,
      width: 0,
      height: 280
		}

    window.addEventListener("resize", this.update.bind(this));
	}

  update() {
    if(window.innerWidth < 745) {
      if(!this.state.isMobile) {
        this.setState({isMobile: true, isDesktop: true})
      }
    } else if (window.innerWidth < 992 && window.innerWidth > 745) {
      this.setState({isDesktop: true, isMobile: false})
    } else {
      if(this.state.isMobile || this.state.isDesktop) {
        this.setState({isMobile: false, isDesktop: false})
      }
    }
  };

  componentDidMount() {
    this.setState({
      width: this.container.offsetWidth + 40,
    });

    if(window.innerWidth < 745) {
      if(!this.state.isMobile) {
        this.setState({isMobile: true, isDesktop: true})
      }
    } else if (window.innerWidth < 992 && window.innerWidth > 745) {
      this.setState({isDesktop: true, isMobile: false})
    } else {
      if(this.state.isMobile || this.state.isDesktop) {
        this.setState({isMobile: false, isDesktop: false})
      }
    }
  }

  UNSAFE_componentWillMount() {
    this._isMounted = true;
  }

  triggerCover() {
    this.setState({displayCover: this.props.displayIngredientsNames === 0 ? 100 : 0 })
  }

  displayIngredients() {
    var { ingredients } = this.props
    let topPosition = this.state.isMobile ? 290 : 280
    let imagePosition = this.state.isMobile ? 465 : 360
    let leftTab = this.state.isMobile ? 1 : 5
    if(ingredients && ingredients.length > 0) {
        return(
          ingredients.map((i, index) => {
            return (
              <div
                key={index}
                style={{  display: this.props.displayIngredientsNames === 0 ? "none" : "flex",
                          position: 'absolute',
                          left: i.X * imagePosition + leftTab,
                          top: i.Y * topPosition,
                          justifyContent: 'center',
                          alignItem: "center",
                          backgroundColor: "rgba(26,26,26,0.5)",
                          borderRadius: 12,
                          zIndex: 1000,
                          width: i.Width * imagePosition,
                          paddingBottom: 3 }}>
                <div style={{position: "absolute",
                            backgroundColor: '#ff5f00',
                            width: 5,
                            height: 5,
                            borderRadius: 13,
                            top: -2}} />
                          <p style={{ fontSize: 13,
                            alignSelf: 'center',
                            fontStyle: "bold",
                            color: "#ffffff",
                            marginTop: 1}}>{i.Name}</p>
              </div>
            )

          })
        )
      } else {
        return (

          <p style={{display: this.props.displayIngredientsNames === 0 ? "none" : "block", color: "#ffffff", top: "48%", left: "25%", position: 'absolute', zIndex: 1000}}>
            Not found any ingredients
          </p>
        )
      }
  }

  renderIngredientImg(){
    if(this.props.ingredientImagePath != undefined){
      return (
        <div
          className="img-syle"
          ref={el => (this.container = el)}
          onMouseEnter={() => this.setState({displayCover: 100 })}
          onMouseLeave={() => this.setState({displayCover: 0 })}>
          <div style={{ display: this.props.displayIngredientsNames === 0 ? "none" : "block", position:'absolute', width:'100%', height: `${this.props.displayIngredientsNames}%`, zIndex: 99, backgroundColor: "black", opacity: 0.6}}>
          </div>
          {this.displayIngredients()}
          <PureImage
            src={this.props.ingredientImagePath}
          />
        </div>
      )
    } else {
      return <div></div>
    }
  }

	render() {
		return (
      this.renderIngredientImg()
    )
	}
}

export default ImageWithIngredients
