import React
  from 'react'

class CookbookFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedSort: props.selected || "name"
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.selected !== newProps.selected) {
      this.setState({selectedSort: newProps.selected})
    }
  }

  setDateSort(item) {
    this.setState({selectedSort: item})
    this.props.changeDateSort(item)
  }

  displaySelectedSortByItems() {
    let preSelected = this.props.placeholder

    if(this.state.selectedSort === "name") {
      preSelected = "Name"
    } else if(this.state.selectedSort === "isPublished") {
      preSelected = "Date published"
    } else if(this.state.selectedSort === "rate") {
      preSelected = "Rating"
    }

    return preSelected
  }

  render(){
    return(
        <div className="col-xs-12 col-sm-6 col-md-3">
        <div className="dropdown-wrapp">
            <h1 className="filter-inner-title">{this.props.label}</h1>
            <div className="dropdown">
                <button className="dropdown-toggle" type="button" data-toggle="dropdown">
                  {this.displaySelectedSortByItems()}
                  <svg version="1.1" width="10" height="6"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    enableBackground="new 0 0 129 129"><g><path fill="#7a7a7a"
                    d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/>
                    </g>
                  </svg>
                </button>
                <div className="dropdown-menu">
                    <div className="select-wrapp">
                        <ul>
                            {this.props.items.map((item, index) => {
                              return <li key={index}>
                                <a href={undefined}  onClick={() => this.setDateSort(item.value)}>{item.label}</a>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

export default CookbookFilter
