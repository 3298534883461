import { FETCH_TOOLS }
  from '../constants/types';

import api
  from '../helpers/api'

import { getLanguage }
  from "../services/token";
  
export function fetchTools() {
  return function(dispatch) {
    api.get(`/tool?LangId=${getLanguage}`)
      .then(response => {
        dispatch({
          type: FETCH_TOOLS,
          payload: response
        })
      })
      .catch((err) => {
        console.log("FETCH TOOLS ERROR", err)
      })
  }
}
