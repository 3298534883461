import React
  from 'react';
import { fetchTools }
  from '../../../actions/tools'
import { connect }
  from 'react-redux';
import PureImage
  from '../../../components/shared/PureImage'

class ToolsModal extends React.Component {
  componentDidMount(){
    if(this.props.tools.length === 0){
      this.props.fetchTools()
    }
  }

  render() {
    let { translate } = this.props
      return(
        <div className="modal flt-modal fade" id="brand" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h1 className="title">{translate('app.recipeDetails.buy_tools_online')}</h1>
              <p>{translate('app.recipeDetails.buy_tools_description')}</p>
            </div>
            <div className="modal-body">
              <div className="row">
                 <div className="recipes-md-tools-wrapp">
                   {this.props.tools && this.props.tools.map((item)=>{
                     return (
                     <a
                       href={`${item.AffiliateLink ? item.AffiliateLink : '#'}`}
                       key={item.Id}>
                        <div
                          key={item.Id}
                          className="col-sm-6 col-md-6"
                        >
                          <div className="recipes-md-tools-item">
                            <div className="recipes-md-tools-item-wrapp">
                              <PureImage src={item.ImagePath} className="img-responsive" />
                              <h1>{item.Name}</h1>
                            </div>
                          </div>
                        </div>
                      </a>
                     )
                    })}
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
  )}
}


function mapStateToProps({ tools }){
  return { tools }
}

export default connect(mapStateToProps, { fetchTools })(ToolsModal)
