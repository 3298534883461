import React
    from 'react'

const FavShapeIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="60" viewBox="0 0 36 60"><path fill="#FF5F00" fillRule="nonzero" d="M35.757 9.594c.162 2.933.243 6.74.243 11.427 0 10.068-.373 22.177-1.119 36.327-.082 1.549-1.297 2.732-2.715 2.643a2.478 2.478 0 0 1-1.67-.818l-10.491-11.46c-.787-.859-1.103-1.092-1.519-1.23a1.504 1.504 0 0 0-.972 0c-.416.138-.732.371-1.519 1.23L5.5 59.177c-1.004 1.097-2.632 1.097-3.636 0a2.933 2.933 0 0 1-.749-1.82C.372 43.574 0 31.51 0 21.156c0-4.745.078-8.59.235-11.539C.494 4.754 3.975.831 8.416.396 11.12.132 14.313 0 18 0c3.684 0 6.875.132 9.576.396 4.434.433 7.912 4.344 8.18 9.198z"/>
    </svg>
  )
}

export default FavShapeIcon
