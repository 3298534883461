import React 
  from 'react'
import TopSixty
  from '../../../components/cookbooks/index/TopSixty'

const Cookbooks = (props) => {
  return(
    <TopSixty
      history={props.history}
    />
  )
}

export default Cookbooks
