import React
  from "react";
import RecipeCard
  from '../../../components/recipes/RecipeCard';
import { fetchDishPart }
  from '../../../helpers/cookbook'
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';

class Nav extends React.Component {
  state = {
    currentTab: 'all',
    items: [],
    loading: false,
    claimed:true,
    partitions:[],
    currentHoverRecipe: null
  }

  componentDidMount() {
    if(this.props.partitions){
      fetchDishPart(
        this.props.id,
        null,
        this.addDishes.bind(this, 'all')
      )
      this.props.partitions.forEach(part => {
        fetchDishPart(this.props.id, part.Id, this.addDishes.bind(this, part.Name) )
      })
      this.setState({claimed:false, loading:true, partitions:this.props.partitions})
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps !== this.props.partitions){
      fetchDishPart(
        newProps.id,
        null,
        this.addDishes.bind(this, 'all') ,
      )
      newProps.partitions.forEach(part => {
        fetchDishPart(newProps.id, part.Id, this.addDishes.bind(this, part.Name) )
      })
      this.setState({claimed:false, loading:true, partitions:newProps.partitions})
    }
  }

  addDishes(name, data){
    this.setState({
      [name]: data,
      loading: false
    })
  }

  activeTab(tab, myComponent){
    this.state.partitions.forEach((part, idx) => {
      if(tab === part.Name){
        this.setState({currentTab:part.Name})
      }
    })
  }

  tabViews(){
    let items = this.state.partitions
    return items.map((item, index) => (
      <li key={index} className={this.state.currentTab === item.Name ? "active" : ''}>
          <a href={undefined}  onClick={()=> this.setState({currentTab:item.Name})}>
              {item.Name}
          </a>
      </li>
    ))
  }

  changeCurrentHoverRecipe(itemId) {
    let { currentHoverRecipe } = this.state

    if(currentHoverRecipe != itemId) {
      this.setState({currentHoverRecipe: itemId})
    }
  }

  displayCategoriyItems(item) {
    let { translate } = this.props
    if(this.state[item.Name].length !== 0) {
      return (
        this.state[item.Name].map((brand, index)=>(
          <RecipeCard
            key={brand.Id}
            item={brand}
            id={brand.Id}
            source={brand.CoverPath}
            heading={brand.Name}
            time={brand.PrepTime}
            paragraph={translate('app.cookbookDetails.by') + (brand.Author && brand.Author.Name)}
            videos={brand.Videos}
            IsFavorized={brand.IsFavorized}
            currentHoverRecipe={this.state.currentHoverRecipe}
            changeCurrentHoverRecipe={(e, itemId) => this.changeCurrentHoverRecipe(itemId)}
          />
          ))
      )
    } else {
      return (
        <p style={{marginLeft: 40, marginBottom: 80}}>
          { translate('app.cookbookDetails.no_recipe_category') }
        </p>
      )
    }
  }

  renderItems(){
    let allItems = this.state.partitions
    let { translate } = this.props
    if(this.state.currentTab !== 'all'){
    return allItems.map(item => (
        this.state.currentTab === item.Name
      &&
        this.displayCategoriyItems(item)
      ))
    }else{
      return this.state.currentTab === 'all'
      &&
      this.state.all && this.state.all.map(brand=>(
        <RecipeCard
          key={brand.Id}
          item={brand}
          id={brand.Id}
          source={brand.CoverPath}
          heading={brand.Name}
          time={brand.PrepTime}
          paragraph={translate('app.cookbookDetails.by') + (brand.Author && brand.Author.Name)}
          videos={brand.Videos}
          IsFavorized={brand.IsFavorized}
          currentHoverRecipe={this.state.currentHoverRecipe}
          changeCurrentHoverRecipe={(e, itemId) => this.changeCurrentHoverRecipe(itemId)}
        />

      ))
    }
  }

  render() {
    let { translate } = this.props

    if(this.state.loading) {
      return (
        <div style={{paddingLeft: "40%", paddingBottom: "10%", paddingTop: "50%"}}>
          <img src={Spinner} alt="logo" width="100px"/>
        </div>
      )
    }

    if(this.state.all && this.state.all.length > 0) {
      return (
        <>
          <div className="col-sm-12 col-md-12">
            <div className="cookbook-profile-nav">
              <ul>
                <li className={this.state.currentTab === "all" && "active"}>
                  <a href={undefined}  onClick={()=> this.setState({currentTab:"all"})}>
                    { translate('app.cookbookDetails.all') }
                  </a>
                </li>
                {this.tabViews()}
              </ul>
            </div>
          </div>
          {this.activeTab()}
          <section className="recipes section-top-60 equal">
            {this.renderItems()}
          </section>
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }
}

export default Nav
