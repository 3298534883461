import {
  BASE_URL
} from '../constants/types'

import api
  from '../helpers/api'

import { getLanguage }
  from "../services/token";

export async function getSuggestions(term, updateSuggestions) {
  let url = `${BASE_URL}/search?term=${term}&LangId=${getLanguage}`
  api.get(url)
    .then((data) => {
      updateSuggestions(data)
    })
    .catch(err => {
      console.log("GET SUGGESTIONS ERROR", err)
    })
}

export async function searchRecipes(term, addRecipes) {
  let url = `${BASE_URL}/recipe?term=${term}&LangId=${getLanguage}`
   api.get(url)
    .then((data) => {
      addRecipes(data)
    })
    .catch(err => {
      console.log("SEARCH RECIPES ERROR", err)
    })
}

export async function searchCookbooks(term, addCookbooks) {
  let url = `${BASE_URL}/book?term=${term}&LangId=${getLanguage}`

  api.get(url)
    .then((data) => {
      addCookbooks(data)
    })
    .catch(err => {
      console.log("SEARCH COOKBOOKS ERROR", err)
    })
}

export async function searchIngredients(term, addIngredient) {
  let url = `${BASE_URL}/ingredient?term=${term}&LangId=${getLanguage}`

  api.get(url)
    .then((data) => {
      addIngredient(data)
    })
    .catch(err => {
      console.log("SEARCH INGREDIENTS ERROR", err)
    })
}

export function fetchAuthors(term, setAuthors) {
  let url = `${BASE_URL}/author?Term=${term}&LangId=${getLanguage}`

  api.get(url)
    .then((response) => {
      setAuthors(response)
    })
    .catch((err) => {
      console.log("FETCH AUTHORS ERROR", err)
    })
}

export async function searchAuthors(term, addAuthor) {
  let url = `${BASE_URL}/author?Term=${term}&LangId=${getLanguage}`

  api.get(url)
    .then((data) => {
      addAuthor(data)
    })
    .catch(err => {
      console.log("SEARCH AUTHORS ERROR", err)
    })
}
