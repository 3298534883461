import React
  from 'react'
import { Link }
  from "react-router-dom"
import PureImage
  from '../../components/shared/PureImage';
import SvgImage
  from '../../components/shared/SvgImage';
import VideoPreview
  from '../../components/shared/recipes/VideoPreview';
import ImageWithIngredients
  from '../../components/shared/recipes/ImageWithIngredients'
import ToolsImageProduct
  from '../../components/shared/recipes/ToolsImageProduct'
import PlayVideo
  from '../../components/shared/recipes/PlayVideo'
import { ToolsIcon, Video20SecIcon, PauseIcon, NoteIcon, PlaybackForwardIcon, IngredientsNames }
  from '../../assets/icons/AppIcons'
import { getToken }
  from '../../services/token';
import { storeCurrentPath }
  from '../../services/currentPath';
import { favoriteRecipe, unFavoriteRecipe }
  from '../../actions/favourites';
import { connect }
  from 'react-redux';
import StarRatings
  from 'react-star-ratings';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel }
  from 'react-responsive-carousel';
import { motion }
  from "framer-motion"
import { isMobile, isTablet }
  from 'react-device-detect';
import ReactGA
  from 'react-ga';
import { isSafari }
  from 'react-device-detect';
import { FavShapeIcon }
  from '../../assets/icons';

class RecipeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      image:'',
      IsFavorite: false,
      favouriteLoading: false,
      selectedItem: 0,
      currentVideo: props.videos.Short,
      videoStatus: "short",
      hovered: false,
      displayIngredientsNames: 0
    };
  }

  renderAuthimg(){
    if(this.props.source != undefined){
      return <PureImage src={this.props.source} className="img-responsive" />
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.currentHoverRecipe != this.state.currentHoverRecipe) {
      if(nextProps.currentHoverRecipe != this.props.item.Id) {
        this.setState({selectedItem: 0})
      }
    }
  }

  mouseLeave() {
    let _this = this
    if(isSafari) {
      setTimeout( function() {
        _this.setState({playing: false, hovered: true})
      }, 1000);
    } else {
      this.setState({playing: false, hovered: true})
    }
  }

  displayRecipiesSection() {
    if(isMobile || isTablet) {
      return (
        <Carousel
          ref={(c) => { this._carousel = c; }}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          swipeable={true}
          selectedItem={this.state.selectedItem}
          >
          <div
            className="video-cover"
            onMouseEnter={() => this.setState({playing: true, hovered: true})}
            onMouseLeave={() => this.mouseLeave()}>
            <VideoPreview
              currentVideo={this.state.currentVideo}
              playing={this.state.playing}
              stopPlaying={() => this.setState({playing: false, currentVideo: this.props.videos.Short, videoStatus: "short"})}
              hovered={this.state.hovered}
              source={this.props.item.CoverPath}
            />
          </div>
          <ImageWithIngredients
            ingredientImagePath={this.props.item.IngredientImagePath}
            ingredients={this.props.item.Ingredients}
            displayIngredientsNames={this.state.displayIngredientsNames}
          />
          <ToolsImageProduct
            toolImagePath={this.props.item.ToolImagePath}
          />
          <PlayVideo
            recipe={this.props.item}
            CoverPath={this.props.item.CoverPath}
            setRecipe={(value) => this.props.setRecipe(value)}
          />
        </Carousel>
      )
    } else {
      return (
        <Link className="recipes-content" to={`/recipes/${this.props.id}`}>
          <Carousel
            ref={(c) => { this._carousel = c; }}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            swipeable={true}
            selectedItem={this.state.selectedItem}
            >
            <div
              className="video-cover"
              onMouseEnter={() => this.setState({playing: true, hovered: true})}
              onMouseLeave={() => this.mouseLeave()}>
              <VideoPreview
                currentVideo={this.state.currentVideo}
                playing={this.state.playing}
                stopPlaying={() => this.setState({playing: false, currentVideo: this.props.videos.Short, videoStatus: "short"})}
                hovered={this.state.hovered}
                source={this.props.item.CoverPath}
              />
            </div>
            <ImageWithIngredients
              ingredientImagePath={this.props.item.IngredientImagePath}
              ingredients={this.props.item.Ingredients}
              displayIngredientsNames={this.state.displayIngredientsNames}
            />
            <ToolsImageProduct
              toolImagePath={this.props.item.ToolImagePath}
            />
            <PlayVideo
              recipe={this.props.item}
              CoverPath={this.props.item.CoverPath}
              setRecipe={(value) => this.props.setRecipe(value)}
            />
          </Carousel>
        </Link>
      )
    }
  }

  addFavorite() {
    let { item } = this.props

    this.setState({favouriteLoading: true, IsFavorite: true})
    this.props.favoriteRecipe(item, this.updateRecipe.bind(this))
  }

  removeFavorite() {
    let { item } = this.props
    this.setState({favouriteLoading: true, IsFavorite: false})
    this.props.unFavoriteRecipe(item, this.updateRecipe.bind(this))

  }

  UNSAFE_componentWillMount(){
    this.updateRecipe(this.props.item.IsFavorized && this.props.item.IsFavorized)
  }

  updateRecipe(IsFavorized){
    this.setState({
      IsFavorite: IsFavorized,
      favouriteLoading: false
    })
  }

  suggestAndSaveCurrentPath() {

  }

  displayFavouriteIcon() {
    let { favouriteLoading } = this.state
    let { IsFavorite } = this.state
    if(favouriteLoading) {

    } else {
      if(IsFavorite) {
        return (
          // <motion.div
          //   className="recipes-asset fav-recipes-on"
          //   onClick={() => this.removeFavorite()}
          //   initial={{ scale: 0 }}
          //   animate={{ rotate: 360, scale: 1 }}
          //   transition={{
          //     type: "spring",
          //     stiffness: 260,
          //     damping: 15
          //   }}
          // />


          <motion.div
            className={"toggle-fav-book fav-book-on"}
            onClick={getToken ? () => this.removeFavorite() : () => storeCurrentPath()}
            initial={{ scale: 0 }}
            animate={{ rotate: 360, scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 15
            }}
          />
        )
      } else {
        return (
          // <motion.div
          //   className="recipes-asset fav-recipes-off"
          //   onClick={() => this.addFavorite()}
          //   initial={{ scale: 0 }}
          //   animate={{ rotate: 360, scale: 1 }}
          //   whileHover={{ scale: 1.5, rotate: 1 }}
          //   transition={{
          //     type: "spring",
          //     stiffness: 260,
          //     damping: 15
          //   }}
          // />


            <motion.div
              className={"toggle-fav-book fav-book-off"}
              onClick={getToken ? () => this.addFavorite() : () => storeCurrentPath()}
              initial={{ scale: 0 }}
              animate={{ rotate: 360, scale: 1 }}
              whileHover={{ scale: 1.5, rotate: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 15
              }}
            />
        )
      }
    }
  }

  changeSliderIngredientSection(e, displayHover) {
    e.preventDefault()
    this.setState({selectedItem: 1, displayIngredientsNames: displayHover, playing: false})
  }

  changeSliderToolSection(e) {
    e.preventDefault()
    this.setState({selectedItem: 2, displayIngredientsNames: 0, playing: false})
  }

  displayPlayVideoIcon() {
    if(this.state.playing && this.state.videoStatus === "preview") {
      return (
        <a href="#/">
          <SvgImage
            svg={PauseIcon}
          />
        </a>
      )
    } else {
      return (
        <a href="#/" >
          <SvgImage
            svg={PlaybackForwardIcon}
          />
        </a>
      )
    }
  }

  displayTags() {
    let { item } = this.props
    if(item.RecipeTags.length > 0) {
      return (
        <div className="recipes-tags">
            <ul>
            { item.RecipeTags.slice(0,3).map((tag, index) => {
              return (
                <li key={index}>
                  <Link to={`/tags?Term=${tag.Name}`}>
                    {tag.Name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  formatTime(time){
    var a = time.split(':');

    var d = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + " hour " : "";
    var mDisplay = m > 0 ? m + " min " : "";
    var sDisplay = s > 0 ? s + " sec" : "";

    return hDisplay + mDisplay + sDisplay;
  }

  cutString(s, n){
    if(s) {
      var cut= s.indexOf(' ', n);
      if(cut === -1) return s;
      return s.substring(0, cut) + "..."
    } else {
      return (
        "Not description"
      )
    }
  }

  changeSlider(e, number, onlyChangePosition = false) {
    e.preventDefault()
    ReactGA.event({
      category: 'Recipe change slider',
      action: `Current slider: ${number}`
    });
    if(number === 0) {
      if(this.state.selectedItem === 0) {
        this.setState({currentVideo: this.props.videos.Preview, playing: true, videoStatus: "preview"})
      } else {
        this.setState({selectedItem: 0, currentVideo: this.props.videos.Preview, playing: true, videoStatus: "preview"})
      }
    } else {
      this.setState({selectedItem: number})
    }
  }

  clickVideoIcon(e) {
    e.preventDefault()
    this.setState({currentVideo: this.props.videos.Preview, playing: !this.state.playing, videoStatus: "preview"})
  }

  render(){
    let { item, changeCurrentHoverRecipe, currentHoverRecipe } = this.props

    return(
      <div className="col-sm-6 col-md-4 recipe-item">
        <div className="recipes-item">
            <div
              className="recipes-item-img"
              onMouseOver={(e, itemId) => changeCurrentHoverRecipe(e, item.Id)}>
              {this.displayRecipiesSection()}
              <div className="recipes-item-content-details">
                <div className="recipes-item-content-details-inner">
                  <ul>
                    <li onMouseEnter={(e, number) => this.changeSlider(e, 0)}
                        onMouseLeave={() => this.setState({currentVideo: this.props.videos.Preview, playing: false, videoStatus: "preview"})}
                        className="active">
                       <div
                         onClick={(e, number) => this.clickVideoIcon(e)}>
                         {this.displayPlayVideoIcon()}
                       </div>
                    </li>
                    <li>
                      <div onMouseOver={(e, number) => this.changeSliderIngredientSection(e, 0)} >
                        <a href="#/">
                          <SvgImage
                            svg={NoteIcon}
                          />
                        </a>
                      </div>
                    </li>
                    {/* extra icon until design is ready */}
                    <li>
                      <div onMouseOver={(e, number) => this.changeSliderIngredientSection(e, 100)} >
                        <a href="#/">
                          <SvgImage
                            svg={IngredientsNames}
                          />
                        </a>
                      </div>
                    </li>
                      {/* extra icon until design is ready */}
                    <li>
                      <div
                        data-toggle="modal" data-target="#brand"
                        onMouseOver={(e, number) => this.changeSliderToolSection(e)}>
                        <a href="#/">
                          <SvgImage
                            svg={ToolsIcon}
                          />
                        </a>
                      </div>
                    </li>
                    <li className="video-tooltip" >
                      <div
                        data-toggle="modal" data-target="#video-plp"
                        onClick={() => this.props.setRecipe(item)}
                        onMouseOver={(e, number) => this.changeSlider(e, 3)}>
                      <div className="video-tooltip-inner">By clicking this, you will start the step by step cooking experience</div>
                        <a href="#/">
                          <SvgImage
                            svg={Video20SecIcon}
                          />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="recipes-assets">
              <FavShapeIcon />
              {this.displayFavouriteIcon()}
            </div>
            <Link className="recipes-content" to={`/recipes/${this.props.id}`}>
              <div className="recipes-content">
                <h1>{this.cutString(this.props.heading, 35)}</h1>
                <div className="recipes-content-ft">
                  <div className="recipes-content-ft-inner">
                    <span className="recipes-time">{this.formatTime(this.props.time)}</span>
                    <span className="recipes-author">{this.cutString(this.props.paragraph, 20)}</span>
                  </div>
                  <div className="content-rate">
                    <StarRatings
                      rating={item.Rating.TotalRatings}
                      starRatedColor="#F5BD00"
                      starDimension="16px"
                      starSpacing="0px"
                      numberOfStars={5}
                      name='rating'
                    />
                  </div>
                </div>
              </div>
            </Link>
            {this.displayTags()}
        </div>
      </div>
    )
  }
}

export default connect(null,
  { favoriteRecipe, unFavoriteRecipe })(RecipeCard);
