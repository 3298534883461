import { combineReducers }
  from 'redux';

import currentUserReducer
  from './currentUser'

import loadingReducer
  from './loading'

import cookbooks
  from './cookbooksReducer'

import sliderCookboksReducer
  from './sliderCookboksReducer'

import latestCookbooks
  from './latestCookbooksReducer';

import recipes
  from './recipes'

import recipeReducer
  from './favourites/recipeReducer';

import cookbookReducer
  from './favourites/cookbookReducer';

import shopping
  from './shoppingReducer';

import tools
  from './toolsReducer';

import { localeReducer }
  from 'react-localize-redux';

import tagsReducer
  from './tagsReducer';

import refreshCurrentPage
  from './refreshCurrentPageReducer';

import numberOfPeople
  from './numberOfPeopleReducer';

const appReducer = combineReducers({
  current_user: currentUserReducer,
  loading: loadingReducer,
  cookbooks,
  latestCookbooks,
  recipes,
  favouriteRecipes: recipeReducer,
  favouriteCookbooks: cookbookReducer,
  shopping,
  tools,
  recipeTags: tagsReducer,
  locale: localeReducer,
  sliderCookbooks: sliderCookboksReducer,
  refreshCurrentPage,
  numberOfPeople
});

export default appReducer;
