import React
  from 'react'
import PureImage
  from '../../../components/shared/PureImage'

class AuthorDetailsBck extends React.Component {
  renderProfileImage(){
    if(this.props.image != undefined){
      return <PureImage src={this.props.image} alt="bg-explorer" />
    }
  }

  render() {
    return(
      <div className="author-profile-banner">
        {this.renderProfileImage()}
      </div>
    )
  }
}

export default AuthorDetailsBck
