import React from 'react';

const Soup = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 140 140" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="soup-2-copy">
                <g id="food" mask="url(#mask-2)" fill={props.color} fillRule="nonzero">
                    <g transform="translate(18.000000, 21.000000)">
                        <path d="M22.0462516,89.6467712 L22.0462516,94.0131117 C22.0179927,96.1764729 23.706839,97.9573006 25.826903,98 L77.1730941,98 C79.2931582,97.9573006 80.9817888,96.176693 80.9537456,94.0133318 L80.9537456,89.6469913 C94.7697552,79.8206344 103.0028,63.7216523 103.000001,46.5380183 L103.000001,43.320599 C103.000001,42.5911881 102.420581,42 101.705695,42 L1.29430183,42 C0.579416577,42 7.15802775e-07,42.5911881 7.15802775e-07,43.320599 L7.15802775e-07,46.5380183 C-0.00280288556,63.7214322 8.23024195,79.8204143 22.0462516,89.6467712 Z M77.135128,95.358802 L25.8648692,95.358802 C25.171987,95.338993 24.6238508,94.7541878 24.6351081,94.047007 C24.6351081,94.0390834 24.6351081,94.0313799 24.6351081,94.0234564 L24.6351081,90.0370082 L78.3653204,90.0370082 L78.3653204,94.0234564 L78.3653204,94.047007 C78.3765778,94.7541878 77.8282258,95.3392131 77.135128,95.358802 Z M2.58860223,44.641198 L100.411395,44.641198 L100.411395,46.5380183 C100.410101,49.2868451 100.186402,52.0310498 99.742673,54.7424596 L28.3404344,54.7424596 C27.6255491,54.7424596 27.046134,55.3336478 27.046134,56.0630586 C27.046134,56.7924694 27.6255491,57.3836576 28.3404344,57.3836576 L99.2426417,57.3836576 C96.588463,69.5397713 89.5761591,80.2445466 79.5828657,87.3958102 L23.4171314,87.3958102 C13.423838,80.2445466 6.41174989,69.5397713 3.75757121,57.3836576 L30.223534,57.3836576 C30.9384192,57.3836576 30.9003272,56.7924694 30.9003272,56.0630586 C30.9003272,55.3336478 29.0553196,54.7424596 28.3404344,54.7424596 L3.2573241,54.7424596 C2.81359478,52.0310498 2.58989653,49.2868451 2.58860223,46.5380183 L2.58860223,44.641198 Z" id="Shape"></path>
                        <path d="M39,10.6245802 C39,8.59271809 38.1496557,7.51936395 37.4663285,6.65697726 C36.8902223,5.93003418 36.4743288,5.40469609 36.4743288,4.24832813 C36.4743288,3.55883489 35.9204916,3 35.2371644,3 C34.5538373,3 34,3.55883489 34,4.24832813 C34,6.27998217 34.8503443,7.35333631 35.5334653,8.21593104 C36.1095715,8.94287413 36.5256712,9.46800416 36.5256712,10.6245802 C36.5256712,11.7811562 36.1093653,12.3062862 35.5334653,13.0332293 C34.8503443,13.895616 34,14.9681379 34,17.0008322 C34,19.0335265 34.8503443,20.1054243 35.5336715,20.967811 C36.1095715,21.694754 36.5256712,22.2198841 36.5256712,23.376044 C36.5256712,24.5322039 36.1095715,25.057542 35.5336715,25.784277 C34.8503443,26.6466637 34,27.7202259 34,29.7516719 C34,30.4411651 34.5538373,31 35.2371644,31 C35.9204916,31 36.4743288,30.4411651 36.4743288,29.7516719 C36.4743288,28.595512 36.8902223,28.0701739 37.4663285,27.3434388 C38.1496557,26.4808441 39,25.40749 39,23.376044 C39,21.344598 38.1496557,20.2712439 37.4661223,19.4088572 C36.8902223,18.6821222 36.4743288,18.1569921 36.4743288,17.0008322 C36.4743288,15.8446723 36.8902223,15.3191262 37.4663285,14.5921831 C38.1496557,13.7295883 39,12.6562342 39,10.6245802 Z" id="Path"></path>
                        <path d="M69,8.65816131 C69,6.39325412 68.1676289,5.1831572 67.4989691,4.21091825 C66.9041237,3.34600011 66.4742268,2.7215682 66.4742268,1.3141324 C66.4742268,0.588293271 65.9204124,0 65.2371134,0 C64.5538144,0 64,0.588293271 64,1.3141324 C64,3.57903959 64.8325773,4.78913651 65.5012371,5.76137546 C66.0958763,6.6262936 66.5257732,7.25072551 66.5257732,8.65816131 C66.5257732,10.0655971 66.0958763,10.690248 65.5012371,11.5549472 C64.8325773,12.5271861 64,13.737283 64,16.0010951 C64,18.2649072 64.8325773,19.4758802 65.5012371,20.4472431 C66.0960825,21.3117232 66.5257732,21.9365931 66.5257732,23.3433718 C66.5257732,24.7501506 66.0960825,25.3750205 65.5012371,26.2397197 C64.8325773,27.2119586 64,28.4218365 64,30.6858676 C64,31.4117067 64.5538144,32 65.2371134,32 C65.9204124,32 66.4742268,31.4117067 66.4742268,30.6858676 C66.4742268,29.2790889 66.9041237,28.6542189 67.4989691,27.7895198 C68.1676289,26.8172808 69,25.6074029 69,23.3433718 C69,21.0793407 68.1676289,19.8688058 67.4989691,18.8972239 C66.9039175,18.0325248 66.4742268,17.4078738 66.4742268,16.0008761 C66.4742268,14.5938783 66.9041237,13.9690084 67.4989691,13.1040902 C68.1676289,12.1338225 69,10.9232875 69,8.65816131 Z" id="Path"></path>
                        <path d="M54,8.65816131 C54,6.39325412 53.1676289,5.1831572 52.4987629,4.21091825 C51.9041237,3.34600011 51.4742268,2.7215682 51.4742268,1.3141324 C51.4742268,0.588293271 50.9204124,0 50.2371134,0 C49.5538144,0 49,0.588293271 49,1.3141324 C49,3.57903959 49.8323711,4.78913651 50.5010309,5.76137546 C51.0958763,6.6262936 51.5257732,7.25072551 51.5257732,8.65816131 C51.5257732,10.0655971 51.0958763,10.690248 50.5010309,11.5549472 C49.8323711,12.5271861 49,13.737283 49,16.0010951 C49,18.2649072 49.8323711,19.4758802 50.5010309,20.4472431 C51.0960825,21.3117232 51.5257732,21.9365931 51.5257732,23.3433718 C51.5257732,24.7501506 51.0960825,25.3750205 50.5010309,26.2397197 C49.8323711,27.2119586 49,28.4218365 49,30.6858676 C49,31.4117067 49.5538144,32 50.2371134,32 C50.9204124,32 51.4742268,31.4117067 51.4742268,30.6858676 C51.4742268,29.2790889 51.9041237,28.6542189 52.4987629,27.7895198 C53.1676289,26.8172808 54,25.6074029 54,23.3433718 C54,21.0793407 53.1676289,19.8688058 52.4987629,18.8972239 C51.9039175,18.0325248 51.4742268,17.4078738 51.4742268,16.0008761 C51.4742268,14.5938783 51.9041237,13.9690084 52.4987629,13.1040902 C53.1676289,12.1338225 54,10.9232875 54,8.65816131 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default Soup
