import React
  from "react";
import { Authors }
  from "./index.js"
import { getIngredients }
  from '../../../helpers/filters';
import { connect }
 from 'react-redux';
import { getTranslate }
 from 'react-localize-redux';
import  CookbookFilter
  from '../../../components/cookbooks/index/CookbookFilter'
// import  SortByFilter
//   from '../../../components/cookbooks/index/SortByFilter'

class CookbooksFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRangeValue: 80,
      caloriesRangeValue: 200,
      ingredients: [],
      includeIngredients: [],
      excludeIngredients: [],
      selectedAuthor: props.selectedAuthor ? props.selectedAuthor : null,
      searchText: props.searchTerm,
      selectedSort: props.selectedSort,
      selectedBy: props.selectedBy
    };
  }

  componentDidMount() {
    window.initialSelects()
    getIngredients(this.addIngredients.bind(this))
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.authorId !== newProps.authorId) {
    }

    if(this.props.searchTerm !== newProps.searchTerm) {
      this.setState({searchText: newProps.searchTerm})
    }

    if(this.props.selectedSort !== newProps.selectedSort) {
      this.setState({selectedSort: newProps.selectedSort})
    }

    if(this.props.selectedBy !== newProps.selectedBy) {
      this.setState({selectedBy: newProps.selectedBy})
    }
  }

  addIngredients(data) {
    this.setState({ingredients: data})
  }

  requestApplyFilters() {
    this.props.applyFilters(this.state)
  }

  clearFilters() {
    this.props.clearFilters()
  }

  searchData(e) {
    let _this = this
    let value = e.target.value
    this.setState({searchText: value})
    this.props.addSearchState(value)
    setTimeout(function() {
      _this.requestApplyFilters()
    }, 150);
  }

  changeAuthor(item) {
    let _this = this
    this.setState({selectedAuthor: item})
    this.props.addAuthorState(item ? item.Id : null)
    setTimeout(function() {
      _this.props.applyFilters(_this.state)
    }, 10);
  }

  changeSortDate(item){
    let _this = this

    this.props.addDateSort(item)
    setTimeout(function() {
      _this.props.applyFilters(_this.state)
    }, 10);
  }

  changeSortBy(item){
    let _this = this

    this.props.addSortBy(item)
    setTimeout(function() {
      _this.props.applyFilters(_this.state)
    }, 10);
  }

  render() {
    let { translate } = this.props

    return (
      <section className="filters">
        <div className="container">
          <div className="row">
            {/* <div className="col-xs-12 col-sm-12 col-md-3">
              <div className="filters-title">
                <h1>{ translate('app.filtersInputs.filters') }</h1>
                <a href={undefined}
                  className="filter-on"
                  style={{display: this.props.listingFilters ? '' : 'none'}}
                  onClick={this.clearFilters.bind(this)}
                >
                  { translate('app.filtersInputs.clear_all') }
                  <CloseIcon />
                </a>
              </div>
            </div> */}
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="dropdown-wrapp">
                <h1 className="filter-inner-title">{ translate('app.filtersInputs.search') }</h1>
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    type="button"
                  >
                    <input
                      type="text"
                      id="ingredients-cook"
                      name="ingredients-cook"
                      className="inp-add-ingr"
                      placeholder={ translate('app.filtersInputs.search_cookbooks') }
                      onChange={(e) => this.searchData(e)}
                      value={this.state.searchText}
                      style={{border: "none", width: "100%", height: "100%", outline: "none"}}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="16" height="16"
                      viewBox="0 0 20 20">
                        <path fill="#7a7a7a"
                              fillRule="nonzero"
                              d="M14.562 13.917l4.462 4.303a.457.457 0 1 1-.646.646l-4.461-4.304a7.761 7.761 0 1 1 .645-.645zM1.912 8.76a6.848 6.848 0 1 0 13.696 0 6.848 6.848 0 0 0-13.696 0z">
                        </path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <Authors
                selectedAuthor={this.state.selectedAuthor}
                changeAuthor={(selectedAuthor) => this.changeAuthor(selectedAuthor)}
                translate={translate}
                preDefineAuthorId={this.props.preDefineAuthorId}
              />
            </div>

            <CookbookFilter
              label={translate('app.filtersInputs.sort_by')}
              placeholder={translate('app.filtersInputs.sort_by')}
              items={[{label: "Name", value: "name"}, {label: "Date published", value: "isPublished"}, {label: "Rating", value: "rate"}]}
              selected={this.state.selectedBy ? this.state.selectedBy : null }
              changeDateSort={(selectedSortBy) => this.changeSortBy(selectedSortBy)}
            />

            {
              /*
              <SortByFilter
                label={translate('app.filtersInputs.sort_by_date')}
                placeholder={translate('app.filtersInputs.sort_by_date')}
                items={["Ascending", "Descending"]}
                selected={this.state.selectedSort ? this.state.selectedSort : null }
                changeDateSort={(selectedSort) => this.changeSortDate(selectedSort)}
              />
              */
            }

            {
              /*
                <div className="col-xs-12 col-sm-6 col-md-3 flex-bottom">
                  <div className="filters-button filters-button-cookbook">
                    <button
                      type="button"
                      className="button"
                      onClick={() => this.requestApplyFilters()} //apply filter
                    >
                      { translate('app.filtersInputs.apply') }
                    </button>
                  </div>
                </div>
              */
            }
            {
              /*
                <CookbookFilter
                label={"Sort by rate"}
                placeholder={"Most Rated"}
                items={[]}/>
              */
            }
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(CookbooksFilter)
