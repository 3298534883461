
import React
  from 'react'
import noRecipe
  from '../../assets/img/recipe-fav.svg';
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';

class NoFavorite extends React.Component{
  render(){
    let { translate } = this.props
    return(
      <div className="col-xs-12 col-sm-12 col-md-12">
        <div className="favorites-empty">
          <div className="favorites-empty-icon">
            <img src={noRecipe} alt="recipes-fav" />
          </div>
          <h1>{this.props.recipe? translate('app.favourite.no_recipe_favorite')  : translate('app.favourite.no_cookbook_favorite')}</h1>
          <p>{this.props.recipe? translate('app.favourite.add_your_favourite_recipe') : translate('app.favourite.add_your_favourite_cookbook')}</p>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(NoFavorite)
