import React
    from 'react'

const SearchIcon = (props) => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
    >
      <path
        fill="#7a7a7a"
        fillRule="nonzero"
        d="M14.562 13.917l4.462 4.303a.457.457 0 1 1-.646.646l-4.461-4.304a7.761 7.761 0 1 1 .645-.645zM1.912 8.76a6.848 6.848 0 1 0 13.696 0 6.848 6.848 0 0 0-13.696 0z"
      />
    </svg>
  )
}

export default SearchIcon
