import React from 'react'

const ResetPassword = (props) => {
  return(
    <>
    <div className="login-title">
      <h1>Change password?</h1>
      <p>Enter a new password you will remember</p>
    </div>
    <div className="login-form">
      <form className="">
        <div className="input-wrap">
          <input id="new-password"
            type="password"
            className="form-control"
            name="password"
            placeholder="New password" />
          <span toggle="#new-password"
                className="pass-eye-off toggle-password"></span>
        </div>
        <div className="input-wrap">
          <input id="confirm-password"
            type="password"
            className="form-control"
            name="password"
            placeholder="Confirm Password" />
          <span toggle="#confirm-password"
                className="pass-eye-off toggle-password"></span>
        </div>
        <div className="submit-button">
          <input
            type="submit"
            className="send"
            value="Reset password"/>
        </div>
      </form>
    </div>
    </>
  )
}

export default ResetPassword
