import React
  from "react";
import { Link }
  from "react-router-dom"
import { getToken }
  from "../../../services/token"
import StarRatings
  from 'react-star-ratings';
import ShareWithMedia
  from "../../../components/shared/modals/ShareWithMedia";
import { motion }
  from "framer-motion"
import PureImage
  from '../../../components/shared/PureImage'
import { setCurrentPath }
  from '../../../helpers/currentPath';

class BigHeading extends React.Component {
  renderAuthimg(){
    if(this.props.source != undefined){
      return <PureImage src={this.props.source} className="img-responsive" />
    }
  }

  componentDidMount(){
    window.initialModals()
    this.renderAuthimg()
  }

  displayFavoriteIcon() {
    if(!this.props.favouriteLike) {
      return (
        <a href={undefined}  onClick={getToken ? this.props.likePress : () => setCurrentPath()} style={this.props.liked === true ? {borderColor:'#ff5f01'}: {borderColor:'#e4e4e4'}}>
          <motion.svg
            initial={{ scale: 0 }}
            animate={{ rotate: 360, scale: 1 }}
            whileHover={{ scale: 1.5, rotate: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 15
            }}
            className="cookbook-like"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="19"
            viewBox="0 0 18 16"
          >
            { this.props.liked ?
              <svg className="cookbook-like" width="19px" height="15px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="favorites-cookbooks" transform="translate(-368.000000, -376.000000)" fill="#ff5f00" fillRule="nonzero"><g id="cookbooks" transform="translate(135.000000, 360.000000)"><g id="1"><g id="favorite-button" transform="translate(224.000000, 0.000000)"><path d="M22.069378,16 C24.7924853,16 27,18.2717696 27,21.0741395 C27,23.5231858 25.7969789,25.8063999 23.6091074,28.0839356 C22.5268118,29.2105863 21.0254776,30.4042451 19.1038416,31.6685345 C18.4310415,32.111185 17.5694435,32.1104324 16.8973748,31.6666072 C14.9630695,30.3892177 13.4615027,29.1963805 12.3911564,28.0842099 C10.2188103,25.8269785 9,23.517888 9,21.0741395 C9,18.2717696 11.2075147,16 13.930622,16 C15.5871187,16 17.0954923,16.8484153 18,18.2076459 C18.9045077,16.8484153 20.4128813,16 22.069378,16 Z" id="Like-1" transform="translate(18.000000, 24.000000) scale(-1, 1) translate(-18.000000, -24.000000) "></path></g></g></g></g></g></svg>
            :
              <path
                fill={"#7a7a7a"}
                fillRule="nonzero"
                d="M4.971.889c1.576 0 2.988.932 3.665 2.37a.4.4 0 0 0 .728 0c.677-1.438 2.09-2.37 3.665-2.37 2.248 0 4.071 1.882 4.071 4.204 0 2.168-1.108 4.275-3.123 6.376-1.012 1.056-2.454 2.205-4.323 3.443-.4.265-.91.265-1.31.002-1.856-1.226-3.297-2.375-4.321-3.445C1.993 9.35.9 7.267.9 5.093.9 2.771 2.723.89 4.971.89zM4.931 0C2.208 0 0 2.272 0 5.074c0 2.45 1.203 4.732 3.39 7.01 1.083 1.127 2.585 2.32 4.506 3.585a1.997 1.997 0 0 0 2.207-.002c1.934-1.278 3.435-2.47 4.506-3.583C16.78 9.827 18 7.518 18 5.074 18 2.272 15.792 0 13.07 0 11.412 0 9.904.848 9 2.208 8.095.848 6.587 0 4.93 0z"
                ></path>
            }
          </motion.svg>
        </a>
      )
    } else {

    }
  }

  render() {
    let { translate, cookbook } = this.props
    return (
      <div className="cookbook-profile-info-inner-st">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <div className="cookbook-profile-info-inner">
            <h1>{this.props.bigHeader}</h1>

          <div className="cookbooks-profile-stats">
            <span className="stats" data-tooltip="Average rating of each recipe in this cookbook">
              <StarRatings
                rating={cookbook.Rating.GlobalRating ? cookbook.Rating.GlobalRating : 0}
                starRatedColor="#F5BD00"
                starDimension="24px"
                starSpacing="0px"
                numberOfStars={5}
                name='rating'
              />
            </span>
            <span className="stats">
              <span className="number">{this.props.views}</span> { translate('app.cookbookDetails.views') }
            </span>
            <div className="cookbook-profile-share">
              <ul>
                <li>
                  {this.displayFavoriteIcon()}
                </li>
                <li>
                  <a href={undefined}  data-toggle="modal" data-target="#share-with-media">
                    <motion.svg
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1 }}
                      whileHover={{ scale: 1.5, rotate: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 15
                      }} width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="details-for-recipes" transform="translate(-1261.000000, -711.000000)" fill="#7A7A7A" fillRule="nonzero">
                          <g id="info" transform="translate(735.000000, 690.000000)">
                            <g id="share" transform="translate(526.000000, 21.000000)">
                              <path
                                d="M14.9850016,11.7303767 C15.3160366,11.7303767 15.5841934,11.9947094 15.5834804,12.3203208 L15.5782319,14.7171976 C15.5778729,14.8811399 15.6470345,15.0378033 15.7690657,15.1494702 C16.0110674,15.3709182 16.3897574,15.35747 16.6148935,15.1194327 L22.5625933,8.83090634 C22.8835631,8.49154372 22.8831375,7.96583539 22.5616186,7.62697601 L16.6194951,1.36437554 C16.5063063,1.24508216 16.3478553,1.17735241 16.1819621,1.17735241 C15.8514305,1.17735241 15.5834818,1.44091172 15.5834818,1.76602861 L15.5834818,4.08782421 C15.5834818,4.4129411 15.3155331,4.67650041 14.9850016,4.67650041 C14.9163084,4.67661262 14.9163084,4.67661262 14.8476176,4.67717366 C7.42553787,4.74991944 1.43167333,10.577876 1.2036895,17.8085506 C4.69951409,13.9485357 9.71149045,11.7303767 14.9850016,11.7303767 Z M14.3865213,3.51077998 L14.3865213,1.76602861 C14.3865213,0.790677942 15.1903675,0 16.1819621,0 C16.6796418,0 17.1549948,0.203189241 17.494561,0.561069385 L23.4366846,6.82366986 C24.1868953,7.61434173 24.1878884,8.84099451 23.4389589,9.63284063 L17.4912591,15.9213669 C16.8158508,16.6354789 15.6797807,16.6758236 14.9537757,16.0114795 C14.587682,15.6764788 14.3801972,15.2064888 14.3812742,14.7146617 L14.3852085,12.9179651 C9.49844724,13.0848446 4.90120214,15.2849958 1.75035184,18.9870112 L1.06854711,19.7880812 C0.711558736,20.207516 0.0167407103,19.9630507 0.0111994782,19.416063 L0.000759618441,18.3855208 C-0.0802048941,10.3933297 6.32112849,3.82351327 14.3865213,3.51077998 Z"
                                id="Forward-2"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </motion.svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="cookbook-profile-content">
            <div className="cookbook-author-info">
              <div className="cookbook-author-info-wrap">
                  <div className="cookbook-author-img">
                    {this.renderAuthimg()}
                  </div>
                  <div className="cookbook-author-content">
                    <h1>{ translate('app.cookbookDetails.by') } {this.props.authorName}</h1>
                    <Link to={{pathname:`/authors/` + this.props.authorId}}>
                      { translate('app.cookbookDetails.view_profile') }
                    </Link>
                  </div>
              </div>
              <div className="cookbook-profile-subscribe" onClick={ getToken ? this.props.subscribe : () => setCurrentPath() }>
                <a href={undefined}  className="button"> {this.props.isSubscribed ? translate('app.cookbookDetails.unsubscribe') : translate('app.cookbookDetails.subscribe')}</a>
              </div>
            </div>
            {/*
            <div className="cookbook-profile-rate">
                <h2>Rate this recipe</h2>
                <div className="content-rate">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 12 12"><g fill="#F5BD00" fillRule="nonzero"><path d="M2.825 10.997a.916.916 0 0 1-1.228-.957 8.956 8.956 0 0 1 .75-2.943 13.086 13.086 0 0 1-2.143-2.18.916.916 0 0 1 .523-1.473c.929-.185 1.874-.28 2.822-.281A8.496 8.496 0 0 1 4.87.377.916.916 0 0 1 6.157.18l-.48 8.522-.06.813a10.14 10.14 0 0 1-2.792 1.482z"/><path d="M2.825 10.997a.916.916 0 0 1-1.228-.957 8.956 8.956 0 0 1 .75-2.943 13.086 13.086 0 0 1-2.143-2.18.916.916 0 0 1 .523-1.473c.929-.185 1.874-.28 2.822-.281A8.496 8.496 0 0 1 4.87.377a.916.916 0 0 1 1.478 0 8.582 8.582 0 0 1 1.325 2.786c.953 0 1.903.094 2.838.281a.916.916 0 0 1 .527 1.465 11.965 11.965 0 0 1-2.165 2.192 9.41 9.41 0 0 1 .743 2.924.916.916 0 0 1-1.23.964 10.393 10.393 0 0 1-2.774-1.478 10.139 10.139 0 0 1-2.788 1.486z" opacity=".4"/></g></svg>
                </div>
            </div>
            */}
          </div>

          <h2>{ translate('app.cookbookDetails.description') }</h2>
          <p>
            {this.props.description}
          </p>
        </div>
      </div>
      <ShareWithMedia
        title={this.props.cookbook.Name}
        cookbook={this.props.cookbook}
        translate={translate}
        match={this.props.match}
      />
    </div>
    );
  }
}

export default BigHeading;
