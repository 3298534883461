// import {  All,
//           Drinks,
//           AlcoholicDrinks,
//           Breakfast,
//           Appetizer,
//           Salad,
//           MainDish,
//           Dessert,
//           Bread,
//           Bake,
//           Cake,
//           Smoothie,
//           Soup,
//           Pasta } from '../assets/img/categories';

import { AlcoholicDrinks, All, Appetizer, Bake, Bread, Breakfast, Cake, Dessert, Drinks, MainDish, Pasta, Salad, Smoothie, Soup }
  from '../assets/icons/categories_svg'

const CATEGORIES = [
  { Name: "All", Value: -1 , image: All },
  { Name: "Drink", Value: 0, image: Drinks },
  { Name: "AlcoholicDrink", Value: 1, image: AlcoholicDrinks },
  { Name: "Breakfast", Value: 2, image: Breakfast },
  { Name: "Appetizer", Value: 3, image: Appetizer },
  { Name: "Salad", Value: 4, image: Salad },
  { Name: "MainDish", Value: 5, image: MainDish },
  { Name: "Dessert", Value: 6, image: Dessert },
  { Name: "Bread", Value: 7, image: Bread },
  { Name: "Bake", Value: 8, image: Bake },
  { Name: "Cake", Value: 9, image: Cake },
  { Name: "Smoothie", Value: 10, image: Smoothie },
  { Name: "Soup", Value: 11, image: Soup },
  { Name: "Pasta", Value: 12, image: Pasta },
];

export default CATEGORIES;
