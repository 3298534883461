import React
    from 'react'

const FavouriteIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 18 16"><path fill="#7a7a7a" fillRule="nonzero" d="M4.971.889c1.576 0 2.988.932 3.665 2.37a.4.4 0 0 0 .728 0c.677-1.438 2.09-2.37 3.665-2.37 2.248 0 4.071 1.882 4.071 4.204 0 2.168-1.108 4.275-3.123 6.376-1.012 1.056-2.454 2.205-4.323 3.443-.4.265-.91.265-1.31.002-1.856-1.226-3.297-2.375-4.321-3.445C1.993 9.35.9 7.267.9 5.093.9 2.771 2.723.89 4.971.89zM4.931 0C2.208 0 0 2.272 0 5.074c0 2.45 1.203 4.732 3.39 7.01 1.083 1.127 2.585 2.32 4.506 3.585a1.997 1.997 0 0 0 2.207-.002c1.934-1.278 3.435-2.47 4.506-3.583C16.78 9.827 18 7.518 18 5.074 18 2.272 15.792 0 13.07 0 11.412 0 9.904.848 9 2.208 8.095.848 6.587 0 4.93 0z"></path>
    </svg>
  )
}

export default FavouriteIcon
