import React from 'react';

const Cake = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
    fill={props.color} stroke="none">
    <path d="M506 1199 c-198 -27 -326 -90 -362 -176 -20 -47 -20 -366 0 -385 10
    -10 15 -49 18 -134 3 -96 7 -125 23 -152 54 -90 207 -141 473 -158 l112 -7 0
    102 c0 55 1 101 3 101 1 0 72 -25 157 -55 85 -30 165 -55 176 -55 30 0 102 75
    114 118 5 21 10 73 10 117 0 68 3 83 20 98 19 16 20 29 20 202 0 138 -4 194
    -14 220 -27 64 -123 114 -291 150 -86 19 -365 27 -459 14z m309 -29 c252 -24
    415 -92 415 -174 0 -22 -49 -108 -68 -120 -6 -3 -113 26 -238 65 -128 40 -230
    67 -235 62 -5 -5 8 -60 30 -126 l40 -117 -43 0 c-105 0 -308 48 -421 99 -127
    58 -155 118 -90 190 57 63 180 105 355 121 126 11 135 11 255 0z m127 -272
    l198 -61 0 -131 c0 -160 -18 -161 -22 -2 l-3 109 -192 65 c-106 35 -193 68
    -193 73 0 13 -3 14 212 -53z m148 -145 c0 -18 -3 -33 -7 -33 -5 0 -76 23 -160
    51 -130 43 -152 53 -157 74 -3 13 -9 30 -12 38 -4 12 40 1 165 -41 l171 -57 0
    -32z m-801 71 c105 -49 231 -78 425 -99 l58 -7 -4 -91 c-5 -122 -10 -129 -62
    -80 -79 75 -117 103 -140 103 -13 0 -51 -18 -85 -40 -34 -22 -68 -40 -76 -40
    -8 0 -22 12 -32 26 -42 61 -86 104 -107 104 -11 0 -36 -9 -54 -20 -18 -11 -35
    -20 -37 -20 -3 0 -5 52 -5 116 l0 116 33 -22 c17 -12 56 -33 86 -46z m801
    -177 c0 -19 -4 -28 -12 -25 -7 3 -71 24 -143 48 -71 23 -130 43 -131 44 -1 0
    -4 15 -7 33 l-6 32 149 -51 c148 -50 150 -51 150 -81z m-2 -95 c2 -18 0 -32
    -5 -31 -4 0 -69 21 -143 47 -133 46 -135 47 -138 79 l-3 32 143 -48 c142 -47
    143 -48 146 -79z m-792 83 c16 -19 42 -51 57 -70 17 -21 38 -35 50 -35 12 0
    53 21 91 45 79 51 63 55 174 -40 l71 -60 0 -127 1 -128 -27 5 c-16 2 -69 7
    -118 10 -145 9 -286 49 -345 97 -44 36 -50 59 -50 179 0 116 0 118 28 138 15
    11 30 21 33 21 4 0 20 -16 35 -35z m792 -178 c2 -15 0 -27 -5 -27 -4 0 -70 21
    -145 46 -133 45 -138 48 -138 75 l0 28 143 -48 c132 -44 142 -49 145 -74z
    m-150 -18 l152 -52 0 -33 c0 -19 -3 -34 -7 -33 -5 0 -75 24 -158 53 -149 52
    -150 52 -153 84 -2 18 0 32 5 32 4 0 77 -23 161 -51z"/>
    </g>
    </svg>
  )
}

export default Cake
