import {
    FETCH_SHOPPING_DEP,
    REMOVE_SHOPPING_DEP,
    ADD_RECIPE_TO_SHOPPING_LIST,
    REMOVE_SHOPPING_RECIPE,
    FETCH_SHOPPING_RECIPES
  } from '../constants/types';

  const DEFAULT_STATE = {
    recipes: [],
    departments: []
  }

  export default function(state=DEFAULT_STATE, action){
    switch(action.type){
      case FETCH_SHOPPING_DEP:
      return { ...state, departments: action.payload }
      case FETCH_SHOPPING_RECIPES:
      return { ...state, recipes: action.payload }
      case REMOVE_SHOPPING_DEP:
      return { ...state, departments: [ ...state.departments.filter(function(v) {
        return v.Id !== action.payload
      })] }
      case ADD_RECIPE_TO_SHOPPING_LIST:
      return { ...state, recipes: [ ...state.recipes, action.payload ]}
      case REMOVE_SHOPPING_RECIPE:
      return { ...state, recipes: [ ...state.recipes.filter(function(v) {
        return v.Id !== action.payload
      })] }
      default:
      return state
    }
  }
