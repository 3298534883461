import React
  from 'react'
import PureImage
  from '../../components/shared/PureImage';
import { checkIngredient, unCheckIngredient }
  from '../../helpers/ingredients';
import { TickIcon }
  from '../../assets/icons'
import UNITS
  from '../../constants/symbols';
import { updateShopppingPersons, shoppingRecipes, shoppingDepartment  }
  from '../../actions/shopping';
import { connect }
  from 'react-redux';

class ShoppingRecipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfPeople: props.persons,
      number: 0,
      selectedIngredients: [],
    };
  }

  componentDidMount() {
    // window.toggleMoreVideo()
  }

  UNSAFE_componentWillMount() {
    let selectedIngredientsPushed = []
    if(this.props.ingredients.length > 0) {
      this.props.ingredients.forEach((ing) => {
        if(ing.State === 1) {
          selectedIngredientsPushed.push(ing)
        }
      })

      this.setState({
        selectedIngredients: selectedIngredientsPushed,
      })
    }
  }

  renderImg(){
    if(this.props.source != undefined){
      return <PureImage src={this.props.source} className="img-responsive" />
    }
  }

  async selectIngradientUpdateList(ingredient) {
    if(this.state.selectedIngredients.map(function(i) { return i.Id; }).indexOf(ingredient.Id) !== -1){
      this.setState({selectedIngredients: this.state.selectedIngredients.filter(function(value) {
          return value.Id !== ingredient.Id
        })
      })
      await unCheckIngredient(ingredient.Id)
    } else {
      this.setState({selectedIngredients: [...this.state.selectedIngredients, ingredient]})
      await checkIngredient(ingredient.Id)
    }
  }

  displayIngredientsList(items) {
    return (
      items.map((item) => {
        let checkSelectIngredient = (this.state.selectedIngredients.map(function(e) { return e.Id; }).indexOf(item.Id) !== -1)
         return (
            <tr
              key={item.Id}
              onClick={() => this.selectIngradientUpdateList(item)}
              style={{opacity: checkSelectIngredient ? 0.4 : 1}}>
              <td>
                <div className="recipes-table-img">
                  <PureImage src={item.ThumbPath} className="img-responsive" />
                </div>
              </td>
              <td>{item.Name}</td>
              <td>
                <span className="mass">{Math.round(item.ImperialAmount * this.state.numOfPeople, 2)} { UNITS[item.MetricUnit] }</span>
              </td>
              { checkSelectIngredient &&
                <td>
                  <TickIcon />
                </td>
              }
            </tr>
          )
        })
      )
  }

  displayIngredientsSection() {
    let { ingredients } = this.props


    let ingredientUnSelect = ingredients.filter(ingredient => !this.state.selectedIngredients.includes(ingredient));
    let ingredientSelect = ingredients.filter(ingredient => this.state.selectedIngredients.includes(ingredient));

    return (
      <div className="recipes-extra-table mg-bottom-15">
        <table className="department-title-style">
          <tbody>
            {this.displayIngredientsList(ingredientUnSelect)}
            {this.displayIngredientsList(ingredientSelect)}
          </tbody>
        </table>
      </div>
    )
  }

  changePeopleState(numType = null) {
    if(numType == "sum") {
      this.setState({numOfPeople: this.state.numOfPeople + 1})
    } else {
      this.setState({numOfPeople: this.state.numOfPeople === 1 ? this.state.numOfPeople : this.state.numOfPeople - 1})
    }

    const self = this;

    if (self.state.changePeopleTimeout) {
       clearTimeout(this.state.changePeopleTimeout);
    }

    self.setState({
       changePeopleTimeout:   setTimeout(function () {
          self.changePeopleToDb()
        }, 1000)
    });
  }

  changePeopleToDb() {
    this.props.updateShopppingPersons(this.props.id, this.state.numOfPeople)
    // this.props.shoppingRecipes()
    // this.props.shoppingDepartment()
  }

  render(){
    return (
      <div className="tab-slider-container">
        <div id="tab1">
          <div className="recipes-md-img">
            {this.renderImg()}
          </div>

          <div className="recipes-md-title">
            <h1>{this.props.name}</h1>
            <div onClick={this.props.deleteClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18"
                viewBox="0 0 14 18">
                <path fill="#7A7A7A" fillRule="nonzero" d="M13.222 5.725v3.262c0 2.228-.07 4.298-.21 6.208-.101 1.392-1.143 2.504-2.464 2.632a36.894 36.894 0 0 1-7.096.001C2.13 17.7 1.09 16.588.988 15.198a84.602 84.602 0 0 1-.21-6.172V5.725a.763.763 0 0 1-.68-.423C.025 5.159 0 5.022 0 4.794V4.09c0-.902.695-1.634 1.554-1.634l5.357.012h.318l5.215-.012c.857-.002 1.554.728 1.556 1.63v.707c0 .228-.025.365-.098.508a.763.763 0 0 1-.68.423zm-11.666.002v3.3c0 2.186.069 4.222.207 6.107.073.994.817 1.788 1.76 1.88a35.854 35.854 0 0 0 6.954-.002c.943-.091 1.687-.885 1.76-1.88.138-1.888.207-3.937.207-6.145v-3.26H1.556zm11.666-.933V4.09c0-.452-.349-.817-.777-.816l-5.214.012h-.322l-5.354-.012c-.43 0-.777.365-.777.816v.703c0 .074 0 .1.003.112a.831.831 0 0 0 .106.003h12.226c.07 0 .095 0 .106-.003a.967.967 0 0 0 .003-.112zM5.444 1.656a.4.4 0 0 1-.388.41.4.4 0 0 1-.39-.41c0-1.015.656-1.51 1.75-1.626a5.527 5.527 0 0 1 1.168 0c1.094.117 1.75.611 1.75 1.626a.4.4 0 0 1-.39.41.4.4 0 0 1-.388-.41c0-.502-.305-.732-1.05-.812a4.788 4.788 0 0 0-1.012 0c-.745.08-1.05.31-1.05.812zm0 12.614a.4.4 0 0 1-.388.41.4.4 0 0 1-.39-.41V6.955a.4.4 0 0 1 .39-.41.4.4 0 0 1 .388.41v7.315zm3.89 0a.4.4 0 0 1-.39.41.4.4 0 0 1-.388-.41V6.955a.4.4 0 0 1 .388-.41.4.4 0 0 1 .39.41v7.315z">
                </path>
              </svg>
            </div>
          </div>

          <div className="recipes-md-ingredients-quantity">
            <h1>Ingredients</h1>
            <div className="quantity-input">
              <form>
                <div className="number-input">
                  <span
                    className="change minus"
                    onClick={() => this.changePeopleState()}
                    readOnly>-</span>
                  <input
                    type="text"
                    className="input-box"
                    value={this.state.numOfPeople}
                    min="1"
                    onChange={()=> this.startChangePeople()}/>
                  <span
                    className="change plus"
                    onClick={() => this.changePeopleState("sum")}
                    >+</span>
                </div>
              </form>
            </div>
          </div>

          {this.displayIngredientsSection()}
        </div>
        <div style={{height:1, background:'#adadad', marginTop: 30, marginBottom:30}} />
      </div>
    )
  }
}

export default connect(null, { updateShopppingPersons, shoppingRecipes, shoppingDepartment })(ShoppingRecipe)
