import React
  from 'react'
import PureImage
  from '../../../components/shared/PureImage'
import { fetchAuthorCookbooks }
  from '../../../helpers/author';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';

class Author extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorCookbooks: null
    };

    this.mounted = false;

  }

  UNSAFE_componentWillMount(){
    this.mounted = true;
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount() {
    fetchAuthorCookbooks(this.props.author.Id, this.addAuthorCookBooks.bind(this))
  }

  addAuthorCookBooks(data){
    this.mounted &&  this.setState({ authorCookbooks: data.length })
  }

  renderProfileImage(){
    let { author } = this.props

    if(author.ImagePath != undefined){
      return <PureImage src={author.ImagePath} className="img-responsive" />
    }
  }

  truncate(str) {
    if(str != null) {
      return (
        str.length > 35 ? str.substring(0, 35) + "..." : str
      )
    } else {
      return (
        "Not description"
      )
    }
  }

  displayAuthorCookbooksCount() {
    if(this.state.authorCookbooks !== null) {
      return (
        <span className="authors-book-number">{this.state.authorCookbooks} { this.state.authorCookbooks === 1 ? "Cookbook" : "Cookbooks" }</span>
      )
    } else {
      return <img src={Spinner} alt="logo" width="20px"/>
    }
  }

  cutString(s, n){
    if(s) {
      var cut= s.indexOf(' ', n);
      if(cut === -1) return s;
      return s.substring(0, cut) + "..."
    } else {
      return (
        "Not description"
      )
    }
  }

  render() {
    let { author } = this.props

    return(
      <div className="col-sm-6 col-md-3">
        <div className="authors-item">
          <div className="authors-img">
            <a href={`/authors/` + author.Id}>
              {this.renderProfileImage()}
            </a>
          </div>
          <div className="authors-content">
            <a href={`/authors/` + author.Id}>
              <h1 className="authors-title">{author.Name}</h1>
            </a>
            <div className="authors-book">
              {this.displayAuthorCookbooksCount()}
              <div className="content-rate">
                {/*
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g fill="#F5BD00" fillRule="nonzero"><path d="M2.825 10.997a.916.916 0 0 1-1.228-.957 8.956 8.956 0 0 1 .75-2.943 13.086 13.086 0 0 1-2.143-2.18.916.916 0 0 1 .523-1.473c.929-.185 1.874-.28 2.822-.281A8.496 8.496 0 0 1 4.87.377.916.916 0 0 1 6.157.18l-.48 8.522-.06.813a10.14 10.14 0 0 1-2.792 1.482z"></path><path d="M2.825 10.997a.916.916 0 0 1-1.228-.957 8.956 8.956 0 0 1 .75-2.943 13.086 13.086 0 0 1-2.143-2.18.916.916 0 0 1 .523-1.473c.929-.185 1.874-.28 2.822-.281A8.496 8.496 0 0 1 4.87.377a.916.916 0 0 1 1.478 0 8.582 8.582 0 0 1 1.325 2.786c.953 0 1.903.094 2.838.281a.916.916 0 0 1 .527 1.465 11.965 11.965 0 0 1-2.165 2.192 9.41 9.41 0 0 1 .743 2.924.916.916 0 0 1-1.23.964 10.393 10.393 0 0 1-2.774-1.478 10.139 10.139 0 0 1-2.788 1.486z" opacity=".4"></path></g></svg>
                */}
              </div>
            </div>
              <p className="authors-content">{this.cutString(author.Description, 20)}</p>
              {/*<p className="authors-content">{this.truncate(author.Description)}</p>*/}
          </div>
        </div>
      </div>
    )
  }
}

export default Author
