import React
  from "react";
import PureImage
  from '../../../components/shared/PureImage'
import { getToken }
  from "../../../services/token";
import { setCurrentPath }
  from '../../../helpers/currentPath';

class AuthorInfo extends React.Component {

  renderProfileImage(){
    if(this.props.author.ImagePath != undefined){
      return <PureImage src={this.props.author.ImagePath} className="img-responsive" />
    }
  }

  render() {
    let { author, translate } = this.props
    return (
      <div className="col-xs-12 col-sm-12 col-md-3">
        <div className="author-profile-img">
          {this.renderProfileImage()}
        </div>
        <div className="author-profile-info">
          <h1>{ author.Name }</h1>
        </div>
        <div className="author-profile-subsribe" onClick={getToken ? this.props.subscribe : () => setCurrentPath()}>
          <a className="button" href={undefined} > {this.props.isSubscribed ? translate('app.cookbookDetails.unsubscribe') : translate('app.cookbookDetails.subscribe') }</a>
        </div>
        <div className="author-profile-link">
          <ul>
            <li>
              <a href={author.Facebook} target="_blank" rel="noopener noreferrer" className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                >
                  {" "}
                  <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M8.002.925a26.131 26.131 0 0 0-.637-.059 12.598 12.598 0 0 0-.962-.048 2.994 2.994 0 0 0-2.311.944 3.208 3.208 0 0 0-.822 2.462v2.741a.409.409 0 0 1-.409.409H.818v2.452h2.044c.226 0 .409.183.409.409v6.948h2.452v-6.948c0-.226.183-.409.409-.409h2.091l.307-2.452h-2.4a.409.409 0 0 1-.409-.409V5.21a2.2 2.2 0 0 1 .366-1.362c.316-.391.81-.595 1.309-.54h1.186V.99a44.46 44.46 0 0 0-.58-.065zm-5.549 3.3a4 4 0 0 1 1.04-3.018A3.808 3.808 0 0 1 6.403 0c.276 0 .623.018 1.028.051a32.788 32.788 0 0 1 1.336.137l.279.035c.203.027.355.2.355.405v3.089a.409.409 0 0 1-.409.409H7.397c-.39 0-.54.062-.658.218-.155.26-.225.564-.2.866v1.346h2.452a.409.409 0 0 1 .406.459l-.409 3.27a.409.409 0 0 1-.406.358H6.539v6.948A.409.409 0 0 1 6.13 18H2.86a.409.409 0 0 1-.409-.409v-6.948H.408a.409.409 0 0 1-.409-.409V6.965a.409.409 0 0 1 .409-.409h2.044l.001-2.331z"
                  />
                </svg>
              </a>
              <a href={author.Facebook} target="_blank" rel="noopener noreferrer" className="link">
                Folge mir auf Facebook
              </a>
            </li>
            <li>
              <a href={author.Website} target="_blank" rel="noopener noreferrer" className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M.727 8A7.272 7.272 0 0 0 8 15.273 7.272 7.272 0 0 0 15.273 8 7.272 7.272 0 0 0 8 .727 7.272 7.272 0 0 0 .727 8zM0 8a8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8zm9.178-6.694a1.818 1.818 0 0 0-2.358-.001c-2.128 1.81-3.184 4.034-3.184 6.697 0 2.663 1.057 4.886 3.185 6.694.68.578 1.677.577 2.356 0 2.13-1.814 3.187-4.037 3.187-6.694 0-2.658-1.057-4.881-3.186-6.696zm.471-.554c2.29 1.951 3.442 4.376 3.442 7.25 0 2.874-1.153 5.298-3.443 7.247-.95.81-2.347.81-3.298.002C4.06 13.306 2.91 10.88 2.91 8c0-2.878 1.15-5.303 3.439-7.25a2.545 2.545 0 0 1 3.3.001zM8.364.364v15.272c0 .485-.728.485-.728 0V.364c0-.485.728-.485.728 0zm-8 8a.364.364 0 1 1 0-.728h15.272a.364.364 0 0 1 0 .728H.364zm1.05-3.639a.364.364 0 0 1 .08-.723 58.868 58.868 0 0 0 13.01 0 .364.364 0 0 1 .081.723 59.582 59.582 0 0 1-13.17 0zm.08 7.273a.364.364 0 1 1-.08-.723 59.595 59.595 0 0 1 13.171 0 .364.364 0 0 1-.08.723 58.855 58.855 0 0 0-13.01 0z"
                  />
                </svg>
              </a>
              <a href={author.Website} target="_blank" rel="noopener noreferrer" className="link">
                Website
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default AuthorInfo;
