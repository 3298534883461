import React
  from "react";
import { changePassword }
  from '../../../helpers/user';
import ReactLoading
  from 'react-loading';
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newPassword: "",
      confirmPassword: "",
      error: null,
      succes: null,
      saveRequest: false
    };
  }

  handleDone() {
    let { password, newPassword, confirmPassword } = this.state
    let { translate } = this.props
    if(password.trim() === "" || newPassword.trim() === "" || confirmPassword.trim() === "") {
      this.addError(translate('app.settings.empty_fields'))
      return
    }
    if(newPassword !== confirmPassword) {
      this.addError(translate('app.settings.password_doesnt_match'))
      return
    }
    changePassword(password, newPassword, this.resultChange.bind(this))
  }

  resultChange(data) {
    if(data === null) {
      this.addSuccess()
    } else {
      this.addError(data.data.Message)
    }
  }

  addError(error) {
    this.setState(
      {error: error}
    );
  }

  addSuccess() {
    let { translate } = this.props
    this.setState({error: null})
    this.props.setMessage(translate('app.settings.change_password'), translate('app.settings.change_password_successfully'))
  }

  displaySaveButton() {
    let { translate } = this.props
    if(this.state.saveRequest) {
      return (
        <div style={{marginLeft: "45%"}}>
          <ReactLoading
            type={"spokes"}
            color={"#000000"}
            height={30} width={30}
          />
        </div>
      )
    } else {
      return (
        <a
          href={undefined}
          onClick={() => this.handleDone()}
          className="button">
          {translate('app.settings.save')}
        </a>
      )
    }
  }

  displayError() {
    let { error } = this.state

    if(error !== null && error !== "") {
      return (
        <p className="form-error" style={{color: "red", marginBottom: 15}}>{error}</p>
      )
    }
  }

  displaySuccessMessage() {
    let { success } = this.state

    if(success !== null && success !== "") {
      return (
        <p style={{color: "green", marginBottom: 15}}>{success}</p>
      )
    }
  }

 render() {
   let { translate } = this.props
   return (
     <div className="row">
       <div className="col-sm-6 col-md-4 col-md-center">
         {this.displayError()}
         {this.displaySuccessMessage()}
         <div className="settings-input">
           <label htmlFor="pass">{translate('app.settings.current_password')}</label>
           <div className="settings-input-wrapper">
             <input
              type="password"
              className="form-control"
              id="pass"
              name="pass"
              value={this.state.password}
              onChange={e => this.setState({password: e.target.value})}
              required
             />
           <span toggle="#pass" className="pass-eye-off toggle-password"></span>
           </div>
         </div>
         <div className="settings-input">
           <label htmlFor="new-pass">{translate('app.settings.new_password')}</label>
           <div className="settings-input-wrapper">
             <input
              type="password"
              className="form-control"
              id="new-pass"
              name="new-pass"
              value={this.state.newPassword}
              onChange={e => this.setState({newPassword: e.target.value})}
              required
             />
           <span toggle="#new-pass" className="pass-eye-off toggle-password"></span>
           </div>
         </div>
         <div className="settings-input">
           <label htmlFor="confirm-pass">{translate('app.settings.confirm_password')}</label>
           <div className="settings-input-wrapper">
             <input
              type="password"
              className="form-control"
              id="confirm-pass"
              name="confirm-pass"
              value={this.state.confirmPassword}
              onChange={e => this.setState({confirmPassword: e.target.value})}
              required
             />
           <span toggle="#confirm-pass" className="pass-eye-off toggle-password"></span>
           </div>
         </div>
         <div className="settings-save">
          {this.displaySaveButton()}
         </div>
       </div>
     </div>
   )
  }
};

function mapStateToProps(state) {
  return ({
            translate: getTranslate(state.locale)
          })
}
export default connect(mapStateToProps, {  })(ChangePassword);
