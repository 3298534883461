import React from 'react';

const Bread = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
        <path d="M727 1199 c-57 -13 -88 -32 -251 -148 -223 -159 -297 -242 -332 -375
        -21 -79 -18 -195 8 -263 25 -68 99 -148 169 -181 100 -48 236 -49 362 -2 150
        56 435 271 548 413 48 61 54 119 15 156 -13 13 -27 39 -31 59 -12 67 -74 169
        -137 227 -68 63 -113 89 -195 110 -63 17 -99 17 -156 4z m216 -62 c105 -48
        221 -189 241 -292 5 -29 -1 -35 -157 -159 l-162 -128 -6 63 c-16 182 -165 328
        -362 357 l-52 7 103 73 c143 102 170 113 262 110 56 -3 90 -10 133 -31z m-361
        -217 c145 -55 248 -197 248 -341 0 -40 -6 -55 -39 -97 -44 -55 -144 -126 -199
        -142 -126 -35 -247 32 -290 159 -36 103 26 244 121 280 86 33 198 -4 232 -76
        54 -114 5 -223 -102 -223 -36 0 -50 6 -74 29 -36 36 -39 83 -7 105 19 14 24
        14 49 -1 16 -9 32 -14 36 -9 11 11 -11 40 -40 50 -56 20 -114 -36 -103 -99 14
        -74 94 -125 173 -110 41 7 96 54 113 95 16 40 9 135 -15 184 -39 80 -158 122
        -260 92 -165 -50 -221 -292 -100 -433 52 -61 121 -86 215 -81 94 5 158 37 244
        120 33 31 63 55 68 52 21 -13 7 -72 -26 -110 -95 -111 -320 -166 -461 -113
        -116 43 -205 169 -205 289 0 140 52 249 173 358 58 54 145 62 249 22z m651
        -161 c32 -51 -77 -170 -332 -363 -11 -9 -12 -5 -7 22 5 21 2 42 -9 64 -15 32
        -15 33 7 50 13 9 86 68 163 132 77 63 146 114 154 113 8 -1 18 -9 24 -18z"/>
        <path d="M785 1141 c-3 -6 32 -35 79 -65 120 -80 185 -163 186 -238 0 -50 -28
        -30 -64 46 -41 84 -91 135 -175 173 -70 33 -165 42 -209 19 -55 -28 -42 -50
        46 -80 138 -49 219 -121 263 -235 20 -53 56 -73 45 -24 -13 55 -60 138 -102
        179 -42 43 -125 90 -198 114 -33 11 -36 14 -21 22 25 14 99 2 159 -26 76 -35
        123 -82 164 -165 38 -77 60 -95 100 -80 19 7 22 16 22 56 -1 95 -61 180 -189
        263 -76 51 -95 58 -106 41z"/>
      </g>
    </svg>
  )
}

export default Bread
