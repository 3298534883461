import {
    FAVOURITE_COOKBOOK,
    ADD_COOKBOOK,
    REMOVE_COOKBOOK
  }
from '../../constants/types';

export default function(state=[], action){
  switch(action.type){
    case FAVOURITE_COOKBOOK:
      return action.payload

    case ADD_COOKBOOK:
      return [ ...state, action.payload ]

    case REMOVE_COOKBOOK:
      return [ ...state.filter(function(v) {
       return v.Id !== action.payload.Id
      })]

    default:
      return state
  }
}
