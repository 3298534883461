import React from 'react'

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <section className="not-found">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="not-found-content">
                <div className="not-found-title">
                  <h1>404</h1>
                  <span className="shadow-text">404</span>
                </div>
                <div className="not-found-text">
                  <h2>Oops! Page not found</h2>
                  <p>The page you are looking for cannot be found. Click on the link below to return home.</p>
                </div>
                <div className="not-found-button">
                  <a className="button" href="/">Go to home</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NotFoundPage
