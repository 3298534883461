import React
  from 'react'
import { Link }
  from "react-router-dom"
import CookBookCard
  from './CookBookCard'
import { fetchLatestCookbooks }
  from '../../../actions/recipes'
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';

class CookBooks extends React.Component{
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {
      loading:true
    }
    this.mounted = false;
  }

  addLatestCookBooks(data){
    this._isMounted && this.setState({
      latestCookbooks: data
    }, () => window.slider())
  }

  componentDidMount(){
    this._isMounted = true;
    let _this = this
    this.props.fetchLatestCookbooks(
      this.addLatestCookBooks.bind(this),
    //   this.errorHandle.bind(this)
    )

    if(this._isMounted) {
      setTimeout(function() {
        _this.setState({loading: false})
      }, 30);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  cookbooks(){
    let latestCookbooks = this.props.latestCookbooks
    let { translate } = this.props
    return latestCookbooks.map((item) => {
      return (
        <CookBookCard
          key={item.Id}
          id={item.Id}
          item={item}
          source={item.CoverPath}
          heading={item.Name}
          slider={true}
          paragraph={item.Description ? item.Description.substring(0,70) + "..." : translate('app.explorePage.no_description_available')}
        />
      )
    })
  }

  render(){
    let { translate } = this.props
      if(!this.state.loading) {
        return(
          <section className="cookbooks section-top-80">
              <div className="container">
                  <div className="main-title">
                      <h1>{ translate('app.explorePage.newstCookbooks') }</h1>
                  </div>
                  <div className="row">
                      <div className="cookbooks-slider container">
                         {this.cookbooks()}
                      </div>
                      <div className="col-md-12">
                          <div className="gen-link-more mg-top-15">
                              <Link className="more effect-underline" to={`/cookbooks`}>{ translate('app.explorePage.view_all_cookbooks') }</Link>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        )
    } else {
      return(
        <div></div>
      )
    }
  }
}

function mapStateToProps(state){
  return ({
            latestCookbooks: state.latestCookbooks,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { fetchLatestCookbooks })(CookBooks)
