import React
  from 'react';
import ReactLoading
  from 'react-loading';
import RenderSearch
  from '../../../components/shared/RenderSearch'
import SearchNotFound
  from '../SearchNotFound'

class CookbookItems extends React.Component {

  displayCookbooks() {
    let { cookbooks } = this.props
    if(cookbooks.length !== 0) {
      return (
      cookbooks.map((item)=> {
       return (
         <RenderSearch
           name={item.Name}
           source={item.CoverPath}
           id={item.Id}
           key={item.Id}
           location={'cookbooks'}
         />
        )
      })
     )
    } else {
      return (
        this.noResults()
      )
    }
  }

	render() {
    let { title } = this.props
    if(this.props.loading) {
      return (
        <ReactLoading
          type={"spokes"}
          color={"#000"}
          height={18} width={18}
        />
      )
    } else {
      return (
        <>
          <div className="search-result-title">
           <h1>{title}</h1>
          </div>
          {this.displayCookbooks()}
        </>
      )
    }
  }

  noResults(){
    return (
      <SearchNotFound />
    )
  }
}

export default CookbookItems
