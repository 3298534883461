import qs
  from 'qs';

import api
  from '../helpers/api'

export function addFeedback(data, afterRequest) {
  return function(dispatch) {
    api.post('/feedback', qs.stringify(data))
      .then(response => {
        afterRequest("Your feedback is done, Thank you ..")
      })
      .catch((err) => {
        afterRequest("Error, Please try again later")
      })
  }
}
