import React
  from "react";
import CookBookCard
  from './CookBookCard';
import { CookbooksFilter }
  from '../../../components/shared/filters'
import { fetchCookbooks }
  from '../../../actions/recipes';
import { fetchCookbooksCount }
  from '../../../helpers/cookbook';
import { getFiltersCookbooks }
  from '../../../helpers/filters';
import { connect }
  from 'react-redux';
import { getTranslate }
 from 'react-localize-redux';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';

class TopSixty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      currentPage: 0,
      loading: false,
      cookbookLoading: false,
      limit: 30,
      pages: null,
      cookbooks: [],
      listingFilters: props.history.location.search === "" ? false : true,
      searchTerm: "",
      authorId: null,
      pathParams: props.history.location.search === "" ? "" : props.history.location.search,
      sortDate: null,
      sortBy:''
    };
  }

  componentDidMount(){
    let searchTerm = ""
    let authorId = null
    let OrderBy = ""
    let OrderAsc = ""

    if(this.state.pathParams !== "") {
      let onlyParams = this.state.pathParams.split("?")[1]

      if(onlyParams.split("Term=")[1] != undefined) {
        searchTerm = onlyParams.split("Term=")[1].split("&")[0]
      }
      if(onlyParams.split("AuthorId=")[1] != undefined) {
        authorId = onlyParams.split("AuthorId=")[1].split("&")[0]
      }
      if(onlyParams.split("OrderBy=")[1] != undefined) {
        OrderBy = onlyParams.split("OrderBy=")[1].split("&")[0]
      }

      if(onlyParams.split("OrderAsc=")[1] != undefined) {
        OrderAsc = onlyParams.split("OrderAsc=")[1].split("&")[0] === "true" ? "Ascending" : "Descending"
      }

    }

    this.setState({searchTerm, authorId, sortBy: OrderBy, sortDate: OrderAsc})
    this.fetchRecords()
  }

  // async UNSAFE_componentWillMount(){
  //   this.fetchRecords()
  // }

  fetchRecords() {
    let { limit, offset, pathParams } = this.state;
    let onlyParams = pathParams.split('?')[1] != undefined ? pathParams.split('?')[1] : ""
    this.setState({loading: true})
    this.props.fetchCookbooks(
      limit,
      offset,
      this.addCookBooks.bind(this),
      this.errorHandle.bind(this),
      onlyParams
    )

    this.props.fetchCookbooksCount(this.addCookbooksCount.bind(this))
  }

  addCookbooksCount(result) {
    if(result > this.state.limit || result !== this.state.limit) {
      this.setState({pages: Math.round(result/30)})
    }
  }

  fetchMoreCoockbooks(number) {
    let { limit } = this.state;

    this.setState({loading: true})
    this.props.fetchCookbooks(
                limit,
                number * 30,
                this.addCookBooks.bind(this),
                this.errorHandle.bind(this))
  }

  errorHandle() {

  }

  clearFilters() {
    this.setState({
      loading: false,
      offset: 0,
      listingFilters: false
    })

    this.props.history.push(`/cookbooks`)
    window.location.reload();
  }

  addCookBooks(data, params){
    this.setState({
      loading: false,
      offset: 30 * this.state.currentPage,
      items: data
    })
    // this.props.history.push(`/cookbooks?${params}`)
  }


  cookbooks(){
    let { items, loading } = this.state
    let { translate } = this.props
    if(items){
      if(items.length !== 0) {
        if(loading) {
          return (
            <div style={{paddingLeft: "45%", marginBottom: "10%",marginTop: "10%"}}>
              <img src={Spinner} alt="logo" width="200px"/>
            </div>
          )
        } else {
          return items.map((item) => {
            return(
              <CookBookCard
                key={item.Id}
                item={item}
                IsFavorized={item.IsFavorized}
                id={item.Id}
                source={item.CoverPath}
                heading={item.Name}
                slider={false}
                paragraph={item.Description ? item.Description.substring(0,70) + "...": translate('app.explorePage.no_description_available') }
              />
              )
            })
          }
        } else {
          return (
            <p style={{marginLeft: "40%", marginBottom: 80, marginTop: 50}}>
              { translate('app.filtersInputs.not_found') }
            </p>
          )
        }
      } else {
        return (
          <div style={{paddingLeft: "45%", marginBottom: "10%",marginTop: "10%"}}>
            <img src={Spinner} alt="logo" width="200px"/>
          </div>
        )
      }
  }

  changePage(number) {
    window.scrollTo(0, 0);
    this.setState({currentPage: number})
    this.fetchMoreCoockbooks(number)
  }

  paginate() {
    return (
      <ul className="pagination">
        {/*this.displayPreviousPage()*/}
        { Array.from(Array(this.state.pages), (e, i) => {
            return (
              <li key={i} className={"page-item " + (this.state.currentPage === i  ? 'active' : '')} >
                <a href={undefined}
                   className="page-link"
                   onClick={(number) => this.changePage(i)}>
                  {i + 1}
                </a>
              </li>
            )
          })
        }
        {/*this.displayNextPage()*/}
      </ul>
    )
  }

  applyFilters(object) {
    let { searchTerm, authorId, sortDate, sortBy } = this.state;

    this.setState({loading: true})
    let params = {
      searchTerm: searchTerm,
      authorId: authorId,
      OrderAsc: sortDate,
      OrderBy: sortBy
    }

    getFiltersCookbooks(params, this.addCookbooksFromFilters.bind(this))
  }

  addCookbooksFromFilters(data, url){
    this.setState({
      loading: false,
      listingFilters: true,
      items: data
    })
    let params = url.split('?')[1]
    this.props.history.push(`/cookbooks?${params}`)
  }

  render() {
    return (
      <>
        <CookbooksFilter
          clearFilters={this.clearFilters.bind(this)}
          applyFilters={this.applyFilters.bind(this)}
          listingFilters={this.state.listingFilters}
          addSearchState={(val) => this.setState({searchTerm: val})}
          addAuthorState={(aId) => this.setState({authorId: aId})}
          addDateSort={(sortDate)=> this.setState({sortDate})}
          addSortBy={(sortBy)=> this.setState({sortBy})}
          searchTerm={this.state.searchTerm}
          authorId={this.state.authorId}
          preDefineAuthorId={this.state.authorId}
          selectedSort={this.state.sortDate ? this.state.sortDate : null}
          selectedBy={this.state.sortBy}
        />
        <section className="cookbooks section-top-60">
          <div className="container noPadding">
            <div className="row">
              {this.cookbooks()}
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <div className="pagination-wrapper">
              { !this.state.loading && this.paginate()}
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state){
  return ({
            cookbooks: state.cookbooks,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { fetchCookbooks, fetchCookbooksCount, getFiltersCookbooks })(TopSixty)
