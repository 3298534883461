import React from 'react';

const MainDish = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
        <path d="M1194 1118 c-11 -13 -25 -35 -32 -50 -6 -16 -33 -39 -62 -56 l-51
        -29 -67 33 c-155 79 -348 113 -473 84 -172 -39 -333 -184 -419 -374 -62 -138
        -58 -236 13 -314 l20 -22 -26 0 c-65 0 -34 -42 74 -100 l57 -30 459 0 460 0
        79 47 c99 60 102 76 14 84 -36 3 -60 6 -54 7 22 4 106 75 135 114 58 81 32
        187 -75 304 l-54 59 20 37 c12 20 34 42 50 49 16 6 40 20 53 31 44 35 27 101
        -35 133 -40 21 -63 19 -86 -7z m76 -28 c11 -6 24 -20 30 -31 15 -26 1 -43 -55
        -68 -40 -18 -47 -27 -93 -123 -27 -56 -62 -132 -78 -166 -63 -140 -182 -183
        -269 -97 -44 44 -53 91 -30 150 21 57 69 97 165 136 88 37 206 102 238 132 12
        11 22 28 22 37 0 35 34 49 70 30z m-472 -40 c87 -24 202 -75 202 -89 0 -5 -17
        -15 -37 -22 -56 -18 -126 -54 -162 -85 -123 -103 -77 -304 73 -321 71 -8 114
        4 160 47 40 37 58 70 122 219 l18 44 48 -54 c57 -63 98 -140 98 -184 0 -57
        -63 -127 -171 -189 l-46 -26 -461 0 -460 0 -32 26 c-41 35 -70 94 -70 142 0
        99 82 255 189 362 80 79 154 123 244 146 67 17 190 10 285 -16z m412 -711
        c-75 -51 -58 -49 -525 -49 l-436 0 -49 25 c-28 14 -50 28 -50 30 0 3 242 5
        538 5 369 0 532 -4 522 -11z"/>
        <path d="M412 728 c-99 -52 -186 -114 -204 -146 -10 -20 -9 -29 7 -60 30 -56
        105 -84 210 -79 166 7 203 76 74 136 -58 28 -58 29 -39 46 31 28 80 106 80
        127 0 29 -38 22 -128 -24z m67 -25 c-8 -10 -29 -35 -47 -56 -18 -21 -32 -43
        -32 -50 0 -7 32 -26 70 -44 39 -17 70 -36 70 -41 0 -18 -66 -37 -130 -37 -80
        0 -157 33 -166 71 -8 29 11 46 134 122 88 54 128 68 101 35z"/>
      </g>
    </svg>
  )
}

export default MainDish
