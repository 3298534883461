import React
  from "react";
import PureImage
  from '../../../components/shared/PureImage'

class Banner extends React.Component {

  renderAuthimg(){
    if(this.props.source != undefined){
      return <PureImage src={this.props.source} className="img-responsive" />
    }
  }

  componentDidMount(){
    this.renderAuthimg()
  }

  render() {
    return (
      <div className="cookbook-profile-info-inner-nd">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <div className="cookbook-profile-img">
            {this.renderAuthimg()}
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
