import React
  from "react";
import { getToken, removeToken }
  from "../../services/token";
import { logOut, userSignedIn }
  from '../../actions/auth'
import { LogOutIcon, SearchIcon, FavouriteIcon, DepartmentIcon, RegisterIcon, LoginIcon }
  from "../../assets/icons";
import { connect }
  from 'react-redux';
import PureImage
  from '../../components/shared/PureImage';
import { getTranslate }
  from 'react-localize-redux';
import { refreshPage }
  from '../../actions/refreshPage'
import $
  from "jquery"

class ProfileStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page:'',
      searchLoading: true
    };
  }

  renderAuthimg(){
    if(this.props.me){
      return <PureImage src={this.props.me.AvatarPath} className="img-responsive" />
    }
  }

  componentDidMount(){
    window.search()
  }

  openSearchModal() {
    this.props.refreshPage()
    $('.search').addClass('show');
    $('body').addClass('search-on');
  }

  displayProfile() {
    let { translate } = this.props
    if(!getToken) {
      return (
        <ul>
          <li className="" onClick={this.openSearchModal.bind(this)}>
            <SearchIcon />
          </li>
          <li className="nav-login mb-hidden">
            <a href={`/auth`}>
              <LoginIcon />
              { translate('auth.login') }
            </a>
          </li>
          <li className="nav-register mb-hidden">
            <a href={`/auth/new`}>
              <RegisterIcon />
              { translate('auth.register_for_free') }
            </a>
          </li>
        </ul>
      )
    } else {
      return (
        this.displayLoggedStatus()
      )
    }
  }

  displayLoggedStatus() {
    let { translate } = this.props
    return (
        <ul>
          <li>
            <a href={undefined}  data-toggle="modal" data-target="#shopingList">
              <DepartmentIcon />
            </a>
          </li>
          <li>
            <a href={`/favorites/recipes`}>
              <FavouriteIcon />
            </a>
          </li>
          <li className="" onClick={this.openSearchModal.bind(this)}>
            <SearchIcon />
          </li>
          <li>
            <div className="dropdown nav-profile-wrapp">
              <div className="nav-profile" data-toggle="dropdown">
                <div className="nav-profile-img">
                  {this.renderAuthimg()}
                </div>
                <div className="nav-profile-icon">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 129 129"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    enableBackground="new 0 0 129 129" width="10" height="9"><g><path fill="#fff" d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <ul className="dropdown-menu" id="dropdown-profile">
                <li>
                  <a href={'/settings/edit_profile'}>
                    { translate('app.profileTabs.edit_profile') }
                  </a>
                </li>
                <li>
                  <a href={'/settings/edit_profile'}>
                    { translate('app.profileTabs.settings') }
                  </a>
                </li>
                <li>
                  <a href={'/settings/change_password'}>
                    { translate('app.profileTabs.change_password') }
                  </a>
                </li>
                <li>
                  <a href={undefined}  onClick={this.logOut.bind(this)}>
                    <LogOutIcon /> { translate('app.profileTabs.sign_out') }
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
    )
  }

  async logOut() {
    await removeToken()
    // await this.props.logOut(this.afterLogOutScreen.bind(this))
    window.location.href = '/';
  }

  afterLogOutScreen(){
  }

  render() {
    return (
      this.displayProfile()
    );
  }
}

function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}
export default connect(mapStateToProps, { logOut, userSignedIn, refreshPage })(ProfileStatus);
