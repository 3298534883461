import React from 'react';
import { IMAGE_BASE_URL }
  from '../../constants/index';

class PureImage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			image: null,
		}
	}

	render() {
    let { src, style, className, alt } = this.props
		if(this.props.src) {
			return <img src={`${IMAGE_BASE_URL}`+src} style={style} className={className} alt={alt} />
		}

		return <p>Loading</p>;

	}
}
export default PureImage
