import React from 'react';
import {

  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  ViberShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  RedditIcon,
  ViberIcon,
  EmailIcon
} from 'react-share';

class ShareWithMedia extends React.Component {
  state = {
    numOfPeople: 1,
  }

  componentDidMount() {
    window.inputnumber()
  }

  render() {
    let { match, title } = this.props
    const shareUrl = `https://web.tendoo.com${match.url}`;

    return(
      <div className="modal blk-modal fade" id="share-with-media" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <div className="blk-modal-content">
                <h1>Share with media</h1>
                <div className="quantity-input">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    style={{marginRight: 12}}
                  >
                    <FacebookIcon size={45} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    style={{marginRight: 12}}
                  >
                    <TwitterIcon size={45} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                    style={{marginRight: 12}}
                  >
                    <WhatsappIcon size={45} round />
                  </WhatsappShareButton>
                  <LinkedinShareButton
                    url={shareUrl}
                    style={{marginRight: 12}}>
                    <LinkedinIcon size={45} round />
                  </LinkedinShareButton>
                  <RedditShareButton
    		            url={shareUrl}
    		            title={title}
                    style={{marginRight: 12}}>
    		            <RedditIcon
    		              size={45}
    		              round />
    		          </RedditShareButton>
                  <EmailShareButton
    		            url={shareUrl}
    		            subject={title}
                    style={{marginRight: 12}}>
    		            <EmailIcon
    		              size={45}
    		              round />
    		          </EmailShareButton>
                  <ViberShareButton
    		            url={shareUrl}
    		            title={title}>
    		            <ViberIcon
    		              size={45}
    		              round />
    		          </ViberShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ShareWithMedia
