import React
  from 'react'
import SingleComment
  from './SingleComment'
import { fetchComments, createComment, likeComment, unlikeComment }
  from '../../helpers/recipe'
import PureImage
  from '../../components/shared/PureImage';
import { getToken }
  from "../../services/token";
import ReactLoading
  from 'react-loading';
import { setCurrentPath }
  from '../../helpers/currentPath';
import StarRatings
  from 'react-star-ratings';
import { connect }
  from 'react-redux';
import { rateRecipe }
  from '../../actions/recipes'

class Comments extends React.Component{
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      newComment: '',
      fetched: false,
      more: false,
      likeLoading: false,
      commentLoading: false,
      rating: props.rating + 1,
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted) {
      this.comments()
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.comments()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  comments(){
    let { id } = this.props
    if (id){
      this.setState({fetched: true})
      fetchComments(getToken, id, this.saveComments.bind(this))
    }
  }

  addComment(data){
    this.setState({
      comments: [ data, ...this.state.comments ],
      commentLoading: false
    })
  }

  saveComments(data){
    if(Array.isArray(data)){
      this.setState({
        comments: data,
        fetched: false,
        showMore: false
      })
    }
  }

  toggleLike(isLiked, recipeId, commentId){
    this.setState({likeLoading: true})
    if(isLiked) {
      likeComment(recipeId, commentId)
    } else {
      unlikeComment(recipeId, commentId)
    }

    let edited_comments = this.state.comments.map(comment => {
      if(comment.Id === commentId) {
        let num_of_likes = comment.Likes
        let num_of_disLikes = comment.Dislikes

        if(comment.IsLiked !== null) {
          if(isLiked !== comment.IsLiked) {
            if(isLiked) {
              num_of_likes += 1
              num_of_disLikes -= 1
            } else {
              num_of_likes -= 1
              num_of_disLikes += 1
            }
          }
        } else {
          if(isLiked) {
            num_of_likes += 1
          } else {
            num_of_disLikes += 1
          }
        }

        return {
          ...comment,
          Likes: num_of_likes,
          Dislikes: num_of_disLikes,
          IsLiked: isLiked
        }
      }

      return comment
    })

    this.setState({
      comments: edited_comments,
      likeLoading: false
    })
  }

  renderAuthimg(){
    if(this.props.user && this.props.user.AvatarPath != undefined){
      return <PureImage src={this.props.user.AvatarPath} />
    }
  }

  changeRating( newRating, name ) {
    this.setState({
      rating: newRating
    });

    this.rateRecipeRequest(newRating)
  }

  rateRecipeRequest(newRating) {
    let { recipe } = this.props

    this.props.rateRecipe(
      recipe.Id,
      newRating,
      this.changeRate.bind(this)
    )
  }

  changeRate(result){
    this.setState({
      rating: result.UserRating + 1,
    })
    this.props.setAverage(result.TotalRatings, result.GlobalRating)
  }

  renderComment(){

    if(this.state.fetched) {
      return (
        <div style={{marginLeft: "46%"}}>
          <ReactLoading
            type={"spokes"}
            color={"#000000"}
            height={40} width={40}
          />
        </div>
      )
    } else {
      let comments = this.state.more ? this.state.comments : this.state.comments.slice(0,3)

      var months = [
        'January','February','March',
        'April','May','June',
        'July','August','September','October',
        'November','December'
      ];


      return comments.map((item) => {
        let date = new Date(item.Created)
        var formatedDate =  date.getDate() + ". " + (months[date.getMonth()]) + " " + date.getFullYear()
        const day = 1000*60*60*24;
        let dteToday = Date.now()-day;
        let dteYesterday = dteToday-day;
        let dteCompare = date.getTime();
        return (
          <SingleComment
              key={item.Id}
              nrlikes={item.Likes}
              nrdislikes={item.Dislikes}
              isLiked={item.IsLiked}
              likePress={()=> this.toggleLike(true, this.props.id, item.Id)}
              unlikePress={()=> this.toggleLike(false, this.props.id, item.Id)}
              name={item.User && (item.User.FirstName + ' ' + item.User.LastName)}
              time={(dteToday < dteCompare) ? 'Today' : (dteYesterday < dteCompare) ? 'Yesterday' :  formatedDate  }
              comments={item.Text}
              image={item.User && item.User.AvatarPath}
              likeLoading={this.state.likeLoading}
          />
        )
      })
    }
  }

  handleKey= (e) => {
    if (e.key === 'Enter') {
      this.setState({commentLoading: true})
      this.createComment()
    }
  }

  createComment(){
    let { id } = this.props
    this.setState({
      newComment: ''
    })
    createComment(getToken, id, this.state.newComment, this.addComment.bind(this))
  }

  laodMore() {
    let { translate } = this.props
    if(!this.state.fetched && this.state.comments.length > 0) {
      return (
        <a href={undefined} >{this.state.more ? translate('app.recipeDetails.load_less_comments') : translate('app.recipeDetails.load_more_comments')}</a>
      )
    }
  }

  displayInput() {
    let { translate } = this.props
    if(!this.state.commentLoading) {
      return (
        <input type="text" id="comment"
            placeholder={translate('app.recipeDetails.write_a_comment')}
            onKeyDown={this.handleKey}
            value={this.state.newComment}
            onChange={(evt) => this.setState({newComment: evt.target.value})}/>
      )
    } else {
      return (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#000000"}
            height={25} width={25}
          />
        </div>
      )
    }
  }

  render(){
    let { translate } = this.props

    return(
      <section className="recipes-comment section-top-70">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="cookbook-profile-rate">
                <h2>{translate('app.recipeDetails.rate_recipe')}</h2>
                <div className="content-rate">
                  <StarRatings
                    rating={this.state.rating}
                    changeRating={getToken ? this.changeRating.bind(this) : () => setCurrentPath()}
                    starRatedColor="#F5BD00"
                    starDimension="27px"
                    starHoverColor="#ff5f00"
                    starSpacing="0px"
                    numberOfStars={5}
                    name='rating'
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="recipes-comment-inner">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="recipes-comment-head">
                  <h1>{translate('app.recipeDetails.comments')} ({this.state.comments.length})</h1>
                </div>
                { getToken ?
                  <div className="recipes-comment-input">
                    <div className="recipes-comment-img">
                      {this.renderAuthimg()}
                    </div>
                    {this.displayInput()}
                  </div>
                  :
                  <div className="recipes-comment-input">
                    <p><a href={undefined}  onClick={() => setCurrentPath()}>Login</a> to leave a comment</p>
                  </div>
                }
                { this.renderComment() }
                { this.state.comments.length > 3 &&
                  <div className="recipes-comment-footer" onClick={()=> this.setState({more:!this.state.more})}>
                    {this.laodMore()}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return ({
    people: state.numberOfPeople,
    me: state.current_user,
  })
}

export default connect(mapStateToProps,
  { rateRecipe })
  ( Comments );
