import React
  from 'react'

import { Route }
  from "react-router-dom";

import Explore
  from './explore'

import Cookbooks
  from './cookbooks'

import CookbookDetails
  from './cookbooks/details.js'

import Recipes
  from './recipes'

import RecipeDetails
  from './recipes/details.js'

import Tags
  from './recipes/tags.js'

import Authors
  from './authors'

import AuthorDetails
  from './authors/details.js'

import Blog
  from './blog'

import BlogDetails
  from './blog/details.js'

import Favorites
  from './favorites'

import Settings
  from './settings'

import Terms
  from './terms'

import Privacy
  from './privacy'

import Footer
  from '../../components/shared/Footer'

import Header
  from '../../components/shared/Header'

import Search
  from '../../components/shared/Search'

import ShoppingModal
  from '../../components/shared/modals/ShoppingModal';

import RateModal
  from "../../components/shared/modals/RateModals";

import { getToken }
  from '../../services/token';

import NotFoundPage
  from '../NotFoundPage.js'

import Banner
  from '../../components/shared/Banner'

class InsideApp extends React.Component{
  componentDidMount(){
    window.slider()

    this.checkRoutes(this.props.history.location.pathname)
  }

  checkRoutes(currentRoute) {
    if(currentRoute === "/") {}
    else if(currentRoute.indexOf("/cookbooks") > -1) {}
    else if(currentRoute.indexOf("/recipes") > -1) {}
    else if(currentRoute.indexOf("/authors") > -1) {}
    else if(currentRoute.indexOf("/blog") > -1) {}
    else if(currentRoute.indexOf("/favorites") > -1) {}
    else if(currentRoute.indexOf("/tags") > -1) {}
    else if(currentRoute.indexOf("/terms") > -1) {}
    else if(currentRoute.indexOf("/privacy") > -1) {}
    else if(currentRoute === "/settings/edit_profile") {}
    else if(currentRoute === "/settings/change_password") {}
    else if(currentRoute === "/settings/language") {}
    else if(currentRoute === "/settings/measurements") {}
    else if(currentRoute === "/settings/push_notification") {}
    else if(currentRoute === "/settings/feedback") {}
    else if(currentRoute.indexOf("/404") > -1) {}
    else { window.location.href = '/404';}
  }

  render(){
    return (
      <>
      <Banner />
      <Header props={this.props}/>
      {/* <div className="container"> */}
      <Route exact path="/" component={Explore} />

      <Route exact path="/cookbooks" component={Cookbooks} />
      <Route exact path="/cookbooks/:id" component={CookbookDetails} />

      <Route exact path="/recipes" component={Recipes} />
      <Route exact path="/recipes/:id" component={RecipeDetails} />
      <Route exact path="/tags" component={Tags} />

      <Route exact path="/authors" component={Authors} />
      <Route exact path="/authors/:id" component={AuthorDetails} />

      <Route exact path="/blog" component={Blog} />
      <Route exact path="/blog/:id" component={BlogDetails} />
      <Route path="/terms" component={Terms} />
      <Route path="/privacy" component={Privacy} />

      <Route path="/favorites" component={Favorites} />
      <Route path="/settings" component={Settings} />

      <Route path="/404" component={NotFoundPage}/>

      {this.checkRoutes(this.props.history.location.pathname)}


      {/* </div> */}
      <Search />
      {getToken && <ShoppingModal />}
      {getToken && <RateModal />}

      <Footer props={this.props}/>
      </>
    )
  }
}

export default InsideApp
