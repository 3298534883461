import React
  from "react";
import PureImage
  from '../../../components/shared/PureImage'

class Banner extends React.Component {

  renderAuthimg(){
    if(this.props.source != undefined){
        return <PureImage src={this.props.source} className="img-responsive" />
    }
  }

  componentDidMount(){
      this.renderAuthimg()
  }

  render() {
    return (
        <div className="cookbook-profile-banner">
          {this.renderAuthimg()}
        </div>
    )
  }
}

export default Banner
