import React from 'react';

const All = (props) => {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
        <path d="M298 1154 c-36 -19 -58 -63 -58 -114 0 -90 110 -149 187 -101 74 46
        83 140 19 198 -29 27 -44 33 -78 33 -24 -1 -55 -7 -70 -16z m126 -50 c37 -38
        36 -84 -5 -118 -40 -34 -79 -33 -113 0 -35 35 -35 86 -1 119 33 34 84 34 119
        -1z" />
        <path d="M638 1153 c-67 -42 -77 -139 -19 -197 27 -27 38 -31 86 -31 48 0 59
        4 86 31 58 59 48 157 -21 197 -36 22 -97 21 -132 0z m127 -49 c34 -36 33 -83
        -1 -118 -79 -78 -195 36 -119 118 17 19 34 26 60 26 26 0 43 -7 60 -26z" />
        <path d="M964 1137 c-91 -84 -23 -230 100 -214 58 8 106 61 106 117 0 81 -49
        130 -130 130 -32 0 -48 -7 -76 -33z m141 -32 c51 -50 14 -145 -57 -145 -40 0
        -88 48 -88 88 0 71 95 108 145 57z" />
        <path d="M299 814 c-81 -52 -78 -175 6 -220 47 -25 109 -14 149 25 27 27 31
        38 31 86 0 48 -4 58 -32 86 -39 39 -112 50 -154 23z m125 -50 c78 -79 -36
        -195 -118 -119 -36 33 -36 87 0 120 36 34 83 33 118 -1z" />
        <path d="M622 796 c-34 -32 -37 -39 -37 -90 0 -49 4 -59 33 -88 29 -29 39 -33
        87 -33 48 0 59 4 86 31 81 81 26 214 -90 214 -33 0 -49 -7 -79 -34z m142 -32
        c17 -18 26 -37 26 -59 0 -75 -91 -112 -144 -59 -35 36 -35 82 0 118 36 35 82
        35 118 0z" />
        <path d="M995 820 c-11 -4 -31 -20 -45 -35 -20 -22 -25 -37 -25 -81 0 -47 4
        -58 31 -85 81 -80 214 -27 214 86 0 90 -91 150 -175 115z m109 -55 c55 -51 17
        -145 -59 -145 -22 0 -41 9 -59 26 -78 79 36 195 118 119z" />
        <path d="M314 480 c-39 -15 -74 -68 -74 -111 0 -80 49 -129 130 -129 86 0 146
        101 106 179 -30 58 -103 85 -162 61z m108 -57 c37 -35 38 -81 2 -117 -35 -35
        -86 -35 -119 -1 -79 78 37 195 117 118z" />
        <path d="M655 481 c-62 -27 -93 -116 -61 -176 33 -61 116 -84 176 -48 101 59
        65 219 -51 229 -24 2 -53 0 -64 -5z m109 -57 c34 -35 35 -82 1 -118 -51 -55
        -145 -17 -145 59 0 44 41 85 85 85 22 0 41 -9 59 -26z" />
        <path d="M983 471 c-74 -46 -83 -140 -19 -198 28 -26 44 -33 76 -33 81 0 130
        49 130 130 0 90 -110 149 -187 101z m121 -47 c35 -35 35 -86 1 -119 -78 -79
        -195 37 -118 117 35 37 81 38 117 2z" />
      </g>
    </svg>
  )
}

export default All
