import React from 'react';

const Appetizer = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 140 140" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill={props.color} fillRule="evenodd">
            <g id="appetizer-2">
                <g id="Group" mask="url(#mask-2)" fill={props.color}>
                    <g transform="translate(8.000000, 19.000000)">
                        <path d="M26.5,0 C41.1355459,0 53,11.8644541 53,26.5 C53,41.1355459 41.1355459,53 26.5,53 C11.8644541,53 0,41.1355459 0,26.5 C0,11.8644541 11.8644541,0 26.5,0 Z M26.5,2 C12.9690236,2 2,12.9690236 2,26.5 C2,40.0309764 12.9690236,51 26.5,51 C40.0309764,51 51,40.0309764 51,26.5 C51,12.9690236 40.0309764,2 26.5,2 Z" id="Oval-Copy-2"></path>
                        <path d="M17,13 C20.8659932,13 24,16.1340068 24,20 C24,23.8659932 20.8659932,27 17,27 C13.1340068,27 10,23.8659932 10,20 C10,16.1340068 13.1340068,13 17,13 Z M17,15 C14.2385763,15 12,17.2385763 12,20 C12,22.7614237 14.2385763,25 17,25 C19.7614237,25 22,22.7614237 22,20 C22,17.2385763 19.7614237,15 17,15 Z" id="Oval-2"></path>
                        <path d="M22,34 C24.7614237,34 27,36.2385763 27,39 C27,41.7614237 24.7614237,44 22,44 C19.2385763,44 17,41.7614237 17,39 C17,36.2385763 19.2385763,34 22,34 Z M22,35.4285714 C20.0275545,35.4285714 18.4285714,37.0275545 18.4285714,39 C18.4285714,40.9724455 20.0275545,42.5714286 22,42.5714286 C23.9724455,42.5714286 25.5714286,40.9724455 25.5714286,39 C25.5714286,37.0275545 23.9724455,35.4285714 22,35.4285714 Z" id="Oval-2-Copy"></path>
                        <path d="M38,15 C39.6568542,15 41,16.3431458 41,18 C41,19.6568542 39.6568542,21 38,21 C36.3431458,21 35,19.6568542 35,18 C35,16.3431458 36.3431458,15 38,15 Z M38,16 C36.8954305,16 36,16.8954305 36,18 C36,19.1045695 36.8954305,20 38,20 C39.1045695,20 40,19.1045695 40,18 C40,16.8954305 39.1045695,16 38,16 Z" id="Oval-2-Copy-2"></path>
                        <path d="M98,24 C101.865993,24 105,27.1340068 105,31 C105,34.8659932 101.865993,38 98,38 C94.1340068,38 91,34.8659932 91,31 C91,27.1340068 94.1340068,24 98,24 Z M98,26 C95.2385763,26 93,28.2385763 93,31 C93,33.7614237 95.2385763,36 98,36 C100.761424,36 103,33.7614237 103,31 C103,28.2385763 100.761424,26 98,26 Z" id="Oval-2-Copy-5"></path>
                        <path d="M42,82 C45.8659932,82 49,85.1340068 49,89 C49,92.8659932 45.8659932,96 42,96 C38.1340068,96 35,92.8659932 35,89 C35,85.1340068 38.1340068,82 42,82 Z M42,84 C39.2385763,84 37,86.2385763 37,89 C37,91.7614237 39.2385763,94 42,94 C44.7614237,94 47,91.7614237 47,89 C47,86.2385763 44.7614237,84 42,84 Z" id="Oval-2-Copy-6"></path>
                        <path d="M67,75 C70.8659932,75 74,78.1340068 74,82 C74,85.8659932 70.8659932,89 67,89 C63.1340068,89 60,85.8659932 60,82 C60,78.1340068 63.1340068,75 67,75 Z M67,77 C64.2385763,77 62,79.2385763 62,82 C62,84.7614237 64.2385763,87 67,87 C69.7614237,87 72,84.7614237 72,82 C72,79.2385763 69.7614237,77 67,77 Z" id="Oval-2-Copy-7"></path>
                        <path d="M89,44 C91.7614237,44 94,46.2385763 94,49 C94,51.7614237 91.7614237,54 89,54 C86.2385763,54 84,51.7614237 84,49 C84,46.2385763 86.2385763,44 89,44 Z M89,45.4285714 C87.0275545,45.4285714 85.4285714,47.0275545 85.4285714,49 C85.4285714,50.9724455 87.0275545,52.5714286 89,52.5714286 C90.9724455,52.5714286 92.5714286,50.9724455 92.5714286,49 C92.5714286,47.0275545 90.9724455,45.4285714 89,45.4285714 Z" id="Oval-2-Copy-4"></path>
                        <path d="M110,43 C111.656854,43 113,44.3431458 113,46 C113,47.6568542 111.656854,49 110,49 C108.343146,49 107,47.6568542 107,46 C107,44.3431458 108.343146,43 110,43 Z M110,44 C108.895431,44 108,44.8954305 108,46 C108,47.1045695 108.895431,48 110,48 C111.104569,48 112,47.1045695 112,46 C112,44.8954305 111.104569,44 110,44 Z" id="Oval-2-Copy-3"></path>
                        <path d="M50.5,72 C52.4329966,72 54,73.5670034 54,75.5 C54,77.4329966 52.4329966,79 50.5,79 C48.5670034,79 47,77.4329966 47,75.5 C47,73.5670034 48.5670034,72 50.5,72 Z M50.5,73.1666667 C49.2113356,73.1666667 48.1666667,74.2113356 48.1666667,75.5 C48.1666667,76.7886644 49.2113356,77.8333333 50.5,77.8333333 C51.7886644,77.8333333 52.8333333,76.7886644 52.8333333,75.5 C52.8333333,74.2113356 51.7886644,73.1666667 50.5,73.1666667 Z" id="Oval-2-Copy-8"></path>
                        <path d="M62,97 C63.6568542,97 65,98.3431458 65,100 C65,101.656854 63.6568542,103 62,103 C60.3431458,103 59,101.656854 59,100 C59,98.3431458 60.3431458,97 62,97 Z M62,99 C61.4477153,99 61,99.4477153 61,100 C61,100.552285 61.4477153,101 62,101 C62.5522847,101 63,100.552285 63,100 C63,99.4477153 62.5522847,99 62,99 Z" id="Oval-2-Copy-9"></path>
                        <path d="M97.5,14 C112.135546,14 124,25.8644541 124,40.5 C124,55.1355459 112.135546,67 97.5,67 C82.8644541,67 71,55.1355459 71,40.5 C71,25.8644541 82.8644541,14 97.5,14 Z M97.5,16 C83.9690236,16 73,26.9690236 73,40.5 C73,54.0309764 83.9690236,65 97.5,65 C111.030976,65 122,54.0309764 122,40.5 C122,26.9690236 111.030976,16 97.5,16 Z" id="Oval-Copy-3"></path>
                        <path d="M54.5,59 C69.1355459,59 81,70.8644541 81,85.5 C81,100.135546 69.1355459,112 54.5,112 C39.8644541,112 28,100.135546 28,85.5 C28,70.8644541 39.8644541,59 54.5,59 Z M54.5,61 C40.9690236,61 30,71.9690236 30,85.5 C30,99.0309764 40.9690236,110 54.5,110 C68.0309764,110 79,99.0309764 79,85.5 C79,71.9690236 68.0309764,61 54.5,61 Z" id="Oval-Copy-4"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default Appetizer
