import {
    BASE_URL,
    FAVOURITE_COOKBOOK,
    FAVOURITE_RECIPE,
    ADD_COOKBOOK,
    REMOVE_COOKBOOK,
    REMOVE_RECIPE
  } from '../constants/types';

import api
  from '../helpers/api'

import { getToken, getLanguage }
  from "../services/token";

export function fetchFavouriteCookbooks(stopLoading) {
  return async function(dispatch) {
    let url = `${BASE_URL}/book?Favorized=true&LangId=${getLanguage}`
    api.get(url)
      .then((data) => {
        dispatch({
          type: FAVOURITE_COOKBOOK,
          payload: data
        })
        stopLoading()
      })
      .catch(err => {
        stopLoading()
        console.log("FETCH FAVOURITE COOKBOOKS ERROR", err)
      })
  }
}

export function favoriteCookbook(item, updateCookbook) {
  return async function(dispatch) {
    let url = `${BASE_URL}/book/${item.Id}/favorize?LangId=${getLanguage}`
    api.patch(url)
      .then((data) => {
        updateCookbook(true)
        dispatch({
          type: ADD_COOKBOOK,
          payload: item
        })
      })
      .catch(err => {
        console.log("FAVORITE COOKBOOK ERROR", err)
      })
  }
}

export function unFavoriteCookbook(item, updateCookbook) {
  return async function(dispatch) {
    let url = `${BASE_URL}/book/${item.Id}/unfavorize?LangId=${getLanguage}`

    api.patch(url)
      .then((data) => {
        updateCookbook(false)

        dispatch({
          type: REMOVE_COOKBOOK,
          payload: item
        })
      })
      .catch(err => {
        console.log("UNFAVORITE COOKBOOK ERROR", err)
      })
  }
}

export function fetchFavouriteRecipes(stopLoading) {
  return async function(dispatch) {
    let url = `${BASE_URL}/recipe?Favorized=true&LangId=${getLanguage}`
    api.get(url)
      .then((data) => {
        dispatch({
          type: FAVOURITE_RECIPE,
          payload: data
        })
        stopLoading()
      })
      .catch(err => {
        stopLoading()
        console.log("FETCH FAVORITE RECIPES ERROR", err)
      })
  }
}

export function favoriteRecipe(item, updateRecipe) {
  return async function(dispatch) {
    let url = `${BASE_URL}/recipe/${item.Id}/favorize?LangId=${getLanguage}`
    api.patch((url), {
      headers: new Headers({
        'Authorization': 'Bearer '+ getToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }).then((data) => {
      updateRecipe(true)
        // dispatch({
        //   type: ADD_RECIPE,
        //   payload: item
        // })
      })
      .catch(err => {
        console.log("FAVORITE RECIPE ERROR", err)
      })
  }
}

export function unFavoriteRecipe(item, updateRecipe) {
  return async function(dispatch) {
    let url = `${BASE_URL}/recipe/${item.Id}/unfavorize?LangId=${getLanguage}`
    api.patch(url)
      .then((data) => {
        updateRecipe(false)
        dispatch({
          type: REMOVE_RECIPE,
          payload: item
        })
      })
      .catch(err => {
        console.log("UNFAVORITE RECIPE ERROR", err)
      })
  }
}
