import React
  from 'react'

// import Slider
//   from '../../components/explore/Slider';

import NewSlider
  from '../../components/explore/NewSlider';

import { userSignedIn }
  from '../../actions/auth'

import { fetchTags }
  from '../../actions/tags'

import CookBooks
  from '../../components/cookbooks/index/CookBooks'

import Recipes
  from '../../components/recipes/Recipes'

import GetApp
  from '../../components/explore/GetApp';

import { connect }
  from 'react-redux';

import { getTranslate }
  from 'react-localize-redux';

import { changeLanguage }
  from '../../actions/language'

import { storeLanguage }
  from '../../services/token';

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callSlider: false,
    };
  }

  componentDidMount(){
    let _this = this
    _this.setState({callSlider: true})

    this.props.fetchTags()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.me !== newProps.me) {
      this.props.changeLanguage(newProps.me.Language == 0 ? "de" : "en")
      storeLanguage(newProps.me.Language == 0 ? "de" : "en")
    }
  }

  render(){
    if(!this.state.callSlider) {

    }

    return(
      <>
        {<NewSlider />}
        {/*<Slider />*/}
        <CookBooks />
        {<Recipes />}
        <GetApp />
      </>
    )
  }
}

function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}
export default connect(mapStateToProps, { userSignedIn, fetchTags, changeLanguage })(Explore);
