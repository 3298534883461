import React from 'react';

export default class RateModal extends React.Component {

render() {
    return(
        <div className="modal blk-modal fade" id="rate" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-body">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <div className="blk-modal-content">
                        <h1>Rate recipe</h1>
                        <p>Tap a star to rate this recipe</p>
                        <div className="content-rate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 12 12"><path fill="#F5BD00" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 12 12"><path fill="#7A7A7A" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 12 12"><path fill="#7A7A7A" fillRule="nonzero" d="M3.017 11.742a.978.978 0 0 1-1.312-1.022 9.564 9.564 0 0 1 .8-3.143A13.975 13.975 0 0 1 .219 5.248a.978.978 0 0 1 .558-1.572c.992-.198 2.002-.299 3.014-.3A9.073 9.073 0 0 1 5.202.4.978.978 0 0 1 6.78.4a9.165 9.165 0 0 1 1.414 2.974c1.018 0 2.033.101 3.03.301a.978.978 0 0 1 .564 1.564 12.777 12.777 0 0 1-2.313 2.341c.431.992.7 2.046.794 3.123a.978.978 0 0 1-1.314 1.03 11.099 11.099 0 0 1-2.962-1.58 10.827 10.827 0 0 1-2.977 1.588z"></path></svg>
                        </div>
                        <div className="blk-modal-input">
                            <textarea className="form-control" name="message" id="message" placeholder="Leave a comment"></textarea>
                        </div>
                        <div className="blk-button">
                            <a href={undefined}  className="button">Submit</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )}
}
