import React, { Component }
  from "react";
import { ArrowDownIcon }
  from "../../../assets/icons";
import CATEGORIES
  from '../../../constants/categories'
import { fetchAuthors }
  from '../../../helpers/author';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';
import { All }
  from '../../../assets/img/categories';
import PureImage
  from '../../../components/shared/PureImage'

class Authors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: CATEGORIES,
      selectAuthor: null,
      selectedColor: "#ff5f00",
      noSelectedColor: "#7a7a7a",
      authors: null,
      opened: false,
    };
  }

  componentDidMount() {
    fetchAuthors(this.setAuthors.bind(this))
  }

  UNSAFE_componentWillReceiveProps(newProps){
    let selectAuthor = null

    if(this.state.authors) {
      if(newProps.preDefineAuthorId) {
        selectAuthor = this.state.authors.filter(function(value) {
          return value.Id == newProps.preDefineAuthorId
        })
      }

      if(selectAuthor !== null) {
        this.setState({selectAuthor: selectAuthor[0]})
      }
    }
  }

  method_p() {
    let selectAuthor = null
    let { preDefineAuthorId } = this.props

    if(preDefineAuthorId && preDefineAuthorId != "undefined") {
      selectAuthor = this.state.authors.filter(function(value) {
        return value.Id == preDefineAuthorId
      })

      if(selectAuthor !== null) {
        this.setState({selectAuthor: selectAuthor[0]})
      }
    }
  }

  setAuthors(data) {
    let costum_data = data.filter(function(value) { return value.CookbookLength != 0 })
    this.setState({authors: costum_data})
    this.method_p()
    window.initialSelects()
  }

  truncate(str) {
    if(str != null) {
      return (
        str.length > 10 ? str.substring(0, 10) + "..." : str
      )
    } else {
      return (
        "Not description"
      )
    }
  }

  displayCategories() {
    let { authors,  selectAuthor } = this.state

    return (
      authors.map((author) => {
        return (
          <div
            key={author.Id}
            className="col-xs-6 col-sm-6 col-md-6">
            <a
              className="category-item"
              href={undefined}
              onClick={() => this.setAuthor(author)}>
              <div className="category-img"
                style={{border: selectAuthor === author ? "1px dotted #ff5f00" : "1px dotted"}}
              >
                <PureImage
                  src={author.ImagePath}
                  className="img-responsive"
                  style={{width: "85%", borderRadius: 50}}
                />
              </div>
              <span
                className="category-title"
                style={{color: selectAuthor === author ? "#ff5f00" : ""}}>
                {this.truncate(author.Name)}
              </span>
            </a>
          </div>
        )
      })
    )
  }

  displayAllCategory() {
    let { selectAuthor } = this.state
    let allCategory = { Name: "All", Value: -1 , image: All }

    return (
      <div
        className="col-xs-6 col-sm-6 col-md-6">
        <a
          className="category-item"
          href={undefined}
          onClick={() => this.setAuthor(allCategory)}>
          <div className="category-img"
            style={{border: selectAuthor ?  selectAuthor.Name === "All" ? "1px dotted #ff5f00" : "1px dotted" : "1px dotted"}}
          >
            <img
              src={allCategory.image}
              width="80px"
              height="80px"
              alt="Category"
            />
          </div>
          <span
            className="category-title"
            style={{color: selectAuthor ? selectAuthor.Name === "All" ? "#ff5f00" : "" : ""}}>
            {"All"}
          </span>
        </a>
      </div>
    )
  }

  setAuthor(item) {
    if(this.state.selectAuthor === item) {
      this.setState({selectAuthor: null})
      this.props.changeAuthor(null)
    } else {
      this.setState({selectAuthor: item, opened:false})
      this.props.changeAuthor(item)
    }
  }

  render() {
    let { translate } = this.props
    if(this.state.authors === null) {
      return (
        <div style={{marginTop: 30, paddingLeft: "40%"}}>
          <img src={Spinner} alt="logo" width="50px"/>
        </div>
      )
    }

    return (
      <div className="dropdown-wrapp">
        <h1 className="filter-inner-title">{ translate('app.filtersInputs.author') }</h1>
        <div className={this.state.opened ? "dropdown open" : "dropdown"}>
          <button
            onClick={()=> this.setState({opened: !this.state.opened})}
            className="dropdown-toggle"
            type="button"
          >
            { this.state.selectAuthor === null ? translate('app.filtersInputs.select_author') : this.state.selectAuthor.Name}
            <ArrowDownIcon />
          </button>
          <div className="dropdown-menu"  style={{height: 360, overflow: "hidden", overflowY: "scroll" }}>
            <div className="select-wrapp">
              <div className="row">
                {this.displayAllCategory()}
                {this.displayCategories()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Authors
