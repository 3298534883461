export const storeToken = (token) => {
  localStorage.setItem('tendooToken', token);
}

export const removeToken = () => {
  localStorage.removeItem('tendooToken');
}

export const storeSelectedDepartmentIngredients = (token) => {
  localStorage.removeItem('selectedDepartmentIngredients');
}

export const storeLanguage = (lang) => {
  localStorage.setItem('language', lang);
}

export const getToken = localStorage.getItem('tendooToken')
export const getLanguage = localStorage.getItem('language') === 'de' ? 0 : 1
export const getSelectedDepartmentIngredients = localStorage.getItem('selectedDepartmentIngredients')
