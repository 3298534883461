import React
  from 'react';
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import { logOut }
  from '../../../actions/auth';
import { removeToken }
  from "../../../services/token";
import { deleteAccount }
  from '../../../helpers/user';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';

class DeleteAccountModal extends React.Component {
  state = {
    message: "",
    loading: false
  }

  async requestDeleteAccount() {
    if(this.state.message.trim().length < 1) {
      alert("Please set a message")
    } else {
      this.setState({loading: true})
      await deleteAccount(this.state.message, this.errorHandle.bind(this))
    }
  }

  async errorHandle(result){
    await removeToken()
    this.setState({loading: false})
    // this.props.logOut(this.afterLogOutScreen.bind(this))
    window.location.href = '/auth';
  }

  afterLogOutScreen(){
  }

  displayButtons() {
    let { translate } = this.props
    if(this.state.loading) {
      return (
        <div style={{marginTop: 20}}>
          <img src={Spinner} alt="logo" width="120px"/>
        </div>
      )
    } else {
      return (
        <div className="settings-delete-buttons">
          <button type="button" className="btn-cancel" data-dismiss="modal">{translate('app.modal.cancel')}</button>
          <button type="button" onClick={this.requestDeleteAccount.bind(this)} className="btn-yes">{translate('app.modal.yes')}</button>
        </div>
      )
    }
  }

  render() {
    let { translate } = this.props
    return(
      <div id="delete" className="modal fade settings-delete" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <form>
            <div className="modal-body">
              <h1>{translate('app.modal.delete_account_title')}</h1>
                <div className="blk-modal-input">
                  <textarea
                    required
                    className="form-control"
                    name="message"
                    id="message"
                    placeholder={translate('app.modal.leave_a_comment')}
                    value={this.state.message}
                    onChange={(e) => this.setState({message: e.target.value})}
                  ></textarea>
                </div>
                {this.displayButtons()}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { logOut })(DeleteAccountModal);
