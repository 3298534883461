import { storeCurrentPath, resetCurrentPath }
  from '../services/currentPath';

export function setCurrentPath(path) {
  storeCurrentPath(path)
  window.location.href = '/auth'
}

export function resetCurrentPathRedirect() {
  resetCurrentPath()
}
