import { REFRESH_CURRENT_PAGE }
  from '../constants/types';

import api
  from '../helpers/api'
import { getLanguage }
  from "../services/token";

export function refreshPage() {
  return function(dispatch) {
    dispatch({
      type: REFRESH_CURRENT_PAGE,
      payload: Math.random()
    })
  }
}
