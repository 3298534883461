import React from 'react';

class AuthImage extends React.Component {
	_isMounted = false
	constructor(props) {
		super(props);

		this.state = {
			image: null,
			error: null
		}
	}

	UNSAFE_componentWillMount() {
		this._isMounted = true;

		const { src, token} = this.props;
		if(this._isMounted) {
			this.fetchImage(src, {
		    method: 'GET',
		    headers: {
	        Authorization: `Bearer ${token}`
		    }
			})
			.then(image => this.setState({ image }))
			.catch(error => this.setState({ error }))
		}
	}

	componentWillUnmount() {
    this._isMounted = false;
  }

	fetchImage(url, headers) {
    return new Promise((resolve, reject) => {
      fetch(url, headers)
      .then(response => response.blob())
      .then(blob => {
          const objectUrl = URL.createObjectURL(blob);
          resolve(objectUrl);
      })
      .catch(err => reject(err));
    });
	}

	render() {
		let otherProps = Object.keys(this.props).filter(key => (key!='src' || key!='token'))

		if(this.state.error) {
			return <p>{this.state.error}</p>
		}

		if(this.state.image) {
			return <img src={this.state.image} style={this.props.style} />
		}

		return <p>Loading</p>;

	}
}
export default AuthImage
