import React
  from 'react'
import Profile
  from '../../../components/recipes/Profile'
import RecipeValues
  from '../../../components/recipes/RecipeValues'
import RecipeItems
  from '../../../components/recipes/RecipeItems'
import Comments
  from '../../../components/recipes/Comments'
import { fetchSingleRecipe, fetchHowTo}
  from '../../../helpers/recipe';
import { getToken }
  from "../../../services/token";
import { favoriteRecipe, unFavoriteRecipe }
  from '../../../actions/favourites';
import { unSubscribeUser, subscribeUser, authSubscribes }
  from '../../../helpers/author';
import { connect }
  from 'react-redux';
// modals import
import HowManyModal
  from "../../../components/shared/modals/HowMany";
import ToolsModal
  from "../../../components/shared/modals/ToolsModal";
import CompleteVideoModal
  from "../../../components/shared/modals/CompleteVideoModal";
import IngredientsModal
  from "../../../components/shared/modals/IngredientsModal";
import { getTranslate }
  from 'react-localize-redux';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';
import SuccessMessage
  from '../../../components/shared/SuccessMessage';
import ReactGA
  from 'react-ga';

class RecipeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipe: null,
      fetched:false,
      howTo:[],
      IsSubscribed:false,
      favouriteLike: false,
      numOfPeople: 2,
      numOfPeopleIngra: 2,
      titleSuccess: "Ingredient successful",
      descriptionSuccess: "5 Ingredients in your shopping list",
      successMessage: false,
      currentPreVideo: null,
      currentPreVideoTime: null,
      startsAt:0,
      fullScreenFromProfile:false,
      stepByStepVideo: false,
      defineVideoSection: false,
      ratings: 1,
      average: 0
    };
  }

  componentDidMount(){
    window.initialModals()
    window.slider()
    window.scrollTo(0, 0);
    let id = this.props.location.pathname.substring('/recipes/'.length)
    fetchSingleRecipe(getToken, id, this.saveRecipeData.bind(this))
    fetchHowTo(id, this.howToData.bind(this))

    ReactGA.event({
      category: 'Recipe navigation',
      action: `Go to: ${window.location.pathname}`
    });
  }

  incrementNumberOfPeople() {
    this.setState({numOfPeople: this.state.numOfPeople + 1})
  }

  decrementNumberOfPeople() {
    this.setState({numOfPeople: this.state.numOfPeople === 1 ? this.state.numOfPeople : this.state.numOfPeople - 1})
  }

  incrementNumberOfPeopleIngra() {
    this.setState({numOfPeopleIngra: this.state.numOfPeopleIngra + 1})
  }

  decrementNumberOfPeopleIngra() {
    this.setState({numOfPeopleIngra: this.state.numOfPeopleIngra === 1 ? this.state.numOfPeopleIngra : this.state.numOfPeopleIngra - 1})
  }

  howToData(data){
    this.setState({
      fetched: true,
      howTo: data
    })
  }

  componentWillUpdate(nextProps, nextState) {
    if(nextProps.match !== this.props.match) {
      let newId = nextProps.match.params.id
      fetchSingleRecipe(getToken, newId, this.saveRecipeData.bind(this))
    }
  }

  async saveRecipeData(data){
    let aid = data.Author && data.Author.Id
    if(getToken) {
      authSubscribes(getToken, aid, this.saveAuthSubscribes.bind(this))
    }

    await this.setState({
      recipe: data,
      loading: false,
      ratings: data.Rating && data.Rating.TotalRatings ? data.Rating.TotalRatings : 0 ,
      average: data.Rating && data.Rating.GlobalRating ? data.Rating.GlobalRating : 0 
    })
  }

  updateRecipe(val){
    this.setState({
      favouriteLike: false,
      recipe: {
        ...this.state.recipe,
        IsFavorized: val
      }
    })
  }

  favoriteOnPress(){
    let { recipe } = this.state
    this.setState({favouriteLike: true})
    if(recipe.IsFavorized) {
      this.props.unFavoriteRecipe(recipe, this.updateRecipe.bind(this))
    } else {
      this.props.favoriteRecipe(recipe, this.updateRecipe.bind(this))
    }
  }

  saveAuthSubscribes(data){
    this.setState({
      ...data,
    })
  }

  onUnSubscribe(authorId){
    let { translate } = this.props
    this.setState({
      IsSubscribed:false
    })

    unSubscribeUser(getToken, authorId, this.userSubscribed.bind(this))
    this.setMessage("Unsubscribe", translate('app.recipeDetails.unsubscribe_success_message'))
  }

  onSubscribe(authorId){
    let { translate } = this.props
    this.setState({
      IsSubscribed:true
    })
    subscribeUser(getToken, authorId, this.userSubscribed.bind(this))
    this.setMessage("Subscribe", translate('app.recipeDetails.subscribe_success_message'))
  }

  userSubscribed(data){
    this.setState({
      ...data,
    })
  }

  setMessage(title, description) {
    let _this = this
    this.setState({
      titleSuccess: title,
      descriptionSuccess: description,
      successMessage: true
    }, () => {
      setTimeout(function() {
        _this.setState({
          titleSuccess: "",
          descriptionSuccess: "description",
          successMessage: false
        })
      }, 4500);
    })
  }

  setStepByStepVideo() {
    this.setState({stepByStepVideo: true})
    document.body.classList.add('video-fullscreen-body')
  }

  setVideoParams() {
    this.setState({defineVideoSection: true})
  }

  render(){
    const recipe = this.state.recipe
    if(this.state.loading) {
      return (
        <div style={{paddingLeft: "45%", paddingBottom: "10%",paddingTop: "20%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }

    if(!recipe) {
      return (
        <div style={{paddingLeft: "45%", paddingBottom: "10%",paddingTop: "20%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }

    let aid = recipe.Author && recipe.Author.Id
    let Rating = recipe.Rating || {}
    let { translate } = this.props
    let recipeId = this.props.location.pathname.substring('/recipes/'.length)

    return(
      <>
        <Profile
          recipe={recipe}
          title={recipe.Name}
          ratings={this.state.ratings}
          average={this.state.average}
          views={recipe.Views}
          favourite={recipe.IsFavorized}
          authImage={recipe.Author && recipe.Author.ImagePath }
          coverImage={recipe.CoverPath}
          isSubscribed={this.state.IsSubscribed}
          author={recipe.Author && recipe.Author.Name}
          likePress={() => this.favoriteOnPress()}
          subscribe={()=> this.state.IsSubscribed ? this.onUnSubscribe(aid) : this.onSubscribe(aid)}
          videoLink={recipe.Videos && recipe.Videos.Main != undefined ? recipe.Videos.Main.Mp4FilePath : null}
          translate={translate}
          favouriteLike={this.state.favouriteLike}
          match={this.props.match}
          setMessage={(title, description) => this.setMessage(title, description)}
          rating={Rating.UserRating || 0}
          changeTimeAndVideo={(time, currentPreVideo, startsAt)=> this.setState({currentPreVideoTime: time, currentPreVideo: currentPreVideo, startsAt})}
          fullScreenClick={()=> this.setState({fullScreenFromProfile:!this.state.fullScreenFromProfile})}
          stepByStepVideo={this.state.stepByStepVideo}
          defineVideoSection={this.state.defineVideoSection}
          numOfPeople={this.state.numOfPeople}
        />

        <RecipeValues
          kcal={recipe.Nutrition && recipe.Nutrition.Calorie}
          protein={recipe.Nutrition && recipe.Nutrition.Protein}
          fat={recipe.Nutrition && recipe.Nutrition.Fat}
          carbos={recipe.Nutrition && recipe.Nutrition.Carbohydrate}
          activeTime={recipe.CookTime}
          totalTime={recipe.TotalTime}
          translate={translate}
        />
        { recipe.Ingredients.length > 0 &&
          <RecipeItems
            recipeId={recipe.Id}
            ingredients={recipe.Ingredients}
            image={recipe.CoverPath}
            toolImage={recipe.ToolImagePath}
            translate={translate}
            ingredientImage={recipe.IngredientImagePath}
            numOfPeopleIngra={this.state.numOfPeopleIngra}
            incrementNumberOfPeopleIngra={this.incrementNumberOfPeopleIngra.bind(this)}
            decrementNumberOfPeopleIngra={this.decrementNumberOfPeopleIngra.bind(this)}
          />
        }
        {/* recipe.Ingredients.length > 0 &&
          <RecipeSlider
            recipeId={recipe.Id}
            ingredients={recipe.Ingredients}
            image={recipe.CoverPath}
            toolImage={recipe.ToolImagePath}
            translate={translate}
            ingredientImage={recipe.IngredientImagePath}
          />
        */}

        { /*
          <Rate
            recipe={recipe}
            translate={translate}
            rating={Rating.UserRating || 0}
          />
        */ }

        <Comments
          recipe={recipe}
          commentsNumber={5}
          id={recipeId}
          translate={translate}
          user={this.props.me}
          rating={Rating.UserRating || 0}
          setAverage={(totalRatings, globalRating) => this.setState({ratings: totalRatings, average: globalRating})}
        />

        <HowManyModal
          incrementNumberOfPeople={this.incrementNumberOfPeople.bind(this)}
          decrementNumberOfPeople={this.decrementNumberOfPeople.bind(this)}
          numOfPeople={this.state.numOfPeople}
          recipe={recipe}
          translate={translate}
          setVideoParams={() => this.setVideoParams()}
          setStepByStepVideo={() => this.setStepByStepVideo()}
        />

        {/*
          <VideoModal
            recipe={this.state.recipe}
            numOfPeople={this.state.numOfPeople}
            translate={translate}
            currentPreVideo={this.state.currentPreVideo}
            currentPreVideoTime={this.state.currentPreVideoTime}
            resetTimeAndVideo={()=> this.setState({currentPreVideoTime: null, currentPreVideo: null})}
          />
        */}

        <CompleteVideoModal
          fullScreen={this.state.fullScreenFromProfile}
          recipe={this.state.recipe}
          numOfPeople={this.state.numOfPeople}
        />

        <IngredientsModal
          recipeId={this.state.recipe.Id}
          translate={translate}
          numOfPeople={this.state.numOfPeopleIngra}
        />

        <ToolsModal
          translate={translate}
        />

        { this.state.successMessage &&
          <SuccessMessage
            titleSuccess={this.state.titleSuccess}
            descriptionSuccess={this.state.descriptionSuccess}
          />
        }
      </>
    )
  }
}

function mapStateToProps(state){
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps,
  { favoriteRecipe, unFavoriteRecipe })
  ( RecipeDetails );
