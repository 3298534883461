import React
  from "react";
import { CloseIcon }
  from "../../../assets/icons";
import { CategoryItems, RangeSlider, IncludeIngredients, ExcludeIngredients }
  from "./index.js"
import { getIngredients }
  from '../../../helpers/filters';
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import { convertHHMMSS }
 from "../../../services/converts";

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRangeValue: props.timeRangeValue,
      caloriesRangeValue: props.caloriesRangeValue,
      ingredients: [],
      includeIngredients: [],
      prepareIncludeIngredients: props.prepareIncludeIngredients,
      prepareExcludeIngredients: props.prepareExcludeIngredients,
      excludeIngredients: [],
      selectedCategories: [-1]
    };
  }

  componentDidMount() {
    window.initialSelects()
    getIngredients(this.addIngredients.bind(this))
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({caloriesRangeValue: parseInt(newProps.caloriesRangeValue)})
    this.setState({timeRangeValue: parseInt(newProps.timeRangeValue)})
    if(this.props.selectedCategorie !== newProps.selectedCategories) {
      this.setState({selectedCategories: newProps.selectedCategories })
    }
  }

  async addIngredients(data) {

    this.setState({ingredients: data})

    if(this.props.prepareIncludeIngredients.length > 0 ) {
      let incIng = []
      let checkIng = ""
      await this.props.prepareIncludeIngredients.forEach((includeIng, index) => {
        checkIng = this.state.ingredients.filter(function(value) {
          return value.Id === includeIng
        })

        incIng.push(checkIng[0])
      })

      this.setState({includeIngredients: incIng})
    }

    if(this.props.prepareExcludeIngredients.length > 0 ) {
      let exclIng = []
      let checkIng = ""
      await this.props.prepareExcludeIngredients.forEach((includeIng, index) => {
        checkIng = this.state.ingredients.filter(function(value) {
          return value.Id === includeIng
        })

        exclIng.push(checkIng[0])
      })

      this.setState({excludeIngredients: exclIng})
    }
  }

  changeTimeRangeValue(value) {
    this.setState({timeRangeValue: value})
    this.props.changeTimeValue(value)
  }

  changeCaloriesRangeValue(value) {
    this.setState({caloriesRangeValue: value})
  }

  changeCategories(selectedCategories) {
    this.setState({selectedCategories: selectedCategories})
    this.props.changeCategories(selectedCategories)
  }

  changeIncludeIngredients(selectedIncludeIngredients) {
    this.setState({includeIngredients: selectedIncludeIngredients})
  }

  changeExcludeIngredients(selectExcludeIngredients) {
    this.setState({excludeIngredients: selectExcludeIngredients})
  }

  requestApplyFilters() {
    this.props.applyFilters(this.state)
  }

  clearFilters() {
    this.props.clearFilters()
  }

  render() {
    let { translate } = this.props
    return (
      <section className="filters">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="filters-head">
                    <div className="filters-title">
                      <h1>{ translate('app.filtersInputs.filters') }</h1>
                      <a
                        href={undefined}
                        className="filter-on"
                        style={{display: this.props.listingFilters ? '' : 'none'}}
                        onClick={this.clearFilters.bind(this)}
                      >
                        { translate('app.filtersInputs.clear_all') }
                        <CloseIcon />
                      </a>
                    </div>
                    <div className="filters-button">
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.requestApplyFilters()}
                      >
                        { translate('app.filtersInputs.apply') }
                      </button>
                    </div>
                </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <RangeSlider
                label={ translate('app.filtersInputs.max_time') }
                upTo={ translate('app.filtersInputs.up_to') }
                type={ "min" }
                min={0}
                max={180}
                value={this.state.timeRangeValue}
                changeMainValue={this.changeTimeRangeValue.bind(this)}
                timeMode
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <RangeSlider
                label={ translate('app.filtersInputs.max_calories') }
                upTo={ translate('app.filtersInputs.up_to') }
                type={ "kcal" }
                min={0}
                max={300}
                value={this.state.caloriesRangeValue}
                changeMainValue={this.changeCaloriesRangeValue.bind(this)}
              />
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <IncludeIngredients
                includeIngredients={this.state.includeIngredients}
                ingredients={this.state.ingredients}
                changeIncludeIngredients={(selectedIncludeIngredients) => this.changeIncludeIngredients(selectedIncludeIngredients)}
                translate={translate}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <ExcludeIngredients
                excludeIngredients={this.state.excludeIngredients}
                ingredients={this.state.ingredients}
                changeExcludeIngredients={(selectExcludeIngredients) => this.changeExcludeIngredients(selectExcludeIngredients)}
                translate={translate}
              />
            </div>
            {/*<div className="col-xs-12 col-sm-6 col-md-3">
              <Categories
                selectedCategories={this.state.selectedCategories}
                changeCategories={(selectedCategories) => this.changeCategories(selectedCategories)}
                translate={translate}
              />
            </div>*/}
          </div>
          <CategoryItems
            selectedCategories={this.state.selectedCategories}
            changeCategories={(selectedCategories) => this.changeCategories(selectedCategories)}
            translate={translate}
          />
        </div>
      </section>
    );
  }

  changeCategories(selectedCategories) {
    this.setState({selectedCategories: selectedCategories})
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(Filters)
