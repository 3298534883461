import React
  from "react";

import terms
  from '../inside/terms.json'

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
     return (
       <div className="row">
          <section className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="page-content-inner">
                            <h1>Terms of use</h1>
                            <h2>Disclaimer</h2>
                            <p>
                            Last updated: 2.11.2019 <br/>
                            The information contained on the www.tendoo.com website and tendoo mobile app (collectively the “Service”)
                            is for general information purposes only. <br/><br/>
                            Content is uploaded by third parties – Tendoo AG assumes no responsibility for errors or omissions in the
                            contents on the Service. In no event shall tendoo be liable for any special, direct, indirect, consequential,
                            or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other sort,
                            arising out of or in connection with the use of the Service or the contents of the Service.<br/><br/>
                            Every effort is made to keep our Service is up and running smoothly and that our websites are free from viruses
                            and malware. However, tendoo takes no responsibility for, and will not be liable for, our Service being temporarily
                             unavailable due to technical issues beyond our control, or any virus and/or malware that may be downloaded from our Service.<br/><br/>
                            Tendoo reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice.<br/><br/>
                            All materials on these pages are copyrighted by tendoo or our third-party provider. All rights reserved. No part of these pages,
                            either text, image or video, may be used in any form or by any means, electronic, mechanical or otherwise, without prior written
                             permission.
                            </p>
                            {terms.map((item,index)=>{
                              return  <div key={index}>
                                {item.h2
                                ?
                                <h3>{item.h2}</h3>
                                :
                                <h2>{item.h1}</h2>
                                }
                                <p>{item.description}</p>
                            </div>
                          })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </div>
     )
  }
};

export default Terms
