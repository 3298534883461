import React from 'react';

const Breakfast = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color} stroke="none">
        <path d="M675 1209 c-49 -14 -101 -65 -161 -157 -77 -118 -87 -128 -177 -173
        -126 -64 -187 -130 -187 -204 0 -50 22 -89 98 -170 38 -41 75 -93 89 -125 53
        -122 64 -141 100 -180 53 -60 104 -83 193 -87 61 -4 82 -1 113 15 l37 20 71
        -34 c39 -19 74 -34 78 -34 5 0 11 19 14 42 3 24 11 44 17 46 19 7 50 79 56
        135 l7 55 61 12 c79 15 112 33 136 73 36 59 22 145 -34 205 -17 19 -21 36 -21
        91 0 62 3 73 39 127 49 73 59 135 28 175 -34 43 -132 89 -190 89 l-49 0 -5
        -51 c-4 -33 -12 -58 -26 -71 l-20 -21 -12 44 c-39 144 -138 213 -255 178z
        m136 -51 c21 -14 45 -46 64 -83 29 -58 30 -66 33 -218 l4 -158 -40 -52 c-72
        -95 -77 -106 -83 -197 -4 -72 -10 -91 -33 -125 -25 -36 -28 -46 -22 -102 l5
        -62 -36 -12 c-45 -15 -120 -6 -171 20 -58 30 -105 90 -146 190 -30 73 -51 104
        -115 175 -55 60 -81 96 -85 120 -13 68 41 129 172 195 89 45 109 65 187 184
        71 109 119 147 185 147 32 0 58 -7 81 -22z m257 -91 c2 -20 -7 -44 -32 -80
        -50 -73 -66 -127 -66 -217 0 -92 -15 -132 -65 -180 -54 -50 -68 -89 -60 -165
        8 -67 -4 -103 -44 -139 -14 -13 -21 -32 -22 -60 0 -32 -2 -37 -9 -21 -15 36
        -11 61 20 106 26 39 30 52 30 120 0 72 2 79 39 135 22 32 49 70 60 84 18 22
        21 39 21 153 l0 129 30 30 c40 40 50 60 50 103 0 32 3 36 23 33 16 -2 23 -11
        25 -31z m62 -7 c15 -28 3 -84 -33 -152 -25 -49 -30 -71 -36 -171 -8 -122 -13
        -137 -63 -163 -15 -8 -41 -38 -58 -67 -26 -45 -30 -62 -30 -127 0 -65 -4 -82
        -30 -125 -16 -27 -30 -60 -30 -72 0 -25 -10 -29 -28 -11 -19 19 -14 73 9 94
        38 35 49 69 49 159 l0 85 54 59 c44 48 56 69 66 114 6 31 8 71 5 91 -9 48 17
        131 60 191 23 33 35 61 35 82 0 37 14 43 30 13z m78 -51 c16 -32 9 -59 -36
        -126 -36 -54 -37 -59 -43 -177 -4 -66 -12 -138 -19 -159 -10 -34 -44 -77 -61
        -77 -4 0 -21 -12 -38 -26 -30 -25 -31 -29 -31 -100 0 -77 -13 -117 -51 -155
        -10 -10 -19 -28 -19 -40 0 -26 -24 -20 -28 8 -2 11 13 51 33 88 33 63 35 72
        29 125 -6 51 -3 64 22 110 15 28 40 58 54 65 55 29 63 48 71 171 6 103 10 123
        38 176 17 33 34 79 38 104 6 48 20 53 41 13z"/>
        <path d="M475 806 c-50 -19 -112 -87 -121 -133 -38 -200 181 -323 329 -185 34
        32 59 113 51 162 -22 121 -147 196 -259 156z m145 -47 c98 -55 107 -195 16
        -264 -78 -59 -186 -34 -234 56 -74 139 79 286 218 208z"/>
      </g>
    </svg>
  )
}

export default Breakfast
