import React
  from 'react';
import PureImage
  from '../../components/shared/PureImage';
import { PlayIcon, Play1Icon, PauseIcon  }
  from '../../assets/icons/AppIcons';
import SvgImage
  from '../../components/shared/SvgImage';
import { getVideoActions }
  from '../../helpers/videos';
import VideoPreview
  from '../../components/shared/recipes/VideoPreview';
import { isSafari }
  from 'react-device-detect';
import { Player, ControlBar }
  from 'video-react';
import Spinner
  from '../../assets/img/eclipse-spinner.gif';
import { connect }
  from 'react-redux';
import { isMobile, isTablet }
  from 'react-device-detect';
import jQuery from 'jquery';
var $ = jQuery
const REGEX = /(<([^>]+)>)/ig;

class SmallSequenceVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playVideo: true,
      autoPlay: true,
      videoActions: [],
      actionMillis: [],
      currentVideo: 0,
      currentTime: 0,
      currentVideoTime: 0,
      currentPreVideo: null,
      currentPreVideoTime: null,
      userActions: false,
      videoText: null,
      fullScreen: true,
      autoStop: true,
      muted: false,
      scrollIdx: 0,
      displayMoreFuc: false,
      moreIconShow: false,
      showSubtitles: true,
      loadingAll: true,
      showSequences: false,
      pauseLine: false
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.setMuted = this.setMuted.bind(this);
  }

  componentDidMount() {
    if(this.props.recipe && this.props.recipe.Videos) {
      this.setState({video: this.props.recipe.Videos.Main, coverPath: this.props.recipe.CoverPath})
      if(this.props.recipe.Videos.Main) {
        let videoId = this.props.recipe.Videos.Main.Id
        this.setState({video: this.props.recipe.Videos.Main, coverPath: this.props.recipe.CoverPath})
        getVideoActions(videoId, this.props.numOfPeople, this.saveVideoActions.bind(this))
      }
    }

    document.addEventListener("keydown", this.escFunction.bind(this), false);
  }

  UNSAFE_componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event){
    let { pauseLine } = this.state
    if(event.keyCode === 27 && this.state.fullScreen) {
      if(this.state.fullScreen) {
        document.body.classList.remove('video-fullscreen-body')
      } else {
        document.body.classList.add('video-fullscreen-body')
      }
      this.setState({fullScreen: false})
    }

    if (event.keyCode === 37) {
      let preview_index = this.state.currentVideo - 1
      if(preview_index >= 0 ) {
        let video = this.state.videoActions[preview_index]
        let startsAt = video.StartsAt.split(':')
        let currentVideoTime = parseInt(this.milliseconds(...video.StartsAt.split(':')) + 1)
        this.changeTimeAndVideo(currentVideoTime, preview_index)
      }
    }

    if (event.keyCode === 39) {
      this.goToNextSequence()
    }

    if (event.keyCode === 32) {
      if(this.player) {
        if(!pauseLine) {
          this.state.playVideo ? this.pause() : this.play()
        } else {
          this.setState({pauseLine: false})
          this.goToNextSequence()
        }
      }
    }
  }

  goToNextSequence() {
    let next_index = this.state.currentVideo + 1
    if(next_index < this.state.videoActions.length) {
      let video = this.state.videoActions[next_index]
      let startsAt = video.StartsAt.split(':')
      let currentVideoTime = parseInt(this.milliseconds(...video.StartsAt.split(':')) + 1)
      this.changeTimeAndVideo(currentVideoTime, next_index)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.player) {
      if(this.state.currentVideo !== prevState.currentVideo) {
        this.scrollToItem(this.state.currentVideo)
      }
      this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }
  }

  handleStateChange() {
    let { videoActions } = this.state
    const { player } = this.player.getState();
    if (this.state.currentTime !== parseInt(player.currentTime)) {
      this.setState({currentTime: parseInt(player.currentTime)})
      this.scrollToItem(this.state.currentVideo)
    }

    if(player.ended) {
      if(this.state.playVideo) {
        this.setState({playVideo: false, userActions: true})
      }
    }

    if(parseInt(player.currentTime) === 0 && this.state.actionMillis.length > 0) {
      this.setState({
        currentVideo: 0
      })
    } else {
      let seconds = player.currentTime
      videoActions.some((act, idx) => {
        let actStartsAt = this.milliseconds(...act.StartsAt.split(':'))
        if( seconds >= actStartsAt - 0.4 && ( !videoActions[idx+1] || seconds <= this.milliseconds(...videoActions[idx+1].StartsAt.split(':')) - 0.4 ) ) {
          if(this.state.currentVideo != idx ) {
            if(this.state.autoStop && this.state.playVideo != false) {
              this.setState({playVideo: false, showSubtitles: false, pauseLine: true})
              this.pause()
            }
          }
          // this.setState({
          //   currentVideo: idx
          // })
        }
      })
    }
  }

  async UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.recipe != newProps.recipe || this.props.numOfPeople != newProps.numOfPeople) {
      if(newProps.recipe.Videos.Main) {
        let videoId = newProps.recipe.Videos.Main.Id
        await this.setState({video: newProps.recipe.Videos.Main, coverPath: newProps.recipe.CoverPath})
        getVideoActions(videoId, this.props.numOfPeople, this.saveVideoActions.bind(this))
      }
    }
 }

  play() {
    let _this = this
    this.player.play();

    this.setState({showSequences: true, playVideo: true, userActions: false})
    setTimeout( function() {
      _this.setState({showSequences: false, showSubtitles: true})
    }, 500);
  }

  pause() {
    this.setState({playVideo: false, userActions: true, showSequences: true})
    this.player.pause();
  }

  load(time) {
    let _this = this
    if(!this.state.autoStop) {
      _this.setState({playVideo: true, autoPlay: true, currentTime: time, showSubtitles: true})
    } else {
      this.setState({playVideo: true, currentTime: time, showSubtitles: true})
    }
    _this.player.load()
  }

  toggleFullscreen() {
    this.player.handleFullscreenChange();
  }

  setMuted(muted) {
    this.player.muted = muted;
  }

  saveSubtitles(action_with_type){
    this.setState({subtitlesObj: action_with_type})
  }

  saveVideoActions(actions){
    let costum_actions = actions.filter((act) => { return act.Type == 0 })
    let action_with_type = actions.filter((act) => { return act.Type == 1 })
    let subtitle = action_with_type.length > 0 ? action_with_type[0].Text : null

    let actions_with_subtitles = []
    let video_subtitles = []

    actions.map((act, index) => {
      if(act.Type) {
        let startsAt = act.StartsAt.split(':')
        let stopsAt = act.StopsAt.split(':')

        video_subtitles.push({startsAt: this.milliseconds(...act.StartsAt.split(':')),
                              stopsAt: this.milliseconds(...act.StopsAt.split(':')),
                              text: act.Text})
      } else {
        if(act.StartsAt){
          let startsAt = act.StartsAt.split(':')
          actions_with_subtitles.push([this.milliseconds(...act.StartsAt.split(':')) ])
        } else {
          actions_with_subtitles.push(act)
        }
      }
    })

    this.setState({
      loadingAll: false,
      subtitles: video_subtitles,
      videoActions: costum_actions,
      actionMillis: actions_with_subtitles
    })
  }

  milliseconds(h, m, s){
    return parseInt(h)*60*60+parseInt(m)*60+parseFloat(s)
  }

  sequenceImage(part) {
    if(part.ThumbPath) {
      return (
        <PureImage src={part.ThumbPath} />
      )
    } else {
      return (
        <PureImage src={this.state.coverPath} />
      )
    }
  }

  scrollToItem(idx) {
    if(this.refs[idx] && idx !== 0) {
     // this.refs[idx].scrollIntoView({block: 'end', behavior: 'smooth', inline: "center"});
    }
  }

  displaySequence() {
    let { videoActions } = this.state

    return (
      videoActions.map((part, idx) => {
        let currentVideoTime = parseInt(this.milliseconds(...part.StartsAt.split(':')))
        if(idx != 0) {
          currentVideoTime = parseInt(this.milliseconds(...part.StartsAt.split(':')) + 1)
        }

        return (
          <li key={idx} ref={idx}>
            <div
              className={`item ${ this.state.currentVideo === idx ? 'active' : ''}`}
              onClick={() => this.changeTimeAndVideo(currentVideoTime, idx)}>
              <div className="item-overlay"></div>
              {this.sequenceImage(part)}
              <div className="slider-number">
                <span>{idx + 1}/{videoActions.length}</span>
              </div>
            </div>
          </li>
        )
      })
    )
  }

  changeTimeAndVideo(time, currentVideo) {
    this.setState({currentVideoTime: time, currentVideo: currentVideo})
    this.player.seek(time)
    this.play()
  }

  changeVideo(currentVideo) {
    this.setState({currentVideo: currentVideo})
  }

  displayVideoPlayer() {
    if(!this.state.loadingAll) {
      if(this.state.video) {
        return (
          <Player
            src={this.state.video.Mp4FilePath}
            ref={player => {
              this.player = player;
            }}
            autoPlay={this.state.autoPlay}
            fluid={false}
            height={"100%"}
            width={"auto"}
            startTime={this.state.currentVideoTime}
            >
            <ControlBar disableCompletely={true} className="my-class" />
          </Player>
        )
      }
    } else {
      return (
        <img src={Spinner} alt="logo" width="120px"/>
      )
    }
  }

  triggerEnterMouse() {
    if(!this.state.fullScreen && !this.state.playVideo) {
      this.setState({userActions: true})
    }
  }

  triggerClickMouse(e) {
    this.triggerPlayVideo(e)
  }

  triggerPlayVideo(e) {
    let { pauseLine } = this.state
    if(e.target.id != "Capa_1" && e.target.id != "show_more_dialog" && e.target.id != "switch-item" && e.target.id != "Capa_full") {
      if(!pauseLine) {
        this.state.playVideo ? this.pause() : this.play()
      } else {
        this.setState({pauseLine: false})
        this.goToNextSequence()
      }
    }
  }

  triggerFullScreen() {
    if(this.state.fullScreen) {
      document.body.classList.remove('video-fullscreen-body')
    } else {
      document.body.classList.add('video-fullscreen-body')
    }
    this.setState({fullScreen: !this.state.fullScreen})
  }

  toggleChange = () => {
    this.setState({
      autoStop: !this.state.autoStop,
    });
  }

  changeSetMuted = () => {
    this.setState({
      muted: !this.state.muted,
    });

    this.setMuted(!this.state.muted)
  }

  displaySubtitles() {
    let subtitle = []

    if(this.player && this.state.subtitles) {
      let currentTime = this.player.getState().player.currentTime
      subtitle = this.state.subtitles.filter((sub) => { return sub.startsAt < currentTime && sub.stopsAt > currentTime })
    }

    if(subtitle.length > 0) {
      return (
        <div className="video-narrated">
          <p>{subtitle[0].text.replace(REGEX, '')}</p>
        </div>
      )
    }
  }

  clickOnVideo(e) {
    const element = document.querySelector(".show-more");
    if(element.classList.contains("active") && e.target.id != "Capa_1" && e.target.id != "show_more_dialog" && e.target.id != "switch-item" && e.target.id != "Capa_full") {
      $(".show-more-dialog").toggle();
      $(".video-narrated").toggle();
      $(".show-more").toggleClass("active");
    } else if(e.target.id == "Capa_1") {
      $(".show-more-dialog").toggle();
      $(".video-narrated").toggle();
      $(".show-more").toggleClass("active");
    }
  }

  displayVideo() {
    let { translate, recipe } = this.props
    let { fullScreen } = this.state

    if(recipe.Videos.Main && recipe.Videos.Main.Mp4FilePath) {
      return (
        <div className="recipe-profile-img" onClick={(e) => this.clickOnVideo(e)}>
            <div className="recipe-profile-img-wrap">
                <div className={`${fullScreen ? 'video-container video-fullscreen ' : 'video-container'}`}
                    onMouseEnter={() => this.triggerEnterMouse()}>
                    <div className="video-container-overlay"></div>
                      {this.displayVideoPlayer()}
                      Your browser does not support HTML5 video.

                      <div id="video-controls" onClick={(e) => this.triggerClickMouse(e)}>
                        <div className="play-pause"
                          id="play-button"
                          onClick={this.play }
                          style={{display: this.state.userActions ? "flex" : "none", zIndex: 1}}>
                          <SvgImage
                            id={"pause-id"}
                            svg={Play1Icon}
                            style={{width: 25, height: 25, marginLeft: 4, objectFit: 'contain'}}
                          />
                        </div>
                          <div className="video-controls-bottom">
                              <div className="show-more">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 408 408">
                                  <g>
                                    <g id="more-vert">
                                      <path fill="#fff" d="M204,102c28.05,0,51-22.95,51-51S232.05,0,204,0s-51,22.95-51,51S175.95,102,204,102z M204,153c-28.05,0-51,22.95-51,51 s22.95,51,51,51s51-22.95,51-51S232.05,153,204,153z M204,306c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51 S232.05,306,204,306z"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="show-more-dialog" id="show_more_dialog">
                                <div className="show-more-dialog-item">
                                  <span>{translate('app.playVideo.stop_automatically')}</span>
                                  <label className="switch">
                                    <input
                                      id="switch-item"
                                      type="checkbox"
                                      onChange={this.toggleChange}
                                      checked={this.state.autoStop}
                                    />
                                  <span className="slider-switch round" id="switch-item"></span>
                                  </label>
                                </div>
                                <div className="show-more-dialog-item">
                                  <span>{translate('app.playVideo.music')}</span>
                                  <label className="switch">
                                    <input
                                      id="switch-item"
                                      type="checkbox"
                                      onChange={this.changeSetMuted}
                                      checked={!this.state.muted}
                                    />
                                    <span className="slider-switch round" id="switch-item"></span>
                                  </label>
                                </div>
                              </div>

                              <div className="full-screen active" onClick={() => this.triggerFullScreen()}>
                                <svg version="1.1" id="Capa_full" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 53 53">
                                  <g>
                                    <path fill={`${fullScreen ? '#ff5f00' : '#fff'}`} d="M52.923,0.618c-0.101-0.244-0.296-0.439-0.541-0.541C52.26,0.027,52.13,0,52,0H40c-0.552,0-1,0.448-1,1s0.448,1,1,1h9.586 L33.293,18.293c-0.391,0.391-0.391,1.023,0,1.414C33.488,19.902,33.744,20,34,20s0.512-0.098,0.707-0.293L51,3.414V13 c0,0.552,0.448,1,1,1s1-0.448,1-1V1C53,0.87,52.973,0.74,52.923,0.618z"/>
                                    <path fill={`${fullScreen ? '#ff5f00' : '#fff'}`} d="M18.293,33.293L2,49.586V40c0-0.552-0.448-1-1-1s-1,0.448-1,1v12c0,0.13,0.027,0.26,0.077,0.382 c0.101,0.244,0.296,0.439,0.541,0.541C0.74,52.973,0.87,53,1,53h12c0.552,0,1-0.448,1-1s-0.448-1-1-1H3.414l16.293-16.293 c0.391-0.391,0.391-1.023,0-1.414S18.684,32.902,18.293,33.293z"/>
                                    <path fill={`${fullScreen ? '#ff5f00' : '#fff'}`} d="M1,14c0.552,0,1-0.448,1-1V3.414l16.292,16.292c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293 c0.391-0.391,0.391-1.023,0-1.414L3.414,2H13c0.552,0,1-0.448,1-1s-0.448-1-1-1H1C0.87,0,0.74,0.027,0.618,0.077 C0.373,0.179,0.179,0.373,0.077,0.618C0.027,0.74,0,0.87,0,1v12C0,13.552,0.448,14,1,14z"/>
                                    <path fill={`${fullScreen ? '#ff5f00' : '#fff'}`} d="M52,39c-0.552,0-1,0.448-1,1v9.586L34.707,33.292c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L49.586,51H40 c-0.552,0-1,0.448-1,1s0.448,1,1,1h12c0.13,0,0.26-0.027,0.382-0.077c0.244-0.101,0.439-0.296,0.541-0.541 C52.973,52.26,53,52.13,53,52V40C53,39.448,52.552,39,52,39z"/>
                                  </g>
                                </svg>
                              </div>
                          </div>

                          {this.displaySubtitles()}
                      </div>
                      <ul className="video-list"
                          style={{display: this.state.showSequences ? "block" : "none", paddingBottom: 15 }} >
                        {this.displaySequence()}
                      </ul>
                  </div>
            </div>
        </div>
      )
    } else {
      return (
        <div className="recipe-profile-img">
          <div className="recipe-profile-img-wrap" style={{textAlign: 'center', paddingTop: "28%"}}>
            <p style={{fontSize: 25}}>{translate('app.recipeDetails.no_video_available')}</p>
          </div>
        </div>
      )
    }
  }

  mouseLeave() {
    let _this = this
    if(isSafari) {
      setTimeout( function() {
        _this.setState({playing: false, hovered: true})
      }, 1000);
    } else {
      this.setState({playing: false, hovered: true})
    }
  }

  displayShortSecVideo() {
    let { translate, recipe } = this.props
      return (
        <div className="recipe-profile-img recipe-profile-single-video">
          <div className="recipe-profile-img-wrap"
               onMouseEnter={() => this.setState({playing: true, hovered: true})}
               onMouseLeave={() => this.mouseLeave()}>
              <VideoPreview
                currentVideo={this.props.recipe.Videos.Short}
                playing={this.state.playing}
                stopPlaying={() => this.setState({playing: false})}
                hovered={this.state.hovered}
                source={recipe.CoverPath}
                noStyle={true}
              />
            </div>
            {recipe.Videos.Main != undefined &&
            <div className="recipes-play" data-tooltip="Click this element to start step by step player">
              <a href={undefined}  data-toggle="modal" data-target="#video-plp">
                <SvgImage
                  svg={PlayIcon}
                  style={{width: 18}}
                />
                <span>{translate('app.recipeDetails.step_to_step')}</span>
              </a>
            </div>
            }
        </div>
    )
  }

  renderVideoThumb(){
    if(this.props.recipe.CoverPath != undefined){
      return <PureImage src={this.props.recipe.CoverPath} />
    }
  }

  render() {
    return (
      this.props.stepByStepVideo ? this.props.defineVideoSection && this.displayVideo() :  this.displayShortSecVideo()
    )
  }
}

function mapStateToProps(state){
  return ({
            me: state.current_user
          })
}

export default connect(mapStateToProps,
  {})
  ( SmallSequenceVideo );
