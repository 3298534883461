import React
  from "react";
import { connect }
  from 'react-redux';
import { updateMeData }
  from '../../../actions/me.js';
import ReactLoading
  from 'react-loading';
import { getTranslate }
  from 'react-localize-redux';

class Measurements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      measurementValue: 0,
      saveRequest: false,
      success: null,
      loadingFetch: true
    };
  }

  componentDidMount() {
    if(this.props.me) {
      let { me } = this.props
      this.setState({
        measurementValue: me.UnitSystem,
        loadingFetch: false
      })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.me !== newProps.me) {
      let { me } = newProps
      this.setState({
        measurementValue: me.UnitSystem,
        loadingFetch: false
      })
    }
  }

  changeMeasurement(event) {
    this.setState({measurementValue: event.target.value})
  }

  displaySaveButton() {
    let { translate } = this.props
    if(this.state.saveRequest) {
       return (
         <div style={{marginLeft: "45%"}}>
           <ReactLoading
             type={"spokes"}
             color={"#000000"}
             height={30} width={30}
           />
         </div>
       )
     } else {
      return (
        <a
          href={undefined} 
          onClick={() => this.handleSave()}
          className="button">
            {translate('app.settings.save')}
        </a>
      )
    }
  }

  handleSave() {
    let { me } = this.props
    this.setState({saveRequest: true})

    let data = {
      FirstName: me.FirstName,
      LastName: me.LastName,
      UnitSystem: this.state.measurementValue
    }

    this.props.updateMeData(
      null,
      data,
      this.afterUpdate.bind(this),
      this.catchedErrors.bind(this)
    )
  }

  afterUpdate(data) {
    this.props.setMessage("Measurements", 'Change measurements successfully')
  }

  catchedErrors(message) {
    this.setState({saveRequest: false})
  }

  displaySuccessMessage() {
    let { success } = this.state

    if(success !== null && success !== "") {
      return (
        <p style={{color: "green", marginBottom: 15}}>{success}</p>
      )
    }
  }

  render() {
   let { translate } = this.props

   if(!this.state.loadingFetch ) {
     return (
       <div className="row">
         <div className="col-sm-6 col-md-4 col-md-center">
           {this.displaySuccessMessage()}
           <div className="settings-input">
             <label htmlFor="name">{translate('app.settings.measurements')} </label>
             <div className="settings-select">
               <select className="form-control" onChange={this.changeMeasurement.bind(this)} value={this.state.measurementValue}>
                 <option value={0}>Meter</option>
                 <option value={1}>Inch</option>
               </select>
             </div>
           </div>
           <div className="settings-save">
             {this.displaySaveButton()}
           </div>
         </div>
       </div>
     )
    } else {
      return (
        <div style={{marginLeft: "48%", marginTop: "5%"}}>
          <ReactLoading
            type={"spokes"}
            color={"#000000"}
            height={30} width={30}
          />
        </div>
      )
    }
  }
};

function mapStateToProps(state) {
  return ({
            me: state.current_user,
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { updateMeData })(Measurements);
