import React from 'react';

const Drinks = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width={props.width} height={props.height} viewBox="0 0 140.000000 140.000000"
     preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
      fill={props.color}  fillRule="nonzero" stroke="none">
        <path d="M414 1207 c-3 -9 -4 -26 -2 -39 3 -22 9 -24 98 -31 52 -5 96 -10 98
        -12 2 -2 10 -24 17 -49 l14 -46 -54 0 -55 0 0 -434 c0 -382 2 -436 16 -450 23
        -24 355 -24 378 0 14 14 16 68 16 450 l0 434 -109 0 -109 0 -26 84 -26 83 -87
        7 c-49 4 -105 10 -125 13 -29 4 -39 2 -44 -10z m255 -272 c11 -36 21 -68 21
        -70 0 -3 -29 -5 -65 -5 l-65 0 0 70 0 70 44 0 43 0 22 -65z m241 -5 l0 -70
        -64 0 c-70 0 -64 -6 -96 88 l-19 52 90 0 89 0 0 -70z m0 -428 c0 -234 -3 -321
        -12 -330 -17 -17 -309 -17 -326 0 -9 9 -12 96 -12 330 l0 318 175 0 175 0 0
        -318z"/>
        <path d="M775 741 c-49 -23 -100 -75 -92 -94 5 -13 126 -57 156 -57 8 0 11 26
        9 82 -3 94 -9 99 -73 69z"/>
        <path d="M633 547 c-12 -14 -43 -105 -43 -124 0 -19 88 -17 130 5 50 24 48 43
        -6 83 -68 49 -71 50 -81 36z"/>
        <path d="M735 336 c-61 -26 -62 -40 -4 -86 28 -22 59 -40 67 -38 17 3 55 105
          47 125 -6 17 -71 16 -110 -1z"/>
      </g>
    </svg>
  )
}

export default Drinks
