import React
  from 'react';
import PureImage
  from '../../components/shared/PureImage';
import { getToken }
  from '../../services/token';
import StarRatings
  from 'react-star-ratings';
import ShareWithMedia
  from "../../components/shared/modals/ShareWithMedia";
import { motion }
  from "framer-motion"
import { rateRecipe }
  from '../../actions/recipes'
import { connect }
  from 'react-redux';
import { Link }
  from "react-router-dom"
import SmallSequenceVideo
  from '../../components/shared/SmallSequenceVideo';
import { setCurrentPath }
  from '../../helpers/currentPath';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing:false,
      image:'',
      rating: props.rating + 1,
      videoActions:[],
      currentVideo: props.recipe.Videos.Short,
      videoStatus: "short",
      hovered: false,
      average: props.ratings
    };
  }

  renderAuthimg(){
    if(this.props.authImage != undefined){
      return <PureImage src={this.props.authImage} />
    }
  }

  componentDidMount(){
    window.initialModals()
    this.renderAuthimg()
  }

  displayFavoriteIcon() {
    if(!this.props.favouriteLike) {
      return (
        <div onClick={getToken ? this.props.likePress : () => setCurrentPath()}>
          <a href={undefined}  style={this.props.favourite ? {borderColor:'#ff5f01'}: {borderColor:'#e4e4e4'}}>
            <motion.svg
              initial={{ scale: 0 }}
              animate={{ rotate: 360, scale: 1 }}
              whileHover={{ scale: 1.5, rotate: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 15
              }}
              className="cookbook-like"
              xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 18 16">
              { this.props.favourite ?
                <svg className="cookbook-like" width="19px" height="15px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="favorites-cookbooks" transform="translate(-368.000000, -376.000000)" fill="#ff5f00" fillRule="nonzero"><g id="cookbooks" transform="translate(135.000000, 360.000000)"><g id="1"><g id="favorite-button" transform="translate(224.000000, 0.000000)"><path d="M22.069378,16 C24.7924853,16 27,18.2717696 27,21.0741395 C27,23.5231858 25.7969789,25.8063999 23.6091074,28.0839356 C22.5268118,29.2105863 21.0254776,30.4042451 19.1038416,31.6685345 C18.4310415,32.111185 17.5694435,32.1104324 16.8973748,31.6666072 C14.9630695,30.3892177 13.4615027,29.1963805 12.3911564,28.0842099 C10.2188103,25.8269785 9,23.517888 9,21.0741395 C9,18.2717696 11.2075147,16 13.930622,16 C15.5871187,16 17.0954923,16.8484153 18,18.2076459 C18.9045077,16.8484153 20.4128813,16 22.069378,16 Z" id="Like-1" transform="translate(18.000000, 24.000000) scale(-1, 1) translate(-18.000000, -24.000000) "></path></g></g></g></g></g></svg>
              :
                <path
                  fill={"#7a7a7a"}
                  fillRule="nonzero"
                  d="M4.971.889c1.576 0 2.988.932 3.665 2.37a.4.4 0 0 0 .728 0c.677-1.438 2.09-2.37 3.665-2.37 2.248 0 4.071 1.882 4.071 4.204 0 2.168-1.108 4.275-3.123 6.376-1.012 1.056-2.454 2.205-4.323 3.443-.4.265-.91.265-1.31.002-1.856-1.226-3.297-2.375-4.321-3.445C1.993 9.35.9 7.267.9 5.093.9 2.771 2.723.89 4.971.89zM4.931 0C2.208 0 0 2.272 0 5.074c0 2.45 1.203 4.732 3.39 7.01 1.083 1.127 2.585 2.32 4.506 3.585a1.997 1.997 0 0 0 2.207-.002c1.934-1.278 3.435-2.47 4.506-3.583C16.78 9.827 18 7.518 18 5.074 18 2.272 15.792 0 13.07 0 11.412 0 9.904.848 9 2.208 8.095.848 6.587 0 4.93 0z"
                  ></path>
              }
            </motion.svg>
          </a>
        </div>
      )
    } else {
      // return (
      //   <div style={{marginRight: 15}}>
      //     <ReactLoading
      //       type={"spokes"}
      //       color={"#000000"}
      //       height={18} width={18}
      //     />
      //   </div>
      // )
    }
  }

  renderVideoThumb(){
    if(this.props.recipe.CoverPath != undefined){
      return <PureImage src={this.props.recipe.CoverPath} />
    }
  }

  displayTags() {
    let { recipe, translate } = this.props
    if(recipe.RecipeTags.length > 0) {
      return (
        <div className="recipes-tags recipes-tags-single">
          <h1>{translate('app.recipeDetails.tags')}</h1>
          <ul>
            { recipe.RecipeTags.map((tag, index) => {
              return (
                <li key={index}>
                  <Link to={`/tags?Term=${tag.Name}`}>
                    {tag.Name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  rateRecipeRequest(newRating) {
    let { recipe } = this.props

    this.props.rateRecipe(
      recipe.Id,
      newRating,
      this.changeRate.bind(this)
    )
  }

  changeRate(result){
    this.setState({
      rating: result.UserRating + 1,
      average: result.GlobalRating
    })
  }

  changeRating( newRating, name ) {
    debugger
    this.setState({
      rating: newRating
    });

    this.rateRecipeRequest(newRating)
  }

  render(){
    let { translate, recipe } = this.props
    return(
      <section className="recipes-profile">
          <div className="container">
              <div className="row equal">
                  <div className="recipes-profile-info mg-top-130">
                      <div className="col-xs-12 col-sm-12 col-md-6">
                          <div className="recipes-profile-info-inner">
                              <h1 className="title">{this.props.title}</h1>
                              <div className="cookbooks-profile-stats">
                                  <span className="stats">
                                    <StarRatings
                                      rating={this.props.average}
                                      starRatedColor="#F5BD00"
                                      starDimension="24px"
                                      starHoverColor="#ff5f00"
                                      starSpacing="0px"
                                      numberOfStars={5}
                                      name='rating'
                                    />
                                    <span style={{marginLeft: 15}} className="number">{this.props.ratings}</span> {translate('app.recipeDetails.ratings')}</span>
                                      <span className="stats"><span className="number">{this.props.views}</span> {translate('app.recipeDetails.views')}</span>
                                      <div className="cookbook-profile-share">
                                        <ul>
                                          <li>
                                             {this.displayFavoriteIcon()}
                                          </li>
                                          <li>
                                            <a href={undefined}  data-toggle="modal" data-target="#share-with-media">
                                                <motion.svg
                                                  initial={{ scale: 0 }}
                                                  animate={{ rotate: 360, scale: 1 }}
                                                  whileHover={{ scale: 1.5, rotate: 1 }}
                                                  transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 15
                                                  }}
                                                  width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                                              >
                                                <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                  <g id="details-for-recipes" transform="translate(-1261.000000, -711.000000)" fill="#7A7A7A" fillRule="nonzero">
                                                    <g id="info" transform="translate(735.000000, 690.000000)">
                                                    <g id="share" transform="translate(526.000000, 21.000000)">
                                                      <path d="M14.9850016,11.7303767 C15.3160366,11.7303767 15.5841934,11.9947094 15.5834804,12.3203208 L15.5782319,14.7171976 C15.5778729,14.8811399 15.6470345,15.0378033 15.7690657,15.1494702 C16.0110674,15.3709182 16.3897574,15.35747 16.6148935,15.1194327 L22.5625933,8.83090634 C22.8835631,8.49154372 22.8831375,7.96583539 22.5616186,7.62697601 L16.6194951,1.36437554 C16.5063063,1.24508216 16.3478553,1.17735241 16.1819621,1.17735241 C15.8514305,1.17735241 15.5834818,1.44091172 15.5834818,1.76602861 L15.5834818,4.08782421 C15.5834818,4.4129411 15.3155331,4.67650041 14.9850016,4.67650041 C14.9163084,4.67661262 14.9163084,4.67661262 14.8476176,4.67717366 C7.42553787,4.74991944 1.43167333,10.577876 1.2036895,17.8085506 C4.69951409,13.9485357 9.71149045,11.7303767 14.9850016,11.7303767 Z M14.3865213,3.51077998 L14.3865213,1.76602861 C14.3865213,0.790677942 15.1903675,0 16.1819621,0 C16.6796418,0 17.1549948,0.203189241 17.494561,0.561069385 L23.4366846,6.82366986 C24.1868953,7.61434173 24.1878884,8.84099451 23.4389589,9.63284063 L17.4912591,15.9213669 C16.8158508,16.6354789 15.6797807,16.6758236 14.9537757,16.0114795 C14.587682,15.6764788 14.3801972,15.2064888 14.3812742,14.7146617 L14.3852085,12.9179651 C9.49844724,13.0848446 4.90120214,15.2849958 1.75035184,18.9870112 L1.06854711,19.7880812 C0.711558736,20.207516 0.0167407103,19.9630507 0.0111994782,19.416063 L0.000759618441,18.3855208 C-0.0802048941,10.3933297 6.32112849,3.82351327 14.3865213,3.51077998 Z" id="Forward-2"></path>
                                                    </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </motion.svg>
                                              </a>
                                          </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="cookbook-profile-content">
                                    <div className="cookbook-author-info">
                                      <div className="cookbook-author-info-wrap">
                                          <div className="cookbook-author-img">
                                            {this.renderAuthimg()}
                                          </div>
                                          <div className="cookbook-author-content">
                                            <h1>{this.props.author}</h1>
                                            <a href={undefined} >{translate('app.recipeDetails.view_profile')}</a>
                                          </div>
                                      </div>
                                      <div className="cookbook-profile-subscribe" onClick={getToken ? this.props.subscribe : () => setCurrentPath()}>
                                        <a href={undefined}  style={this.props.isSubscribed ? {backgroundColor:'#ff4000'}: {backgroundColor:'#ff5f00'}} className="button">
                                          {this.props.isSubscribed ? translate('app.recipeDetails.unsubscribe') : translate('app.recipeDetails.subscribe')}
                                        </a>
                                      </div>
                                    </div>
                              </div>
                              <h2>{translate('app.recipeDetails.description')}</h2>
                              <p>
                                  {this.props.description
                                  ? this.props.description
                                  : translate('app.recipeDetails.no_description_available')}
                              </p>
                          </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-6" id="video-controls">
                        <SmallSequenceVideo
                          recipe={recipe}
                          translate={translate}
                          stepByStepVideo={this.props.stepByStepVideo}
                          numOfPeople={this.props.people}
                          defineVideoSection={this.props.defineVideoSection}
                        />

                        {this.displayTags()}
                      </div>
                  </div>
              </div>
          </div>
          <ShareWithMedia
            title={this.props.recipe.Name}
            cookbook={this.props.recipe}
            translate={translate}
            match={this.props.match}
          />
      </section>
    )
  }
}

function mapStateToProps(state) {
  return ({
    people: state.numberOfPeople,
    me: state.current_user,
  })
}

export default connect(mapStateToProps,
  { rateRecipe })
  ( Profile );
