import React
  from "react";
import BackgroundImage
  from "../../../components/authors/index/BackgroundImage";
import Author
  from "../../../components/authors/index/Author";
import Author1
  from '../../../assets/img/author-1.jpg'
import AuthorServices1
  from '../../../assets/img/author-services-1.svg'
import AuthorServices2
  from '../../../assets/img/author-services-2.svg'
import AuthorServices3
  from '../../../assets/img/author-services-3.svg'
import AuthorServices4
  from '../../../assets/img/author-services-4.svg'
import { fetchAuthors }
  from '../../../helpers/author';
import Spinner
  from '../../../assets/img/eclipse-spinner.gif';
import PureImage
  from '../../../components/shared/PureImage'
import { connect }
  from 'react-redux';
import { getTranslate }
 from 'react-localize-redux';

class Authors extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = {
      authors: null,
      bestAuthors: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // fetchBestAuthors(this.setBestAuthors.bind(this))
    fetchAuthors(this.setAuthors.bind(this))
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setBestAuthors(data){
    this.setState({bestAuthors: data})
    setTimeout(function() {
      window.slider()
    }, 150);
  }

  setAuthors(data){
    this.setState({authors: data})
  }


  displayAuthors() {
    return (
      this.state.authors.map((author, index) => {
        return (
          <Author
            key={index}
            author={author}
            source={Author1}
            name={"Abdur Wade"}
            numberOfCookbooks={3}
            content={
              "“It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.”"
            }
          />
       )
      })
    )
  }

  displayServices() {
    let { translate } = this.props

    return (
      <section className="author-services section-top-90">
          <div className="container">
              <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="authors-main-title">
                          <h1>{translate('app.authors.service_title')}</h1>
                          <p>{translate('app.authors.service_desc')}</p>
                      </div>
                  </div>
              </div>
              <div className="row section-top-90">
                  <div className="col-xs-12 col-sm-3 col-md-3">
                      <div className="author-services-item">
                          <div className="author-services-icon">
                              <img src={AuthorServices1} alt="author-services" />
                          </div>
                          <div className="author-services-content">
                            <h1>{translate('app.authors.service1_title')}</h1>
                            <p>{translate('app.authors.service1_desc')}</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-3">
                      <div className="author-services-item">
                          <div className="author-services-icon">
                            <img src={AuthorServices2} alt="author-services" />
                          </div>
                          <div className="author-services-content">
                            <h1>{translate('app.authors.service2_title')}</h1>
                            <p>{translate('app.authors.service2_desc')}</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-3">
                      <div className="author-services-item">
                          <div className="author-services-icon">
                            <img src={AuthorServices3} alt="author-services" />
                          </div>
                          <div className="author-services-content">
                            <h1>{translate('app.authors.service3_title')}</h1>
                            <p>{translate('app.authors.service3_desc')}</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-3">
                      <div className="author-services-item">
                          <div className="author-services-icon">
                            <img src={AuthorServices4} alt="author-services" />
                          </div>
                          <div className="author-services-content">
                            <h1>{translate('app.authors.service4_title')}</h1>
                            <p>{translate('app.authors.service4_desc')}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    )
  }

  setCurrentItem(itemId) {
    if(this._isMounted) {
      this.setState({currentItem: itemId})
    }
  }

  displayAuthorItems() {
    let { bestAuthors } = this.state
    let { translate } = this.props

    return (
      bestAuthors && bestAuthors.map((item, index) => {
        return(
          <div className="item" key={item.Id}>
            <PureImage src={item.ImagePath} alt="bg-explorer" />
            <div className="container">
              <div className="content">
                <h1>{item.Name}</h1>
                <p>{translate('app.authors.explore_chefs')}</p>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  sliderImages(){
    let { bestAuthors } = this.state
    return bestAuthors && bestAuthors.map((item, index) => {
      return(
        <div key={index} className="item" onClick={()=> this.setCurrentItem(index)}>
          <PureImage src={item.ImagePath} alt="bg-explorer" />
          <div className="content">
            <h1>{item.Name}</h1>
          </div>
        </div>
      )
    })
  }

  render() {
    let { translate } = this.props

    if(this.state.authors == null) {
      return (
        <div style={{paddingLeft: "45%", paddingBottom: "15%", paddingTop: "15%"}}>
          <img src={Spinner} alt="logo" width="200px"/>
        </div>
      )
    }

    return (
      <>
        <BackgroundImage
          translate={translate}
        />
        <section className="authors section-top-120">
          <div className="container">
            <div className="row">
              {this._isMounted && this.displayAuthors()}
            </div>
          </div>
        </section>
        {this.displayServices()}
      </>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, { })(Authors);
